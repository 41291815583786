import React, { useState } from 'react'
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { ViewSwitcher } from "./view-switcher";
import { getStartEndDateForProject, initTasks } from "./helper";
import moment from "moment";
import axios from "../../../services/axios"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap';
import swal from 'sweetalert';
import './style.css'

function GanttChart({ recommendations, visit, categories, updateRecommendationsList, editMode = false }) {
    const [view, setView] = React.useState(ViewMode.Year);
    const [modal, setModal] = React.useState(false);
    const [role, setRole] = React.useState('')
    const [initialState, setInitialState] = React.useState({
        name: '',
        start: '',
        end: '',
        progress: '',
        type: '',
        category_id: "",
        status_remarks: '',
        budget: '',
        visit_id: visit.visit_id,
        recommendation_id: ''
    })
    const [activeObj, setActiveObj] = React.useState(initialState);
    const toggle = (type = '', edit = false) => {
        if (type != '') setActiveObj({...activeObj, type: type, visit_id: initialState.visit_id, edit: edit })
        if (modal == true) setActiveObj(initialState)
        setModal(!modal)
    };


    const getRProgress = (recommendation) => {
        if (recommendation.action_plans.length < 1) return 0
        return recommendation.action_plans.reduce((a, b) => a + getSingleProgress(b.activity_status), 0) / recommendation.action_plans.length //percentage of all action plans
    }
    const getSingleProgress = (status) => {
        switch (status.toLowerCase()) {
            case "25%":
                return 25
            case "50%":
                return 50
            case "partially completed":
                return 50
            case "75%":
                return 75
            case "100%":
                return 100
            case "completed":
                return 100
            default:
                return 0
        }
    }

    const checkRecommendationDates = (recommendation) => {
        let startDate = '2022-01-01'
        let endDate = '2022-01-01'
        const action_plans = recommendation.action_plans
        if (!action_plans || action_plans.length < 1) return { startDate, endDate }
        startDate = recommendation.action_plans[0].activity_start_date
        endDate = recommendation.action_plans[0].activity_finish_date
        action_plans.forEach(ap => {
            const start = ap.activity_start_date
            const finish = ap.activity_finish_date
            if (moment(start).isBefore(moment(startDate))) startDate = start
            if (moment(finish).isAfter(moment(endDate))) endDate = finish
        })
        return { startDate, endDate }
    }

    const [tasks, setTasks] = React.useState([]);

    React.useEffect(() => {
        setRole(sessionStorage.getItem("urole"))
        if (visit.visit_id) {
            setInitialState({ ...initialState, visit_id: visit.visit_id })
        }
        let tasks = []
        let displayOrder = 1
        recommendations.forEach(r => {
            let projectTasks = []
            if (r.action_plans.length > 0) {
                r.action_plans.sort((a, b) => new Date(a.activity_start_date) - new Date(b.activity_start_date))
                    .forEach((ap, index) => {
                        const isTaskLate = new Date(ap.activity_finish_date) <= new Date() && getSingleProgress(ap.activity_status) < 100
                        projectTasks.push(
                            {
                                start: new Date(ap.activity_start_date),
                                end: new Date(ap.activity_finish_date),
                                name: ap.activity_name,
                                id: ap.action_plan_id,
                                progress: getSingleProgress(ap.activity_status),
                                type: "task",
                                project: r.recommendation_id,
                                budget: ap.activity_budget,
                                status_remarks: ap.status_remarks,
                                visit_id: r.visit_id,
                                recommendation_id: r.recommendation_id,
                                displayOrder: displayOrder + index + 1,
                                styles: { backgroundSelectedColor: '#6c757d', progressColor: isTaskLate ? 'tomato' : '#5cb876', progressSelectedColor: isTaskLate ? 'tomato' : '#5cb876' }
                            })
                    })
            }
            const { startDate, endDate } = checkRecommendationDates(r)
            const isLate = new Date(endDate) <= new Date() && getRProgress(r) < 100
            tasks = [...tasks, {
                start: new Date(startDate),
                end: new Date(endDate),
                name: r.recommendation_description,
                id: r.recommendation_id,
                progress: getRProgress(r),
                type: "project",
                hideChildren: false,
                displayOrder: displayOrder,
                category_id: r.category_id,
                visit_id: r.visit_id,
                styles: { backgroundSelectedColor: '#4452cc', backgroundColor: '#4452cc', progressColor: isLate ? 'tomato' : '#5cb876', progressSelectedColor: isLate ? 'tomato' : '#5cb876' }
            }, ...projectTasks]
            displayOrder += r.action_plans.length + 1
        })
        setTasks(tasks)
    }, [getRProgress, initialState, recommendations, visit.visit_id])
    const [isChecked, setIsChecked] = React.useState(true);
    let columnWidth = 65;
    if (view === ViewMode.Year) {
        columnWidth = 350;
    } else if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    const handleTaskChange = (task) => {
        updateRecommendationsList()
    };

    const handleTaskDelete = (task) => {
        updateRecommendationsList()
    };

    const handleProgressChange = async (task) => {
        updateRecommendationsList()
    };

    const handleClick = (task) => {
        if (task.type === 'project') return
        setActiveObj({...task, edit: true});
        toggle('', true);
    };

    const handleExpanderClick = (task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    return (
        <div>
            {modal &&
                <RecommendationModal editMode={editMode} edit={activeObj.edit} obj={activeObj} recommendations={recommendations} updateChart={updateRecommendationsList} categories={categories} dismissModal={() => toggle()} />
            }
            <br></br>
            <br></br>
            <h3>Action Plan {visit.school_name ? `for ${visit.school_name} visit conducted on ${visit.present_visitation_date}` : ''}</h3>
            <br></br>
            <Row>
                <Col md={6}>
                    {editMode &&
                        <>

                            {/* {role == "Inspector" &&
                                <button onClick={() => toggle('project')} className="btn btn-primary">Add Recommendation</button>
                            } */}
                            &nbsp;
                            &nbsp;
                            {["Head Teacher", "Secondary Advisor", "PEA"].includes(role) &&
                                <button onClick={() => toggle('task', false)} className="btn btn-primary">Add Action</button>
                            }
                            <br></br>
                            <br></br>
                        </>
                    }
                </Col>
                <Col md={6} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <ViewSwitcher
                        onViewModeChange={viewMode => setView(viewMode)}
                        onViewListChange={setIsChecked}
                        isChecked={isChecked}
                    />
                </Col>
                <Col md={12} id="gantt-div">
                    <br></br>
                    {tasks.length > 0 &&
                        <Gantt
                            tasks={tasks}
                            viewMode={view}
                            onDateChange={handleTaskChange}
                            onDelete={handleTaskDelete}
                            onProgressChange={handleProgressChange}
                            onClick={handleClick}
                            onExpanderClick={handleExpanderClick}
                            listCellWidth={isChecked ? "100px" : ""}
                            columnWidth={columnWidth}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
}

const RecommendationModal = ({ obj, edit, categories, recommendations, updateChart, dismissModal, editMode }) => {
    const [modal, setModal] = useState(true);
    const [form, setForm] = useState(obj);

    const deleteObj = () => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to revert",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (form.type == 'project') {
                        axios.delete(`/recommendations/major/delete/${form.id}`)
                            .then(handleResponse)
                    } else {
                        axios.delete(`/action_plans/delete/${form.id}`)
                            .then(response => {
                                dismissModal()
                                if (response.statusText == 'OK') {
                                    swal({
                                        icon: 'success',
                                        text: `Successfully Deleted`,
                                        timer: 2500
                                    }).then(updateChart)
                                }
                            })
                    }
                }
            });
    }

    const getProgress = (num) => {
        switch (parseInt(num)) {
            case 25:
                return '25%'
            case 50:
                return '50%'
            case 75:
                return '75%'
            case 100:
                return '100%'
            default:
                return '0%'
        }
    }
    const validation = {
        name: '',
        start: '',
        end: '',
        type: "",
        progress: '',
        category_id: '',
        budget: '',
        status_remarks: '',
        displayOrder: ''
    }

    React.useEffect(() => {
        console.log(edit)
    },[])

    const onChange = (key, value) => {
        setForm({ ...form, [key]: value })
    }

    const handleResponse = (response) => {
        dismissModal()
        if (response.statusText == 'OK') {
            swal({
                icon: 'success',
                text: `Successfully ${form.id ? 'Updated' : 'Created'}`,
                timer: 2500
            }).then(updateChart)
        }
    }

    const handlePost = () => {
        if (form.type == 'project') {
            const recommendation = {
                recommendation_description: form.name,
                recommendation_type: 'Major',
                category_id: form.category_id,
                visit_id: obj.visit_id,
                nes_id: form.category_id
            }
            axios.post(`/recommendations/major`, recommendation)
                .then(handleResponse)
        } else {
            const action_plan = {
                activity_name: form.name,
                activity_start_date: form.start,
                activity_finish_date: form.end,
                activity_status: 'Open',
                activity_budget: form.budget,
                recommendation_id: form.recommendation_id,
                priority_id: 1,
                visit_id: obj.visit_id,
                status_remarks: form.status_remarks
            }
            axios.post(`/action_plans`, action_plan)
                .then(handleResponse)
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (obj.name.length < 1) return handlePost()
        if (form.type == 'project') {
            const recommendation = {
                recommendation_description: form.name,
                category_id: form.category_id
            }
            axios.patch(`/recommendations/major/update/${form.id}`, recommendation)
                .then(handleResponse)
        } else {
            const action_plan =
            {
                activity_name: form.name,
                activity_start_date: form.start,
                activity_finish_date: form.end,
                activity_status: getProgress(form.progress),
                activity_budget: form.budget,
                status_remarks: form.status_remarks
            }
            axios.patch(`/action_plans/update/${form.id}`, action_plan)
                .then(handleResponse)
        }
    }

    return (
        <div>
            <Modal isOpen={modal}>
                <Form onSubmit={handleFormSubmit}>
                    <ModalHeader>
                        {edit && ['PEA', 'Secondary Advisor'].includes(sessionStorage.getItem('urole')) ? 'Edit Action' : edit ? 'Action Details' : 'Add New Action'}
                        {
                            new Date(obj.end) <= new Date() && obj.progress < 100 &&
                            <p className="text-danger">
                                This Action is Behind Schedule
                            </p>
                        }
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                {/* <FormGroup>
                                    <Label for="type">
                                        Type
                                    </Label>
                                    <Input
                                        disabled={(form.type.length > 0 || false) && !editMode}
                                        id="type"
                                        type="select"
                                        value={form.project ? 'Action Plan' : 'Recommendation'}
                                        required
                                        onChange={(e) => onChange('type', e.target.value)}
                                    >
                                        <option>
                                            Recommendation
                                        </option>
                                        <option>
                                            Action Plan
                                        </option>
                                    </Input>
                                    <FormFeedback>
                                        {validation.type || ''}
                                    </FormFeedback>
                                </FormGroup> */}
                            </Col>
                           
                            {form.type == 'project' &&
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="category">
                                            Nes Category
                                        </Label>
                                        <Input value={form.category_id} type="select" name="nes" id="nes" onChange={(e) => onChange('category_id', e.target.value)} required>
                                            <option></option>
                                            {
                                                categories.map(category => (
                                                    <option value={category.category_id} key={category.category_id}>{category.category_name}</option>
                                                )
                                                )
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                            }
                            {form.type == 'task' &&
                                <>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="category">
                                                Recommendation
                                            </Label>
                                            <Input disabled={edit && ['PEA', 'Secondary Advisor'].includes(sessionStorage.getItem('urole')) || !editMode} value={form.recommendation_id} type="select" name="nes" id="nes" onChange={(e) => onChange('recommendation_id', e.target.value)} required>
                                                <option></option>
                                                {
                                                    recommendations.map(r => (
                                                        <option value={r.recommendation_id} key={r.recommendation_id}>{r.recommendation_description}</option>
                                                    )
                                                    )
                                                }
                                            </Input>
                                        </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="name">
                                            Description
                                        </Label>
                                        <Input type="textarea" disabled={!editMode} required value={form.name} onChange={(e) => onChange('name', e.target.value)} />
                                        <FormFeedback>
                                            {validation.name || ''}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="start">
                                                Start Date
                                            </Label>
                                            <Input disabled={!editMode} required type="date" value={moment(form.start).format('YYYY-MM-DD') || ''} onChange={(e) => onChange('start', e.target.value)} />
                                            <FormFeedback>
                                                {validation.start || ''}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="end">
                                                End Date
                                            </Label>
                                            <Input disabled={!editMode} required type="date" value={moment(form.end).format('YYYY-MM-DD') || ''} onChange={(e) => onChange('end', e.target.value)} />
                                            <FormFeedback>
                                                {validation.end || ''}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="end">
                                                Budget
                                            </Label>
                                            <Input disabled={!editMode} type="number" value={form.budget || ''} onChange={(e) => onChange('budget', e.target.value)} />
                                            <FormFeedback>
                                                {validation.budget || ''}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="type">
                                                Progress
                                            </Label>
                                            <Input
                                                disabled={!editMode}
                                                id="progress"
                                                type="select"
                                                value={form.progress}
                                                onChange={(e) => onChange('progress', e.target.value)}
                                            >
                                                <option key={0} value="0">
                                                    0%
                                                </option>
                                                <option key={25} value="25">
                                                    25%
                                                </option>
                                                <option key={50} value="50">
                                                    50%
                                                </option>
                                                <option key={75} value="75">
                                                    75%
                                                </option>
                                                <option key={100} value="100">
                                                    100%
                                                </option>
                                            </Input>
                                            <FormFeedback>
                                                {validation.progress || ''}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="end">
                                                Remarks
                                            </Label>
                                            <Input disabled={!editMode} type="textarea" value={form.status_remarks || ''} onChange={(e) => onChange('status_remarks', e.target.value)} />
                                            <FormFeedback>
                                                {validation.status_remarks || ''}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {editMode == true &&
                            <Button submit color="primary">
                                Submit
                            </Button>
                        }
                        {' '}
                        <Button color="secondary" onClick={dismissModal}>
                            Cancel
                        </Button>
                        {obj.name.length > 0 && editMode == true &&
                            <Button color="danger" onClick={deleteObj}>
                                Delete
                            </Button>
                        }
                    </ModalFooter>
                </Form>
            </Modal>
        </div >
    )
}

export default GanttChart