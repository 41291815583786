import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Table, TableBody, TableHead, Container, Row, FormFeedback, Media, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import PDF, { Text, AddPage, Line, Image, Html } from 'jspdf-react';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";
import poor from "../../../assets/images/poor.png";
import vgood from "../../../assets/images/vgood.png";
import excellent from "../../../assets/images/excellent.png";
import good from "../../../assets/images/good.png";
import ReactApexChart from 'react-apexcharts';
import ApexCharts from "apexcharts";
import { PictureAsPdf, Print } from "@material-ui/icons"

import { Add, Edit, Delete, ArrowLeft } from '@material-ui/icons';
import swal from 'sweetalert';

import { saveAs } from "file-saver";
import * as docx from "docx";
import moment from "moment";

import NesAchievementGraph from '../KeyEvidence/NesAchievementGraph';
import KeyEvidenceOnVisits from './KeyEvidenceOnVisits';


import jsPDF from 'jspdf';

import html2canvas from "html2canvas";
//import 'jspdf-autotable'
import autoTable from 'jspdf-autotable';

import html2PDF from 'jspdf-html2canvas';


import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class InspectionPerformanceReport extends Component {
	constructor(props) {
		super(props);
		//const visit_id=this.props.match.params.visit_id;
		// console.log(props)
		const visit_id = this.props.match.params.visit_id;
		const isExpired = this.props.match.params.isExpired;
		const logged_user_id = this.props.match.params.logged_user;
		const lead_inspector_id = this.props.match.params.lead_inspector;
		const first_inspector_id = this.props.match.params.first_inspector;


		this.state = {
			y_space: 10,
			visit_id: visit_id,
			isExpired: isExpired,
			logged_user_id: logged_user_id,
			lead_inspector_id: lead_inspector_id,
			first_inspector_id: first_inspector_id,
			School_Data: [],
			school_head: '',
			lead_inspector: '',
			first_inspector: '',
			second_inspector: '',
			third_inspector: '',
			fourth_inspector: '',
			zone_name: '',
			district_name: '',
			establishment: '',
			est_year: '',
			post_address: '',
			full_insp_date: '',
			prev_insp_date: '',
			enrolment: '',
			enrolment_boys: '',
			enrolment_girls: '',
			attendance: '',
			attendance_boys: '',
			attendance_girls: '',
			total_teachers: '',
			non_qualified_teachers: '',
			admin_phone: '',
			publication_date: '',
			action_plan_date: '',
			next_inspection_date: '',
			school_name: '',
			sector_name: '',
			division_name: '',
			emis_id: '',
			visit_id: visit_id,
			Strengths: [],
			Weaknesses: [],
			GoodPractices: [],
			CriticalIssues: [],
			//Major
			Recommendations: [],
			//Minor
			MinorRecommendation: [],
			Standards: [],
			KeyEvidence: [],
			LevelAchieved: [],
			TrackEvidence: [],
			bearer_auth: window.sessionStorage.getItem('token'),
			loading: '',

		};

		this.generatePDF = this.generatePDF.bind(this);
		this.printPDF = this.printPDF.bind(this);
		this.emailReport = this.emailReport.bind(this);
		this.incrementYSpace = this.incrementYSpace.bind(this);
	}

	componentDidMount() {

		this.setState({ loading: true });

		Promise.all([axios.get(API_URL + `/inspection_report/school_data/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/inspection_report/admin/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/inspection_report/lead_inspector/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/inspection_report/first_inspector/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/inspection_report/second_inspector/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/inspection_report/third_inspector/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/inspection_report/fourth_inspector/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/strengths/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/weaknesses/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/good_practices/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/critical_issues/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/recommendations/major/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/track_evidences`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/key_evidences`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/key_evidences/nes_level_achieved/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		axios.get(API_URL + `/recommendations/minor/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		]).then(([school_data, head_data, lead_insp_data, first_insp_data, second_insp_data, third_insp_data, fourth_insp_data, strength, weakness, good_practice, critical_issue, recommendation, standard, track_evidence, key_evidence, level_achieved, minor_recommendation]) => {
			//console.log(third_insp_data.data.length);
			//console.log(fourth_insp_data.data.length);
			this.setState({
				school_name: school_data.data[0]['school_name'],
				emis_id: school_data.data[0]['emis_id'],
				zone_name: school_data.data[0]['zone_name'],
				sector_id: school_data.data[0]['sector_id'],
				district_name: school_data.data[0]['district_name'],
				division_name: school_data.data[0]['division_name'],
				sector_name: school_data.data[0]['sector_name'],
				establishment: school_data.data[0]['establishment'],
				est_year: school_data.data[0]['establishment_year'],
				post_address: school_data.data[0]['school_address'],
				full_insp_date: new Date(school_data.data[0]['present_visitation_date']).toLocaleDateString(),
				prev_insp_date: new Date(school_data.data[0]['prev_visitation_date']).toLocaleDateString(),
				enrolment: parseInt(school_data.data[0]['enrolment_boys']) + parseInt(school_data.data[0]['enrolment_girls']),
				enrolment_boys: school_data.data[0]['enrolment_boys'],
				enrolment_girls: school_data.data[0]['enrolment_girls'],
				attendance: parseInt(school_data.data[0]['attendance_boys']) + parseInt(school_data.data[0]['attendance_girls']),
				attendance_boys: school_data.data[0]['attendance_boys'],
				attendance_girls: school_data.data[0]['attendance_girls'],
				total_teachers: school_data.data[0]['number_of_teachers'],
				non_qualified_teachers: school_data.data[0]['unqualified_teachers'],
				admin_phone: school_data.data[0]['phone_number'],
				publication_date: new Date(school_data.data[0]['publication_date']).toLocaleDateString(),
				action_plan_date: new Date(school_data.data[0]['action_plan_date']).toLocaleDateString(),
				next_inspection_date: new Date(school_data.data[0]['next_inspection_date']).toLocaleDateString(),
				school_head: school_data.data[0]['head_teacher'],
				lead_inspector: lead_insp_data.data[0]['full_name'],
				first_inspector: first_insp_data.data[0]['full_name'],
				second_inspector: second_insp_data.data[0]['full_name'],
				third_inspector: third_insp_data.data.length != 0 ? third_insp_data.data[0]['full_name'] : 'None',
				fourth_inspector: fourth_insp_data.data.length != 0 ? fourth_insp_data.data[0]['full_name'] : 'None',
				Strengths: strength.data,
				Weaknesses: weakness.data,
				GoodPractices: good_practice.data,
				CriticalIssues: critical_issue.data,
				Recommendations: recommendation.data,
				MinorRecommendation: minor_recommendation.data,
				Standards: standard.data,
				TrackEvidence: track_evidence.data,
				KeyEvidence: key_evidence.data,
				LevelAchieved: level_achieved.data,
				loading: false

			})



		}
		).catch(error => {
			this.setState({ loading: false });

			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	incrementYSpace(num) {
		if ((num + this.state.y_space) > 225) {
			this.setState({
				...this.state,
				y_space: 10
			})
			return false
		}
		this.setState({
			...this.state,
			y_space: this.state.y_space + num
		})
		console.log(this.state.y_space)
		return true
	}


	generatePDF() {
		this.setState({ loading: true })
		const state = this
		html2PDF(document.getElementsByClassName('page'), {
			jsPDF: {
				format: 'a4',
			},
			imageQuality: 1,
			margin: {
				top: 20,
				right: 20,
				bottom: 20,
				left: 20,
			},
			imageType: 'image/jpeg',
			output: `PERFOMACE REPORTS ${this.state.school_name} ${this.state.full_insp_date}.pdf`,
			init: function () { },
			success: function (pdf) {
				state.setState({ loading: false })
				pdf.save(this.output);
			}
		});



		// 	window.html2canvas = html2canvas;

		// 	const page_number = 0;

		// 	var doc = new jsPDF();
		// 	doc.setProperties({
		// 		title: 'COMPREHENSIVE REPORT',
		// 		subject: 'Inspection Report',
		// 		author: 'ISMAS',
		// 		keywords: 'Inspection, Report, NES',
		// 		creator: 'ISMAS'
		// 	});

		// 	const width = document.getElementById("report").clientWidth;
		// 	const height = document.getElementById("report").clientHeight;

		// 	console.log("width", width);
		// 	console.log("height", height);

		// 	doc.addImage(logo, 'png', 83, 10);
		// 	doc.setFontSize("16");
		// 	doc.setFont('times', 'normal', 'bold')
		// 	doc.text('INSPECTION REPORT AT ' + this.state.school_name.toUpperCase() + ' ' + this.state.sector_name.toUpperCase() + ' SCHOOL', 105, 55, null, null, "center");

		// 	doc.line(1, 56, 208, 57);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 65, "HEAD TEACHER");
		// 	doc.text(100, 65, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 65, this.state.school_head);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 75, "DIVISION");
		// 	doc.text(100, 75, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 75, this.state.division_name);



		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 85, "DISTRICT");
		// 	doc.text(100, 85, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 85, this.state.district_name);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 95, this.state.sector_id == 1 ? 'Zone Name' : this.state.sector_id == 2 ? 'Cluster Name' : 'Zone/Cluster Name');
		// 	doc.text(100, 95, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 95, this.state.zone_name);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 105, "ESTABLISHMENT");
		// 	doc.text(100, 105, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 105, this.state.establishment);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 115, "YEAR OF ESTABLISHMENT");
		// 	doc.text(100, 115, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 115, this.state.est_year);


		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 125, "POSTAL ADDRESS");
		// 	doc.text(100, 125, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 125, this.state.post_address);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 135, "FULL INSPECTION DATE");
		// 	doc.text(100, 135, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 135, this.state.full_insp_date);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 145, "DATE OF PREVIOUS INSPECTION");
		// 	doc.text(100, 145, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 145, this.state.prev_insp_date);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 155, "ENROLMENT");
		// 	doc.text(100, 155, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 155, "" + this.state.enrolment + "");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 165, "QUALIFIED TEACHERS");
		// 	doc.text(100, 165, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 165, this.state.total_teachers);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 175, "UNQUALIFIED TEACHERS");
		// 	doc.text(100, 175, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 175, this.state.non_qualified_teachers);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 185, "LEAD INSPECTOR");
		// 	doc.text(100, 185, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 185, this.state.lead_inspector);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 195, "INSPECTION TEAM");
		// 	doc.text(100, 195, ":");
		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 195, '1.' + this.state.first_inspector);
		// 	doc.text(135, 200, '2.' + this.state.second_inspector);
		// 	doc.text(135, 205, this.state.third_inspector != '' ? '3.' + this.state.third_inspector : '3. None');
		// 	doc.text(135, 210, this.state.fourth_inspector != '' ? '4.' + this.state.fourth_inspector : '4. None');

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 220, "HEAD TEACHER's PHONE");
		// 	doc.text(100, 220, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 220, this.state.admin_phone);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 225, "DATE FOR REPORT PUBLICATION");
		// 	doc.text(100, 225, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 225, this.state.publication_date);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 235, "DATE OF EXPECTED ACTION PLAN");
		// 	doc.text(100, 235, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 235, this.state.action_plan_date);

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 245, "EXPECTED RE-INSPECTION DATE");
		// 	doc.text(100, 245, ":");
		// 	doc.setFont("times", "normal", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(135, 245, this.state.next_inspection_date);


		// 	doc.addPage();

		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 30, "1.0. BACKGROUND TO THE SCHOOL");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 40, this.state.school_name + " " + this.state.sector_name + " school opened its doors in " + this.state.est_year + ". Currently the school has an enrolment");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 45, "of " + this.state.enrolment + " of which " + this.state.enrolment_boys + " are boys and " + this.state.enrolment_girls + " are girls. On this day of inspection " + this.state.attendance + " students attended classes.");


		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 50, "Out of these students " + this.state.attendance_boys + " are boys and " + this.state.attendance_girls + " are girls.");


		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 65, "2.0 THE PURPOSE OF INSPECTION AND THIS REPORT");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 75, "The purpose of this inspection and this report is to evaluate the quality of education provided to the school and to");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 80, "make Recommendations on how it should be  improved." + this.state.school_name + ' ' + this.state.sector_name + " school was inspected");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 85, "on " + this.state.full_insp_date + ",by a team of at least" + 3 + " inspectors led by " + this.state.lead_inspector + ". The inspection team observed ");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 90, " lessons, carried out interviews with different stakeholders, scrutinized learners work in exercise books and then");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 95, "checked administrative and teaching records. The information contained in the Pre-Inspection Self Assessment");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 100, "Document (PISAD)completed by the school and data from the National Education Management System were taken");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 105, "into account also. The evaluations in this report were made against the National Education Standards (NES).On");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 110, "receipt of this report the school, should build on its strengths, and then act on its shortfalls and meet the");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 115, "Recommendations set down at the end of this report. Thereafter,the school will produce an Action Plan");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 120, "in order to guide its work on improvement.  As a result students’");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 125, "learning will improve and they will achieve better outcomes for their own benefits and that of the nation.");

		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, 140, "3.0 SCHOOL'S PERFORMANCE AGAINST NATIONAL EDUCATION STANDARDS");

		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, 150, "Graphical Summary of " + this.state.school_name.toUpperCase() + " " + this.state.sector_name.toUpperCase() + " SCHOOL'S inspection performance against NES:");



		// 	doc.addImage(imgURI, 'PNG', 5, 155, 150, 100);

		// 	doc.addPage();

		// 	//NES, Tables, Achieve
		// 	let Standards = this.state.Standards;

		// 	//console.log(Standards)

		// 	//Loop though the standards
		// 	for (let i = 0; i < Standards.length; i++) {

		// 		//Track Evidence algo
		// 		let TrackEvidence = this.state.TrackEvidence;
		// 		//    this.state.y_space = this.state.y_space + 5;

		// 		//console.log("TrackEvidence");
		// 		//console.log(TrackEvidence);

		// 		let control_page = 0;


		// 		//Loop through track evidence
		// 		for (let t = 0; t < TrackEvidence.length; t++) {
		// 			if (TrackEvidence[t]['nes_id'] == Standards[i]['nes_id'] && TrackEvidence[t]['visit_id'] == this.state.visit_id) {
		// 				// this.state.y_space = this.state.y_space + 10;
		// 				if (!this.incrementYSpace(15)) doc.addPage()
		// 				doc.text(10, this.state.y_space, Standards[i]['nes_name']);

		// 				//Level Achived
		// 				// this.state.y_space = this.state.y_space + 10;
		// 				if (!this.incrementYSpace(10)) doc.addPage()
		// 				let LevelAchieved = this.state.LevelAchieved;

		// 				for (let l = 0; l < LevelAchieved.length; l++) {
		// 					//const pageWidth = doc.internal.pageSize.getWidth();
		// 					//   this.state.y_space = this.state.y_space + 10;

		// 					if (LevelAchieved[l]['level'] == 1 && LevelAchieved[l]['nes_id'] == Standards[i]['nes_id']) {
		// 						doc.addImage(poor, 'png', 14, this.state.y_space - 4, 5, 5);
		// 						doc.text(20, this.state.y_space, "This NES Does not meet minimum standards");
		// 					} else if (LevelAchieved[l]['level'] == 2 && LevelAchieved[l]['nes_id'] == Standards[i]['nes_id']) {
		// 						doc.addImage(good, 'png', 14, this.state.y_space - 4, 5, 5);
		// 						doc.text(20, this.state.y_space, "This NES Meets minimum standards");
		// 					} else if (LevelAchieved[l]['level'] == 3 && LevelAchieved[l]['nes_id'] == Standards[i]['nes_id']) {
		// 						doc.addImage(vgood, 'png', 14, this.state.y_space - 4, 5, 5);
		// 						doc.text(20, this.state.y_space, "This NES Exceeds minimum standards");
		// 					} else if (LevelAchieved[l]['level'] == 4 && LevelAchieved[l]['nes_id'] == Standards[i]['nes_id']) {
		// 						doc.addImage(excellent, 'png', 14, this.state.y_space - 4, 5, 5);
		// 						doc.text(20, this.state.y_space, "This NES have an Effective Practice");
		// 					}
		// 				}


		// 				let KeyEvidence = this.state.KeyEvidence;
		// 				let MinorRecommendation = this.state.MinorRecommendation;

		// 				//console.log("KeyEvidence");
		// 				//console.log(KeyEvidence);

		// 				const ke_data = [];
		// 				for (let ti = 0; ti < KeyEvidence.length; ti++) {

		// 					for (let idx = 0; idx < MinorRecommendation.length; idx++) {
		// 						//Status
		// 						if (KeyEvidence[ti]['visit_id'] == MinorRecommendation[idx]['visit_id'] && KeyEvidence[ti]['nes_id'] == MinorRecommendation[idx]['nes_id'] && KeyEvidence[ti]['nes_id'] == Standards[i]['nes_id'] && KeyEvidence[ti]['visit_id'] == this.state.visit_id) {

		// 							let ke_list_data = [];
		// 							if (KeyEvidence[ti]['key_evidence_status'] == 'Positive') {
		// 								ke_list_data.push(Standards[i]['nes_id'] + "." + KeyEvidence[ti]['requirement_id'] + " " + KeyEvidence[ti]['requirement_name']);
		// 								ke_list_data.push("+");
		// 								ke_list_data.push(KeyEvidence[ti]['key_evidence_description']);
		// 								(ti != 0) ? (KeyEvidence[ti]['nes_name'] != KeyEvidence[ti - 1]['nes_name']) ? ke_list_data.push(MinorRecommendation[idx]['recommendation_description']) : ke_list_data.push('') : ke_list_data.push(MinorRecommendation[idx]['recommendation_description']);

		// 							} else if (KeyEvidence[ti]['key_evidence_status'] == 'Negative') {

		// 								ke_list_data.push(Standards[i]['nes_id'] + "." + KeyEvidence[ti]['requirement_id'] + " " + KeyEvidence[ti]['requirement_name']);
		// 								ke_list_data.push("-");
		// 								ke_list_data.push(KeyEvidence[ti]['key_evidence_description']);
		// 								(ti != 0) ? (KeyEvidence[ti]['nes_name'] != KeyEvidence[ti - 1]['nes_name']) ? ke_list_data.push(MinorRecommendation[idx]['recommendation_description']) : ke_list_data.push('') : ke_list_data.push(MinorRecommendation[idx]['recommendation_description']);

		// 							}
		// 							//console.log("ke_list_data");
		// 							//console.log(ke_list_data);

		// 							ke_data.push(ke_list_data);


		// 						}
		// 					}


		// 				}

		// 				//console.log("ke_data");
		// 				//console.log(ke_data);
		// 				if (!this.incrementYSpace(10)) doc.addPage()
		// 				doc.autoTable({
		// 					startY: this.state.y_space,
		// 					head: [['Requirement', 'Status', 'Evidence', 'Minor Recommendation']],
		// 					body: ke_data
		// 				});
		// 				// console.log(ke_data);
		// 				if (ke_data.length > 0) {
		// 					if (!this.incrementYSpace(15 * ke_data.length)) doc.addPage()
		// 					// console.log('incrementYSpace:', this.state.y_space)
		// 				}
		// 				else {
		// 					if (!this.incrementYSpace(10)) doc.addPage()
		// 				}
		// 				//Add page 
		// 				// doc.addPage();



		// 			}



		// 		}



		// 	}







		// 	//    doc.addPage(); // add new page in pdf
		// 	if (!this.incrementYSpace(20)) doc.addPage()
		// 	// console.log("Major strengths", this.state.y_space)
		// 	// this.state.y_space = this.state.y_space + 20
		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, this.state.y_space, "4.0 MAJOR STRENGTHS");

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(10)) doc.addPage()
		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, this.state.y_space, "These strengths are based on strengths seen during the inspection and recorded earlier in this report.");

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(5)) doc.addPage()
		// 	let Strengths = this.state.Strengths;


		// 	const strength_data = [];

		// 	for (let i = 0; i < Strengths.length; i++) {
		// 		// this.state.y_space = this.state.y_space + 5;
		// 		if (!this.incrementYSpace(5)) doc.addPage()

		// 		doc.text(15, this.state.y_space, parseInt(i + 1) + ". " + Strengths[i]['strength_description']);
		// 	}

		// 	/* for(let i=0; i<Strengths.length;i++){
		// 		const strong_list_data = [];
		// 		//strong_list_data.push(Strengths[i]['nes_name']);
		// 		strong_list_data.push(Strengths[i]['strength_description']);

		// 		strength_data.push(strong_list_data);
		// 	}
		// 	doc.autoTable({
		// 	  startY: 45,
		// 	  //head: [['DESCRIPTION(S)']],
		// 	  head: [['']],
		// 	  body: strength_data
		// 	}) */

		// 	// this.state.y_space = this.state.y_space + 20
		// 	if (!this.incrementYSpace(20)) doc.addPage()
		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, this.state.y_space, "5.0 MAJOR WEAKNESSES");

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(10)) doc.addPage()
		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, this.state.y_space, "These weaknesses are based on weaknesses seen during the inspection and recorded earlier in this report.");

		// 	/* this.state.Weaknesses.map(weakness=>(
		// 			 <tr>
		// 				 <td>
		// 					 {weakness.nes_name}
		// 				 </td>
		// 				 <td>{weakness.weakness_description}</td>
		// 				 </tr>
		// 				 )) */

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(5)) doc.addPage()
		// 	let Weaknesses = this.state.Weaknesses;

		// 	for (let i = 0; i < Weaknesses.length; i++) {
		// 		// this.state.y_space = this.state.y_space + 5;
		// 		if (!this.incrementYSpace(5)) doc.addPage()

		// 		doc.text(15, this.state.y_space, parseInt(i + 1) + ". " + Weaknesses[i]['weakness_description']);
		// 	}

		// 	/* const weakness_data = [];

		// 	for(let i=0; i<Weaknesses.length;i++){
		// 		const weak_list_data = [];
		// 		//weak_list_data.push(Weaknesses[i]['nes_name']);
		// 		weak_list_data.push(Weaknesses[i]['weakness_description']);

		// 		weakness_data.push(weak_list_data);
		// 	}

		// 	//console.log(weakness_data);

		//    doc.autoTable({
		// 	  startY: 150,
		// 	  //head: [['DESCRIPTION(S)']],
		// 	  head: [['']],
		// 	  body: weakness_data
		// 	}) */

		// 	//    doc.addPage(); // add new page in pdf
		// 	// this.state.y_space = this.state.y_space + 20
		// 	if (!this.incrementYSpace(20)) doc.addPage()
		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, this.state.y_space, "6.0 GOOD PRACTICES");

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(10)) doc.addPage()
		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, this.state.y_space, "These Good practices were observed during this visit.");

		// 	if (!this.incrementYSpace(5)) doc.addPage()
		// 	// this.state.y_space = this.state.y_space + 5;
		// 	let GoodPractices = this.state.GoodPractices;



		// 	for (let i = 0; i < GoodPractices.length; i++) {
		// 		if (!this.incrementYSpace(5)) doc.addPage()
		// 		// this.state.y_space = this.state.y_space + 5;
		// 		doc.text(15, this.state.y_space, parseInt(i + 1) + ". " + GoodPractices[i]['good_practice_description']);
		// 	}



		// 	// doc.setFont("times","nomal","bold");
		// 	// this.state.y_space = this.state.y_space + 20
		// 	if (!this.incrementYSpace(20)) doc.addPage()
		// 	doc.setFontSize("14");
		// 	doc.text(10, this.state.y_space, "7.0 CRITICAL ISSUES");

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(10)) doc.addPage()
		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, this.state.y_space, "The following issues were also observed during this visit.");

		// 	// this.state.y_space = this.state.y_space + 5;
		// 	if (!this.incrementYSpace(5)) doc.addPage()
		// 	let CriticalIssues = this.state.CriticalIssues;



		// 	for (let i = 0; i < CriticalIssues.length; i++) {
		// 		// this.state.y_space = this.state.y_space + 5;
		// 		if (!this.incrementYSpace(5)) doc.addPage()

		// 		doc.text(15, this.state.y_space, parseInt(i + 1) + ". " + CriticalIssues[i]['critical_issue_description']);
		// 	}


		// 	//    doc.addPage(); // add new page in pdf    
		// 	// this.state.y_space = this.state.y_space + 20
		// 	if (!this.incrementYSpace(20)) doc.addPage()
		// 	doc.setFont("times", "normal", "bold");
		// 	doc.setFontSize("14");
		// 	doc.text(10, this.state.y_space, "8.0 MAJOR RECOMMENDATIONS");

		// 	// this.state.y_space = this.state.y_space + 5
		// 	if (!this.incrementYSpace(5)) doc.addPage()
		// 	doc.setFont("times", "italic", "normal");
		// 	doc.setFontSize("12");
		// 	doc.text(10, this.state.y_space, "These Recommendations are based on weaknesses identified during the inspection and recorded earlier in this report.");
		// 	let Recommendations = this.state.Recommendations;


		// 	if (!this.incrementYSpace(5)) doc.addPage()
		// 	for (let i = 0; i < Recommendations.length; i++) {
		// 		// this.state.y_space = this.state.y_space + 5;
		// 		if (!this.incrementYSpace(5)) doc.addPage()

		// 		doc.text(15, this.state.y_space, parseInt(i + 1) + ". " + Recommendations[i]['recommendation_description']);
		// 	}

		// 	const date = new Date();

		// 	const date_time = date.getFullYear() + '_' + date.getMonth() + "_" + date.getHours() + "_" + date.getMinutes() + "_" + date.getSeconds() + "_" + date.getMilliseconds();

		// 	const report_name = 'performance_reports_' + this.state.school_name.replace(" ", "_") + "_" + date_time;

		// 	//doc.save('katara.pdf');
		// 	doc.output('save', report_name + '.pdf')


		// 	//this.setState({gURI:imgURI})
		// 	//gURI.push(imgURI)
		// 	//doc.addImage(this.state.gURI, 'PNG',5, 155);
		// 	//doc.text(10, 155, 'Pitson');
		// 	//return imgURI

		// });
	}

	emailReport() {
		window.print();
	}

	printPDF() {
		window.print();
	}



	render() {
		//const {KeyEvidence, Standards, Requirements, RequirementAchieved,NesLevelAchieved} = tdis.state;
		//const {School_Data} = this.state;

		//const Reqs = [];
		//const Levels = [];





		return (

			<React.Fragment>
				<div className="container">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>

					<Row>
						<Col sm={6}>
							<div className="page-title-box">
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>

									<li className="breadcrumb-item active">Comprehensive Report</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">
								<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
							</div>
						</Col>
					</Row>

				</div>
				<div className="container">
					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>


							<div className="card">
								<div className="card-body">
									<hr />
									<Row>
										<Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
											<Button onClick={this.generatePDF} className="btn btn-success"><PictureAsPdf /></Button>
											&nbsp;
											&nbsp;
											<Button onClick={this.printPDF} className="btn btn-success pull-right"><Print /></Button>
										</Col>
									</Row>
									<hr />
									<div id="page">
										<Table className='table table-responsive' id='report' borderless responsive>
											<div className="page page-1">
												<thead>
													<tr>
														<th md={5} className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"></th>
														<th md={2} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															<Media src={logo} />

														</th>
														<th md={5} className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"></th>
													</tr>
													<tr>
														<th colSpan='3'>
															<center><h2>{'INSPECTION REPORT AT ' + this.state.school_name.toUpperCase() + ' ' + this.state.sector_name.toUpperCase() + ' SCHOOL'}</h2></center>
														</th>
													</tr>
													<tr>
														<th ></th>
														<th md={2}></th>
														<td md={5}></td>
													</tr>
													<tr>
														<th >HEAD TEACHER</th>
														<th md={2}>:</th>
														<td md={5}> {this.state.school_head}</td>
													</tr>
													<tr>
														<th md={5}>DIVISION</th>
														<th md={2}>:</th>
														<td md={5}> {this.state.division_name}</td>
													</tr>
													<tr>
														<th md={5}>DISTRICT</th>
														<th md={2}>:</th>
														<td md={5}> {this.state.district_name}</td>
													</tr>
													<tr>
														<th md={5}>{this.state.sector_id == 1 ? 'ZONE' : this.state.sector_id == 2 ? 'CLUSTER' : 'ZONE/CLUSTER'}</th>
														<th md={2}>:</th>
														<td md={5}> {this.state.zone_name}</td>
													</tr>
													<tr>
														<th>ESTABLISHMENT</th>
														<th>:</th>
														<td> {this.state.establishment}</td>
													</tr>
													<tr>
														<th>YEAR OF ESTABLISHMENT</th>
														<th>:</th>
														<td> {this.state.est_year}</td>
													</tr>
													<tr>
														<th>POSTAL ADDRESS</th>
														<th>:</th>
														<td> {this.state.post_address}</td>
													</tr>
													<tr>
														<th>FULL INSPECTION DATE</th>
														<th>:</th>
														<td> {this.state.full_insp_date}</td>
													</tr>
													<tr>
														<th>DATE OF PREVIOUS INSPECTION</th>
														<td>:</td>
														<td> {this.state.prev_insp_date}</td>
													</tr>
													<tr>
														<th>ENROLMENT</th>
														<th>:</th>
														<td> {this.state.enrolment}</td>
													</tr>
													<tr>
														<th>NUMBER OF ENROLLED BOYS</th>
														<th>:</th>
														<td> {this.state.enrolment_boys}</td>
													</tr>
													<tr>
														<th>NUMBER OF ENROLLED GIRLS</th>
														<th>:</th>
														<td> {this.state.enrolment_girls}</td>
													</tr>
													<tr>
														<th>NUMBER OF BOYS IN ATTENDANCE</th>
														<th>:</th>
														<td> {this.state.attendance_boys}</td>
													</tr>
													<tr>
														<th>NUMBER OF GIRLS IN ATTENDANCE</th>
														<th>:</th>
														<td> {this.state.attendance_girls}</td>
													</tr>
													<tr>
														<th>QUALIFIED TEACHERS</th>
														<th>:</th>
														<td> {this.state.total_teachers}</td>
													</tr>
													<tr>
														<th>UNQUALIFIED TEACHERS</th>
														<th>:</th>
														<td> {this.state.non_qualified_teachers}</td>
													</tr>
													<tr>
														<th>LEAD INSPECTOR</th>
														<th>:</th>
														<td> {this.state.lead_inspector}</td>
													</tr>
													<tr>
														<th style={{ display: 'flex' }}>INSPECTION TEAM</th>
														<td>:</td>
														<td>
															{'1. ' + this.state.first_inspector}<br />
															{'2. ' + this.state.second_inspector}<br />
															{this.state.third_inspector != '' ? '3. ' + this.state.third_inspector : '3. None'}<br />
															{this.state.fourth_inspector != '' ? '4. ' + this.state.fourth_inspector : '4. None'}<br />
														</td>
													</tr>
													<tr>
														<th>HEAD TEACHER'S PHONE(S)</th>
														<th>:</th>
														<td> {this.state.admin_phone}</td>
													</tr>
													<tr>
														<th>DATE FOR REPORT PUBLICATION</th>
														<th>:</th>
														<td> {this.state.publication_date}</td>
													</tr>
													<tr>
														<th>DATE OF EXPECTED ACTION PLAN</th>
														<th>:</th>
														<td> {this.state.action_plan_date}</td>
													</tr>
													<tr>
														<th>EXPECTED RE-INSPECTION DATE</th>
														<th>:</th>
														<td> {this.state.next_inspection_date}</td>
													</tr>
													<tr>
														<td colSpan="3">

														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<hr />
														</td>
													</tr>
													<tr>
														<td colSpan="3">

														</td>
													</tr>
												</thead>
											</div>
											<div className="page page-2">
												<tbody>
													<tr>
														<td colSpan="3">
															<h3><strong>1.0. BACKGROUND TO THE SCHOOL</strong></h3><br />
															<strong>{this.state.school_name + ' ' + this.state.sector_name + ' SCHOOL'}</strong> opened its doors in <strong>{moment(this.state.est_year).format("LL")}</strong>. Currently the school has
															an enrolment of <strong>{this.state.enrolment}</strong> of which <strong>{this.state.enrolment_boys}</strong> are boys and <strong>{this.state.enrolment_girls}</strong> are
															girls. On this day of inspection <strong>{this.state.attendance}</strong> students attended classes.  Out of these
															students <strong>{this.state.attendance_boys}</strong> are boys and <strong>{this.state.attendance_girls}</strong> are girls.
														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>2.0 THE PURPOSE OF INSPECTION AND THIS REPORT</strong></h3><br />
															The purpose of this inspection and this report is to evaluate the quality of
															education provided to the school and to make Recommendations on how it should
															be improved.<strong>{this.state.school_name + ' ' + this.state.sector_name + ' SCHOOL'}</strong> was inspected on <strong>{moment(this.state.full_insp_date).format("LL")}</strong>, by a team
															of at least <strong>{'3'}</strong> inspectors led by <strong>{this.state.lead_inspector}</strong>.  The inspection team observed
															lessons, carried out interviews with ldifferent stakeholders, scrutinized
															learners work in exercise books and then checked administrative and teaching
															records. The information contained in the Pre-Inspection Self Assessment
															Document (PISAD) completed by the school and data from the National Education
															Management System were taken into account also.  The evaluations in this
															report were made against the National Education Standards (NES).On receipt
															of this report the school, should build on its strengths, and then act on its
															shortfalls and meet the Recommendations set down at the end of this report.
															Thereafter, the school will  produce an Action Plan in order to guide its
															work on improvement.  As a result students’ learning will improve and they
															will achieve better outcomes for their own benefits and that of the nation.
														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>3.0 SCHOOL'S PERFORMANCE AGAINST NATIONAL EDUCATION STANDARDS</strong></h3><br />

															Graphical Summary of {this.state.school_name.toUpperCase() + ' ' + this.state.sector_name.toUpperCase() + ' SCHOOL'}'S inspection performance against NES:
															<hr />
															<div>
																<NesAchievementGraph visit_id={this.state.visit_id} />
															</div>
															<hr />

															<KeyEvidenceOnVisits visit_id={this.state.visit_id} />


														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>4.0 MAJOR STRENGTHS</strong></h3><br />
															These strengths are based on strengths seen during the inspection and recorded earlier in this report.

															<ul>
																{
																	this.state.Strengths.map(strength => (

																		<li>{strength.strength_description}</li>
																	))
																}
															</ul>
														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>5.0 AREAS OF IMPROVEMENT</strong></h3><br />
															These weaknesses are based on weaknesses seen during the inspection and recorded earlier in this report.

															<ul>
																{
																	this.state.Weaknesses.map(weakness => (
																		<li>

																			{weakness.weakness_description}
																		</li>
																	))
																}
															</ul>




														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>6.0 GOOD PRACTICES</strong></h3><br />
															<strong>These Good practices were observed during this visit.</strong>
															<ul>
																{
																	this.state.GoodPractices.map(good_practice => (
																		<li>{good_practice.good_practice_description}</li>
																	))
																}
															</ul>
														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>7.0 CRITICAL ISSUES</strong></h3><br />
															<strong>The following issues were also observed during this visit.</strong>
															<ul>
																{
																	this.state.CriticalIssues.map(critical_issue => (
																		<li>{critical_issue.critical_issue_description}</li>
																	))
																}

															</ul>
														</td>
													</tr>
													<tr>
														<td colSpan="3">
															<h3><strong>8.0 MAJOR RECOMMENDATIONS</strong></h3><br />
															<strong>
																These major Recommendations are based on weaknesses identified during the inspection and recorded earlier in this
																report.
															</strong>
															<ol>
																{
																	this.state.Recommendations.map(recommendation => (
																		<li>{recommendation.recommendation_description}</li>
																	))
																}

															</ol>
														</td>
													</tr>
												</tbody>
											</div>
										</Table>
									</div>
								</div>
							</div>

						</Col>
					</Row>
				</div>
			</React.Fragment>
		);
	}
}

export default InspectionPerformanceReport;
