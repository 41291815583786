import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../routes/API_URL";
import swal from 'sweetalert';


const API_URL = backend_url['api_url'];


class NesAchievementGraph extends Component {
   constructor(props) {
    super(props);
	const visit_id=this.props.visit_id;
	
    this.state = {
      visit_id:visit_id,
	  GraphicalData:[],
	  bearer_auth:window.sessionStorage.getItem('token'),
    };

  }
  
  componentDidMount () {
	 
	 Promise.all([axios.get(API_URL + `/key_evidences/nes_level_achieved/${this.state.visit_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([g_data])=>{
		 this.setState({
			 GraphicalData:g_data.data,
		 })
	 }
	 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the data. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  
  render() {
    const {GraphicalData} = this.state;
	const label = [];
	const serie = [];
	//console.log(graphical_data);
	
	for(let i=0;i<GraphicalData.length;i++){
		label.push(GraphicalData[i]['nes_id']);
		serie.push(GraphicalData[i]['level']);
	}
	
	//console.log(label);
	//console.log(serie);
	
	/* var barChartData = {
      labels: label,
	  series: [serie]
    };
	
    var barChartOptions = {
      low: 0,
      showArea: true,
      seriesBarDistance: 10,
	  stretch: true,
	  showPoint: true,
	  fillHoles: true,
	  anchorToPoint: true
	  
    }; */
    //const label = ...GraphicalData['nes_id'];
    //const serie = ...GraphicalData['nes_level_achieved'];
	
	const options = {
                chart: {
                    toolbar: {
                        show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
						  download: true,
						  selection: true,
						  zoom: true,
						  zoomin: true,
						  zoomout: true,
						  pan: true,
						  reset: true
						}
                    },
					id:'graphical-data',
                },
                //colors: ['#efac51'],
                colors: ['#FF8C00'],
                plotOptions: {
                    bar: {
                        columnWidth: '45',
                        dataLabels: {
                            show: false
                        },

                    },
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: label,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
					title: {
                        text: 'National Educational Standard'
                    },
                },
                yaxis: {
                    labels:{
						formatter: function(val){
							return val.toFixed(0);
						}
					},
					forceNiceScale: true,
					max: 4,
					min: 0,
					title: {
                        text: 'NES LEVEL ACHIEVEMENT'
                    },
                },
				export: {
				  png: {
					filename: 'p899jh.png',
				  }
				}
            }
			const series = [{
                name: 'Level Achieved',
                data: serie
            },]
    return (
      <React.Fragment>
		<ReactApexChart options={options} series={series} type="bar" height="290" width="100%" />
      </React.Fragment>
    );
  }
}

export default NesAchievementGraph;
