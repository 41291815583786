import React, { Component } from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Avatar from '@material-ui/core/Avatar';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import Loader from "../../../components/Loader";


import backend_url from "../../../routes/API_URL";

const API_URL = backend_url['api_url'];

class PasswordResetModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
			ModalVisible:true,
			user_password:'',
			email_id : sessionStorage.getItem("umail"),
			id : sessionStorage.getItem("uid"),
			bearer_auth:window.sessionStorage.getItem('token'),
			
        };
        this.toggleModal = this.toggleModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.updatePassword = this.updatePassword.bind(this);
		this.IsAlphaNumeric = this.IsAlphaNumeric.bind(this);
		
    }

	toggleModal(){
		if(this.state.ModalVisible==false)
		{
		 this.setState({ModalVisible:true});
		}else{
		 this.setState({ModalVisible:false}); 
		}
  }
  
  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
      if(e.target.name==='user_password'){ 
		    if(e.target.value!='' && this.IsAlphaNumeric(e.target.value)){
			   this.setState({UpwordState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({UpwordState :'has-danger'});
		   }
	   }else if(e.target.name==='user_password_confirm'){
		    if(e.target.value!='' && e.target.value === this.state.user_password){
			   this.setState({UpwordConfirmState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({UpwordConfirmState :'has-danger'});
		   }
	   }
        
  }
	
   //updating password
   updatePassword(event){
		event.preventDefault()

        const { history } = this.props;
        
		const id = this.state.id;
		
        const user = {
		  email_id : this.state.email_id,
		  user_password : this.state.user_password,
		  
        }
		
		//this.setState({loading : true});
		
		//console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/users/reset_password/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({ModalVisible:false});
				swal({
				  title: "",
				  text: "Password updated successfully! For this reason we're logging you out. Use the password for next log in.",
				  icon: "success",
				  button:true,
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
          .catch(error => {
            this.setState({
				ModalVisible:false, 
				//loading : false,
				
				});
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". Contact the system administrator for more.",
				  icon: "error",
				  button:true,
				})
				.then((sucess) => {
					this.setState({ModalVisible:true});
					//window.location.reload();
				});
          }) 
	 }
	
	//Validate paswsord
	IsAlphaNumeric = (f_string) => {
		const expression = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.,;~%?!@$%^&*-]).{8,}$/;
		return f_string.match(expression);
	}

    render() {
        const user =  sessionStorage.getItem("uname");
		const salute =  sessionStorage.getItem("sal");
		const role =  sessionStorage.getItem("urole");
		const full_name = sessionStorage.getItem("sal") + " " + sessionStorage.getItem("fname") + " " + sessionStorage.getItem("lname");
        return (
            <React.Fragment>
					{
					//<Modal isOpen={this.state.ModalVisible}>
					}
                        <Form onSubmit={this.updatePassword} >
							<ModalHeader><center>PASSWORD RESET FORM FOR {full_name.toUpperCase()}</center></ModalHeader>
							
							{
/* 								  <ModalHeader toggle={this.toggleModal}>
									<center className="justify-content-center">PASSWORD RESET FORM FOR {full_name}</center>
								  </ModalHeader>
 */							}
                          <ModalBody>
                                <FormGroup>
                                  <Row>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                      <Label for="Password">New Password</Label>					 
                                    </Col>
                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                      <Input type="password"  name="user_password" id="user_password" pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.,;~%?!@$%^&*-]).{8,}$" invalid={this.state.UpwordState==='has-danger'} onChange={(e)=>{this.handleInput(e); }} placeholder='Enter password' required/>
                                       <FormFeedback invalid>
										  The password must be atleast 8 characters long and this must be mixed cases alpha-numeric with special characters.
									   </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								<FormGroup>
                                  <Row>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                      <Label for="ConfirmPassword">Confirm Password</Label>					 
                                    </Col>
                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                      <Input type="password"  name="user_password_confirm" id="user_password_confirm" invalid={this.state.UpwordConfirmState==='has-danger'} onChange={(e)=>{this.handleInput(e); }} placeholder='Re-enter password' required/>
                                       <FormFeedback invalid>
										  Password Mismatched. Retry...
									   </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Reset</Button>
                            <Button type="reset" color="danger">Cancel</Button>
							{
							 //<Button color="danger" onClick={this.toggleModal}>Cancel</Button>
                            }
						  </ModalFooter>
                        </Form>
						{
								//</Modal>
						}
				
            </React.Fragment>
        );
    }
}

export default PasswordResetModal;


