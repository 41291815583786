import React, { Component } from "react";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
// Custom Scrollbar
import SimpleBar from "simplebar-react";
import MinistryHQDashboard from './MinistryHQDashboard';
import TeacherDashboard from './TeacherDashboard';
import SchoolAdminDashboard from './SchoolAdminDashboard';
import SystemAdminDashboard from './SystemAdminDashboard';
import DivisonalDashboard from './DivisonalDashboard';
import DistrictDashboard from './DistrictDashboard';
import ZonalClusterDashboard from './ZonalClusterDashboard';
import PupilStudentDashboard from './PupilStudentDashboard';
import GuardianDashboard from './GuardianDashboard';
import PtaDashboard from './PtaDashboard';
import InspectorDashboard from './InspectorDashboard';

import swal from 'sweetalert';




import "chartist/dist/scss/chartist.scss";

const ChooseDashboardData = props => {
	
        const user =  window.sessionStorage.getItem("uname");
        const role =  window.sessionStorage.getItem("urole");
		
		
		if(role == 'System Administrator'){
		 return(<SystemAdminDashboard />);
		} else if(role == 'Teacher'){
		  return(<TeacherDashboard />);
		} else if(role == 'Head Teacher' || role == 'Deputy Head') {
		  return(<SchoolAdminDashboard />);
		} else if(role == 'PEA' || role == 'Secondary Advisor' ){
		  return(<ZonalClusterDashboard />);
		} else if (role == 'DEM'){
		  return(<DistrictDashboard />);
		} else if (role == 'Division Manager'){
		  return(<DivisonalDashboard />);
		} else if (role == 'Inspector'){
		  return(<InspectorDashboard />);
		} else if (role == 'Ministry HQ'){
		  return(<MinistryHQDashboard /	>);
		} else if (role == 'Pupil' || role == 'Student'){
		  return(<PupilStudentDashboard />);
		}else if (role == 'Guardian'){
		  return(<GuardianDashboard />);
		} else if (role == 'School Governance Chair'){  
		  return(<PtaDashboard />);
		}
      
  
};
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <ChooseDashboardData />
		</div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
