import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// Custom Scrollbar
import { Link } from "react-router-dom";

// import charts
/* import BarChart from "../AllCharts/chartist/barchart";
import StackBarChart from "../AllCharts/chartist/stackedbarchart";
import DountChart from "../AllCharts/chartist/dountchart";
import PieChart from "../AllCharts/chartist/piechart";
import SmilAnimationsChart from "../AllCharts/chartist/smilanimations";
import LineChart from "../AllCharts/chartist/linechart";
import ChartBar from "../AllCharts/chartist/chartbar";
import LineAreaChart from "../AllCharts/chartist/lineareachart"; */
// import apexChart
import LineApexChart from "../AllCharts/apex/chartapex";
import BarApexChart from "../AllCharts/apex/barchart";
import AreaChart from "../AllCharts/apex/areachart";
import DonutChart from "../AllCharts/apex/dountchart";
import StackedBarChart from "../AllCharts/apex/stackedbarchart";

import "chartist/dist/scss/chartist.scss";

import swal from 'sweetalert';


class PupilStudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">LEARNERS LEVEL</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Visualization</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>
		  <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <h6 className="card-title mb-4">QUICK LINKS</h6>
				  <hr className="bg-success" />
					<Row className="text-white">	
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/divisions">
							<Card className="bg-success">
								<CardBody>
								  <center>Divisions</center>
								</CardBody>	
							  </Card>	
						  </Link>
						  
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/districts">
							<Card className="bg-success">
								<CardBody>
								  <center>Districts</center>
								</CardBody>
							</Card>	
						  </Link>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/constituencies">
							<Card className="bg-success">
								<CardBody>
								  <center>Constituencies</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/zones">
							<Card className="bg-success">
								<CardBody>
								  <center>Zones/Clusters</center>
								</CardBody>
							</Card>
						  </Link>
						  
						</Col>
						
						
						
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/schools">
							<Card className="bg-success">
								<CardBody>
								  <center>Schools</center>
								</CardBody>
							</Card>
						  </Link>
						  
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/nes_categories">
							<Card className="bg-success">
								<CardBody>
								  <center>NES Categories</center>
								</CardBody>
							</Card>
						  </Link>
						  
						</Col>
						
						
						
						
					</Row>
					<hr className="bg-success" />
					<Row className="text-white">	
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/national_standards">
							<Card className="bg-success">
								<CardBody>
								  <center>Education Standards</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/nes_requirements">
							<Card className="bg-success">
								<CardBody>
								  <center>NES Requirements</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/school_visits">
							<Card className="bg-success">
								<CardBody>
								  <center>Inspection Visits</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/advisory_visits">
							<Card className="bg-success">
								<CardBody>
								  <center>Advisory Visits</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/perf_report">
							<Card className="bg-success">
								<CardBody>
								  <center>Performance Graph(s)</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/progress_report">
							<Card className="bg-success">
								<CardBody>
								  <center>Progress Report</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						
					</Row>
						
					
				  
                </CardBody>
              </Card>
            </Col>
          </Row>
		  
		  <Row>
            <Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Annual School Admissions Summary</h4>
				  <hr />
				  
				  <LineApexChart />
				  
                </CardBody>
              </Card>
            </Col>

			
			<Col xs={12} sm={12} md={5} lg={5} xl={5}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Total Schools</h4>
				  <hr />
                  <BarApexChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
		  
         

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Inspection Visitation Trends</h4>
				  <hr />
				  
                  <AreaChart />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">General Average Performance Trends on Inspection</h4>
				  <hr />
                   <BarApexChart />
                </CardBody>
              </Card>
            </Col>
			<Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">General Average Performance Trends on Inspection By Educational Standards</h4>
				  <hr />
                   <BarApexChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default PupilStudentDashboard;
