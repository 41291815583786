import React, { Component } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';
import swal from 'sweetalert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class LevelAchieved extends Component {
  constructor(props) {
    super(props);
	const visit_id=this.props.match.params.visit_id;
	const isExpired = this.props.match.params.isExpired;
	const logged_user_id = this.props.match.params.logged_user;
	const lead_inspector_id = this.props.match.params.lead_inspector;
	const first_inspector_id = this.props.match.params.first_inspector;
	
	
    this.state = {
	  visit_id :visit_id,
	  isExpired: isExpired,
	  logged_user_id: logged_user_id,
	  lead_inspector_id:lead_inspector_id,
	  first_inspector_id:first_inspector_id,
	  bearer_auth:window.sessionStorage.getItem('token'),
    Levels: [],
    Visit: {},
	  loading: '',
    };

  }

  
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
    Promise.all([
      axios.get(API_URL + `/key_evidences/nes_level_achieved/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
      axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
    ]).then(
	 ([level_data, visit])=>{
		 this.setState({
			 Levels:level_data.data,
       loading: false,
       Visit: visit.data[0]
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	

  render() {
    const {Levels} = this.state;
    const data = {
      columns: [
        { 
          title: 'NES',
           field: 'nes_id' 
        },
        {
          title: 'Level Achieved',
          field: 'level_achieved_id',
        },
		/* {
          //title: 'Image',
          //field: 'image_id',
        }, */
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Nes Level Achievements</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Level Achieved Management</h3>
                <hr/>

                {
                      //Add user modal
                }
                
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                      title={`Levels Achieved at ${this.state.Visit.school_name || ''} ${this.state.Visit.sector_name || ''}`}
                          columns={data.columns}
                          data={
								Levels.map(level=>(	
									{
									  
									  nes_id : level.nes_name,
									  level_achieved_id :level.level_name,
									  //image_id :level.image,
									}
						  ))}

						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default LevelAchieved;
