import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from "react-router-dom"
import { ArrowLeft } from '@material-ui/icons';
import { TextField, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from "../../../components/Loader";
import axios from "../../../services/axios"
import moment from "moment"
import GanttChart from '../../common/Recommendations/Gantt'

function InspectorGantt({editMode = true}) {
    const [inspections, setInspections] = useState([])
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState("")
    const [zone, setZone] = useState("")
    const [selectedInspection, setselectedInspection] = useState({})
    const [recommendations, setrecommendations] = useState([])
    const [categories, setcategories] = useState([])
    const [loadGantt, setLoadGantt] = useState(false)


    const fetchInspections = async () => {
        const { data } = await axios.get(`/inspection_visits`)
        setInspections(data)
    }

    const refreshRecommendation = () => {
        axios.get(`/recommendations/major/${selectedInspection.visit_id}`)
            .then(({ data }) => {
                setrecommendations(data)
            })
    }

    const fetchNesCategories = () => {
        axios.get('/nes_categories')
            .then(({ data }) => {
                setcategories(data)
            })
    }

    React.useEffect(() => {
        setLoading(true)
        fetchInspections()
        fetchNesCategories()
        setLoading(false)
    }, [])

    const fetchRecommendations = (active) => {
        axios.get(`/recommendations/major/${active.visit_id}`)
            .then(({ data }) => {
                setrecommendations(data)
            })
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row className="position-center">
                    {loading && <Loader />}
                </Row>

                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">Home</Link>
                                </li>

                                <li className="breadcrumb-item active">Action Plans</li>
                            </ol>
                        </div>
                    </Col>

                    <Col sm="6">
                        <div className="float-right d-none d-md-block">
                            <Link className="btn btn-primary " to={`/`} ><ArrowLeft />Back</Link>
                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">Action Plans</h3>
                                <hr />
                                <div className="row">

                                    <div className="col-md-12">
                                        <Autocomplete
                                            fullwidth
                                            options={inspections.map(ins => (
                                                `${ins.school_name} on ${moment(ins.present_visitation_date).format("YYYY-MM-DD")}`
                                            ))}
                                            renderInput={(params) => <TextField {...params} label="Search Inspection name or date" />}
                                            onChange={(event, newValue) => {
                                                const f = event.target.firstChild.data.split(' on ');
                                                const inspection = inspections.filter(i => i.school_name == f[0] && i.present_visitation_date == f[1])[0]
                                                setLoading(true)
                                                setselectedInspection(inspection);
                                                fetchRecommendations(inspection)
                                                setLoadGantt(true)
                                                setLoading(false)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {loadGantt &&
                                        <GanttChart editMode={editMode} visit={selectedInspection} updateRecommendationsList={refreshRecommendation} categories={categories} recommendations={recommendations} />
                                    }
                                </div>
                            </div>
                        </div>
                        &nbsp;
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default InspectorGantt