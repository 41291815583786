import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';


// customizable method: use your own `Plotly` object
import createPlotlyComponent from 'react-plotly.js/factory';
//const Plot = createPlotlyComponent(Plotly);

import backend_url from "../../../../routes/API_URL";

import swal from 'sweetalert';


const API_URL = backend_url['api_url'];

var Plotly = require('plotly.js')
//var createPlotlyComponent = require('plotlyjs-react')
var Plot = createPlotlyComponent(Plotly)

//const API_URL = "http://localhost:8000/api";

class InspectedSchools extends Component {
    constructor(props) {
		super(props);
		//const visit_id=this.props.visit_id;
		const year_id = props.year_id;
		
		const date_object1 = new Date();
		const current_year1 = date_object1.getFullYear();
		
		this.state = {
		   Graph_Data:[],
		   year:year_id,
		   current_year1:current_year1,
		   division_id: sessionStorage.getItem("division"),
		   bearer_auth:window.sessionStorage.getItem('token'),
		};

	  }
	  
	  componentDidMount () {
		 
		 //Promise.all([axios.get(API_URL + `/progress_report/${this.state.current_year1}`)]).then(
		 Promise.all([axios.get(API_URL + `/total_schools/visited/division/${this.state.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([g_data])=>{
			 this.setState({
				 Graph_Data:g_data.data,
			 })
		 }
		 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
	  }
	  
    render() {
		const {Graph_Data} = this.state;
		
		
		const date_object = new Date();
		const current_year = date_object.getFullYear();
		const years = []
		const average_achievement = []
		const years_dataset = []
		const average_achievement_dataset=[]
		const x_label = [];
		const y_label = []
		const data_container  =[];
		const g_label = [];
		
		
		for(let i=0; i<Graph_Data.length; i++){
				
				//g_label.push(Graph_Data[i]['NES']);
				years.push(Graph_Data[i]['YEAR'])
				//average_achievement.push(Graph_Data[i]['ACHIEVEMENT'])
			} 
		
		
		const unique_years = [...new Set(years)]
		console.log(unique_years)
		/* const code = {x: [x_label], y:[y_label]}
		
		console.log("Printing code");
		console.log(code); */
		const color_labels = ['#efac51','#cbec0d','#798a16','#080d4f','#e10e41','#76525b','#4e3239','#2ec487','#13271f','#7f49a9','#4c0f7c','#af8ccb','#300750','#eb26af','#2ecc71','#943126','#f8c471','#7d3c98','#fdedec','#aed6f1'];
		
		let m = {type:'bar',mode: 'group', x:[],y:[], text:[],name:'Primary Schools'}
		let z = {type:'bar',mode: 'group', x:[],y:[], text:[],name:'Secondary Schools'}
		for(let i=0; i<Graph_Data.length; i++){
				
			//for(let y=0; y<unique_years.length;y++){
				if(parseInt(current_year-Graph_Data[i]['YEAR'])<10 && parseInt(current_year-Graph_Data[i]['YEAR'])>=0){
				  if(Graph_Data[i]['sector_id']=='1'){
					//m['type'].push('bar');
					m['x'].push(Graph_Data[i]['YEAR']);
					m['y'].push(Graph_Data[i]['school_count']);
					m['text'].push("Primary Schools");
				  }else if(Graph_Data[i]['sector_id']=='2'){
					//m['type'].push('bar');
					z['x'].push(Graph_Data[i]['YEAR']);
					z['y'].push(Graph_Data[i]['school_count']);
					z['text'].push("Secondary Schools");
				  }
				}
			
            //}
			
					
		}	
		//console.log(m);
		//console.log(z);

		
		/* for(let y=0; y<unique_years.length;y++){
			
			console.log(current_year-unique_years[y]);
			
			if(parseInt(current_year-unique_years[y])<10 && parseInt(current_year-unique_years[y])>=0){	
				let m = {type:'bar',mode: 'group', x:[],y:[], marker: {color:[]}, text:[]}	
				for(let i=0; i<Graph_Data.length; i++){
				  if(Graph_Data[i]['YEAR']== unique_years[y] && Graph_Data[i]['sector_id']=='1'){
					//m['type'].push('bar');
					m['x'].push(Graph_Data[i]['YEAR']);
					m['y'].push(Graph_Data[i]['school_count']);
					m['marker']['color'].push(color_labels[y]);
					m['text'].push("Primary Schools");
				  }else if(Graph_Data[i]['YEAR']== unique_years[y] && Graph_Data[i]['sector_id']=='2'){
					m['x'].push(Graph_Data[i]['YEAR']);
					m['y'].push(Graph_Data[i]['school_count']);
					m['marker']['color'].push(color_labels[y]);
					m['text'].push("Secondary Schools"); 
				  }
				  	
					
				}
				data_container.push(m);
            }				
			
		} */
		
		
		
		/* console.log('Printing m ');
		console.log(m);
		console.log('Printing m ');
		console.log(z); */
		//console.log('Printing Data ');
		//console.log(data_container);
		
		const data = [m,z];
		
		
		
		
		/* for(let d=0; d<data_container.length; d++){
			data.push(data_container[d]);
		}
		
		console.log("Data");
		console.log(data); */
		
		/* const data = [
		  {
			type: 'bar',
			mode: 'lines+points',
			x: [1,2,3],
			y: [2,4,4],
			marker: {color: 'red'},
			text: ['Apples', 'Pears', 'Peaches'],
		  },
		  {
			type: 'bar',
			x: [1,2,3],
			y: [2,4,4],
			marker: {color: 'blue'},
			text: ['Apples', 'Pears', 'Peaches'],
		  },
		]; */
		
		
		/* let data =[{}]
		
		for(let i=0; i<Graph_Data.length; i++){
				for(let v=0; v<average_achievement.length;v++){
				    if(parseInt(current_year-Graph_Data[i]['DATE']) <=10){
						  data[v]['type'] = 'bar';
						  data[v]['x'] = Graph_Data[i]['NES'];
						  data[v]['y'] = Graph_Data[i]['COUNT'];
						  data[v]['text'] = Graph_Data[i]['DATE'];
					}
				 
				}
				
		}  */
		
		//console.log("Data Values");
		//console.log(data);
		
		const layout= {
			title:"INSPECTION STATISTICS FOR SCHOOLS OVER THE PAST 10 YEARS",
			autosize: true,
			 xaxis: {
				type: 'category',
				title: 'YEAR',
				"categoryorder": "array",
                "categoryarray":  unique_years
			  },
			  yaxis: {
				title: 'TOTAL SCHOOLS',
				align: 'center'
			  },
		}
		
		const useResizeHandler= true;
		const style = {width: "100%", height: "100%"}
		
		const opt =  {
		  responsive: true,
		  displaylogo: false
		}
        return (
            <React.Fragment>
                <Plot
					data={data}
					layout={layout}
				    useResizeHandler={useResizeHandler}
					style={style}
				  />
            </React.Fragment>
        );
    }
}

export default InspectedSchools;