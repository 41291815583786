import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];

class SchoolAvgPerformanceGraph extends Component {
   constructor(props) {
    super(props);
	const emis_id=this.props.emis_id;
	
	//console.log("Huhu! " + emis_id);
	
    this.state = {
      emis_id:emis_id,
	  GraphicalData:[],
	  school_name:'',
	  bearer_auth:window.sessionStorage.getItem('token'),
    };

  }
  
  componentDidMount () {
	 
	 Promise.all([axios.get(API_URL + `/key_evidences/perf/school/${this.props.emis_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/schools/${this.state.emis_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([g_data, school_data])=>{
		 this.setState({
			 GraphicalData:g_data.data,
			 school_name:(school_data.data.length>=1)?school_data.data[0]['school_name']  + " SCHOOL NES-AVERAGE PERFORMANCE GRAPH":'NULL DATA ON',
		 })
	 }
	 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the graphical data for the school. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  
  componentDidUpdate (prevProps) {
	 
	 if(this.props.emis_id != prevProps.emis_id){
			Promise.all([axios.get(API_URL + `/key_evidences/perf/school/${this.props.emis_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/schools/${this.props.emis_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([g_data, school_data])=>{
					this.setState({
						 GraphicalData:g_data.data,
						 school_name:(school_data.data.length>=1)?school_data.data[0]['school_name']  + " SCHOOL NES-AVERAGE PERFORMANCE GRAPH":'NULL DATA ON',
					 }) 
			 }
			 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the graphical data for the school. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
  }
  
  render() {
    const {GraphicalData} = this.state;
	const label = [];
	const serie = [];
	//console.log(graphical_data);
	
	for(let i=0;i<GraphicalData.length;i++){
		label.push(GraphicalData[i]['NES']);
		serie.push(GraphicalData[i]['ACHIEVEMENT']);
	}
	
	const options = {
                chart: {
                    toolbar: {
                        show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
						  download: true,
						  selection: true,
						  zoom: true,
						  zoomin: true,
						  zoomout: true,
						  pan: true,
						  reset: true
						}
                    },
                },
                //colors: ['#efac51'],
                colors: ['#FF8C00'],
                plotOptions: {
                    bar: {
                        columnWidth: '45',
                        dataLabels: {
                            show: false
                        },

                    },
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
				title: {
					text: this.state.school_name,
					align: 'center',
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
					fontSize:  '12px',
					fontWeight:  'bold',
					fontFamily:  undefined,
					color:  '#263238'
					}
				 },
                xaxis: {
                    categories: label,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
					title: {
                        text: 'NATIONAL EDUCATION STANDARDS'
                    },
                },
                yaxis: {
                    labels:{
						formatter: function(val){
							return val.toFixed(0);
						}
					},
					forceNiceScale: true,
					max: 4,
					min: 0,
					title: {
                        text: 'LEVEL ACHIEVEMENT'
                    },
                }
            }
			const series = [{
                name: 'Average',
                data: serie
            },]
			
    return (
      <React.Fragment>
        <div className="colxs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<div className="card">
				<ReactApexChart options={options} series={series} type="bar" height="290" />
				
			</div>
		</div>
		
		
      </React.Fragment>
    );
  }
}

export default SchoolAvgPerformanceGraph;
