import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';

import ClusterAvgPerformanceGraph from './ClusterAvgPerformanceGraph';
import ClusterNesAvgPerformanceGraph from './ClusterNesAvgPerformanceGraph';
import DistrictAvgPerformanceGraph from './DistrictAvgPerformanceGraph';
import DistrictAvgNesPerformanceGraph from './DistrictAvgNesPerformanceGraph';
import DivisionAvgPerformanceGraph from './DivisionAvgPerformanceGraph';
import DivisionNesAvgPerformanceGraph from './DivisionNesAvgPerformanceGraph';
import NationalAvgPerformanceGraph from './NationalAvgPerformanceGraph';
import NationalNesAvgPerformanceGraph from './NationalNesAvgPerformanceGraph';
import SchoolAvgPerformanceGraph from './SchoolAvgPerformanceGraph';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class PerformanceReport extends Component {
  constructor(props) {
    super(props);
	//const visit_id=this.props.match.params.visit_id;
	
	
    const urole = window.sessionStorage.getItem("urole");
	
	this.state = {

	  modalVisible:false,
      EditModalVisible:false,
	  //NOTE: For the sake of the graph, the default values for emis_id, district_id,division_id and zone_id have been set to default values
	  emis_id:(urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("emis"):'',
      zone_id:(urole=='PEA' || urole=='Secondary Advisor' || urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("zone"):'',
      district_id:(urole=='DEM' || urole=='PEA' || urole=='Secondary Advisor' || urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("district"):'',
      division_id:(urole=='Division Manager' || urole=='DEM' || urole=='PEA' || urole=='Secondary Advisor' || urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("division"):'',
	  visit_id:'',
	  sector_id:window.sessionStorage.getItem('sector'),
	  //visit_id :visit_id,
      bearer_auth:window.sessionStorage.getItem('token'),
	  Sectors:[],
	  start_date:'',
	  end_date:'',
	  Districts:[],
	  Zones:[],
	  Divisions:[],
	  Schools:[],
	  loading : '',
	  urole : urole,
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	
	this.filterDistricts = this.filterDistricts.bind(this);
	this.autoFilterDistricts = this.autoFilterDistricts.bind(this);
	this.filterZones = this.filterZones.bind(this);
	this.autoFilterZones = this.autoFilterZones.bind(this);
	this.filterSchools = this.filterSchools.bind(this);
	this.autoFilterSchools = this.autoFilterSchools.bind(this);
	
	this.triggerGraph = this.triggerGraph.bind(this);
	
	//Automatic filtering of reports 
	if(this.state.division_id!='' && this.state.Districts.length==0){
		this.autoFilterDistricts();
	}
	
	if(this.state.district_id!='' && this.state.Zones.length==0){
		this.autoFilterZones();
	}
	
	if(this.state.zone_id!='' && this.state.Schools.length==0){
		this.autoFilterSchools();
	}
	
	this.formatDate = this.formatDate.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({modalVisible:false}); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({EditModalVisible:false}); 
    }
  }
  
   //Format date 
   formatDate(date){
	   
	   return new Date(date).toLocaleDateString('en-GB');
   }
   //Filtering
	 filterDistricts(e){
		 e.preventDefault();
		 //this.setState({loading : true});
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the districts. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 //Auto filter districts
	 autoFilterDistricts(){
		 
		 axios.get(API_URL + `/districts/filter/${this.state.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the districts. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 filterZones(e){
		 e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the zones. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 //Auto filter zones
	 autoFilterZones(){
		 
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${this.state.district_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the zones. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 filterSchools(e){
		 e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the schools. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 //Filter schools automatically
	 autoFilterSchools(){
		 
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the schools. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
  //Edit user 
  triggerGraph(id) {
	
    //this.setState({loading : true});	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/recommendations/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].recommendation_id,
		  recommendation_description : response.data[0].recommendation_description,
		  category_id : response.data[0].category_id,
		  visit_id : response.data[0].visit_id,
		  EditModalVisible:true,
		  //loading : false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the data. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([division, sector])=>{
		 this.setState({
			 Divisions:division.data,
			 Sectors:sector.data,
			 loading : false,
		 })
	 }
	 ).catch(error => {
			  
			  this.setState({loading : false});
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the data. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
	


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
   
   if(e.target.name==='emis_id'){
		   this.setState({emis_id:''});
		   if(e.target.value!=''){
			   this.setState({EmisIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EmisIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='zone_id'){
		   this.setState({zone_id:'',emis_id:''});
		   if(e.target.value!=''){
			   this.setState({ZoneIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ZoneIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='district_id'){
		   this.setState({district_id:'',zone_id:'',emis_id:''});
		   if(e.target.value!=''){
			   this.setState({DistrictIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DistrictIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='division_id'){
		   this.setState({division_id:'',district_id: '',zone_id:'',emis_id:''});
		   if(e.target.value!=''){
			   this.setState({DivisionIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DivisionIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='sector_id'){
		   if(e.target.value!=''){
			   this.setState({SectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='start_date'){
		   if(e.target.value!=''){
			   this.setState({StartDateState :'has-success',[e.target.id]: this.formatDate(e.target.value)});
		   }else{
			   this.setState({StartDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='end_date'){
		   if(e.target.value!=''){
			   this.setState({EndDateState :'has-success',[e.target.id]: this.formatDate(e.target.value)});
		   }else{
			   this.setState({EndDateState :'has-danger'});
		   }
	   
    }
        
  }

  render() {
    const {Divisions, Sectors} = this.state;
    

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Performance Report</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id } ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Performance Report</h3>
                <hr/>
				{
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
				}
				
					<div className="container-fluid">
						<div className="row">
							<table className='table'>
											<thead>
													<tr>
														<th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Start date</th>
														<th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">End date</th>
														<th hidden={(this.state.urole=='Division Manager' || this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Division</th>
														<th hidden={(this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">District</th>
														<th hidden={(this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{this.state.sector_id==1?'Zone':this.state.sector_id==2?'Cluster':'Zone/Cluster'}</th>
														<th hidden={(this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">School</th>
													</tr>
											</thead>
											<tbody>
													<tr>
														<td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															<Input type="date" name="start_date" id="start_date" invalid={this.state.StartDateState==='has-danger'} onChange={(e)=>{this.handleInput(e);}} required={true}/> 
															<FormFeedback invalid>
																  The field is required.
															</FormFeedback>
														</td>
														<td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															<Input type="date" name="end_date" id="end_date" invalid={this.state.EndDateState==='has-danger'} onChange={(e)=>{this.handleInput(e);}} required={true} /> 
															<FormFeedback invalid>
																  The field is required.
															</FormFeedback>
														</td>
														<td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" hidden={(this.state.urole=='Division Manager' || this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}>
															<Input type="select" name="division_id" id="division_id" invalid={this.state.DivisionIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistricts(e);}} required={true}> 
																<option></option>
																{
																  Divisions.map(division=>(
																	  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																	)
																  )
																}
															</Input>
															<FormFeedback invalid>
																  The field is required.
															</FormFeedback>
														</td>
														<td className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" hidden={(this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}> 
															<Input type="select" name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZones(e);}} required={true}> 
																	<option></option>
																	{
																		  
																	  this.state.Districts.map(district=>(
																					 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																			)
																		   )
																				
																	}
															</Input>
															
															<FormFeedback invalid>
																  The field is required.
															</FormFeedback>
														</td>
														<td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" hidden={(this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}>
															<Input type="select" name="zone_id" id="zone_id" invalid={this.state.ZoneIdState==='has-danger'}  onChange={(e)=>{this.handleInput(e); this.filterSchools(e); }}> 
																<option></option>
																{
																		  this.state.Zones.map(zone=>(
																				//Logic circuit and
																				(this.state.sector_id==zone.sector_id)
																				  ?
																					<option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																				  :
																					(this.state.sector_id=='null') && <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																				
																			)
																		   )
																				
																}
															</Input>
															<FormFeedback invalid>
																  The field is required.
															 </FormFeedback>
														</td>
														<td className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" hidden={(this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}>
															<Input type="select" name="emis_id" id="emis_id" invalid={this.state.EmisIdState==='has-danger'}  onChange={this.handleInput} required={true}> 
																  <option></option>
																  {
																	  this.state.Schools.map(school=>(
																				 <option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
																		)
																	   )
																			
																  }
															</Input>
															
															<FormFeedback invalid>
																  The field is required.
															 </FormFeedback>
														</td>
													</tr>
											</tbody>
							</table>
							<div className="text-danger" hidden={(this.state.urole=="Head Teacher" || this.state.urole=="Teacher" || this.state.urole=="PTA")?true:false}>
							    ** 
								<i>
								The form above is for the customization for the charts below and is crucial in filtering(Top-Botton) the graphs to display below. You're then advised
								to filter the data based on the table header fields and the graph will display accordingly. It should 
								also be noted that the graph will automatically appear after filtering, and initially the default charts are based on your Juridistiction.
								</i>
								**
							</div>
						</div>
					</div>
                <hr/>

                {
                      //Add user moadl with filteres for report data
                }
                
				
				<div className="container-fluid">
					<div className="row">
						{
							(this.state.start_date != '' || this.state.end_date!='') &&
								(
									<NationalAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} />
								)
						}
						
						{
							(this.state.start_date != '' || this.state.end_date!='') &&
								(
									<NationalNesAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} />
								)
						}

						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.division_id!='') &&
									(<DivisionAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} division_id={this.state.division_id}/>)
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.division_id!='') &&
									(<DivisionNesAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} division_id={this.state.division_id}/>)
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.district_id!='') &&
									(<DistrictAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} district_id={this.state.district_id}/>)
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.district_id!='') &&
									(<DistrictAvgNesPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} district_id={this.state.district_id}/>)
									
						}
						
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.zone_id!='') &&
									(<ClusterAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} zone_id={this.state.zone_id} />)
									
						}
						
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.zone_id!='') &&
									(<ClusterNesAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} zone_id={this.state.zone_id}/>)
									
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.emis_id!='') &&
									(<SchoolAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} emis_id={this.state.emis_id}/>)
									
						}
						
						
						
					</div>
				</div>
				  
				  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default PerformanceReport;
