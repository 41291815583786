import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';
import swal from 'sweetalert';


import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Strengths extends Component {
  constructor(props) {
    super(props);
	const visit_id=this.props.match.params.visit_id;
	const isExpired = this.props.match.params.isExpired;
	const logged_user_id = this.props.match.params.logged_user;
	const lead_inspector_id = this.props.match.params.lead_inspector;
	const first_inspector_id = this.props.match.params.first_inspector;
	
	  
	
    this.state = {
	  //Get logged user role
	  role:window.sessionStorage.getItem("urole"),
      modalVisible:false,
      EditModalVisible:false,
	  nes_id:'',
	  strength_description:'',
	  visit_id :visit_id,
	  isExpired: isExpired,
	  logged_user_id: logged_user_id,
	  lead_inspector_id:lead_inspector_id,
	  first_inspector_id:first_inspector_id,
	  bearer_auth:window.sessionStorage.getItem('token'),
	  Strengths:[],
	  Standards: [],
	  Visit: {},
	  loading: '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addStrengths = this.addStrengths.bind(this);
	this.editStrengths= this.editStrengths.bind(this);
	this.updateStrengths = this.updateStrengths.bind(this);
	this.deleteStrengths =this.deleteStrengths.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 strength_description:'',
		 NesIdState:'',
		 StrengthIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 strength_description:'',
		 NesIdState:'',
		 StrengthIdState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit user 
  editStrengths(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/strengths/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].strength_id,
		  strength_description : response.data[0].strength_description,
		  visit_id : response.data[0].visit_id,
		  EditModalVisible:true,
		  //loading : false
		  
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateStrengths(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const strength = {
		  strength_id : this.state.strength_id,
		  strength_description : this.state.strength_description,
		  visit_id : this.state.visit_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/strengths/update/${id}`, strength,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				EditModalVisible:false, 
				//loading : false
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false,
					
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500,
				  button: false
				})
				axios.get(API_URL + `/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					{ headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					).then(
						(visit) => {
							this.setState({
								Strengths: visit.data
							})
						}
					)
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false
				});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteStrengths(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the strength?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
							  text: "Delete",
							  value: true,
							  visible: true,
							  className: "",
							  closeModal: true
							},
							cancel: {
							  text: "Cancel",
							  value: false,
							  visible: true,
							  className: "",
							  closeModal: true,
							}
						  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
						
						    //this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/strengths/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								
								swal({
								  title: "",
								  text: "The strength record Deleted Successfully!",
								  icon: "success",
								  timer : 2500,
								  button: false
								})
								axios.get(API_URL + `/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					            { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					            ).then(
						        (visit) => {
							        this.setState({
							  	    Strengths: visit.data
							     })
						}
					)
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
						  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	  Promise.all([
		  axios.get(API_URL + `/strengths/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		  axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		  axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
	  ]).then(
	 ([strength, standard, visit])=>{
		 this.setState({
			 Strengths:strength.data,
			 Standards: standard.data,
			 Visit: visit.data[0],
			 loading : false
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addStrengths(event){
		event.preventDefault()

        const { history } = this.props

        const strength = {
		  strength_description : this.state.strength_description,
		  visit_id : this.state.visit_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/strengths', strength,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading : false
				});
				
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible: false,
					Strengths: [response.data.data, ...this.state.Strengths],
					strength_description: '',
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500,
				  button: false
				})
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            
			this.setState({
				modalVisible:true, 
				//loading : false
				});
				
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='strength_description'){
		   if(e.target.value!=''){
			   this.setState({StrengthIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({StrengthIdState :'has-danger'});
		   }
	   
    } else if(e.target.name==='nes_id'){
		   if(e.target.value!=''){
			   this.setState({NesIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesIdState :'has-danger'});
		   }
	   
    } 
	
        
  }

  render() {
    const {Strengths, Standards} = this.state;
    const data = {
      columns: [
        /* { 
          title: 'ID',
          field: 'strength_id' 
        }, */
        {
          title: 'Description',
          field: 'strength_description',
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Strengths</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link  className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Strengths Management</h3>
                <hr/>
                <Button hidden={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?true:false} color="success" onClick={this.toggle}> {<Add />} New Strength</Button>
                <hr/>

                {
                    //Add user modal
					
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addStrengths} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">STRENGTH ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                
                                
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="strength_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea" name="strength_description" id="strength_description" invalid={this.state.StrengthIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}/>
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
						  title={`STRENGTHS AT ${this.state.Visit.school_name  || ''} ${this.state.Visit.sector_name || ''}`}
                          columns={data.columns}
                          data={
								Strengths.map(strength=>(	
									(this.state.role!="Inspector") ?
									({
									  
									  //strength_id : strength.strength_id,
									  strength_description :strength.strength_description,
									 'OPTIONS': <pre><Link className="btn disabled" to={`/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editStrengths(strength.strength_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className="btn disabled" to={`/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteStrengths(strength.strength_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									})
									
									:
									({
									  
									  //strength_id : strength.strength_id,
									  strength_description :strength.strength_description,
									 'OPTIONS': <pre><Link className={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?"btn disabled":"btn"} to={`/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editStrengths(strength.strength_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?"btn disabled":"btn"} to={`/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteStrengths(strength.strength_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									})
									
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateStrengths} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">STRENGTHS EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								  </FormGroup>
								 
                                
                                
								 
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="strength_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  defaultValue={this.state.strength_description} name="strength_description" id="strength_description" invalid={this.state.StrengthIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Strengths;
