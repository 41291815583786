import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../../routes/API_URL";

import swal from 'sweetalert';


const API_URL = backend_url['api_url'];
//const API_URL = "http://localhost:8000/api";

class NationalPerformanceReport extends Component {
    constructor(props) {
		super(props);
		//const visit_id=this.props.visit_id;
		
		this.state = {
		  GraphicalData:[],
		  bearer_auth:window.sessionStorage.getItem('token'),
		};

	  }
	  
	  componentDidMount () {
		 
		 Promise.all([axios.get(API_URL + `/key_evidences/perf/national`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([g_data])=>{
			 this.setState({
				 GraphicalData:g_data.data,
			 })
		 }
		 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
	  }
	
    render() {
		const {GraphicalData} = this.state;
		const label = [];
		const serie = [];
		
		
		for(let i=0;i<GraphicalData.length;i++){
			label.push(GraphicalData[i]['division_name']);
			serie.push(GraphicalData[i]['AVERAGE']);
		}
		
		const options = {
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                //colors: ['#efac51'],
                colors: ['#FF8C00'],
                plotOptions: {
                    bar: {
                        columnWidth: '45',
                        dataLabels: {
                            show: false
                        },

                    },
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: label,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
					title: {
                        text: 'DIVISION NAME(S)'
                    },
                },
                yaxis: {
                    title: {
                        text: 'AVERAGE ACHIEVEMENT COUNT'
                    },
                }
            }
			const series = [{
                name: 'Average Achievement Count',
                data: serie
            },]
        return (
            <React.Fragment>
                <ReactApexChart options={options} series={series} type="bar" height="290" />
            </React.Fragment>
        );
    }
}

export default NationalPerformanceReport;