import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Sectors extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
	  sector_id:'',
	  sector_name:'',
	  SectorIdState:'',
	  SectorNameState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  role:window.sessionStorage.getItem('urole'),
	  Sectors:[],
	  loading : '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addSectors = this.addSectors.bind(this);
	this.editSectors = this.editSectors.bind(this);
	this.updateSectors = this.updateSectors.bind(this);
	this.deleteSectors =this.deleteSectors.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 sector_id:'',
	     sector_name:'',
	     SectorIdState:'',
	     SectorNameState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 sector_id:'',
	     sector_name:'',
	     SectorIdState:'',
	     SectorNameState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Zones 
  editSectors(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/sectors/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].sector_id,
		  sector_name : response.data[0].sector_name,
		  //division_id : response.data[0].division_id,
		  EditModalVisible:true,
		  //loading : false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateSectors(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const sector = {
		  sector_name : this.state.sector_name,
		  //division_id : this.state.division_id,
		  
        }
        
		//this.setState({loading : true});
		//console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/sectors/update/${id}`, sector,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500,button:false
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([sector]) => {
							this.setState({
								Sectors: sector.data,
								loading: false,
							})
						}
					)
				});
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				});
				if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteSectors(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the sector?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/sectors/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								
								swal({
								  title: "",
								  text: "The sector record Deleted Successfully!",
								  icon: "success",
								  button:false,timer:2500
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
										([sector]) => {
											this.setState({
												Sectors: sector.data,
												loading: false,
											})
										}
									)
								});
							}).catch(error => {
									if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([sector])=>{
		 this.setState({
			 Sectors:sector.data,
			 loading : false,
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  
				if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addSectors(event){
		event.preventDefault()

        const { history } = this.props

        const sector = {
		  sector_id : this.state.sector_id,
		  sector_name: this.state.sector_name,
		  
        }
        
		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/sectors', sector,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading : false,
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:1250
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([sector]) => {
							this.setState({
								Sectors: sector.data,
								loading: false,
							})
						}
					)
				});
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
			    //loading : false,
				});
				
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='sector_id'){
		   if(e.target.value!=''){
			   this.setState({SectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='sector_name'){
		   if(e.target.value!=''){
			   this.setState({SectorNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SectorNameState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {Sectors} = this.state;
    const data = {
      columns: [
        { 
           title: 'Sector Id',
           field: 'sector_id' 
        },
		
		{ 
           title: 'Sector Name',
           field: 'sector_name' 
        },
        
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">sectors</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Sector Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true} onClick={this.toggle}> {<Add />} New Sector</Button>
                <hr/>

                {
                    //Add user modal
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addSectors} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">SECTOR ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="sector_id">Sector ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="sector_id" id="sector_id" invalid={this.state.SectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="sector_name">Sector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  name="sector_name" id="sector_name"  invalid={this.state.SectorNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title="EDUCATIONAL SECTORS IN MALAWI"
                          columns={data.columns}
                          data={
								Sectors.map(sector=>(	
									{
									  
									  sector_id : sector.sector_id,
									  sector_name : sector.sector_name,
									 'OPTIONS': <pre><Link to={`/sectors`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.editSectors(sector.sector_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
											<Link to={`/sectors`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.deleteSectors(sector.sector_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateSectors} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">SECTOR EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								  
								  {
									  /*
										<FormGroup>
										  <Row>
											<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
											  <Label for="sector_id">Sector ID</Label>					 
											</Col>
											<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
											  <Input type="number" value={this.state.sector_id} name="sector_id" id="sector_id" onChange={this.handleInput} />
											</Col>
										  </Row>
										</FormGroup>
									  */
								  }
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="sector_name">Sector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={4} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text" value={this.state.sector_name} name="sector_name" id="sector_name" invalid={this.state.SectorNameState==='has-danger'}  onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Sectors;
