import React, { useState, useEffect } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Table, TableBody, TableHead,Container, Row, FormFeedback, Media, Col,Modal, ModalHeader, ModalBody, ModalFooter,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import TrackEvidenceAlgo from './TrackEvidenceAlgo';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import PDF, { Text, AddPage, Line, Image, Html } from 'jspdf-react';
import axios from 'axios';

import swal from 'sweetalert';


export default function LevelAchievedTexts(props){
	const Standards = props.standards;
	const KeyEvidence = props.key_evidence;
	const LevelAchieved = props.level_achieved;
	const TrackEvidence = props.track_evidence;
	const MinorRecommendation = props.minor_recommendation;
	const levels_array = [1,2,3,4];
	const visit_id = props.visit_id;
	const nes_id = props.nes_id;
	const nes_name = props.nes_name;
	
	 
	const level_one_pct = '';
	const level_two_pct = '';
	const level_three_pct = ''; 
	const level_four_pct = '';
	
	const level_obj = {'one':'','two':'','three':'','four':''};
	//console.log(LevelAchieved);
	
	return(
	<div>
		
		
		
					
		<TrackEvidenceAlgo LevelAchieved={LevelAchieved} visit_id={visit_id} nes_id={nes_id} nes_name={nes_name} track_evidence={TrackEvidence} key_evidence={KeyEvidence} minor_recommendation={MinorRecommendation} level_one_pct1={level_obj['one']} level_two_pct2={level_obj['two']} level_three_pct3={level_obj['three']} level_four_pct4={level_obj['four']} />
		
		
	</div>
	)
}