import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import swal from 'sweetalert'
import axios from '../../../services/axios'


function SendEmailModal({ visit_id, nes_achieved, showModal, closeModal }) {
    const [emails, setEmails] = React.useState([]);
    const [levels, setLevels] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setEmails(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleResponse = (response) => {
        closeModal()
        if (response.statusText == 'OK') {
            swal({
                icon: 'success',
                text: 'Email notification sent'
            })
        } else {
            swal({
                icon: 'error',
                text: 'Something went wrong... Please contact your administrator.'
            })
        }
    }

    const sendEmails = () => {
        const fromEmails = emails.map(name => deps.find(dep => dep.name == name).email)
        const fromNes = levels.map(l => nes_achieved.find(n => n.nes_name == l).nes_id)
        axios.post(`nes_requirements/send_notifications`, { emails: fromEmails, nes: fromNes, visit_id })
            .then(handleResponse)
            .catch(err => {
                closeModal()
                swal({
                    icon: 'error',
                    text: 'Something went wrong... please contact your administrator.'
                })
            })
    }

    const deps = [
        { name: 'EMIU', email: 'etungande@technixmw.com' },
        { name: 'HR', email: '1' },
        { name: 'DSE', email: '2' },
        { name: 'DEM', email: '3' },
        { name: 'EDM', email: '5' },
        { name: 'DTED', email: '6' },
        { name: 'EPLANNING', email: '7' },
        { name: 'DIE', email: '8' },
        { name: 'DST', email: '9' },
        { name: 'MANEB', email: '10' }
    ];

    return (
        <div>

            <Modal isOpen={showModal}>
                <ModalHeader>Send Email Notification For NES</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <FormControl className="col-md-12" fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">NES</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={levels}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setLevels(
                                        // On autofill we get a stringified value.
                                        typeof value === 'string' ? value.split(',') : value,
                                    );
                                }}
                                input={<OutlinedInput label="Select Levels To Send" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {nes_achieved.map((level) => (
                                    <MenuItem key={level.nes_name} value={level.nes_name}>
                                        <Checkbox checked={levels.indexOf(level.nes_name) > -1} />
                                        <ListItemText primary={level.nes_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;
                        <FormControl className="col-md-12" fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">Departments</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={emails}
                                onChange={handleChange}
                                input={<OutlinedInput label="Select Departments" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {deps.map((dep) => (
                                    <MenuItem key={dep.name} value={dep.name}>
                                        <Checkbox checked={emails.indexOf(dep.name) > -1} />
                                        <ListItemText primary={dep.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={sendEmails}>
                        Send
                    </Button>{' '}
                    <Button onClick={closeModal} color="secondary">
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default SendEmailModal