import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../../routes/API_URL";

import swal from 'sweetalert';


const API_URL = backend_url['api_url'];


class InspectedSecondarySchools extends Component {
    constructor(props) {
		super(props);
		//const visit_id=this.props.visit_id;
		
		this.state = {
		   Prim_Data:[],
		   bearer_auth:window.sessionStorage.getItem('token'),
		};

	  }
	  
	  componentDidMount () {
		 
		 Promise.all([axios.get(API_URL + `/total_schools/visited`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 //Promise.all([axios.get(API_URL + `/progress_report/`)]).then(
		 ([s_data])=>{
			 this.setState({
				 Prim_Data:s_data.data,
			 })
		 }
		 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
	  }
	
    render() {
		const {Prim_Data} = this.state;
		
		const vs_label = [];
		//Primary school data for the graph
		const p_serie = [];
		
		//Secondary school data for the graph
		const s_serie = [];
		
		const date_object = new Date();
		const current_year = date_object.getFullYear();
		
		
		
		for(let i=0; i<Prim_Data.length;i++){
				
				if(parseInt(current_year-Prim_Data[i]['YEAR'])<10){
					if(Prim_Data[i]['sector_id']==="2"){
						s_serie.push(Prim_Data[i]['COUNT']); 
						vs_label.push(Prim_Data[i]['YEAR']);
					   
					}
					
				}
				
		}
		
		
			
			
			
			const options= {
                colors: ['#efac51'],
                chart: {
                    toolbar: {
                        show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
						  download: true,
						  selection: true,
						  zoom: true,
						  zoomin: true,
						  zoomout: true,
						  pan: true,
						  reset: true
						}
                    },
                },
                legend: {
                    show: true
                },

                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    //categories: Prim_Data.map(n=>(n['YEAR'])),
                    categories: vs_label,
					//type: 'category',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
					title: {
                        text: 'YEAR'
                    },
                },
                yaxis: {
                    title: {
                        text: 'TOTAL'
                    },
                },
            }
            
			
			const  series = [
			    {
                name: "Secondary Schools",
                data: s_serie,
                },
            ]
			//console.log(Prim_Data);
			
           /* const  series = [
		   {
                //data: [].push(Prim_Data.map((n)=>n))
				
                data: Prim_Data.map(n=>({x:n['YEAR'],y:n['COUNT']})),
		   }
            ] */
		    
			//console.log(vs_label);
			//console.log(p_serie);
			//console.log(s_serie);
        return (
            <React.Fragment>
                <ReactApexChart options={options} series={series} type="bar" height="290" />
            </React.Fragment>
        );
    }
}

export default InspectedSecondarySchools;