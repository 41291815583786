import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core'; 
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";

import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Schools extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  sector_id: sessionStorage.getItem('sector'),
      modalVisible:false,
      EditModalVisible:false,
	  emis_id:'',
	  school_name:'',
	  establishment_type:'',
	  establishment_date:'',
	  school_head_master_id:'',
	  school_deputy_head_id:'',
	  EmisIdState:'',
	  SchoolNameState:'',
	  EstablishmentTypeState:'',
	  EstablishmentDateState:'',
	  SchoolHeadIdState:'',
	  SchoolDeputyIdState:'',
	  zone_id:'',
	  ZoneIdState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  Schools:[],
	  Zones:[],
	  Users:[],
	  role:window.sessionStorage.getItem("urole"),
	  juridistiction:'',
	  loading : '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addSchools = this.addSchools.bind(this);
	this.editSchools = this.editSchools.bind(this);
	this.updateSchools = this.updateSchools.bind(this);
	this.deleteSchools =this.deleteSchools.bind(this);
	this.getSectorByZone= this.getSectorByZone.bind(this);
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		  modalVisible:false,
		  emis_id:'',
	      school_name:'',
	      establishment_type:'',
	      establishment_date:'',
	      school_head_master_id:'',
	      school_deputy_head_id:'',
	      EmisIdState:'',
	      SchoolNameState:'',
	      EstablishmentTypeState:'',
	      EstablishmentDateState:'',
	      SchoolHeadIdState:'',
	      SchoolDeputyIdState:'',
	      zone_id:'',
	      ZoneIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		  EditModalVisible:false,
		  emis_id:'',
	      school_name:'',
	      establishment_type:'',
	      establishment_date:'',
	      school_head_master_id:'',
	      school_deputy_head_id:'',
	      EmisIdState:'',
	      SchoolNameState:'',
	      EstablishmentTypeState:'',
	      EstablishmentDateState:'',
	      SchoolHeadIdState:'',
	      SchoolDeputyIdState:'',
	      zone_id:'',
	      ZoneIdState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  getSectorByZone(e) {
	e.preventDefault();
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/zones/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  sector_id : response.data[0].sector_id,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  //Edit Schools
  editSchools(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/schools/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].emis_id,
		  school_name : response.data[0].school_name,
		  establishment_type:response.data[0].establishment_type,
		  //establishment_date:response.data[0].establishment_date,
		  sector_id:response.data[0].sector_id,
		  zone_id : response.data[0].zone_id,
		  //division_id : response.data[0].division_id,
		  EditModalVisible:true,
		  //loading : false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateSchools(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const school = {
		  school_name : this.state.school_name,
		  establishment_type:this.state.establishment_type,
		  //establishment_date:this.state.establishment_date,
		  zone_id : this.state.zone_id,
		  //division_id : this.state.division_id,
		  sector_id: this.state.sector_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/schools/update/${id}`, school,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
                //this.setState({loading : false});
				
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if(response.data['status']=='success'){
					this.setState({
						EditModalVisible:false, 
					//loading : false,
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  timer:1250
					})
					.then((sucess) => {
						window.location.reload();
					});
				}else{
					this.setState({
						EditModalVisible:true, 
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
				}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				});
				  
				  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							this.setState({EditModalVisible:true});
						});
				  }
          }) 
	 }
	 
	 //delete
	deleteSchools(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the school?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
							  text: "Delete",
							  value: true,
							  visible: true,
							  className: "",
							  closeModal: true
							},
							cancel: {
							  text: "Cancel",
							  value: false,
							  visible: true,
							  className: "",
							  closeModal: true,
							}
						  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							
							//this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/schools/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The school record Deleted Successfully!",
								  icon: "success",
								  timer:2000,
								})
								.then((sucess) => {
									window.location.reload();
								});
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  });
					  //unconfirmed condition
					  } else {
						  window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 if(this.state.role=='Division Manager'){
		 const div_id = window.sessionStorage.getItem('division');
		 
		  Promise.all([axios.get(API_URL + `/divisions/school/${div_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/divisions/${div_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([school, zone,division_data])=>{
				 this.setState({
					 Schools:school.data,
					 Zones:zone.data,
					 juridistiction:(division_data.data.length>=1)? "SCHOOLS IN " + division_data.data[0]['division_name'] + " region educational division":'NULL DATA ON',
					 loading : false,
				 })
			 }
			 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
			
		 
		 
	 }else if(this.state.role=='DEM'){
		 const dist_id = window.sessionStorage.getItem('district');
		 
		 
		 Promise.all([axios.get(API_URL + `/districts/school/${dist_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/districts/${dist_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([school, zone,district_data])=>{
				 this.setState({
					 Schools:school.data,
					 Zones:zone.data,
					 juridistiction:(district_data.data.length>=1)? "SCHOOLS IN " + district_data.data[0]['district_name'] + " educational district":'NULL DATA ON',
					 loading : false,
				 })
			 }
			 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
		 
		 
	 }else if(this.state.role=='PEA'){
		 const zon_id = window.sessionStorage.getItem('zone');
		 
		 
		 Promise.all([axios.get(API_URL + `/zones/school/${zon_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/zones/${zon_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([school, zone,zone_data])=>{
				 this.setState({
					 Schools:school.data,
					 Zones:zone.data,
					 juridistiction:(zone_data.data.length>=1)? "SCHOOLS IN " + zone_data.data[0]['zone_name'] + " educational zone":'NULL DATA ON',
					 loading : false,
				 })
			 }
			 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
		 
		 
	 }else if(this.state.role=='Secondary Advisor'){
		 const zon_id = window.sessionStorage.getItem('zone');
		 
		 
		 Promise.all([axios.get(API_URL + `/zones/school/${zon_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/zones/${zon_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([school, zone,zone_data])=>{
				 this.setState({
					 Schools:school.data,
					 Zones:zone.data,
					 juridistiction:(zone_data.data.length>=1)? "SCHOOLS IN " + zone_data.data[0]['zone_name'] + " educational cluster":'NULL DATA ON',
					 loading : false,
				 })
			 }
			 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
		 
		 
	 } else if (this.state.role == 'Ministry HQ' || this.state.role == 'Inspector' || this.state.role == 'System Administrator') {
		var url = '/schools'
		if (this.state.sector_id) url = `${url}?sector_id=${this.state.sector_id}`  
		Promise.all([axios.get(API_URL + url,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([school, zone])=>{
			 this.setState({
				 Schools:school.data,
				 Zones:zone.data,
				 juridistiction:"SCHOOLS IN MALAWI",
				 loading : false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }
	 
  }
	
  //Add user
	 addSchools(event){
		event.preventDefault()

        const { history } = this.props

        const school = {
		  emis_id : this.state.emis_id,
		  school_name: this.state.school_name,
		  establishment_type:this.state.establishment_type,
		  //establishment_date:this.state.establishment_date,
		  zone_id : this.state.zone_id,
		  sector_id: this.state.sector_id,
		  //division_id : this.state.division_id,
		  
        }
        
		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/schools', school,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            
			this.setState({
				modalVisible:false, 
				//loading : false,
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if(response.data['status']=='success'){
					this.setState({
						modalVisible:false, 
					//loading : false,
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  timer:1250
					})
					.then((sucess) => {
						window.location.reload();
					});
				}else{
					this.setState({
						modalVisible:true, 
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
				}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading : false,
				});
			    if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='emis_id'){
		   if(e.target.value!=''){
			   this.setState({EmisIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EmisIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='school_name'){
		   if(e.target.value!=''){
			   this.setState({SchoolNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SchoolNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='establishment_type'){
		   if(e.target.value!=''){
			   this.setState({EstablishmentTypeState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EstablishmentTypeState :'has-danger'});
		   }
	   
    }else if(e.target.name==='establishment_date'){
		   if(e.target.value!=''){
			   this.setState({EstablishmentDateState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EstablishmentDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='school_head_master_id'){
		   if(e.target.value!=''){
			   this.setState({SchoolHeadIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SchoolHeadIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='school_deputy_head_id'){
		   if(e.target.value!=''){
			   this.setState({SchoolDeputyIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SchoolDeputyIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='zone_id'){
		   if(e.target.value!=''){
			   this.setState({ZoneIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ZoneIdState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {Schools, Zones} = this.state;
    const data = {
      columns: [
        { 
          title: 'EMIS',
           field: 'emis_id' 
        },
		{ 
          title: 'School Name',
           field: 'school_name' 
        },
		//{ 
          // title: 'Establishment Year',
           //field: 'establishment_date' 
        //},
		{ 
			title: this.state.sector_id == 1 ? 'Zone' : 'Cluster',
           field: 'zone_name' 
        },
		{
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">schools</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">School Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true}  onClick={this.toggle}> {<Add />} New School</Button>
                <hr/>

                {
                    //Add user modal
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addSchools} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">SCHOOL ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="emis_id">EMIS&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="emis_id" id="emis_id" invalid={this.state.EmisIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="school_name">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  name="school_name" id="school_name" invalid={this.state.SchoolNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="zone_name">{this.state.cluster_id == 1 ? 'Zone' : 'Cluster'} Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="zone_id" id="zone_id" invalid={this.state.ZoneIdState==='has-danger'} onChange={(e)=>{this.handleInput(e);this.getSectorByZone(e)}} onBlur={(e)=>{this.handleInput(e);this.getSectorByZone(e)}} > 
                                            <option></option>
                                            {
											  Zones.map(zone=>(
												  <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
												)
											  )
											}
                                        </Input>
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
									  <Row>
										<Col xs={4} sm={4} md={4} lg={4} xl={4}>
											{
												(this.state.sector_id!='') && 
													(<Label for="establishment_type">Establishment level:&nbsp;<i className="text-danger">*</i>&nbsp;</Label>)
											}					 
										</Col>
										<Col xs={8} sm={8} md={8} lg={8} xl={8}>
										  {
											(this.state.sector_id=='1') &&
											  (
											  <Input type="select"  name="establishment_type" id="establishment_type" invalid={this.state.EstablishmentTypeState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
													<option></option>
													<option>Full Primary</option>
													<option>LEA</option>
											  </Input>
											  )
											  
											  
										  }
										  
										  {
											(this.state.sector_id=='2') &&
											  (
												  <Input type="select"  name="establishment_type" id="establishment_type" invalid={this.state.EstablishmentTypeState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
														<option></option>
														<option>Form 1</option>
														<option>Form 1-2</option>
														<option>Form 1-3</option>
														<option>Form 1-4</option>
												  </Input>
											  )  
										  }
										  
										  
										  <FormFeedback invalid>
											  The field is required.
										 </FormFeedback>
										 
										</Col>
									  </Row>
									</FormGroup>
								{
									/**
									
									<FormGroup>
									  <Row>
										<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										  <Label for="establishment_date">Establishment Date:</Label>					 
										</Col>
										<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
										  <Input type="date"  name="establishment_date" id="establishment_date" invalid={this.state.EstablishmentDateState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} />
										  <FormFeedback invalid>
											  The field is required.
										 </FormFeedback>
										</Col>
									  </Row>
									</FormGroup>
									**/
									
								}
                                
                                
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
				 
                  <Table responsive striped size="lg">
				 
                      <MaterialTable
                          title={this.state.juridistiction.toUpperCase()}
                          columns={data.columns}
                          
						  data={
								Schools.map(school=>(	
									{
									  
									  emis_id : school.emis_id,
									  //school_name : school.school_name + " " + school.establishment_type + " School",
									  school_name : school.school_name + " School",
									  //establishment_date:school.establishment_date,
									  zone_name : school.zone_name,
									 'OPTIONS': <pre><Link className={(this.state.role=='System Administrator')?"btn":"btn invisible"} to={`/schools`} onClick={()=>this.editSchools(school.emis_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
									            <Link className={(this.state.role=='System Administrator')?"btn":"btn invisible"} to={`/schools`} onClick={()=>this.deleteSchools(school.emis_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}
						       
                          options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
							  /* actions={[
								{
								  icon: 'edit',
								  tooltip: 'Edit School Data',
								  iconProps: { style: { color: "blue" } },
								  onClick: (event, rowData) => this.editSchools(rowData.emis_id),
								  disabled: this.state.role == 'PEA'
								},
								rowData => ({
								  icon: 'delete',
								  tooltip: 'Delete School Data',
								  iconProps: { style: { color: "red" } },
								  onClick: (event, rowData) => this.deleteSchools(rowData.emis_id),
								  disabled: this.state.role == 'PEA'
								})
							  ]} */
                        />
						
					</Table>
						

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateSchools} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">SCHOOL EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								  
								<FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="school_name">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  value={this.state.school_name} name="school_name" id="school_name" invalid={this.state.SchoolNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								{
									/**
									<FormGroup>
									  <Row>
										<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										  <Label for="establishment_type">Establishment level:</Label>					 
										</Col>
										<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
										  <Input type="select"  name="establishment_type" id="establishment_type" invalid={this.state.EstablishmentTypeState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} >
												<option></option>
												<option>Full Primary</option>
												<option>LEA</option>
												<option>Secondary</option>
												<option>CDSS</option>
												<option>Private Secondary</option>
												<option>High School</option>
										  </Input>
										  <FormFeedback invalid>
											  The field is required.
										 </FormFeedback>
										 
										</Col>
									  </Row>
									</FormGroup>
									<FormGroup>
									  <Row>
										<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										  <Label for="establishment_date">Establishment Date:</Label>					 
										</Col>
										<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
										  <Input type="date"  name="establishment_date" id="establishment_date" invalid={this.state.EstablishmentDateState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} />
										  <FormFeedback invalid>
											  The field is required.
										 </FormFeedback>
										</Col>
									  </Row>
									</FormGroup>
									**/
									
								}
								
								<FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="zone_name">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="zone_id" defaultValue={this.state.zone_id} id="zone_id" invalid={this.state.ZoneIdState==='has-danger'} onChange={(e)=>{this.handleInput(e);this.getSectorByZone(e)}} onBlur={(e)=>{this.handleInput(e);this.getSectorByZone(e)}} required={true} > 
                                            <option></option>
                                            {
											  Zones.map(zone=>(
												  <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
												)
											  )
											}
                                        </Input>
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
								  <Row>
									<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										{
											(this.state.sector_id!='') && 
												(<Label for="establishment_type">Establishment level:&nbsp;<i className="text-danger">*</i>&nbsp;</Label>)
										}
									</Col>
									<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
										  {
											(this.state.sector_id=='1') &&
											  (
											  <Input type="select" defaultValue={this.state.establishment_type} name="establishment_type" id="establishment_type" invalid={this.state.EstablishmentTypeState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
													<option></option>
													<option>Full Primary</option>
													<option>LEA</option>
											  </Input>
											  )
											  
											  
										  }
										  
										  {
											(this.state.sector_id=='2') &&
											  (
												  <Input type="select" defaultValue={this.state.establishment_type} name="establishment_type" id="establishment_type" invalid={this.state.EstablishmentTypeState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
														<option></option>
														<option>Form 1</option>
														<option>Form 1-2</option>
														<option>Form 1-3</option>
														<option>Form 1-4</option>
												  </Input>
											  )  
										  }
										  
										  
										  <FormFeedback invalid>
											  The field is required.
										 </FormFeedback>
									 
									</Col>
								  </Row>
								</FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Schools;
