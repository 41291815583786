import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";
import Loader from "../../../components/Loader";

import { Add, Edit, Delete, VpnKey, FormatListNumbered, OfflinePin, ThumbUp, ThumbDown, Warning, RateReview, Check, Equalizer, CallToAction, ArrowLeft } from '@material-ui/icons';
import Ghantt from "../Charts/ActionPlan/Ghantt";

import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";

const API_URL = backend_url['api_url'];
//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class ActionPlans extends Component {
	constructor(props) {
		super(props);
		const visit_id = this.props.match.params.visit_id;
		const recommendation_id = this.props.match.params.recommendation_id;
		const isExpired = this.props.match.params.isExpired;
		const logged_user_id = this.props.match.params.logged_user;
		const lead_inspector_id = this.props.match.params.lead_inspector;
		const first_inspector_id = this.props.match.params.first_inspector;
		//console.log(visit_id);
		//console.log(recommendation_id);

		this.state = {
			//Get logged user role

			role: window.sessionStorage.getItem("urole"),
			modalVisible: false,
			EditModalVisible: false,
			visit_id: visit_id,
			isExpired: isExpired,
			logged_user_id: logged_user_id,
			lead_inspector_id: lead_inspector_id,
			first_inspector_id: first_inspector_id,
			action_plan_id: '',
			activity_name: '',
			activity_start_date: '',
			activity_finish_date: '',
			activity_status: '',
			activity_budget: '',
			status_remarks: '',
			recommendation_id: recommendation_id,
			priority_id: '',
			recommendation_description: '',
			bearer_auth: window.sessionStorage.getItem('token'),
			Recommendations: [],
			Priorities: [],
			Visits: [],
			ActionPlans: [],
			loading: '',
			ActivityNameState: '',
			ActivityStartDateState: '',
			ActivityFinishDateState: '',
			ActivityBudgetState: '',
			ActionPlanStatusState: '',
			PriorityIdState: '',
			StatusRemarksState: ''
		};


		//Toggle modal
		this.toggle = this.toggle.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.addActionPlans = this.addActionPlans.bind(this);
		this.editActionPlans = this.editActionPlans.bind(this);
		this.updateActionPlans = this.updateActionPlans.bind(this);
		this.deleteActionPlans = this.deleteActionPlans.bind(this);

		this.setId = this.setId.bind(this);
	}

	//Handle the display of the modal
	toggle() {
		if (this.state.modalVisible == false) {
			this.setState({ modalVisible: true });
		} else {
			this.setState({
				modalVisible: false,
				action_plan_id: '',
				activity_name: '',
				activity_start_date: '',
				activity_finish_date: '',
				activity_status: '',
				priority_id: '',
				activity_budget: '',
				ActivityNameState: '',
				ActivityStartDateState: '',
				ActivityFinishDateState: '',
				ActivityBudgetState: '',
				ActionPlanStatusState: '',
				PriorityIdState: '',
				StatusRemarksState: ''
			});
		}
	}
	toggleEdit() {
		if (this.state.EditModalVisible == false) {
			this.setState({ EditModalVisible: true });
		} else {
			this.setState({
				EditModalVisible: false,
				action_plan_id: '',
				activity_name: '',
				activity_start_date: '',
				activity_finish_date: '',
				activity_status: '',
				priority_id: '',
				activity_budget: '',
				ActivityNameState: '',
				ActivityStartDateState: '',
				ActivityFinishDateState: '',
				ActivityBudgetState: '',
				ActionPlanStatusState: '',
				PriorityIdState: '',
			});
		}
	}

	//Setting Edit ID
	setId(e) {
		this.setState({
			[e.target.id]: e.target.value
		});
	}

	//Edit user 
	editActionPlans(id) {

		//this.setState({loading : true});
		//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/action_plans/edit/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			//console.log(response.data[0]);
			this.setState({
				id: response.data[0].action_plan_id,
				activity_name: response.data[0].activity_name,
				activity_start_date: response.data[0].activity_start_date,
				activity_finish_date: response.data[0].activity_finish_date,
				activity_status: response.data[0].activity_status,
				activity_budget: response.data[0].activity_budget,
				recommendation_id: response.data[0].recommendation_id,
				priority_id: response.data[0].priority_id,
				visit_id: response.data[0].visit_id,
				status_remarks: response.data[0].status_remarks,
				EditModalVisible: true,
				//loading : false
			})
		}).catch(error => {
			//this.setState({loading : false});
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: "Failed to load the action plan. You should retry or check your network connection ..",
					icon: "error",
					button: true
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	//Update 
	updateActionPlans(event) {
		event.preventDefault()

		const { history } = this.props
		const id = this.state.id

		const action_plan = {
			action_plan_id: this.state.action_plan_id,
			activity_name: this.state.activity_name,
			activity_start_date: this.state.activity_start_date,
			activity_finish_date: this.state.activity_finish_date,
			activity_status: this.state.activity_status,
			activity_budget: this.state.activity_budget,
			recommendation_id: this.state.recommendation_id,
			priority_id: this.state.priority_id,
			visit_id: this.state.visit_id,
			status_remarks: this.state.status_remarks

		}

		//this.setState({loading : true});

		//console.log(user);
		//axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.patch(API_URL + `/action_plans/update/${id}`, action_plan, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({
					EditModalVisible: false,
					ActionPlans: [response.data.data, ...this.state.ActionPlans],
					//loading : false
				});
				swal({
					title: "",
					text: "Action plan updated successfully!",
					icon: "success",
					timer:2500,
					buttons: false
				  })
				
			})
			.catch(error => {
				this.setState({
					EditModalVisible: false,
					//loading : false
				});
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: "Failed to update the action plan. You should retry or check your network connection..",
						icon: "error",
						button: true
					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
	}

	//delete
	deleteActionPlans(id) {

		//e.preventDefault();
		//Confirming deletion
		swal({
			title: "",
			text: "Are you sure to delete the Activity?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				confirm: {
					text: "Delete",
					value: true,
					visible: true,
					className: "",
					closeModal: true
				},
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: "",
					closeModal: true,
				}
			}
		})
			.then((willDelete) => {
				//Confirmed condition
				if (willDelete) {
					//this.setState({loading : true});

					//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
					axios.delete(API_URL + `/action_plans/delete/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then((response) => {
						//this.setState({loading : false});

						swal({
							title: "",
							text: "The activity's record Deleted Successfully!",
							icon: "success",
							timer:2500,
							buttons: false
						  })
						  axios.get(API_URL + `/action_plans/`+ this.state.visit_id + '&' + this.state.recommendation_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
										  { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
										  ).then(
										  (visit) => {
											  this.setState({
												ActionPlans: visit.data
										   })
								  }
							  )
					}).catch(error => {
						if (error.response.status == '401') {
							swal({
								title: "",
								text: "Your session has expired. You'll automatically be logged out within seconds.",
								icon: "warning",
								timer: 2000
							})
								.then((sucess) => {
									window.sessionStorage.clear();
									window.location.reload();
								});
						} else {
							swal({
								title: "",
								text: "Failed to delete the action plan. You should retry or check your network connection..",
								icon: "error",
								button: true
							})
								.then((sucess) => {
									window.location.reload();
								});
						}
					})
					//unconfirmed condition
				} else {
					//window.location.reload();
				}
			});
	}
	componentDidMount() {

		this.setState({ loading: true });

		//Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		//Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		Promise.all([axios.get(API_URL + `/action_plans/by_recommendation/${this.state.recommendation_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/recommendations/major/edit/${this.state.recommendation_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/priorities`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
			([action_plan, recommendation, priority]) => {
				this.setState({
					ActionPlans: action_plan.data,
					Recommendations: recommendation.data,
					Priorities: priority.data,
					loading: false
				})
			}
		).catch(error => {
			this.setState({ loading: false });
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: "Failed to load the action plans. Check your network connection while we reload the page.",
					icon: "error",
					button: true
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	//Add user
	addActionPlans(event) {
		event.preventDefault()

		const { history } = this.props
		const recommendation = {
			recommendation_id: this.state.recommendation_id,
			recommendation_type: this.state.recommendation_type,
			recommendation_description: this.state.recommendation_description,
			nes_id: this.state.nes_id,
			category_id: this.state.category_id,
			visit_id: this.state.visit_id,

		}
		const action_plan = {
			activity_name: this.state.activity_name,
			activity_start_date: this.state.activity_start_date,
			activity_finish_date: this.state.activity_finish_date,
			activity_status: this.state.activity_status,
			activity_budget: this.state.activity_budget,
			recommendation_id: this.state.recommendation_id,
			priority_id: this.state.priority_id,
			visit_id: this.state.visit_id,
			status_remarks: this.state.status_remarks

		}

		//this.setState({loading : true});

		//console.log(user);
		//console.log(exam);
		//axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.post(API_URL + '/action_plans', action_plan, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(({ data }) => {
				console.log(data)
				this.setState({
					modalVisible: false,
					ActionPlans: [data.data, ...this.state.ActionPlans],
					//loading : false
				});
				swal({
					title: "",
					text: "Activity Added Successfully!",
					icon: "success",
					timer: 1250
				})
			})
			.catch(error => {
				this.setState({
					modalVisible: true,
					//loading : false
				});

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: "Failed to create the action plan. You should retry or check your network connection..",
						icon: "error",
						button: true
					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
	}


	//Form inputs handler and validator
	handleInput(e) {
		e.preventDefault();
		if (e.target.name === 'activity_start_date') {
			if (e.target.value != '') {
				this.setState({ ActivityStartDateState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ ActivityStartDateState: 'has-danger' });
			}

		} else if (e.target.name === 'activity_name') {
			if (e.target.value != '') {
				this.setState({ ActivityNameState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ ActivityNameState: 'has-danger' });
			}

		} else if (e.target.name === 'activity_finish_date') {
			if (e.target.value != '' && e.target.value > this.state.activity_start_date) {
				this.setState({ ActivityFinishDateState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ ActivityFinishDateState: 'has-danger' });
			}

		} else if (e.target.name === 'activity_budget') {
			if (e.target.value != '') {
				this.setState({ ActivityBudgetState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ ActivityBudgetState: 'has-danger' });
			}

		} else if (e.target.name === 'recommendation_id') {
			if (e.target.value != '') {
				this.setState({ RecommendationIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ RecommendationIdState: 'has-danger' });
			}
		} else if (e.target.name === 'status_remarks') {
			if (e.target.value != '') {
				this.setState({ StatusRemarksState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ StatusRemarksState: 'has-danger' });
			}
		} else if (e.target.name === 'priority_id') {
			if (e.target.value != '') {
				this.setState({ PriorityIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ PriorityIdState: 'has-danger' });
			}

		} else if (e.target.name === 'activity_status') {
			if (e.target.value != '') {
				this.setState({ ActionPlanStatusState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ ActionPlanStatusState: 'has-danger' });
			}

		}



	}

	render() {
		const { ActionPlans, Recommendations, Priorities } = this.state;

		const role = this.state.role;

		//console.log("Role " + this.state.role);


		const data = {
			columns: [
				/* { 
				  title: 'Activity ID',
				   field: 'action_plan_id' 
				}, */
				{
					title: 'Activity Name',
					field: 'activity_name'
				},
				{
					title: 'Start Date',
					field: 'activity_start_date',
				},
				{
					title: 'End Date',
					field: 'activity_finish_date',
				},
				{
					title: 'Activity Status',
					field: 'activity_status',
				},
				{
					title: 'Budget',
					field: 'activity_budget',
				},
				{
					title: 'Priority Level',
					field: 'priority_id',
				},
				{
					title: 'Status Remarks',
					field: 'status_remarks',
				},
				{
					title: 'Actions',
					field: 'OPTIONS',
					export: false,
				},
			],

		};

		return (
			<React.Fragment>
				<div className="container-fluid">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>

					<Row className="align-items-center">
						<Col sm={6}>
							<div className="page-title-box">
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>

									<li className="breadcrumb-item active">Action Plan</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">
								<Link className="btn btn-primary " to={`/recommendations/` + this.state.visit_id + '&' + this.state.recommendation_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
							</div>
						</Col>
					</Row>

					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<h3 className="card-title">Action Plan Management</h3>
									<hr />
									<Button hidden={(this.state.role != "Head Teacher") ? (this.state.role != "Secondary Advisor") ? true : false : false} color="success" onClick={this.toggle}> {<Add />} New Activity</Button>
									<hr />

									<div className="card">
										<div className="card-body">
											<div className="row">

												<div className="col-md-12 col-lg-12 col-xl-12">
													<hr />
													Recommendation
													<hr />
													<em className="justify-content-center">

														<ul>
															{
																Recommendations.map(recommend => (
																	<center key={recommend.recommendation_id}><li>{recommend.recommendation_description}</li></center>
																))
															}
														</ul>


													</em>
												</div>



											</div>

											<div className="row">

												<div className="col-md-12 col-lg-12 col-xl-12">
													<hr />
													Ghantt Chart for the activities on this recommendation
													<hr />
													<Ghantt recommendation_id={this.state.recommendation_id} />
												</div>

											</div>

										</div>

									</div>




									{
										//Add user modal
										/*
										NOTE the usage of the onChange and onBlur event to call the same function.
										The onBlur event has been used to enforce validation once the user move out of the field.
										*/
									}
									<Modal isOpen={this.state.modalVisible}>
										<Form onSubmit={this.addActionPlans} >
											<ModalHeader toggle={this.toggle}>
												<center className="justify-content-center">ACTION PLAN'S ACTIVITY ADDITION FORM</center>
											</ModalHeader>
											<ModalBody>

												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_name">ACTIVITY NAME&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="textarea" name="activity_name" id="activity_name" invalid={this.state.ActivityNameState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>

														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_start_date">START DATE&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="date" name="activity_start_date" id="activity_start_date" invalid={this.state.ActivityStartDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_finish_date">DUE DATE&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="date" name="activity_finish_date" id="activity_finish_date" invalid={this.state.ActivityFinishDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required and the supplied date must be greater than the activity start date.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_budget">Activity Budget&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="number" name="activity_budget" id="activity_budget" invalid={this.state.ActivityBudgetState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>

												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_status">Activity Status&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="select" name="activity_status" id="activity_status" invalid={this.state.ActionPlanStatusState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																<option></option>
																<option>Open</option>
																<option>Partially Completed</option>
																<option>Completed</option>
																<option>Closed</option>

															</Input>

															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>

												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="priority_id">Priority Level&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="select" name="priority_id" id="priority_id" invalid={this.state.PriorityIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																<option></option>
																{
																	Priorities.map(priority => (
																		<option value={priority.priority_id} key={priority.priority_id}>{priority.priority_description}</option>
																	)
																	)
																}
															</Input>
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="status_remarks">STATUS REMARKS&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="textarea" name="status_remarks" id="status_remarks" invalid={this.state.StatusRemarksState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>

														</Col>
													</Row>
												</FormGroup>



											</ModalBody>
											<ModalFooter>
												<Button type="submit" color="success">Create</Button>
												<Button color="danger" onClick={this.toggle}>Cancel</Button>
											</ModalFooter>
										</Form>
									</Modal>


									<Table responsive striped size="lg">
										<MaterialTable
											title="ACTION PLAN(S)"
											columns={data.columns}
											data={
												ActionPlans.map((action_plan) => (
													(this.state.role != "Head Teacher") ?
														({
															//action_plan_id : action_plan.action_plan_id,
															activity_name: action_plan.activity_name,
															activity_start_date: action_plan.activity_start_date,
															activity_finish_date: action_plan.activity_finish_date,
															activity_status: action_plan.activity_status,
															activity_budget: "MWK " + action_plan.activity_budget,
															priority_id: action_plan.priority_description,
															status_remarks: action_plan.status_remarks,
															'OPTIONS': <pre><Link className={(this.state.role != "Secondary Advisor") ? "btn disabled" : "btn"} to={`/action_plans/` + this.state.visit_id + '&' + this.state.recommendation_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.editActionPlans(action_plan.action_plan_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className={(this.state.role != "Secondary Advisor") ? "btn disabled" : "btn"} to={`/action_plans/` + this.state.visit_id + '&' + this.state.recommendation_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.deleteActionPlans(action_plan.action_plan_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
														})

														:
														({
															//action_plan_id : action_plan.action_plan_id,
															activity_name: action_plan.activity_name,
															activity_start_date: action_plan.activity_start_date,
															activity_finish_date: action_plan.activity_finish_date,
															activity_status: action_plan.activity_status,
															activity_budget: "MWK " + action_plan.activity_budget,
															priority_id: action_plan.priority_description,
															status_remarks: action_plan.status_remarks,
															'OPTIONS': <pre><Link to={`/action_plans/` + this.state.visit_id + "&" + this.state.recommendation_id} onClick={() => this.editActionPlans(action_plan.action_plan_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link to={`/action_plans/` + this.state.visit_id + "&" + this.state.recommendation_id} onClick={() => this.deleteActionPlans(action_plan.action_plan_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
														})

												))}

											options={{
												exportButton: true,
												tableLayout: 'auto',
												loadingType: 'overlay',
												pageSizeOptions: [5, 20, 50, 100, 500, 1000],
												emptyRowsWhenPaging: false,
												columnsButton: true,
												exportAllData: true,
												actionsColumnIndex: -1,
												search: true,
												sorting: true
											}}
										/>

									</Table>

									{
										//Edit user record modal
									}
									<Modal isOpen={this.state.EditModalVisible}>
										<Form onSubmit={this.updateActionPlans} >
											<ModalHeader toggle={this.toggleEdit}>
												<center className="justify-content-center">ACTION PLAN'S EDITING FORM</center>
											</ModalHeader>
											<ModalBody>
												<FormGroup>
													<Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
												</FormGroup>

												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_name">ACTIVITY NAME&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="textarea" defaultValue={this.state.activity_name} name="activity_name" id="activity_name" invalid={this.state.ActivityNameState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_start_date">START DATE&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="date" defaultValue={this.state.activity_start_date} name="activity_start_date" id="activity_start_date" invalid={this.state.ActivityStartDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_finish_date">DUE DATE&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="date" defaultValue={this.state.activity_finish_date} name="activity_finish_date" id="activity_finish_date" invalid={this.state.ActivityFinishDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required and the supplied date must be greater than the activity start date.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_budget">Activity Budget&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="number" defaultValue={this.state.activity_budget} name="activity_budget" id="activity_budget" invalid={this.state.ActivityBudgetState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>

												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="activity_status">Activity Status&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="select" defaultValue={this.state.activity_status} name="activity_status" id="activity_status" invalid={this.state.ActionPlanStatusState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																<option></option>
																<option>Open</option>
																<option>Partially Completed</option>
																<option>Completed</option>
																<option>Closed</option>

															</Input>

															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>


												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="priority_id">Priority Level&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="select" defaultValue={this.state.priority_id} name="priority_id" id="priority_id" invalid={this.state.PriorityIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																<option></option>
																{
																	Priorities.map(priority => (
																		<option value={priority.priority_id} key={priority.priority_id}>{priority.priority_description}</option>
																	)
																	)
																}
															</Input>

															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
													</FormGroup>
													<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="status_remarks">STATUS REMARKS&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="textarea" name="status_remarks" id="status_remarks" invalid={this.state.StatusRemarksState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>

														</Col>
													</Row>
												</FormGroup>



											</ModalBody>
											<ModalFooter>
												<Button type="submit" color="success">Update</Button>
												<Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
											</ModalFooter>
										</Form>
									</Modal>

								</div>
							</div>
						</div>
					</div>


				</div>
			</React.Fragment>
		);
	}
}

export default ActionPlans;
