import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Constituencies extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
	  constituency_id:'',
	  constituency_name:'',
	  district_id:'',
      bearer_auth:window.sessionStorage.getItem('token'),
      role:window.sessionStorage.getItem('urole'),
	  Constituencies:[],
	  Districts:[],
	  juridistiction:'',
	  loading: '',
	  
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addConstituencies = this.addConstituencies.bind(this);
	this.editConstituencies = this.editConstituencies.bind(this);
	this.updateConstituencies = this.updateConstituencies.bind(this);
	this.deleteConstituencies =this.deleteConstituencies.bind(this);
	
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		  modalVisible:false,
		  constituency_id:'',
		  constituency_name:'',
		  district_id:'',
		  ConstituencyIdState:'',
		  ConstituencyNameState:'',
		  DistrictIdState:'',
	  }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		  EditModalVisible:false,
		  constituency_id:'',
		  constituency_name:'',
		  district_id:'',
		  ConstituencyIdState:'',
		  ConstituencyNameState:'',
		  DistrictIdState:'',
		  
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Schools
  editConstituencies(id) {
	 
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/constituencies/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].constituency_id,
		  constituency_name : response.data[0].constituency_name,
		  district_id: response.data[0].district_id,
		  //division_id : response.data[0].division_id,
		  EditModalVisible:true,
		  //loading: false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "error",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the constituency. You should retry or check your network connection ..",
					  icon: "error",
					  timer : 1250
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateConstituencies(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const constituency = {
		  constituency_name : this.state.constituency_name,
		  district_id : this.state.district_id,
		  //division_id : this.state.division_id,
		  
        }
		
		//this.setState({loading : true});
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/constituencies/update/${id}`, constituency,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
                //this.setState({loading : false});
				swal({
				  title: "",
				  text: "Constituency updated successfully!",
				  icon: "success",
				  timer : 2000
				})
				.then((sucess) => {
					window.location.reload();
				});
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
				
				  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "error",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to update the constituency. You should retry, and ensure the constituency ID should be unique or check your network connection..",
						  icon: "error",
						  timer : 1250
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }
	 
	 //delete
	deleteConstituencies(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the constituency?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/constituencies/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The constituency record Deleted Successfully!",
								  icon: "success",
								  timer : 2000
								})
								.then((sucess) => {
									window.location.reload();
								});
							}).catch(error => {
								  if(error.response.status=='401'){
									swal({
									  title: "",
									  text: "Your session has expired. You'll automatically be logged out within seconds.",
									  icon: "error",
									  timer : 2000
									})
									.then((sucess) => {
										window.sessionStorage.clear();
										window.location.reload();
									});
								  }else{
										  swal({
											  title: "",
											  text: "Failed to delete the constituency. You should retry or check your network connection..",
											  icon: "error",
											  timer : 1250
											})
											.then((sucess) => {
												window.location.reload();
											});
								  }
							  });
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 if(this.state.role == 'Division Manager'){
		 const div_id = window.sessionStorage.getItem('division');
		 
		 Promise.all([axios.get(API_URL + `/divisions/constituency/${div_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/divisions/${div_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([constituency, district, division_data])=>{
			 this.setState({
				 Constituencies:constituency.data,
				 Districts:district.data,
				 juridistiction:(division_data.data.length>=1)? "AVAILABLE CONSTITUENCIES IN " + division_data.data[0]['division_name'] + " DIVISION":'NULL DATA ON',
				 loading: false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
			  
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "error",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the constituencies. Check your network connection while we reload the page.",
						  icon: "error",
						  timer : 1250
						})
						.then((sucess) => {
							window.location.reload();
						});
				  }
          })
		 
		 
	 }else if(this.state.role=='DEM'){
		 const dist_id = window.sessionStorage.getItem('district');
		 
		Promise.all([axios.get(API_URL + `/districts/constituency/${dist_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get( API_URL + `/districts/${dist_id}`, { headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([constituency, district, district_data])=>{
			 this.setState({
				 Constituencies:constituency.data,
				 Districts:district.data,
				 juridistiction:(district_data.data.length>=1)? "CONSTITUENCIES IN " + district_data.data[0]['district_name'] + " DISTRICT":'NULL DATA ON',
				 loading: false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "error",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the constituencies. Check your network connection while we reload the page.",
						  icon: "error",
						  timer : 1250
						})
						.then((sucess) => {
							window.location.reload();
						});
				  }
          })
		 
		 
	 }else if(this.state.role=='Ministry HQ' || this.state.role=='Inspector' || this.state.role == 'System Administrator'){
		 Promise.all([axios.get(API_URL + `/constituencies`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([constituency, district])=>{
			 this.setState({
				 Constituencies:constituency.data,
				 Districts:district.data,
				 juridistiction:'CONSTITUENCIES IN MALAWI',
				 loading: false,
			 })
		 }
		 ).catch(error => {
			  this.setState({loading : false});
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "error",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the constituencies. Check your network connection while we reload the page.",
						  icon: "error",
						  timer : 1250
						})
						.then((sucess) => {
							window.location.reload();
						});
				  }
          })
	 }
	 
	 
	 
	 
  }
	
  //Add user
	 addConstituencies(event){
		event.preventDefault()

        const { history } = this.props

        const constituency = {
		  constituency_id : this.state.constituency_id,
		  constituency_name: this.state.constituency_name,
		  district_id : this.state.district_id,
		  //division_id : this.state.division_id,
		  
        }
        
		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/constituencies', constituency,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading: false,
				});
			swal({
			  title: "",
			  text: "New constituency Added Successfully!",
			  icon: "success",
			  timer : 2000
			})
			.then((sucess) => {
				window.location.reload();
			});
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading: false,
				});
				
				if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "error",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to create the constituency. You should retry, and ensure the constituency ID should be unique or check your network connection..",
					  icon: "error",
					  timer : 1250
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='constituency_id'){
		   if(e.target.value!=''){
			   this.setState({ConstituencyIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ConstituencyIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='constituency_name'){
		   if(e.target.value!=''){
			   this.setState({ConstituencyNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ConstituencyNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='district_id'){
		   if(e.target.value!=''){
			   this.setState({DistrictIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DistrictIdState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {Constituencies, Districts} = this.state;
    const data = {
      columns: [
        { 
          title: 'Constituency Id',
           field: 'constituency_id' 
        },
		{ 
          title: 'Constituency Name',
           field: 'constituency_name' 
        },
		{ 
           title: 'District Name',
           field: 'district_name' 
        },
        
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">constituencies</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
				
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Constituency Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true} onClick={this.toggle}> {<Add />} New Constituency</Button>
                <hr/>

                {
                      //Add user modal
						/*
							NOTE the usage of the onChange and onBlur event to call the same function.
							The onBlur event has been used to enforce validation once the user move out of the field.
						*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addConstituencies} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">CONSTITUENCY ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="emis_id">Constituency ID</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="constituency_id" id="constituency_id" invalid={this.state.ConstituencyIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
                                      <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="constituency_name">Constituency Name</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  name="constituency_name" id="constituency_name" invalid={this.state.ConstituencyNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="district_name">District Name</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required> 
                                            <option></option>
                                            {
											  Districts.map(district=>(
												  <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
												)
											  )
											}
                                        </Input>
										<FormFeedback invalid>
										  The field is required.
									    </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title={this.state.juridistiction.toUpperCase()}
                          columns={data.columns}
                          data={
								Constituencies.map(constituency=>(	
									{
									  
									  constituency_id : constituency.constituency_id,
									  constituency_name : constituency.constituency_name,
									  district_name : constituency.district_name,
									 'OPTIONS': <pre><Link className={(this.state.role=='System Administrator')?"btn":"btn disabled"}  to={`/constituencies`} onClick={()=>this.editConstituencies(constituency.constituency_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
									           <Link className={(this.state.role=='System Administrator')?"btn":"btn disabled"} to={`/constituencies`} onClick={()=>this.deleteConstituencies(constituency.constituency_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateConstituencies} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">CONSTITUENCY EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								  
								<FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="constituency_name">Constituency Name</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
									  <Input type="text"  value={this.state.constituency_name} name="constituency_name" id="constituency_name" invalid={this.state.ConstituencyNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="district_name">District Name</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" value={this.state.district_id} name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required> 
                                            <option></option>
                                            {
											  Districts.map(district=>(
												  <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
												)
											  )
											}
                                        </Input>
										<FormFeedback invalid>
										  The field is required.
									    </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Constituencies;
