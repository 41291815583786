import React, { useState, useEffect } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Table, TableBody, TableHead,Container, Row, FormFeedback, Media, Col,Modal, ModalHeader, ModalBody, ModalFooter,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import LevelAchievedTexts from './LevelAchievedTexts';
//import KeyEvidenceStatusTable from './KeyEvidenceStatusTable';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import PDF, { Text, AddPage, Line, Image, Html } from 'jspdf-react';
import axios from 'axios';

import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];


export default function KeyEvidenceOnVisits(props){
	const [Standards, setStandards] = useState([]);
	const [KeyEvidence, setKeyEvidence] = useState([]);
	const [LevelAchieved, setLevelAchieved] = useState([]);
	const [TrackEvidence, setTrackEvidence] = useState([]);
	const [MinorRecommendation, setMinorRecommendation] = useState([]);
	const visit_id = props.visit_id;
	const bearer_auth=window.sessionStorage.getItem('token');

	
	//console.log("Pitson " + visit_id);
	
	
	useEffect(()=>{
		//Fetching
		Promise.all([
			 axios.get(API_URL + `/national_standards`,{ headers: {"Authorization" : `Bearer ${bearer_auth}`}}),
			 axios.get(API_URL + `/key_evidences`,{ headers: {"Authorization" : `Bearer ${bearer_auth}`}}),
			 axios.get(API_URL + `/key_evidences/nes_level_achieved/${visit_id}`,{ headers: {"Authorization" : `Bearer ${bearer_auth}`}}),
			 axios.get(API_URL + `/track_evidences`,{ headers: {"Authorization" : `Bearer ${bearer_auth}`}}), 
			 axios.get(API_URL + `/recommendations/minor/${visit_id}`,{ headers: {"Authorization" : `Bearer ${bearer_auth}`}})
		 ]).then(([standard, key_evidences, nes_achievement, track_evidence, minor_recommendation])=>{
			      setStandards(standard.data);
				  setKeyEvidence(key_evidences.data);
			      setLevelAchieved(nes_achievement.data);
				  setTrackEvidence(track_evidence.data);
				  setMinorRecommendation(minor_recommendation.data);
		
			}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the data. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	},[])
	
	/*
	Codebase for handling the levels and key evidences
	*/
	
	//Store all retrieved standards
	//const Stds = this.state.Standards;
	//const LevelAchieved = this.state.LevelAchieved;
	
	
	
	//console.log(Standards);
	//console.log(LevelAchieved);
	//console.log(KeyEvidence);
	//console.log(TrackEvidence);
	
	return(
	<div>
	   
	    {
				Standards.map((std, idx=0)=>(	
					<div>
						
						 <LevelAchievedTexts visit_id={visit_id} nes_id={std['nes_id']} nes_name={std['nes_name']} standards={Standards} key_evidence={KeyEvidence} level_achieved={LevelAchieved} track_evidence={TrackEvidence} minor_recommendation={MinorRecommendation} />
						
						
						
						{
							//<LevelAchievedTexts levels={levels_array} standards={Standards} key_evidence={KeyEvidence} level_achieved={LevelAchieved} track_evidence={TrackEvidence} />
							 
							//<KeyEvidenceStatusTable key_evidence={KeyEvidence} visit_id={props.visit_id} nes_id={std['nes_id']} />
						}
						
						
						<span hidden='True'>
						{
						idx++
						}
						</span>
					</div>
					
					
		  ))
		}  

	</div>
	)
}