import React from 'react'
import { Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import Loader from "../../../components/Loader";
import { Add, Edit, Delete, LockOpen } from '@material-ui/icons';
import axios from "../../../services/axios"
import swal from 'sweetalert';

function Departments() {

    const [loading, setLoading] = React.useState(false);
    const [departments, setDepartments] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const [toggle, setToggle] = React.useState(false)
    const [modal, setModal] = React.useState(false)
    const [active, setActive] = React.useState({})

    const [deptName, setName] = React.useState("")
    const [shortName, setShortName] = React.useState("")
    const [emailAddress, setemailAddress] = React.useState("")
    const [contactPerson, setContactPerson] = React.useState("")

    const setupData = () => {
        axios.get('departments').then(({ data }) => {
            setDepartments(data.sort((a, b) => a.created_at < b.created_at))
        })
    }
    React.useEffect(() => {
        setupData()
    }, [])

    const clearForm = () => {
        setName("")
        setShortName("")
        setemailAddress("")
        setContactPerson("")
    }

    const toggleModal = (isEditing = false) => {
        if (isEditing == false) clearForm()
        setModal(!modal)
    }

    const toggleEdit = (dept) => {
        setActive(dept)
        setName(dept.name)
        setShortName(dept.short_name)
        setemailAddress(dept.email)
        setContactPerson(dept.contact_person)
        setEdit(true)
        toggleModal(true)
    }

    const resetForm = () => {
        setLoading(false)
        setEdit(false)
        setToggle(false)
        setModal(false)
        setActive({})
        setName("")
        setShortName("")
        setemailAddress("")
        setContactPerson("")
    }

    const deleteDept = (id) => {
        swal({
            title: "",
            text: "Are you sure to delete the department?",
            icon: "warning",
            dangerMode: true,
            buttons: {
                confirm: {
                    text: "Delete",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                },
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                }
            }
        })
            .then((willDelete) => {
                //Confirmed condition
                if (willDelete) {
                    axios.delete(`departments/${id}`)
                        .then(() => {
                            setDepartments(departments.filter(d => d.department_id !== id))
                            swal({
                                title: "",
                                text: "Department Deleted Successfully!",
                                icon: "success",
                                timer: 2500, button: false
                            })
                        })
                }
            })
    }

    const editDept = () => {
        const dept = {
            contact_person: contactPerson,
            short_name: shortName,
            name: deptName,
            email: emailAddress
        }
        axios.patch(`departments/${active.department_id}`, dept).then(({ data }) => {
            swal({
                title: "",
                text: "New Department Edited Successfully!",
                icon: "success",
                timer: 2500, button: false
            })
            setupData()
        }).catch(e => {
            swal({
                title: "",
                text: "Something went wrong!",
                icon: "error",
                timer: 2500, button: false
            })
        })
        toggleModal()
    }

    const submitForm = (e) => {
        e.preventDefault()
        if (edit === true) return editDept()
        const dept = {
            contact_person: contactPerson,
            short_name: shortName,
            name: deptName,
            email: emailAddress
        }
        axios.post('departments', dept).then(({ data }) => {
            swal({
                title: "",
                text: "New Department Added Successfully!",
                icon: "success",
                timer: 2500, button: false
            })
            setDepartments([data.data, ...departments])
        }).catch(e => {
            swal({
                title: "",
                text: "Something went wrong!",
                icon: "error",
                timer: 2500, button: false
            })
        })
        setupData()
        resetForm()
        toggleModal()
    }

    const columns = [
        {
            title: 'Name',
            field: 'name'
        },
        {
            title: 'Short Name',
            field: 'short_name'
        },
        {
            title: 'Email',
            field: 'email'
        },
        {
            title: 'Contact Person',
            field: 'contact_person'
        },
        {
            title: 'Options',
            field: 'options'
        },
    ]
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row className="position-center">
                    {loading && <Loader />}
                </Row>
                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">Home</Link>
                                </li>

                                <li className="breadcrumb-item active">departments</li>
                            </ol>
                        </div>
                    </Col>

                    <Col sm="6">
                        <div className="float-right d-none d-md-block">

                        </div>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">Departments Management</h3>
                                <hr />
                                <Button onClick={toggleModal} color="success"> {<Add />} New Department</Button>
                                <hr />
                                <div>
                                    <table className="table table-responsive table-bordered table-condensed table-hover table-striped">
                                        <MaterialTable
                                            title="DEPARTMENTS"
                                            columns={columns}
                                            data={departments.map(dept => (
                                                {
                                                    ...dept,
                                                    'options': <><><Edit onClick={() => {
                                                        toggleEdit(dept)
                                                    }} style={{ cursor: 'pointer' }} color='primary' /></><>&nbsp;&nbsp;<Delete onClick={() => deleteDept(dept.department_id)} style={{ cursor: 'pointer', color: 'red' }} /></></>
                                                }
                                            ))}
                                            options={{
                                                exportButton: true,
                                                tableLayout: 'auto',
                                                loadingType: 'overlay',
                                                pageSizeOptions: [5, 20, 50, 100, 500, 1000],
                                                emptyRowsWhenPaging: false,
                                                columnsButton: true,
                                                exportAllData: true,
                                                actionsColumnIndex: -1,
                                                search: true,
                                                sorting: true
                                            }}
                                        />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modal}>
                <Form autoComplete="off" onSubmit={(e) => submitForm(e)} >
                    <ModalHeader>
                        <center className="justify-content-center">{edit ? 'EDIT' : 'ADD'} DEPARTMENT</center>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Label for="Name">Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input autoComplete='off' type="text" name="first_name" id="first_name" defaultValue={deptName} onChange={(e) => {
                                        const { value } = e.target
                                        setName(value)
                                    }} required={true} />
                                    <FormFeedback invalid>
                                        The field is required.
                                    </FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Label for="Name">Short Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input autoComplete='off' type="text" name="short_name" id="short_name" defaultValue={shortName} onChange={(e) => {
                                        const { value } = e.target
                                        setShortName(value)
                                    }} required={true} />
                                    <FormFeedback invalid>
                                        The field is required.
                                    </FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Label for="email">Email&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input autoComplete='off' type="email" name="email" id="emailAddress" defaultValue={emailAddress} onChange={(e) => {
                                        const { value } = e.target
                                        setemailAddress(value)
                                    }} required={true} />
                                    <FormFeedback invalid>
                                        The field is required.
                                    </FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Label for="Name">Contact Person&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input autoComplete='off' type="text" name="first_name" id="first_name" defaultValue={contactPerson} onChange={(e) => {
                                        const { value } = e.target
                                        setContactPerson(value)
                                    }} required={true} />
                                    <FormFeedback invalid>
                                        The field is required.
                                    </FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="success">Submit</Button>
                        <Button color="danger" onClick={resetForm}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Departments