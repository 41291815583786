import React, { Component } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import { Add, Edit, Delete, VpnKey, FormatListNumbered, OfflinePin, ThumbUp, ThumbDown, Warning, RateReview, Check, Equalizer, CallToAction, ArrowLeft } from '@material-ui/icons';
import swal from 'sweetalert';
import GanttChart from "./Gantt"

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Recommendations extends Component {
	constructor(props) {
		super(props);
		const visit_id = this.props.match.params.visit_id;
		const isExpired = this.props.match.params.isExpired;
		const logged_user_id = this.props.match.params.logged_user;
		const lead_inspector_id = this.props.match.params.lead_inspector;
		const first_inspector_id = this.props.match.params.first_inspector;


		this.state = {

			//Get logged user role
			role: window.sessionStorage.getItem("urole"),
			modalVisible: false,
			EditModalVisible: false,
			recommendation_id: '',
			recommendation_type: 'Major',
			recommendation_description: '',
			nes_id: '',
			category_id: '',
			visit_id: visit_id,
			isExpired: isExpired,
			logged_user_id: logged_user_id,
			lead_inspector_id: lead_inspector_id,
			first_inspector_id: first_inspector_id,
			bearer_auth: window.sessionStorage.getItem('token'),
			Recommendations: [],
			Categories: [],
			Visit: {},
			loading: '',
			activeTab: ['Inspector'].includes(window.sessionStorage.getItem("urole")) ? 2 : 1,

		};

		//Toggle modal
		this.refreshRecommendationsList = this.refreshRecommendationsList.bind(this);
		this.setActiveTab = this.setActiveTab.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.addRecommendations = this.addRecommendations.bind(this);
		this.editRecommendations = this.editRecommendations.bind(this);
		this.updateRecommendations = this.updateRecommendations.bind(this);
		this.deleteRecommendations = this.deleteRecommendations.bind(this);
		;

		this.setId = this.setId.bind(this);
	}

	refreshRecommendationsList() {
		this.setState({ loading: true });
		axios.get(API_URL + `/recommendations/major/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
		.then(
			(resp) => {
				this.setState({
					Recommendations: resp.data,
					loading: false
				})
			}
		)
	}

	setActiveTab(tabId) {
		this.setState({
			activeTab: tabId
		})
	}

	//Handle the display of the modal
	toggle() {
		if (this.state.modalVisible == false) {
			this.setState({ modalVisible: true });
		} else {
			this.setState({
				modalVisible: false,
				recommendation_id: '',
				recommendation_description: '',
				category_id: '',
				RecommendationIdState: '',
				CategoryIdState: '',
			});
		}
	}
	toggleEdit() {
		if (this.state.EditModalVisible == false) {
			this.setState({ EditModalVisiblet: true });
		} else {
			this.setState({
				EditModalVisible: false,
				recommendation_id: '',
				recommendation_description: '',
				category_id: '',
				CategoryIdState: '',
				RecommendationIdState: '',
			});
		}
	}

	//Setting Edit ID
	setId(e) {
		this.setState({
			[e.target.id]: e.target.value
		});
	}

	//Edit user 
	editRecommendations(id) {

		//this.setState({loading : true});

		//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/recommendations/major/edit/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			//console.log(response.data[0]);
			this.setState({
				id: response.data[0].recommendation_id,
				recommendation_description: response.data[0].recommendation_description,
				category_id: response.data[0].category_id,
				visit_id: response.data[0].visit_id,
				EditModalVisible: true,
				//loading : false

			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	//Update 
	updateRecommendations(event) {
		event.preventDefault()

		const { history } = this.props
		const id = this.state.id

		const recommendation = {
			recommendation_id: this.state.recommendation_id,
			recommendation_type: this.state.recommendation_type,
			recommendation_description: this.state.recommendation_description,
			nes_id: this.state.nes_id,
			category_id: this.state.category_id,
			visit_id: this.state.visit_id,

		}

		//this.setState({loading : true});

		//console.log(user);
		//axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.patch(API_URL + `/recommendations/major/update/${id}`, recommendation, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({
					EditModalVisible: false,
					//loading : false
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.data['status'] == 'success') {
					this.setState({
						EditModalVisible: false,
						//loading : false,
					});
					swal({
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						timer: 2500,
						button: false
					})
					Promise.all([
						axios.get(API_URL + `/recommendations/major/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
						axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
					]).then(
						([recommendation, category, visit]) => {
							this.setState({
								Recommendations: recommendation.data,
								Categories: category.data,
								Visit: visit.data[0],
								loading: false
							})
						}
					)
				} else {
					this.setState({
						EditModalVisible: true,
					});
					swal({
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						button: true
					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
			.catch(error => {
				this.setState({
					EditModalVisible: false,
					//loading : false
				});

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							this.setState({ EditModalVisible: true });
						});
				}
			})
	}

	//delete
	deleteRecommendations(id) {
		//Confirming deletion
		swal({
			title: "",
			text: "Are you sure to delete the recommendation?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				confirm: {
					text: "Delete",
					value: true,
					visible: true,
					className: "",
					closeModal: true
				},
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: "",
					closeModal: true,
				}
			}
		})
			.then((willDelete) => {
				//Confirmed condition
				if (willDelete) {
					//this.setState({loading : true});

					//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
					axios.delete(API_URL + `/recommendations/major/delete/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then((response) => {
						//this.setState({loading : false});
						swal({
							title: "",
							text: "The recommendation's record Deleted Successfully!",
							icon: "success",
							timer: 2500,
							button: false

						})
						Promise.all([
							axios.get(API_URL + `/recommendations/major/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
							axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
						]).then(
							([recommendation, category, visit]) => {
								this.setState({
									Recommendations: recommendation.data,
									Categories: category.data,
									Visit: visit.data[0],
									loading: false
								})
							}
						)
					}).catch(error => {
						if (error.response.status == '401') {
							swal({
								title: "",
								text: "Your session has expired. You'll automatically be logged out within seconds.",
								icon: "warning",
								timer: 2000
							})
								.then((sucess) => {
									window.sessionStorage.clear();
									window.location.reload();
								});
						} else {
							swal({
								title: "",
								text: error.name + ': ' + error.message,
								icon: "error",
								button: true
							})
								.then((sucess) => {
									//window.location.reload();
								});
						}
					});
					//unconfirmed condition
				} else {
					//window.location.reload();
				}
			});
	}
	componentDidMount() {
		this.setState({ loading: true });
		//Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		//Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		Promise.all([
			axios.get(API_URL + `/recommendations/major/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
		]).then(
			([recommendation, category, visit]) => {
				this.setState({
					Recommendations: recommendation.data,
					Categories: category.data,
					Visit: visit.data[0],
					loading: false
				})
			}
		).catch(error => {
			this.setState({ loading: false });
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	//Add user
	addRecommendations(event) {
		event.preventDefault()

		const { history } = this.props

		const recommendation = {
			recommendation_description: this.state.recommendation_description,
			recommendation_type: this.state.recommendation_type,
			nes_id: this.state.nes_id,
			category_id: this.state.category_id,
			visit_id: this.state.visit_id,

		}

		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
		//axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.post(API_URL + '/recommendations/major', recommendation, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({
					modalVisible: false,
					//loading : false
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.data['status'] == 'success') {
					this.setState({
						modalVisible: false,
						//loading : false,
					});
					swal({
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						timer: 2500,
						button: false
					})
					Promise.all([
						axios.get(API_URL + `/recommendations/major/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
						axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
					]).then(
						([recommendation, category, visit]) => {
							this.setState({
								Recommendations: recommendation.data,
								Categories: category.data,
								Visit: visit.data[0],
								loading: false
							})
						}
					)
				} else {
					this.setState({
						modalVisible: true,
					});
					swal({
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						button: true
					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
			.catch(error => {
				this.setState({
					modalVisible: true,
					//loading : false
				});
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
	}


	//Form inputs handler and validator
	handleInput(e) {
		e.preventDefault();
		if (e.target.name === 'recommendation_description') {
			if (e.target.value != '') {
				this.setState({ RecommendationIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ RecommendationIdState: 'has-danger' });
			}

		} else if (e.target.name === 'category_id') {
			if (e.target.value != '') {
				this.setState({ CategoryIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ CategoryIdState: 'has-danger' });
			}

		}


	}

	render() {
		const { Recommendations, Categories } = this.state;
		const data = {
			columns: [
				/* { 
				  title: 'ID',
				   field: 'recommendation_id' 
				}, */
				{
					title: 'Name',
					field: 'recommendation_description',
				},
				{
					title: 'NES Category',
					field: 'category_id',
				},
				{
					title: 'Actions',
					field: 'OPTIONS',
					export: false,
				},
			],

		};

		return (
			<React.Fragment>
				<div className="container-fluid">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>

					<Row className="align-items-center">
						<Col sm={6}>
							<div className="page-title-box">
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>

									<li className="breadcrumb-item active">Recommendations</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">
								<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
							</div>
						</Col>
					</Row>

					<div>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={this.state.activeTab == 2 ? 'active' : ''}
									onClick={() => this.setActiveTab(2)}
								>
								Recommendations
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={this.state.activeTab == 1 ? 'active' : ''}
									onClick={() => this.setActiveTab(1)}
									>
									Action Plan
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={`${this.state.activeTab}`}>
							<TabPane tabId="1">
								<Row>
									<Col sm="12">
											<GanttChart editMode={['Inspector'].includes(sessionStorage.getItem('urole')) ? false : true} visit={this.state.Visit} updateRecommendationsList={this.refreshRecommendationsList} categories={this.state.Categories} recommendations={this.state.Recommendations} />
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="2">
								<Row>
									<Col sm="12">
										<div className="row">
											<div className="col-12">
												<div className="card">
													<div className="card-body">
														<h3 className="card-title">Recommendations Management</h3>
														<hr />
														<Button hidden={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? true : false} color="success" onClick={this.toggle}> {<Add />} New Recommendation</Button>
														<hr />

														{
															//Add user modal

															/*
																NOTE the usage of the onChange and onBlur event to call the same function.
																The onBlur event has been used to enforce validation once the user move out of the field.
															*/
														}
														<Modal isOpen={this.state.modalVisible}>
															<Form onSubmit={this.addRecommendations} >
																<ModalHeader toggle={this.toggle}>
																	<center className="justify-content-center">RECOMMENDATION ADDITION FORM</center>
																</ModalHeader>
																<ModalBody>


																	<FormGroup>
																		<Row>
																			<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				<Label for="recommendation_description">Major Recommendation&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																			</Col>
																			<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																				<Input type="textarea" name="recommendation_description" id="recommendation_description" invalid={this.state.RecommendationIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
																				<FormFeedback invalid>
																					The field is required.
																				</FormFeedback>
																			</Col>
																		</Row>
																	</FormGroup>
																	<FormGroup>
																		<Row>
																			<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				<Label for="category_id">NES Category&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																			</Col>
																			<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																				<Input type="select" name="category_id" id="category_id" invalid={this.state.CategoryIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																					<option></option>
																					{
																						Categories.map(category => (
																							<option value={category.category_id} key={category.category_id}>{category.category_name}</option>
																						)
																						)
																					}
																				</Input>
																				<FormFeedback invalid>
																					The field is required.
																				</FormFeedback>
																			</Col>
																		</Row>
																	</FormGroup>



																</ModalBody>
																<ModalFooter>
																	<Button type="submit" color="success">Create</Button>
																	<Button color="danger" onClick={this.toggle}>Cancel</Button>
																</ModalFooter>
															</Form>
														</Modal>


														<Table responsive striped size="lg">
															<MaterialTable
																title={`RECOMMENDATIONS AT ${this.state.Visit.school_name || ''} ${this.state.Visit.sector_name || ''}`}
																columns={data.columns}
																data={
																	Recommendations.map(recommendation => (
																		(this.state.role != "Inspector") ?
																			({

																				//recommendation_id : recommendation.recommendation_id,
																				recommendation_description: recommendation.recommendation_description,
																				category_id: recommendation.category_name,
																				'OPTIONS': <pre><Link className="btn invisible" to={`/recommendations/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.editRecommendations(recommendation.recommendation_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className="btn invisible" to={`/recommendations/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.deleteRecommendations(recommendation.recommendation_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
																			})

																			:
																			({

																				//recommendation_id : recommendation.recommendation_id,
																				recommendation_description: recommendation.recommendation_description,
																				category_id: recommendation.category_name,
																				'OPTIONS': <pre><Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? "btn invisible" : "btn"} to={`/recommendations/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.editRecommendations(recommendation.recommendation_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? "btn invisible" : "btn"} to={`/recommendations/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.deleteRecommendations(recommendation.recommendation_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
																			})

																	))}

																options={{
																	exportButton: true,
																	tableLayout: 'auto',
																	loadingType: 'overlay',
																	pageSizeOptions: [5, 20, 50, 100, 500, 1000],
																	emptyRowsWhenPaging: false,
																	columnsButton: true,
																	exportAllData: true,
																	actionsColumnIndex: -1,
																	search: true,
																	sorting: true
																}}
															/>

														</Table>

														{
															//Edit user record modal
														}
														<Modal isOpen={this.state.EditModalVisible}>
															<Form onSubmit={this.updateRecommendations} >
																<ModalHeader toggle={this.toggleEdit}>
																	<center className="justify-content-center">RECOMMENDATIONS EDITING FORM</center>
																</ModalHeader>
																<ModalBody>
																	<FormGroup>
																		<Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
																	</FormGroup>

																	<FormGroup>
																		<Row>
																			<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				<Label for="recommendation_description">Major Recommendation&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																			</Col>
																			<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																				<Input type="textarea" defaultValue={this.state.recommendation_description} name="recommendation_description" id="recommendation_description" invalid={this.state.RecommendationIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
																				<FormFeedback invalid>
																					The field is required.
																				</FormFeedback>
																			</Col>
																		</Row>
																	</FormGroup>
																	<FormGroup>
																		<Row>
																			<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				<Label for="category_id">NES Category&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																			</Col>
																			<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																				<Input type="select" defaultValue={this.state.category_id} name="category_id" id="category_id" invalid={this.state.CategoryIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																					<option></option>
																					{
																						Categories.map(category => (
																							<option value={category.category_id} key={category.category_id}>{category.category_name}</option>
																						)
																						)
																					}
																				</Input>
																				<FormFeedback invalid>
																					The field is required.
																				</FormFeedback>
																			</Col>
																		</Row>
																	</FormGroup>



																</ModalBody>
																<ModalFooter>
																	<Button type="submit" color="success">Update</Button>
																	<Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
																</ModalFooter>
															</Form>
														</Modal>

													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</TabPane>
						</TabContent>
					</div>

				</div>
			</React.Fragment>
		);
	}
}

export default Recommendations;
