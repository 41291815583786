import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";

export const ViewSwitcher = ({
    onViewModeChange,
    onViewListChange,
    isChecked,
}) => {
    return (
        <><div className="ViewContainer">
            <button className="Button btn btn-primary" onClick={() => onViewModeChange(ViewMode.Day)}>
                Day
            </button>
            &nbsp;
            <button
                className="Button btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.Week)}
            >
                Week
            </button>
            &nbsp;
            <button
                className="Button btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.Month)}
            >
                Month
            </button>
            &nbsp;
            <button
                className="Button btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.Year)}
            >
                Year
            </button>
        </div>
            {/* &nbsp;&nbsp;
            &nbsp;&nbsp;
            <div className="Switch">
                <label className="Switch_Toggle">
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    <input
                        type="checkbox"
                        defaultChecked={isChecked}
                        onClick={() => onViewListChange(!isChecked)} />
                    <span className="Slider" />
                </label>
                &nbsp;&nbsp;
                Show Task List
            </div> */}
        </>
    );
};