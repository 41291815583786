import { SET_METADATA, RESET_METADATA } from './actionTypes';

const initialState = {
    error: null,
    loading: false,
    data: {}
}

const metadata = (state = initialState, action) => {
    switch (action.type) {
        case SET_METADATA:
            state = {
                ...state,
                data: {...state.data, [action.payload.type]: action.payload.data },
                loading: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default metadata;