import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import {Table, TableBody, TableHead,Container, Row, FormFeedback, Media, Col,Modal, ModalHeader, ModalBody, ModalFooter,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';


import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';

import { saveAs } from "file-saver";
import * as docx from "docx";

import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

class ExecutiveSummary extends Component {
   constructor(props) {
    super(props);
	
    this.state = {
		loading:false,
	};
	
	this.generateDocx = this.generateDocx.bind(this);
    
  }
  
  componentDidMount () {
	 
	 this.generateDocx();
  }
  generateDocx(){
	
	const doc = new docx.Document({
		creator: "Pitson Mwakabila",
    	description: "SIISv2 Inspection Report",
    	title: "Inspection Performance Report",
		sections: [{
			properties: {},
			children: [
					new docx.Paragraph({
						children: [
							new docx.TextRun({
								text: "INSPECTION REPORT AT MAJUNI OPEN SECONDARY SCHOOL",
								allCaps: true,
								bold:true,
								italic:true,
							})
						]
					}),
					new docx.Table({
						columnWidths: [3505, 5505],
						rows: [
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("HEAD TEACHER")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("DIVISION")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("DISTRICT")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("ZONE/CLUSTER")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("ESTABLISHMENT")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("YEAR OF ESTABLISHMENT")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("POSTAL ADDRESS")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("FULL INSPECTION DATE")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("DATE OF PREVIOUS INSPECTION")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("ENROLMENT")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("QUALIFIED TEACHERS")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("UNQUALIFIED TEACHERS")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("LEAD INSPECTOR")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("INSPECTION TEAM")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("HEAD TEACHER'S PHONE")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("DATE FOR REPORT PUBLICATION")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("DATE OF EXPECTED ACTION PLAN")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
							new docx.TableRow({
								children: [
									new docx.TableCell({
										width: {
											size: 3505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("EXPECTED RE-INSPECTION DATE")],
									}),
									new docx.TableCell({
										width: {
											size: 5505,
											type: docx.WidthType.DXA,
										},
										children: [new docx.Paragraph("")],
									}),
								],
							}),
						],
					})
					
					
					
				],
			}]
	});
	
	this.setState({
		loading:true
	});
	

	docx.Packer.toBlob(doc).then(blob => {
		saveAs(blob, "SIISv2 Report.docx");
		this.setState({
				loading:false
			});
		
		swal({
		  title: "",
		  text: "The document downloaded successfully.",
		  icon: "success",
		  Button : true
		})
		.then((sucess) => {
			window.location = "/";
		});
	  }).catch(error=>{
		  swal({
			  title: "",
			  text: "Failed to download the document.",
			  icon: "error",
			  timer : 1250
			})
			.then((sucess) => {
				//window.location.reload();
			});
	  });
	
	
	
	
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="colxs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<div className="card">
				{(this.state.loading == true) ? <Loader /> : null}
				
			</div>
		</div>
      </React.Fragment>
    );
  }
}

export default ExecutiveSummary;
