import React from 'react'
import { Row, Col, Button, Table, Media, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import Loader from "../../../components/Loader";
import logo from "../../../assets/images/gov-logo.png";
import axios from "../../../services/axios";
import swal from 'sweetalert';
import NationalProgressGraph from './NationalProgressGraph';
import { Close, ArrowLeft } from "@material-ui/icons"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DivisionalProgressGraph from "./DivisionalProgressGraph";
import DistrictProgressGraph from "./DistrictProgressGraph";
import ClusterProgressGraph from "./ClusterProgressGraph";
import moment from 'moment';
import html2canvas from 'html2canvas';



import ClusterNesAvgPerformanceGraph from './ClusterNesAvgPerformanceGraph';
import DistrictAvgNesPerformanceGraph from './DistrictAvgNesPerformanceGraph';
import DivisionNesAvgPerformanceGraph from './DivisionNesAvgPerformanceGraph';
import NationalNesAvgPerformanceGraph from './NationalNesAvgPerformanceGraph';
import SchoolAvgPerformanceGraph from './SchoolAvgPerformanceGraph';

function ExecutiveSummaryReport() {

    const [loading, setloading] = React.useState(false)
    const [report, setReport] = React.useState({})
    const [showReport, setshowReport] = React.useState(false)
    const [recommendations, setRecommendations] = React.useState([])
    const [categories, setCategories] = React.useState([])
    const [divisions, setDivisions] = React.useState([])
    const [districts, setDistricts] = React.useState([])
    const [clusters, setClusters] = React.useState([])

    const [params, setParams] = React.useState({
        start_date: '2021-01-01',
        end_date: moment().format("YYYY-MM-DD"),
        level: 'national',
        division: '',
        district: '',
        cluster: '',
        sector_id: 2
    })


    const getData = () => {
        return axios.get(`/inspection_report/executive_summary_report?start_date=${params.start_date}&end_date=${params.end_date}&sector_id=${params.sector_id}&level=${params.level}&division=${params.division}&district=${params.district}&cluster=${params.cluster}`)
            .then(({ data }) => {
                console.log(data.data.inspections_per_cluster)
                console.log(data.data.inspections_per_district)
                setReport(data.data)
                setRecommendations(data.data.recommendations.reduce(function (r, a) {
                    r[a.short_name] = r[a.short_name] || [];
                    r[a.short_name].push(a);
                    return r;
                }, Object.create(null)));
                return true
            })
            .catch(err => {
                swal({
                    text: 'Error getting report',
                    icon: 'error'
                })
            })
    }

    const setupData = () => {
        setParams({ ...params, sector_id: sessionStorage.getItem('sector') })
    }

    const getDivisions = () => {
        axios.get('/divisions').then(({ data }) => {
            setDivisions(data)
        })
    }

    const getDistricts = (divisionId) => {
        axios.get(`/districts/filter/${divisionId}`).then(({ data }) => {
            setDistricts(data)
        })
    }

    const getClusters = (districtId) => {
        axios.get(`/zones/filter/${districtId}`).then(({ data }) => {
            setClusters(data.filter(d => d.sector_id == params.sector_id))
        })
    }

    React.useEffect(() => {
        if (!params.division || params.division == '') {
            setDistricts([])
            setClusters([])
        }
        if (!params.district || params.district == '') {
            setClusters([])
        }
        if (params.level.toLowerCase() == 'national') {
            setDivisions([])
            setDistricts([])
            setClusters([])
        }
    }, [params])

    React.useEffect(() => {
        setupData()
    }, [])

    const generateWord = () => {
        var img = new Image();
        html2canvas(document.getElementById("chart-section")).then((canvas) => {
            img.src = canvas.toDataURL();

            var filename = 'EXECUTIVE SUMMARY REPORT'
            var element = 'report'
            var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>EXECUTIVE SUMMARY REPORT</title></head><body>";
            var postHtml = "</body></html>";
            const originalInnerSection = document.getElementById("chart-section").innerHTML
            document.getElementById("chart-section").innerHTML = "<img width='900' style='width: 900px !important' src='" + img.src + "'/>";
            var html = preHtml + document.getElementById(element).innerHTML + postHtml;

            var blob = new Blob(['\ufeff', html], {
                type: 'application/msword'
            });

            // Specify link url
            var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

            // Specify file name
            filename = filename ? filename + '.doc' : 'document.doc';

            // Create download link element
            var downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = url;

                // Setting the file name
                downloadLink.download = filename;

                //triggering the function
                downloadLink.click();
            }

            document.body.removeChild(downloadLink);
            document.getElementById("chart-section").innerHTML = originalInnerSection
        })

    }

    return (
        <div>
            <React.Fragment>
                <Row className="position-center">
                    {loading && <Loader />}
                </Row>

                <Row className="align-items-center">
                    <Col sm={6}>
                        <div className="page-title-box">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard">Home</Link>
                                </li>

                                <li className="breadcrumb-item active">Executive Summary Report</li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm="6">
                        {showReport &&
                            <div className="float-right d-none d-md-block">
                                <Link className="btn btn-primary " onClick={(e) => { e.preventDefault(); setshowReport(false) }} ><ArrowLeft />Back</Link>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {!showReport &&
                            <><table className='table'>
                                <thead>
                                    <tr>
                                        <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Start Date</th>
                                        <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">End Date</th>
                                        <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Level</th>
                                        <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Division</th>
                                        <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">District</th>
                                        <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Cluster</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Input onChange={(e) => {
                                                setParams({ ...params, start_date: e.target.value })
                                            }} defaultValue={moment(params.start_date).format("YYYY-MM-DD")} type="date" name="start_date" id="start_date" required={true}>
                                            </Input>
                                        </td>
                                        <td>
                                            <Input onChange={(e) => {
                                                setParams({ ...params, end_date: e.target.value })
                                            }} defaultValue={moment(params.end_date).format("YYYY-MM-DD")} type="date" name="end_date" id="end_date" required={true}>
                                            </Input>
                                        </td>
                                        <td>
                                            <Input onChange={(e) => {
                                                const { value } = e.target
                                                setParams({ ...params, level: value, division: '', district: '', cluster: '' });
                                                getDivisions()
                                            }} defaultValue={params.level} type="select" name="division_id" id="division_id" required={true}>
                                                <option></option>
                                                <option value='national' key='national'>National</option>
                                                <option value='division' key='division'>Divisional</option>
                                            </Input>
                                        </td>
                                        <td>
                                            <Input onChange={(e) => {
                                                setParams({ ...params, division: e.target.value, district: '', cluster: '' });
                                                getDistricts(e.target.value);
                                            }} defaultValue={params.division} type="select" name="division_id" id="division_id" required={true}>
                                                <option></option>
                                                {divisions.map(div => (
                                                    <option value={div.division_id} key={div.division_id}>{div.division_name}</option>
                                                ))
                                                }
                                            </Input>
                                        </td>
                                        <td>
                                            <Input onChange={(e) => {
                                                setParams({ ...params, district: e.target.value, cluster: '' });
                                                getClusters(e.target.value);
                                            }} defaultValue={params.district} type="select" name="division_id" id="division_id" required={true}>
                                                <option></option>
                                                {districts.map(d => (
                                                    <option value={d.district_id} key={d.district_id}>{d.district_name}</option>
                                                ))
                                                }
                                            </Input>
                                        </td>
                                        <td>
                                            <Input onChange={(e) => {
                                                setParams({ ...params, cluster: e.target.value });
                                            }} defaultValue={params.cluster} type="select" name="division_id" id="division_id" required={true}>
                                                <option></option>
                                                {clusters.map(c => (
                                                    <option value={c.zone_id} key={c.zone_id}>{c.zone_name}</option>
                                                ))}
                                            </Input>
                                        </td>
                                    </tr>
                                </thead>
                            </table><table className='table'>
                                    <thead>
                                        <tr>
                                            <th className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <button onClick={() => {
                                                    setshowReport(true);
                                                    setloading(true)
                                                    getData().then(() => {
                                                        setloading(false);
                                                    });
                                                }} className="btn btn-primary">Search</button>
                                            </th>
                                        </tr>
                                    </thead>
                                </table></>
                        }
                        {showReport &&

                            <div className="container">
                                <div className="card">
                                    <div className="card-body">
                                        <hr />
                                        <Row>
                                            <Col style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                <Button onClick={generateWord} className="btn btn-success pull-right"><FileDownloadIcon /></Button>
                                                &nbsp;&nbsp;
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Table className='table table-responsive' id='report' borderless responsive>
                                            <thead>
                                                <tr>
                                                    <th md={5} className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"></th>
                                                    <th md={2} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                        <Media src={logo} />

                                                    </th>
                                                    <th md={5} className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"></th>
                                                </tr>
                                                <tr>
                                                    <th colSpan='3'>
                                                        <center><h2>{'EXECUTIVE SUMMARY'}</h2></center>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <td colSpan="10">
                                                </td>
                                                <tr>
                                                    <td colSpan="3">
                                                        <center><h5> MINISTRY OF EDUCATION</h5></center>
                                                    </td>
                                                </tr>
                                                <td colSpan="5">
                                                </td>
                                                <tr>
                                                    <td className="text-center" colSpan="3">
                                                        <center><h5>
                                                            {params.division != '' && params.district == '' && params.cluster == '' ? `${divisions.find(c => c.division_id == params.division).division_name} DIVISION` : params.district != '' && params.cluster == '' ? `${districts.find(c => c.district_id == params.district).district_name} DISTRICT` : params.cluster != '' ? `${clusters.find(c => c.zone_id == params.cluster).zone_name} CLUSTER` : 'NATIONAL'} INSPECTION REPORT SUMMARY FOR {params.sector_id == 2 ? 'SECONDARY' : 'PRIMARY'} SCHOOLS'
                                                        </h5></center>

                                                        <center><h5>
                                                            FOR
                                                        </h5></center>

                                                        <center><h5>
                                                            {moment(params.start_date).format('YYYY-MM-DD')}/{moment(params.end_date).format('YYYY-MM-DD')}
                                                        </h5></center>
                                                    </td>
                                                </tr>
                                                <td colSpan="5">
                                                </td>
                                                <tr>
                                                    <td className="text-center" colSpan="3">
                                                        <center><h5>
                                                            DIRECTORATE OF QUALITY ASSURANCE
                                                            <center><h5>

                                                                <td colSpan="5">
                                                                </td>
                                                            </h5></center>
                                                            {moment().format("LL")}
                                                        </h5></center>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <hr />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>1.0. INTRODUCTION</strong></h3><br />
                                                        The Department of Quality Assurance conducted inspections in secondary schools in the six education divisions in the country. The inspection was aimed at evaluating schools’ outcomes for students’ outcomes, provisions and practices against the National Education Standards. Officers involved were from DQAS headquarters and Divisions offices. A total of <strong>{report.inspections_per_division && report.inspections_per_division.reduce((a, b) => a + parseInt(b.visits), 0)}</strong> secondary schools were inspected from <strong>{moment(params.start_date).format("LL")}</strong> to <strong>{moment(params.end_date).format("LL")}</strong>. Figure 1.1 summarises the number of schools inspected in each division.
                                                        <b></b>
                                                        <p></p>
                                                        {params.division != '' && params.district == '' && params.cluster == '' ?
                                                            <table class="table table-bordered">
                                                                <tr>
                                                                    <th className="text-center">#</th>
                                                                    <th className="text-center">DISTRICT</th>
                                                                    <th className="text-center">TOTAL SCHOOLS INSPECTED</th>
                                                                </tr>
                                                                {
                                                                    report.inspections_per_district &&

                                                                    report.inspections_per_district.map((ins, index) => (
                                                                        <tr index={index}>
                                                                            <td className="text-center">{index + 1}</td>
                                                                            <td className="text-center">{ins.district_name}</td>
                                                                            <td className="text-center">{ins.visits}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </table>
                                                            : params.district != '' && params.cluster == '' ?
                                                                <table class="table table-bordered">
                                                                    <tr>
                                                                        <th className="text-center">#</th>
                                                                        <th className="text-center">CLUSTER</th>
                                                                        <th className="text-center">TOTAL SCHOOLS INSPECTED</th>
                                                                    </tr>
                                                                    {
                                                                        report.inspections_per_cluster &&

                                                                        report.inspections_per_cluster.map((ins, index) => (
                                                                            <tr index={index}>
                                                                                <td className="text-center">{index + 1}</td>
                                                                                <td className="text-center">{ins.zone_name}</td>
                                                                                <td className="text-center">{ins.visits}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </table> : params.cluster != '' ?
                                                                    <table class="table table-bordered">
                                                                        <tr>
                                                                            <th className="text-center">#</th>
                                                                            <th className="text-center">SCHOOL</th>
                                                                            <th className="text-center">TOTAL SCHOOLS INSPECTED</th>
                                                                        </tr>
                                                                        {
                                                                            report.inspections_per_school &&

                                                                            report.inspections_per_school.map((ins, index) => (
                                                                                <tr index={index}>
                                                                                    <td className="text-center">{index + 1}</td>
                                                                                    <td className="text-center">{ins.school_name}</td>
                                                                                    <td className="text-center">{ins.visits}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </table> :
                                                                    <table class="table table-bordered">
                                                                        <tr>
                                                                            <th className="text-center">#</th>
                                                                            <th className="text-center">DIVISION</th>
                                                                            <th className="text-center">TOTAL SCHOOLS INSPECTED</th>
                                                                        </tr>
                                                                        {
                                                                            report.inspections_per_division &&

                                                                            report.inspections_per_division.map((ins, index) => (
                                                                                <tr index={index}>
                                                                                    <td className="text-center">{index + 1}</td>
                                                                                    <td className="text-center">{ins.division_name}</td>
                                                                                    <td className="text-center">{ins.visits}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </table>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>2.0 PURPOSE</strong></h3><br />
                                                        The purpose of the inspection was to examine and evaluate teaching and learning alongside other matters that affect students’ learning. The overall expectation is that the schools and stakeholders will act upon the key recommendations contained in the inspection reports. In this way students will benefit from improved provision of education leading to higher attainment and consequently, be better able to serve both their own needs and those of the nation as a whole.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>3.0 METHODOLOGY</strong></h3><br />

                                                        There were two forms of inspection which were conducted, the full inspection and the follow up inspection. During the exercise, inspectors observed lessons; scrutinized students’ exercise books, teaching and administrative records; observed school structures and their surroundings. The team also conducted interviews and focus group discussions with teachers, students and community members.



                                                        <p> The inspection targeted the following National Education Standards (NES): - </p>
                                                        <br></br>
                                                        <p></p>
                                                        <ul>
                                                            {
                                                                report.targeted_nes && report.targeted_nes.map((nes, index) => (
                                                                    <li index={index}>{nes.nes_name}</li>
                                                                ))
                                                            }
                                                        </ul>

                                                        <br></br>
                                                        <p>Judgment was based on four levels of achievement which are: -</p>

                                                        <br></br>
                                                        <ul>
                                                            <li>Level 1: Below minimum standards</li>

                                                            <li> Level 2: Meets minimum standards</li>

                                                            <li> Level 3: Exceeds minimum standards</li>

                                                            <li> Level 4: Effective Practice</li>
                                                        </ul>
                                                        <br></br>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>4.0 RESULTS</strong></h3><br />
                                                        The section presents results of schools’ performance during the inspections.

                                                        Performance of schools during inspection

                                                        Figure 4.1 summarises the performance of schools at national level against the National Education Standard during full inspection of the institutions.
                                                        <br></br>
                                                        <p></p>
                                                        <div id="chart-section">
                                                            {params.level == 'national' && <NationalNesAvgPerformanceGraph start_date={new Date(params.start_date).toLocaleDateString('en-GB')} size={"fullscreen"} end_date={new Date(params.end_date).toLocaleDateString('en-GB')} />}
                                                            {params.level == 'division' && params.division != '' && params.district == '' &&
                                                                params.cluster == '' && <DivisionNesAvgPerformanceGraph division_id={params.division} start_date={new Date(params.start_date).toLocaleDateString('en-GB')} size={"fullscreen"} end_date={new Date(params.end_date).toLocaleDateString('en-GB')} />}
                                                            {params.level == 'division' && params.district != '' && params.cluster == '' && <DistrictAvgNesPerformanceGraph district_id={params.district} start_date={new Date(params.start_date).toLocaleDateString('en-GB')} size={"fullscreen"} end_date={new Date(params.end_date).toLocaleDateString('en-GB')} />}
                                                            {params.level == 'division' && params.cluster != '' && <ClusterNesAvgPerformanceGraph zone_id={params.cluster} start_date={new Date(params.start_date).toLocaleDateString('en-GB')} size={"fullscreen"} end_date={new Date(params.end_date).toLocaleDateString('en-GB')} />}
                                                        </div>
                                                        <br></br>
                                                        <p></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>5.0 STRENGTHS</strong></h3><br />
                                                        The section presents results of schools’ key strengths during the inspections.
                                                        <ul>
                                                            {report.strengths && report.strengths.map(s => (
                                                                <li>{s.strength_description}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>6.0 BEST PRACTICES</strong></h3><br />
                                                        The section presents results of schools’ key best practices during the inspections.
                                                        <ul>
                                                            {report.good_practices && report.good_practices.map(s => (
                                                                <li>{s.good_practice_description}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>7.0 CRITICAL ISSUES</strong></h3><br />
                                                        The section presents results of schools’ key critical issues during the inspections.
                                                        <ul>
                                                            {report.critical_issues && report.critical_issues.map(s => (
                                                                <li>{s.critical_issue_description}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>8.0 WEAKNESSES</strong></h3><br />
                                                        The section presents results of schools’ key weaknesses during the inspections.
                                                        <ul>
                                                            {report.weaknesses && report.weaknesses.map(s => (
                                                                <li>{s.weakness_description}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        <h3><strong>9.0 RECOMMENDATIONS FOR DEPARTMENTS</strong></h3><br />
                                                        The section presents results of schools’ key recommendations during the inspections grouped by respective department.
                                                        <br></br>
                                                        <br></br>
                                                        {recommendations && Object.keys(recommendations).map((key, index) => (
                                                            <>
                                                                <h5>{`9.${index + 1} To the ${key}`}</h5>
                                                                <ul>
                                                                    {recommendations[key].map(r => (
                                                                        <li>{r.recommendation_description}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </React.Fragment>
        </div>
    )
}

export default ExecutiveSummaryReport