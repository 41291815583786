import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../../routes/API_URL";

import swal from 'sweetalert';


const API_URL = backend_url['api_url'];

class TotalSchools extends Component {
    constructor(props) {
		super(props);
		//const visit_id=this.props.visit_id;
		this.state = {
            Sec_Data:[],
		    Prim_Data:[],
			bearer_auth:window.sessionStorage.getItem('token'),
        }
	  }
	  
	  componentDidMount () {
		 
		 Promise.all([axios.get(API_URL + `/total_schools/primary`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/total_schools/secondary`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([p_data,s_data])=>{
			 this.setState({
				 Prim_Data:p_data.data,
				 Sec_Data:s_data.data,
			 })
		 }
		 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
	  }
	
    render() {
		const {Prim_Data, Sec_Data} = this.state;
		
		//Disecting total primary and secondary schools via loop
		let prim_serie = '';
		let sec_serie = '';
		
		for(let i=0; i<Prim_Data.length;i++){
			prim_serie = Prim_Data[i]['COUNT'];
		}
		
		for(let i=0; i<Sec_Data.length;i++){
			sec_serie = Sec_Data[i]['COUNT'];
		}
		
		
		const options = {
                chart: {
                    toolbar: {
                        show: true,
						offsetX: 0,
						offsetY: 0,
						tools: {
						  download: true,
						  selection: true,
						  zoom: true,
						  zoomin: true,
						  zoomout: true,
						  pan: true,
						  reset: true
						}
                    },
                },
                colors: ['#0000ff','#FF8C00'],
                plotOptions: {
                    bar: {
                        columnWidth: '45',
                        dataLabels: {
                            show: false
                        },

                    },
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: ['SECTOR'],
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
					title: {
                        text: 'CATEGORIES'
                    },
                },
                yaxis: {
                    title: {
                        text: 'TOTAL SCHOOLS'
                    },
                },
            }
			
            const series = [{
                name: 'Primary School',
                data: [prim_serie]
            }, {
                name: 'Secondary School',
                data: [sec_serie]
            }]
			
        return (
            <React.Fragment>
                <ReactApexChart options={options} series={series}  type="bar" height="220" />
            </React.Fragment>
        );
    }
}

export default TotalSchools;