import React, { Component } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import { Add, Edit, Delete, VpnKey, FormatListNumbered, HourglassFull, ThumbUp, ThumbDown, Warning, RateReview, Check, Equalizer, CallToAction, ArrowLeft} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class ControlPanel extends Component {
  constructor(props) {
    super(props);
	//capturing the dynamic visit id from the school visit 
	
	const visit_id=this.props.match.params.visit_id;
	const isExpired = this.props.match.params.isExpired;
	const logged_user_id = this.props.match.params.logged_user;
	const lead_inspector_id = this.props.match.params.lead_inspector;
	const first_inspector_id = this.props.match.params.first_inspector;
	
	
	this.state = {

      modalVisible:false,
      EditModalVisible:false,
      emis_id:window.sessionStorage.getItem('emis'),
      zone_id:window.sessionStorage.getItem('zone'),
      district_id:window.sessionStorage.getItem('district'),
      division_id:window.sessionStorage.getItem('division'),
	  visit_id:visit_id,
	  present_visitation_date:'',
	  isExpired: isExpired,
	  logged_user_id: logged_user_id,
	  prev_visitation_date:'',
	  lead_inspector_id:lead_inspector_id,
	  first_inspector_id:first_inspector_id,
	  second_inspector_id:'',
	  publication_date:'',
	  action_plan_date:'',
	  next_inspection_date:'',
	  lead_advisor_id:'', 
	  govt_chair_id:'',
	  visit_type_id:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  Visit:{},
	  VisitTypes:[],
	  Users:[],
	  loading : '',
    };
  }
  
  
  componentDidMount () {
	 
	 this.setState({loading : true});
	 axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
	  .then(
		  (visit) => {
		console.log(visit.data) 
		 this.setState({
			 loading: false,
			 Visit:visit.data[0]
		 })
	 }
	 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				})
				.then((sucess) => {
					// window.sessionStorage.clear();
					// window.location.reload();
				});
          })
  }


  
  render() {
    const {Visits,VisitTypes,Users} = this.state;
    

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{
				//(this.state.loading == true) ? <Loader /> : null
			}
		  </Row>
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Inspection Panel Options</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                <Link className="btn btn-primary " to={`/inspection_visits/` + this.state.visit_id } ><ArrowLeft />Back</Link>
              </div>
            </Col>
          </Row>

          <div className="card">
					{this.state.Visit && this.state.Visit.school_name &&
						
					<div className="card-header">{`CONTROL PANEL FOR ${this.state.Visit.school_name} at ${this.state.Visit.present_visitation_date}`}</div>
					}
		       <div className="card-body">
					<div className="row">
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
							  <h5>
								<Link to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
									<span className="text-primary">	
										<VpnKey fontSize='large' />
									</span>
									<br />
									<span className="text-primary" >
										
										Key Evidence
									</span>
								</Link>
								
							  </h5>
								
							</center>
					  </div>
					  {/* <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>
									<Link to={`/level_achieved/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-primary">	
											<FormatListNumbered fontSize='large' />
										</span>
										<br />
										<span className="text-primary" >
											Level Achieved
										</span>
									</Link>
								</h5>
							</center>
					  </div> */}
					  
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>
									<Link to={`/strengths/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-success">	
											<ThumbUp fontSize='large'/>
										</span>
										<br />
										<span className="text-primary" >
											
											Major Strengths
										</span>
									</Link>
								</h5>
							</center>
					  </div>
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>
									<Link to={`/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-danger">	
												<HourglassFull fontSize='large'/>
										</span>
										<br />
										<span className="text-primary" >
											
											Areas of Improvement
										</span>
									</Link>
								</h5>
							</center>
					  </div>
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>
									<Link to={`/good_practices/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-success">	
											<Check fontSize='large' />
										</span>
										<br />
										<span className="text-primary" >
											
											Good Practices
										</span>
									</Link>
								</h5>
							</center>
					  </div>
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>
									<Link to={`/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-warning">	
											<Warning fontSize='large'/>
										</span>
										<br />
										<span className="text-primary" >
											
											Critical Issues
										</span>
									</Link>
								</h5>
							</center>
					  </div>
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>
									<Link to={`/recommendations/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-primary">	
											<RateReview fontSize='large'/>
										</span>
										<br />
										<span className="text-primary" >
											
											Major Recommendations
										</span>
									</Link>
								</h5>
							</center>
					  </div>
					  <div className="col-md-6 col-sm-4 col-lg-3 col-xl-3" style={{padding: '2em'}}>
							<center>
								<h5>	
									<Link to={`/performance_reports/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} >	
										<span className="text-primary">	
											<Equalizer fontSize='large'/>
										</span>
										<br />
										<span className="text-primary" >
											
											
											School Visit Report
										</span>
									</Link>
								</h5>
							</center>
					  </div>
					</div>
					  
			   </div>
				
		  </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default ControlPanel;
