import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';
import swal from 'sweetalert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Weaknesses extends Component {
  constructor(props) {
    super(props);
	const visit_id=this.props.match.params.visit_id;
	const isExpired = this.props.match.params.isExpired;
	const logged_user_id = this.props.match.params.logged_user;
	const lead_inspector_id = this.props.match.params.lead_inspector;
	const first_inspector_id = this.props.match.params.first_inspector;
	
	
    this.state = {
	  //Get logged user role
	  role:sessionStorage.getItem("urole"),
      modalVisible:false,
      EditModalVisible:false,
	  nes_id:'',
	  weakness_description:'',
	  visit_id :visit_id,
	  isExpired: isExpired,
	  logged_user_id: logged_user_id,
	  lead_inspector_id:lead_inspector_id,
	  first_inspector_id:first_inspector_id,
	  bearer_auth:window.sessionStorage.getItem('token'),
	  Weaknesses:[],
	  Standards: [],
	  Visit: {},
	  loading: '',
	  
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addWeaknesses = this.addWeaknesses.bind(this);
	this.editWeaknesses= this.editWeaknesses.bind(this);
	this.updateWeaknesses = this.updateWeaknesses.bind(this);
	this.deleteWeaknesses =this.deleteWeaknesses.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 nes_id:'',
		 weakness_description:'',
		 NesIdState:'',
		 WeaknessIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisible:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 nes_id:'',
		 weakness_description:'',
		 NesIdState:'',
		 WeaknessIdState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit user 
  editWeaknesses(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/weaknesses/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].weakness_id,
		  weakness_description : response.data[0].weakness_description,
		  visit_id : response.data[0].visit_id,
		  EditModalVisible:true,
		  //loading : false
		  
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateWeaknesses(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const weakness = {
		  weakness_id : this.state.weakness_id,
		  weakness_description : this.state.weakness_description,
		  visit_id : this.state.visit_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/weaknesses/update/${id}`, weakness,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				EditModalVisible:false, 
				//loading : false
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500,
				  button: false
				})
				axios.get(API_URL + `/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					            { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					            ).then(
						        (visit) => {
							        this.setState({
							  	    Weaknesses: visit.data
							     })
						}
					)
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false
				});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteWeaknesses(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the weakness?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
					  confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/weaknesses/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								
								swal({
								  title: "",
								  text: "The weakness record Deleted Successfully!",
								  icon: "success",
								  timer : 2000,
								  button : false
								})
								axios.get(API_URL + `/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					            { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					            ).then(
						        (visit) => {
							        this.setState({
							  	    Weaknesses: visit.data
							     })
						}
					)
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	  Promise.all([
		  axios.get(API_URL + `/weaknesses/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		  axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		  axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
	  ]).then(
	 ([weakness, standard, visit])=>{
		 this.setState({
			 Weaknesses:weakness.data,
			 Standards: standard.data,
			 Visit: visit.data[0],
			 loading : false
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addWeaknesses(event){
		event.preventDefault()

        const { history } = this.props

        const weakness = {
		  weakness_description : this.state.weakness_description,
		  visit_id : this.state.visit_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/weaknesses', weakness,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading : false
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500,
				  button: false
				})
				axios.get(API_URL + `/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					            { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					            ).then(
						        (visit) => {
							        this.setState({
							  	    Weaknesses: visit.data
							     })
						}
					)
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading : false
				});
			if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='weakness_description'){
		   if(e.target.value!=''){
			   this.setState({WeaknessIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({WeaknessIdState :'has-danger'});
		   }
	   
    } else if(e.target.name==='nes_id'){
		   if(e.target.value!=''){
			   this.setState({NesIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesIdState :'has-danger'});
		   }
	   
    } 
	
        
  }

  render() {
    const {Weaknesses, Standards} = this.state;
    const data = {
      columns: [
        /* { 
          title: 'ID',
           field: 'weakness_id' 
        }, */
        {
          title: 'Description',
          field: 'weakness_description',
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Areas of Improvement</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Areas of Improvement</h3>
                <hr/>
                <Button hidden={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?true:false} color="success" onClick={this.toggle}> {<Add />} New Area Of Improvement</Button>
                <hr/>

                {
                    //Add user modal
					
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addWeaknesses} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">AREA OF IMPROVMENTS ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                
                                
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="weakness_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea" name="weakness_description" id="weakness_description" invalid={this.state.WeaknessIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									   <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title={`AREAS OF IMPROVMENT AT ${this.state.Visit.school_name || ''} ${this.state.Visit.sector_name || ''}`}
                          columns={data.columns}
                          data={
								Weaknesses.map(weakness=>(	
									(this.state.role!="Inspector") ?
									({
									  
									  //weakness_id : weakness.weakness_id,
									  weakness_description :weakness.weakness_description,
									 'OPTIONS': <pre><Link className="btn disabled" to={`/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editWeaknesses(weakness.weakness_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className="btn disabled" to={`/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteWeaknesses(weakness.weakness_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									})
									
									:
									({
									  
									  //weakness_id : weakness.weakness_id,
									  weakness_description :weakness.weakness_description,
									 'OPTIONS': <pre><Link className={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?"btn disabled":"btn"} to={`/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editWeaknesses(weakness.weakness_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?"btn disabled":"btn"} to={`/weaknesses/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteWeaknesses(weakness.weakness_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									})
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateWeaknesses} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">AREA OF IMPROVMENT EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								  </FormGroup>
								 
                                
                                
								 
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="weakness_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  defaultValue={this.state.weakness_description} name="weakness_description" id="weakness_description" invalid={this.state.WeaknessIdState==='has-danger'}  onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Weaknesses;
