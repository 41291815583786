import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";

import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class NESLevels extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  modalVisible:false,
      EditModalVisible:false,
	  nes_level_id:'',
	  nes_level_name:'',
	  NesLevelIdState:'',
	  NesLevelNameState:'',
	  nes_level_graphic:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  role:window.sessionStorage.getItem('urole'),
	  NESLevels:[],
	  loading: '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addNESLevels = this.addNESLevels.bind(this);
	this.editNESLevels = this.editNESLevels.bind(this);
	this.updateNESLevels = this.updateNESLevels.bind(this);
	this.deleteNESLevels =this.deleteNESLevels.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 nes_level_id:'',
	     nes_level_name:'',
	     NesLevelIdState:'',
	     NesLevelNameState:'',
		 
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 nes_level_id:'',
	     nes_level_name:'',
	     NesLevelIdState:'',
	     NesLevelNameState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Schools
  editNESLevels(id) {
	
	//this.setState({loading : true});
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/nes_levels/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].nes_level_id,
		  nes_level_name : response.data[0].nes_level_name,
		  EditModalVisible:true,
		  //loading: false,

	  })
	}).catch(error => {
		  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
	  })
  }
  
  //Update 
	  updateNESLevels(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const nes_level = {
		  nes_level_name : this.state.nes_level_name,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/nes_levels/update/${id}`, nes_level,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
                this.setState({
					//loading : false,
					EditModalVisible:false,});
				
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer: 2500,
				  button: false
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([nes_level]) => {
							this.setState({
								NESLevels: nes_level.data,
								loading: false,
							})
						}
					)
				});
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
				if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteNESLevels(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the nes level?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							
							//this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/nes_levels/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The nes level record Deleted Successfully!",
								  icon: "success",
									button: false,
								  timer: 2500,
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
										([nes_level]) => {
											this.setState({
												NESLevels: nes_level.data,
												loading: false,
											})
										}
									)
								});
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/nes_levels`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([nes_level])=>{
		 this.setState({
			 NESLevels:nes_level.data,
			 loading: false,
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addNESLevels(event){
		event.preventDefault()

        const { history } = this.props

        const nes_level = {
		  nes_level_id: this.state.nes_level_id,
		  nes_level_name: this.state.nes_level_name,
		  
        }
        
		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/nes_levels', nes_level,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading: false,
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:1250
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
						([nes_level]) => {
							this.setState({
								NESLevels: nes_level.data,
								loading: false,
							})
						}
					)
				});
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading: false,
				});
			
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='nes_level_id'){
		   if(e.target.value!=''){
			   this.setState({NesLevelIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesLevelIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='nes_level_name'){
		   if(e.target.value!=''){
			   this.setState({NesLevelNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesLevelNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='nes_level_graphic'){
		   if(e.target.value!=''){
			   this.setState({NesLevelGraphicState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesLevelGraphicState :'has-danger'});
		   }
	   
    }
        
  }

  render() {
    const {NESLevels} = this.state;
    const data = {
      columns: [
        { 
          title: 'Level Id',
           field: 'nes_level_id' 
        },
		{ 
          title: 'Level Name',
           field: 'nes_level_name' 
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">nes levels</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">NES Levels Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true} onClick={this.toggle}> {<Add />} New Nes Level</Button>
                <hr/>

                {
                    //Add user modal
					
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form enctype="multipart/form-data" onSubmit={this.addNESLevels} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">NES LEVEL ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_level_id">Level ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="nes_level_id" id="nes_level_id" invalid={this.state.NesLevelIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}/>
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={8} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_level_name">Level Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={4} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  name="nes_level_name" id="nes_level_name" invalid={this.state.NesLevelNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
				 
                  <Table responsive striped size="lg">
				 
                      <MaterialTable
                          title="NES LEVELS IN MALAWI"
                          columns={data.columns}
                          data={
								NESLevels.map(nes_level=>(	
									{
									  
									  nes_level_id : nes_level.nes_level_id,
									  nes_level_name : nes_level.nes_level_name,
									 'OPTIONS': <pre><Link to={`/nes_levels`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.editNESLevels(nes_level.nes_level_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
													<Link to={`/nes_levels`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.deleteNESLevels(nes_level.nes_level_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />
						
					</Table>
						

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form enctype="multipart/form-data" onSubmit={this.updateNESLevels} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">SCHOOL EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								 
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_level_name">Level Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea" value={this.state.nes_level_name} name="nes_level_name" id="nes_level_name" invalid={this.state.NesLevelNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>	
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default NESLevels;

