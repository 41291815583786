import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";
import GanttChart from '../../common/Recommendations/Gantt'
import moment from 'moment'

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class InpectionFollowup extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  //Get logged user role
	  role: window.sessionStorage.getItem("urole"),
	  visit: {},
	  followup_visit: {},
      modalVisible:false,
      EditModalVisible:false,
      emis_id:'',
      zone_id:'',
      district_id:'',
      division_id:'',
	  visit_id:'',
	  sector_id:window.sessionStorage.getItem("sector"),
	  head_teacher:'',
	  head_teacher_phone:'',
	  head_teacher_email:'',
	  present_visitation_date:'',
	  prev_visitation_date:'00/00/0000',
	  lead_inspector_id:'',
	  first_inspector_id:'',
	  second_inspector_id:'',
	  third_inspector_id:'',
	  fourth_inspector_id:'',
	  school_address:'',
	  establishment_year:'',
	  enrolment_boys:'',
	  enrolment_girls:'',
	  attendance_boys:'',
	  attendance_girls:'',
	  number_of_teachers:'',
	  unqualified_teachers:'',
	  publication_date:'',
	  action_plan_date:'',
	  next_inspection_date:'',
	  lead_advisor_id:'', 
	  govt_chair_id:'',
	  visit_type_id:'',
	  bearer_auth:window.sessionStorage.getItem('token'),
	  logged_user_id:window.sessionStorage.getItem('uid'),
	  Visits:[],
	  VisitTypes:[],
	  recentVisits: [],
	  Users:[],
	  Sectors:[],
	  Districts:[],
	  Zones:[],
	  Divisions:[],
	  Schools:[],
	  years:[],
	  juridistiction:'',
	  loading : '',
	  isExpired:false,
	  MajorRecommendations:[],
	  MinorRecommendations:[],
	  followup_comments:[],
	  followup_status:[],
	  ActionPlans: [],
	  categories: [],
	  national_standards: []
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addInspectionVisit = this.addInspectionVisit.bind(this);
	this.editInspectionVisit = this.editInspectionVisit.bind(this);
	this.updateInspectionVisit = this.updateInspectionVisit.bind(this);
	this.deleteInspectionVisit =this.deleteInspectionVisit.bind(this);
	
	this.filterDistrictsBeforeLoad = this.filterDistrictsBeforeLoad.bind(this);
	this.filterDistrictsAfterLoad = this.filterDistrictsAfterLoad.bind(this);
	this.filterZonesBeforeLoad = this.filterZonesBeforeLoad.bind(this);
	this.filterZonesAfterLoad = this.filterZonesAfterLoad.bind(this);
	this.filterSchoolsBeforeLoad = this.filterSchoolsBeforeLoad.bind(this);
	this.filterSchoolsAfterLoad = this.filterSchoolsAfterLoad.bind(this);
	//After modalload
	this.getRecentVisitsBySchool = this.getRecentVisitsBySchool.bind(this);
	//Before modalload: Crucial for the followup update
	this.getRecentVisitsBySchoolBeforeLoad = this.getRecentVisitsBySchoolBeforeLoad.bind(this);
	this.getYears = this.getYears.bind(this);
	this.calculateDateDifference = this.calculateDateDifference.bind(this);
	this.getMinorRecommendationsByVisitId = this.getMinorRecommendationsByVisitId.bind(this);
	this.getMajorRecommendationsByVisitId = this.getMajorRecommendationsByVisitId.bind(this);
	this.getActionPlansByVisitId = this.getActionPlansByVisitId.bind(this);
	this.fetchData = this.fetchData.bind(this);
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		  modalVisible:false,
		  //Defaults the state
		  visit: {},
		  emis_id:'',
		  zone_id:'',
		  district_id:'',
		  division_id:'',
		  visit_id:'',
		  head_teacher:'',
		  head_teacher_phone:'',
		  head_teacher_email:'',
		  present_visitation_date:'',
		  prev_visitation_date:'00/00/0000',
		  lead_inspector_id:'',
		  first_inspector_id:'',
		  second_inspector_id:'',
		  third_inspector_id:'',
		  fourth_inspector_id:'',
		  school_address:'',
		  establishment_year:'',
		  enrolment_boys:'',
		  enrolment_girls:'',
		  attendance_boys:'',
		  attendance_girls:'',
		  number_of_teachers:'',
		  unqualified_teachers:'',
		  publication_date:'',
		  action_plan_date:'',
		  next_inspection_date:'',
		  lead_advisor_id:'', 
		  govt_chair_id:'',
		  visit_type_id:'',
		  EmisIdState:'',
		  ZoneIdState:'',
		  DistrictIdState:'',
		  DivisionIdState:'',
		  HeadTeacherIdState:'',
		  presentDateState:'',
		  prevDateState:'',
		  leadInspectorIdState:'',
		  firstInspectorIdState:'',
		  secondInspectorIdState:'',
		  thirdInspectorIdState:'',
		  fourthInspectorIdState:'',
		  SchoolAddressState:'',
		  EstYearState:'',
		  EnrolledBoysState:'',
		  EnrolledGirlsState:'',
		  AttendanceBoysState:'',
		  AttendanceGirlsState:'',
		  TotalTeachersState:'',
		  UnqualifiedTeachersState:'',
		  pubDateState:'',
		  actionDateState:'',
		  nextInspectionDateState:'',
		  leadAdvisorIdState:'',
		  govChairIdState:'',
		  visitTypeIdState:'',
		  recentVisits:[],
		  MinorRecommendations:[],
		  MajorRecommendations:[],
		  Recommendations:[],
		  followup_comments:[],
		  followup_status:[],
		 ActionPlans: [],
		 extra_comments: ''
		  
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisible:true});
    }else{
     this.setState({
		  EditModalVisible:false,
		  //Defaults the state
		  emis_id:'',
		  zone_id:'',
		  district_id:'',
		  division_id:'',
		  visit_id:'',
		  head_teacher:'',
		  head_teacher_phone:'',
		  head_teacher_email:'',
		  present_visitation_date:'',
		  prev_visitation_date:'00/00/0000',
		  lead_inspector_id:'',
		  first_inspector_id:'',
		  second_inspector_id:'',
		  third_inspector_id:'',
		  fourth_inspector_id:'',
		  school_address:'',
		  establishment_year:'',
		  enrolment_boys:'',
		  enrolment_girls:'',
		  attendance_boys:'',
		  attendance_girls:'',
		  number_of_teachers:'',
		  unqualified_teachers:'',
		  publication_date:'',
		  action_plan_date:'',
		  next_inspection_date:'',
		  lead_advisor_id:'', 
		  govt_chair_id:'',
		  visit_type_id:'',
		  EmisIdState:'',
		  ZoneIdState:'',
		  DistrictIdState:'',
		  DivisionIdState:'',
		  HeadTeacherIdState:'',
		  presentDateState:'',
		  prevDateState:'',
		  leadInspectorIdState:'',
		  firstInspectorIdState:'',
		  secondInspectorIdState:'',
		  thirdInspectorIdState:'',
		  fourthInspectorIdState:'',
		  SchoolAddressState:'',
		  EstYearState:'',
		  EnrolledBoysState:'',
		  EnrolledGirlsState:'',
		  AttendanceBoysState:'',
		  AttendanceGirlsState:'',
		  TotalTeachersState:'',
		  UnqualifiedTeachersState:'',
		  pubDateState:'',
		  actionDateState:'',
		  nextInspectionDateState:'',
		  leadAdvisorIdState:'',
		  govChairIdState:'',
		  visitTypeIdState:'',
		  recentVisits:[],
		  MinorRecommendations:[],
		  MajorRecommendations:[],
		  followup_comments:[],
		  followup_status:[],
		  ActionPlans:[],
		 
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
	 
	 calculateDateDifference(date){
			//Check the expiry of priviliges on school visits actions
			let inspection_date = new Date(date);
			let today_date = new Date();
			
			let date_difference = today_date.getTime()-inspection_date.getTime();
			
			let number_of_days = date_difference / (1000 * 3600 * 24);
			
			if(number_of_days>=0 && number_of_days<=28){
				return false;
			}
			
			return true;
			
	 }
	 
	 //Filtering
	 filterDistrictsBeforeLoad(id){
		 //e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/districts/filter/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }
	 
	 getActionPlansByVisitId(e){
		 e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/action_plans/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   ActionPlans:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 getMinorRecommendationsByVisitId(e){
		 e.preventDefault();
		 if (!e.target.value) return
		 //this.setState({loading : true});
		 
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/recommendations/followup_minor/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   MinorRecommendations:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 getMajorRecommendationsByVisitId(e){
		 e.preventDefault();
		 if(!e.target.value) return
		 //this.setState({loading : true});
		 
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/recommendations/major/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   MajorRecommendations:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 filterDistrictsAfterLoad(e){
		 e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 filterZonesBeforeLoad(id){
		 //e.preventDefault();
		  
		  //this.setState({loading : true});
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }
	 
	 filterZonesAfterLoad(e){
		 e.preventDefault();
		  
		  //this.setState({loading : true});
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
			   //console.log(this.state.Zones);
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 filterSchoolsBeforeLoad(id){
		 //e.preventDefault();
		  
		  //this.setState({loading : true});
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }
	 
	 filterSchoolsAfterLoad(e){
		 e.preventDefault();
		  
		  //this.setState({loading : true});
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 getRecentVisitsBySchool(e){
		 e.preventDefault();
		  
		  //this.setState({loading : true});
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/inspection_visits/recent/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			  console.log(e.target.value) 
			  console.log(response.data) 
			  this.setState({
				   recentVisits:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 getRecentVisitsBySchoolBeforeLoad(id){
		 //e.preventDefault();
		  
		  //this.setState({loading : true});
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		 axios.get(API_URL + `/inspection_visits/recent/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			  console.log(response.data);
			   this.setState({
				   recentVisits:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
  
  //Edit user 
  editInspectionVisit(id) {
	
	//this.setState({loading : true});
	  Promise.all([axios.get(API_URL + `/inspection_visits/edit/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/action_plans/${this.state.Visits.find(v => v.visit_id == id).assoc_visit}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/recommendations/major/${this.state.Visits.find(v => v.visit_id == id).assoc_visit}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/recommendations/followup_minor/edit/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/inspection_visits/recent/${this.state.Visits.find(v => v.visit_id == id).emis_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
			 ([visit, ap, re, recommendation, rece])=> {
	  //console.log(response.data);
	  
	  this.filterDistrictsBeforeLoad(visit.data[0].division_id);
	  this.filterZonesBeforeLoad(visit.data[0].district_id);
	  this.filterSchoolsBeforeLoad(visit.data[0].zone_id);
	  
	  //Process followup_comments data 
	  let comments =[];
	  let comments_data = recommendation.data;
	  
	  for(let i=0; i<comments_data.length;i++){
		let d = {'state': comments_data[i]['recommendation_id'] + ":" + comments_data[i]['followup_comments']};
		
		comments.push(d);
	  }	
	  
	  //Process followup_status data 
	  let states =[];
	  let status_data = recommendation.data;
	  
	  for(let i=0; i<status_data.length;i++){
		let f = {'state': status_data[i]['recommendation_id'] + ":" + status_data[i]['need_extra_followup']};
		
		states.push(f);
			  }	
	  
		this.setState({
		  recentVisits: rece.data,
		  MajorRecommendations: re.data,
		  ActionPlans: ap.data,
		  visit: rece.data.find(d => d.visit_id == visit.data[0].assoc_visit),
		  followup_visit: visit.data[0],
		  id:visit.data[0].assoc_visit,
		  emis_id: visit.data[0].emis_id,
		  zone_id: visit.data[0].zone_id,
		  district_id: visit.data[0].district_id,
		  visit_id: visit.data[0].assoc_visit,
		  division_id: visit.data[0].division_id,
		  present_visitation_date: visit.data[0].present_visitation_date,
		  prev_visitation_date: visit.data[0].prev_visitation_date,
		  lead_inspector_id: visit.data[0].lead_inspector_id,
		  first_inspector_id: visit.data[0].first_inspector_id, 
		  second_inspector_id : visit.data[0].second_inspector_id,
		  third_inspector_id:visit.data[0].third_inspector_id,
		  fourth_inspector_id:visit.data[0].fourth_inspector_id,
		  followup_comments: comments,	
		  followup_status: states,
		  MinorRecommendations: recommendation.data,
		  extra_comments: recommendation.data.length > 0 ? recommendation.data[0].extra_comments : '',
		  EditModalVisible:true,
		  //loading : false,
	  })
	  
	  
		  }).catch(error => {
			  console.log(error)
			  if(error.response && error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: 'Something went wrong',
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateInspectionVisit(event){
		event.preventDefault()

        const { history } = this.props
		
		//For updating school visit
		const id = this.state.visit_id
		const followupId = this.state.followup_visit.visit_id
		
	

        const followup_inspection = {
		  emis_id: this.state.emis_id,
		  head_teacher: 'Not collected',
		  phone_number: 'Not collected',
		  present_visitation_date: this.state.present_visitation_date,
		  prev_visitation_date: 'Not collected',
		  lead_inspector_id: this.state.lead_inspector_id,
		  first_inspector_id: this.state.first_inspector_id, 
		  second_inspector_id : this.state.second_inspector_id,
		  third_inspector_id:this.state.third_inspector_id,
		  fourth_inspector_id:this.state.fourth_inspector_id,
		  school_address:'Not collected',
		  establishment_year:'0000',
		  enrolment_boys:'0',
		  enrolment_girls:'0',
		  attendance_boys:'0',
		  attendance_girls:'0',
		  number_of_teachers:'0',
		  unqualified_teachers:'0',
		  publication_date : '00/00/0000',
		  action_plan_date : '00/00/0000',
		  next_inspection_date : '00/00/00',
		  zone_id: this.state.zone_id,
		  sector_id: this.state.sector_id,
		  district_id: this.state.district_id,
		  division_id: this.state.division_id,
		  lead_advisor_id : 'Not collected',
		  govt_chair_id : 'Not collected',
		  visit_type_id :'2',
		  followup_comments: this.state.followup_comments,
		  followup_status: this.state.followup_status,
		  assoc_visit: this.state.visit_id,
		  extra_comments: this.state.extra_comments
        }
		
		//this.setState({loading : true});
		//   
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		  Promise.all([axios.patch(API_URL + `/inspection_visits/update/${followupId}`, followup_inspection, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.patch(API_URL + `/recommendations/minor/update_followup/${id}`, followup_inspection,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([visit, recommendation])=> {
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(visit.data['status']=='success'){
				this.setState({
					EditModalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: visit.data['message'],
				  icon: visit.data['status'],
				  timer:1250
				});
				axios.get(API_URL + `/inspection_visits/followup`, 
					{ headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					).then(
						(visit) => {
							this.setState({
								Visits: visit.data
							})
						}
					)
			}else{
				this.setState({
					EditModalVisible:true, 
				});
				swal({
				  title: "",
				  text: visit.data['message'],
				  icon: visit.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				});
				  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }
	 
	 //delete
	deleteInspectionVisit(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the inspection visit?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							
							//this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/inspection_visits/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading: false});
								
								swal({
								  title: "",
								  text: "School visit deleted Successfully!",
								  icon: "success",
								  timer:2000,
								  button: false
								});
								axios.get(API_URL + `/inspection_visits/followup`, 
								{ headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
								).then(
								(visit) => {
								this.setState({
								Visits: visit.data
							})
						}
					)
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
	
	getYears(){
		for(let i=new Date().getFullYear();i>=1500;i--){
			this.state.years.push(i);
		}
	}

	fetchData() {
		this.setState({ loading: true });
		axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(({ data }) => {
				this.setState({
					national_standards: data
				})	
			})


		//Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		//Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(

		if (this.state.role == 'Division Manager') {
			const div_id = window.sessionStorage.getItem('division');

			Promise.all([axios.get(API_URL + `/inspection_visits/division/${div_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions/${div_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, ns, sector, visit_type, user, div_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (div_data.data.length >= 1) ? div_data.data[0]['division_name'] + " DIVISION'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					});


				}
			).catch(error => {
				this.setState({ loading: false });
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'DEM') {
			const dist_id = window.sessionStorage.getItem('district');

			Promise.all([axios.get(API_URL + `/inspection_visits/district/${dist_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/districts/${dist_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, district_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (district_data.data.length >= 1) ? district_data.data[0]['district_name'] + " DISTRICT'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'Secondary Advisor') {
			const zon_id = window.sessionStorage.getItem('zone');

			Promise.all([axios.get(API_URL + `/inspection_visits/followup/zone/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/zones/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, categories, division, sector, visit_type, user, zone_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						categories: categories.data,
						juridistiction: (zone_data.data.length >= 1) ? zone_data.data[0]['zone_name'] + " CLUSTER'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'PEA') {
			const zon_id = window.sessionStorage.getItem('zone');

			Promise.all([axios.get(API_URL + `/inspection_visits/followup/zone/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/zones/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, zone_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (zone_data.data.length >= 1) ? zone_data.data[0]['zone_name'] + " ZONE'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'Head Teacher') {
			const sch_id = window.sessionStorage.getItem('emis');

			Promise.all([axios.get(API_URL + `/inspection_visits/school/${sch_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/schools/${sch_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, school_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (school_data.data.length >= 1) ? school_data.data[0]['school_name'] + " SCHOOL'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							window.location.reload();
						});
				}
			})

		} else {
			Promise.all([axios.get(API_URL + `/inspection_visits/followup`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: "NATIONAL FOLLOW-UP VISITS",
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							window.location.reload();
						});
				}
			})
		}

		this.getYears();
	}
  componentDidMount () {
	 this.fetchData()
  }
	
  //Add user
	 addInspectionVisit(event){
		event.preventDefault()

        const { history } = this.props
		
		const id = this.state.visit_id

        const followup_inspection = {
		  emis_id: this.state.emis_id,
		  head_teacher: 'Not collected',
		  phone_number: 'Not collected',
		  present_visitation_date: this.state.present_visitation_date,
		  prev_visitation_date: 'Not collected',
		  lead_inspector_id: this.state.lead_inspector_id,
		  first_inspector_id: this.state.first_inspector_id, 
		  second_inspector_id : this.state.second_inspector_id,
		  third_inspector_id:this.state.third_inspector_id,
		  fourth_inspector_id:this.state.fourth_inspector_id,
		  school_address:'Not collected',
		  establishment_year:'0000',
		  enrolment_boys:'0',
		  enrolment_girls:'0',
		  attendance_boys:'0',
		  attendance_girls:'0',
		  number_of_teachers:'0',
		  unqualified_teachers:'0',
		  publication_date : '00/00/0000',
		  action_plan_date : '00/00/0000',
		  next_inspection_date : '00/00/00',
		  zone_id: this.state.zone_id,
		  sector_id: this.state.sector_id,
		  district_id: this.state.district_id,
		  division_id: this.state.division_id,
		  lead_advisor_id : 'Not collected',
		  govt_chair_id : 'Not collected',
		  visit_type_id :'2',
		  followup_comments: this.state.followup_comments,
		  followup_status: this.state.followup_status,
		  assoc_visit: this.state.visit_id,
		  extra_followup_comments: this.state.extra_comments
        }
		
		//console.log(followup_inspection);
		Promise.all([axios.post(API_URL + `/inspection_visits/followup`, followup_inspection,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.patch(API_URL + `/recommendations/minor/update_followup/${id}`, followup_inspection,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			([visit, recommendation]) => {
				this.setState({
					modalVisible: false,
				})
				if (recommendation.data.status == 'success' && visit.data.status == 'success') {
					swal({
						text: "The followup inspection has been added successfully!",
						icon: "success",
						timer: 3500,
						button: false
					});
				} else {
					swal({
						text: visit.data.message,
						icon: "error",
						timer: 5000,
						button: false
					});
				}
				this.fetchData()
			 }
			 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='present_visitation_date'){
		   if(e.target.value!=''){
			   this.setState({presentDateState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({presentDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='prev_visitation_date'){
		   if(e.target.value!='' && e.target.value < this.state.present_visitation_date){
			   this.setState({prevDateState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({prevDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='lead_inspector_id'){
		   if(e.target.value!=''){
			   this.setState({leadInspectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({leadInspectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='first_inspector_id'){
		   if(e.target.value!=''){
			   this.setState({firstInspectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({firstInspectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='second_inspector_id'){
		   if(e.target.value!=''){
			   this.setState({secondInspectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({secondInspectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='third_inspector_id'){
		   if(e.target.value!=''){
			   this.setState({thirdInspectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({thirdInspectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='fourth_inspector_id'){
		   if(e.target.value!=''){
			   this.setState({fourthInspectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({fourthInspectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='publication_date'){
		   if(e.target.value!='' && e.target.value > this.state.present_visitation_date && e.target.value > this.state.prev_visitation_date){
			   this.setState({pubDateState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({pubDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='action_plan_date'){
		   if(e.target.value!='' && e.target.value > this.state.present_visitation_date){
			   this.setState({actionDateState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({actionDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='next_inspection_date'){
		   if(e.target.value!='' && e.target.value > this.state.action_plan_date && e.target.value > this.state.publication_date){
			   this.setState({nextInspectionDateState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({nextInspectionDateState :'has-danger'});
		   }
	   
    }else if(e.target.name==='lead_advisor_id'){
		   if(e.target.value!='' && e.target.value != this.state.govt_chair_id){
			   this.setState({leadAdvisorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({leadAdvisorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='govt_chair_id'){
		   if(e.target.value!=''  && e.target.value != this.state.lead_advisor_id){
			   this.setState({govChairIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({govChairIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='visit_type_id'){
		   if(e.target.value!=''){
			   this.setState({visitTypeIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({visitTypeIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='emis_id'){
		   if(e.target.value!=''){
			   this.setState({EmisIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EmisIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='zone_id'){
		   if(e.target.value!=''){
			   this.setState({ZoneIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ZoneIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='district_id'){
		   if(e.target.value!=''){
			   this.setState({DistrictIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DistrictIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='division_id'){
		   if(e.target.value!=''){
			   this.setState({DivisionIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DivisionIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='sector_id'){
		   if(e.target.value!=''){
			   this.setState({SectorIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SectorIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='phone_number'){
		   if(e.target.value!=''){
			   this.setState({PhoneState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({PhoneState :'has-danger'});
		   }
	   
    }else if(e.target.name==='head_teacher'){
		   if(e.target.value!=''){
			   this.setState({HeadTeacherIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({HeadTeacherIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='head_teacher_email'){
		   if(e.target.value!=''){
			   this.setState({HeadTeacherEmailState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({HeadTeacherEmailState :'has-danger'});
		   }
	   
    }else if(e.target.name==='head_teacher_phone'){
		   if(e.target.value!=''){
			   this.setState({HeadTeacherPhoneState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({HeadTeacherPhoneState :'has-danger'});
		   }
	   
    }else if(e.target.name==='visit_id'){
		   if(e.target.value!=''){
			   this.setState({VisitIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({VisitIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='school_address'){
		   if(e.target.value!=''){
			   this.setState({SchoolAddressState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SchoolAddressState :'has-danger'});
		   }
	   
    }else if(e.target.name==='establishment_year'){
		   if(e.target.value!=''){
			   this.setState({EstYearState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EstYearState :'has-danger'});
		   }
	   
    }else if(e.target.name==='enrolment_boys'){
		   if(e.target.value!=''){
			   this.setState({EnrolledBoysState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EnrolledBoysState :'has-danger'});
		   }
	   
    }else if(e.target.name==='enrolment_girls'){
		   if(e.target.value!=''){
			   this.setState({EnrolledGirlsState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EnrolledGirlsState :'has-danger'});
		   }
	   
    }else if(e.target.name==='attendance_boys'){
		   if(e.target.value!=''){
			   this.setState({AttendanceBoysState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({AttendanceBoysState :'has-danger'});
		   }
	   
    }else if(e.target.name==='attendance_girls'){
		   if(e.target.value!=''){
			   this.setState({AttendanceGirlsState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({AttendanceGirlsState :'has-danger'});
		   }
	   
    }else if(e.target.name==='number_of_teachers'){
		   if(e.target.value!=''){
			   this.setState({TotalTeachersState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({TotalTeachersState :'has-danger'});
		   }
	   
    }else if(e.target.name==='unqualified_teachers'){
		   if(e.target.value!=''){
			   this.setState({UnqualifiedTeachersState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({UnqualifiedTeachersState :'has-danger'});
		   }
	   
    }else if(e.target.name==='followup_comments'){
		if(e.target.value!=''){
				//String to be added to the  key_evidence_status array
				let input_object = e.target.id + ":" + e.target.value;
				
				//Check the length of the key_evidence_status array
				if(this.state.followup_comments.length!=0){
					
					//Loop through the key_evidence_status array
					for(let i=0;i<this.state.followup_comments.length;i++){
						//Capture the array valuefor the current index 
						let rec_comment = this.state.followup_comments[i]['state'];
						
						//Explode the requirement id from the KE status
						let split_rec_comment = rec_comment.split(":");
						
						//Make a copy of the key_evidence_status array
						let followup_comments_copy = this.state.followup_comments;
						
						console.log("Echoing...");
						console.log(i);
						
						//Check if the  requirement id already exists in the key_evidence_status state
						if(split_rec_comment[0]==e.target.id){
							//Check if the KE status already exists in the key_evidence_status state
							if(split_rec_comment[1]!=e.target.value){	
								console.log(split_rec_comment[0]+ "==" + e.target.id);
								
								//Remove the array value by index in the copied  key_evidence_status
								this.state.followup_comments.splice(i, 1);
								//key_evidence_status_copy[i] = e.target.id + ":" + e.target.value;
								
								//Add  the new id and value to key_evidence_status
								this.setState({followup_comments : this.state.followup_comments.concat([{state:input_object}])});
							}
						}else if(rec_comment!=input_object){
							console.log(split_rec_comment[0]+ "!=" + e.target.id);
							//Add the new string as before
							this.setState({followup_comments : this.state.followup_comments.concat([{state:input_object}])});
						}
					}
				}else{
					console.log("Zero...");
					
					//Add the input_object if key_evidence_status state is empty
					this.setState({followup_comments : this.state.followup_comments.concat([{state:input_object}])});
				}
		   }
	}else if(e.target.name==='followup_status'){
		if(e.target.value!=''){
				//String to be added to the  key_evidence_status array
			let input_object = e.target.id + ":" + e.target.value;
				
				//Check the length of the key_evidence_status array
				if(this.state.followup_status.length!=0){
					
					//Loop through the key_evidence_status array
					for(let i=0;i<this.state.followup_status.length;i++){
						//Capture the array valuefor the current index 
						let rec_status = this.state.followup_status[i]['state'];
						
						//Explode the requirement id from the KE status
						let split_rec_status = rec_status.split(":");
						
						//Make a copy of the key_evidence_status array
						let followup_status_copy = this.state.followup_status;
						
						console.log("Echoing...");
						console.log(i);
						
						//Check if the  requirement id already exists in the key_evidence_status state
						if(split_rec_status[0]==e.target.id){
							//Check if the KE status already exists in the key_evidence_status state
							if(split_rec_status[1]!=e.target.value){	
								console.log(split_rec_status[0]+ "==" + e.target.id);
								
								//Remove the array value by index in the copied  key_evidence_status
								this.state.followup_status.splice(i, 1);
								//key_evidence_status_copy[i] = e.target.id + ":" + e.target.value;
								
								//Add  the new id and value to key_evidence_status
								this.setState({followup_status : this.state.followup_status.concat([{state:input_object}])});
							}
						}else if(rec_status!=input_object){
							console.log(split_rec_status[0]+ "!=" + e.target.id);
							//Add the new string as before
							this.setState({followup_status : this.state.followup_status.concat([{state:input_object}])});
						}
					}
				}else{
					console.log("Zero...");
					
					//Add the input_object if key_evidence_status state is empty
					this.setState({followup_status : this.state.followup_status.concat([{state:input_object}])});
				}
		   }
	}
        
  }

  render() {
    const {Visits,Divisions, Sectors, VisitTypes,Users} = this.state;
    const data = {
      columns: [
        /* { 
          title: 'Visit Id',
           field: 'visit_id' 
        }, */
		{
          title: 'School',
          field: 'emis_id',
        },
		{
          title: 'Date',
          field: 'present_visitation_date',
        },
        // {
        //   title: 'Head Teacher',
		// 	field: 'head_teacher',
		//   hidden: true,
        // },
		{
			title: 'Lead Inspector',
			field: 'lead_inspector_id',
			hidden: true,
        },
        // {
		// 	title: 'Advisor',
		// 	field: 'pea',
		// 	hidden: true,
        // },
		{
			title: 'Cluster',
			field: 'cluster_id',
			hidden: true,
        },
		{
			title: 'District',
			field: 'district_id',
			hidden: true,
        },
		{
			title: 'Division',
			field: 'division_id',
			hidden: true,
        },
        {
			title: 'Actions',
			field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">inspection visit</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">School Inspection's Management</h3>
                <hr/>
                <Button hidden={!['Secondary Advisor', 'Inspector', 'PEA'].includes(this.state.role)} color="success" onClick={this.toggle}> {<Add />} New Follow-up Visit</Button>
                <hr/>

                {
					//Add user modal
					
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal size="xl" fullscreen="true" isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addInspectionVisit} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">FOLOW-UP INSPECTION VISIT FORM</center>
                          </ModalHeader>
                          <ModalBody>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input type="select" name="division_id" id="division_id" invalid={this.state.DivisionIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e);}} onBlur={this.handleInput} required={true}> 
										<option></option>
										{
										  Divisions.map(division=>(
											  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
											)
										  )
										}
									</Input>
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input type="select" name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e);}} onBlur={this.handleInput} required={true}> 
											<option></option>
											{
												  
											  this.state.Districts.map(district=>(
															 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
													)
												   )
														
											}
									</Input>
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="Zone">{this.state.sector_id==1?'Zone Name':this.state.sector_id==2?'Cluster Name':'Zone/Cluster Name'}&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input type="select" name="zone_id" id="zone_id" invalid={this.state.ZoneIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchoolsAfterLoad(e);}} onBlur={this.handleInput} required={true}> 
										<option></option>
										{
												  this.state.Zones.map(zone=>(
														//Logic circuit and
														(this.state.sector_id==zone.sector_id)&& <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
													)
												   )
														
										}
									</Input>
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="School">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="select" name="emis_id" id="emis_id" invalid={this.state.EmisIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.getRecentVisitsBySchool(e);}} onBlur={(e)=>{this.handleInput(e);}} required={true}> 
										  <option></option>
										  {
											  this.state.Schools.map(school=>(
														 <option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
												)
											   )
													
										  }
								  </Input>
								  <FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="School Visit">School Visit&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="select" name="visit_id" id="visit_id" invalid={this.state.VisitIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.getMinorRecommendationsByVisitId(e); this.getMajorRecommendationsByVisitId(e); this.getActionPlansByVisitId(e); }} onBlur={(e)=>{this.handleInput(e);}} required={true}> 
										  <option></option>
										  {
											  this.state.recentVisits.map(visit=>(
														 <option value={visit.visit_id} key={visit.visit_id}>{visit.school_name + " conducted on " + new Date(visit.present_visitation_date).toLocaleDateString()}</option>
												)
											   )
													
										  }
								  </Input>
								  <FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="present_visitation_date">Present Visitation Date&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="date"  name="present_visitation_date" id="present_visitation_date" invalid={this.state.presentDateState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							
							<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="lead_inspector_id">Lead Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input type="select" defaultValue={this.state.lead_inspector_id} name="lead_inspector_id" id="lead_inspector_id" invalid={this.state.leadInspectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>
											<option></option>
											{
												  Users.map(user=>(
													  (user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.first_inspector_id) &&
													  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
													)
												  )
												}
									</Input>
									<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>

                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="first_inspector_id">First Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select"  name="first_inspector_id" id="first_inspector_id" invalid={this.state.firstInspectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
												<option></option>
												{
												  Users.map(user=>(
													  (user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
													  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
													)
												  )
												}
									  </Input>
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="second_inspector_id">Second Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select"  name="second_inspector_id" id="second_inspector_id" invalid={this.state.secondInspectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
												<option></option>
												{
												  Users.map(user=>(
													  (user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
													  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
													)
												  )
												}
									  </Input>
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="third_inspector_id">Third Inspector Name</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="select"  name="third_inspector_id" id="third_inspector_id"  onChange={this.handleInput} onBlur={this.handleInput} >
												<option></option>
												{
												  Users.map(user=>(
													  (user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.lead_inspector_id) &&
													  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
													)
												  )
												}
									  </Input>
                                    </Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="fourth_inspector_id">Fourth Inspector Name</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="select"  name="fourth_inspector_id" id="fourth_inspector_id" onChange={this.handleInput} onBlur={this.handleInput} >
												<option></option>
												{
												  Users.map(user=>(
													  (user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
													  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
													)
												  )
												}
									  </Input>
                                    </Col>
                                  </Row>
                                </FormGroup>
							<div>
												The following action plan on this visit:<br />
												{this.state.MajorRecommendations && this.state.MajorRecommendations.length > 0 &&
													<GanttChart editMode={false} visit={this.state.Visits.filter(v => v.visit_id === this.state.visit_id)} updateRecommendationsList={() => { }} categories={this.state.categories} recommendations={this.state.MajorRecommendations} />
												}
							</div>
							<Table responsive striped size="lg">
								<thead>
										<tr>
											<th style={{width:'20%'}} className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">Nes</th>
											<th style={{width:'30%'}} className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">Minor Recommendations</th>
											<th style={{width:'30%'}} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">Followup Comments</th>
											<th style={{width:'20%'}} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Status</th>
										</tr>
								</thead>
								
								
								
								<tbody>
								{ this.state.MinorRecommendations.length > 0 &&
									
									this.state.MinorRecommendations.map(rcmdtn=>(
										<tr key={rcmdtn.recommendation_id}>
											<td style={{ width: '20%' }} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												{this.state.national_standards.find(cat => cat.nes_id == rcmdtn.nes_id).nes_name}
											</td>
											<td style={{width:'30%'}} className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
												{rcmdtn.recommendation_description}
											</td>
											<td style={{width:'30%'}} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<Input required type="textarea" name="followup_comments" id={rcmdtn.recommendation_id} xs={12} sm={12} md={12} lg={12} xl={12} onChange={(e) => { this.handleInput(e); }}  onBlur={(e)=>{this.handleInput(e);}}  />
											</td>
											<td style={{width:'20%'}} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
												<Input required type="select" defaultValue='' name="followup_status" id={rcmdtn.recommendation_id} onBlur={(e) => { this.handleInput(e); }} onChange={(e) => { this.handleInput(e); }}>
													<option></option>
													<option value='Achieved'>Achieved</option>
													<option value='Partially Achieved'>Partially Achieved</option>
													<option value='Not Achieved'>Not Achieved</option>
												</Input>
											</td>
										</tr>
									))
													}
													<tr>
														<td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															Extra Followup Comments
														</td>
														<td colspan="3" className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															<Input type="textarea" name="other_comments" xs={12} sm={12} md={12} lg={12} xl={12} onBlur={(e) => {
																this.setState({ 
																extra_comments: e.target.value
															}) }} />
														</td>
													</tr>
								
									
									
								</tbody>
								
								
								
							</Table>
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="Submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title={this.state.juridistiction.toUpperCase()}
                          columns={data.columns}
                          data={
								Visits.map(visit=>(	
								{
									  //visit_id:visit.visit_id,
									  emis_id:visit.school_name,
									  present_visitation_date:new Date(visit.present_visitation_date).toLocaleDateString(),
									  //head_teacher:Users.map(user=>((user.user_id==visit.head_teacher)&& user.user_name)),
									  //head_teacher:visit.head_teacher,
									  //phone_number:visit.user_phone,
									  lead_inspector_id:Users.map(user=>((user.user_id==visit.lead_inspector_id)?user.user_name:'')),
									  //pea:visit.lead_advisor_id,
									  //sector:visit.sector_name,
									  cluster_id:visit.zone_name,
									  district_id:visit.district_name,
									  division_id:visit.division_name,
										'OPTIONS': <pre><Link className={((this.state.role == 'Inspector' || this.state.role == 'PEA' || this.state.role == 'Secondary Advisor' || this.state.role == 'Head Teacher' || this.calculateDateDifference(visit.present_visitation_date)==true))?"btn" : "btn invisible"} to={`/followup_visits`} onClick={()=>this.editInspectionVisit(visit.visit_id)} ><span className='text-success' ><Edit /></span>
										</Link>&nbsp;&nbsp;<Link className={((this.state.role == 'Inspector' || this.state.role == 'PEA' || this.state.role == 'Secondary Advisor' || this.state.role == 'Head Teacher' || this.calculateDateDifference(visit.present_visitation_date)==true))?"btn":"btn invisible"} to={`/followup_visits`} onClick={()=>this.deleteInspectionVisit(visit.visit_id)} ><span className="text-danger" ><Delete /></span></Link>
									 &nbsp;&nbsp;
									 <Link className={(this.state.role=='Inspector' || this.state.role=='PEA' || this.state.role=='Secondary Advisor' || this.state.role=='Head Teacher')?"btn btn-primary":"btn btn-primary invisible"} to={`/followup-report/${visit.assoc_visit}/${visit.visit_id}`} >VIEW REPORT</Link></pre>
								}
						  ))}      
                          
						  options={{
                            exportButton: true, 
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
					<Modal size="xl" fullscreen="true" isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateInspectionVisit} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">INSPECTION VISIT EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input disabled type="select" value={this.state.division_id} name="division_id" id="division_id" invalid={this.state.DivisionIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e);}} onBlur={this.handleInput} required={true}> 
										<option></option>
										{
										  Divisions.map(division=>(
											  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
											)
										  )
										}
									</Input>
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input disabled type="select" value={this.state.district_id} name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e);}} onBlur={this.handleInput} required={true}> 
											<option></option>
											{
												  
											  this.state.Districts.map(district=>(
															 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
													)
												   )
														
											}
									</Input>
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="Zone">{this.state.sector_id==1?'Zone Name':this.state.sector_id==2?'Cluster Name':'Zone/Cluster Name'}&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
									<Input disabled type="select" value={this.state.zone_id}  name="zone_id" id="zone_id" invalid={this.state.ZoneIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchoolsAfterLoad(e);}} onBlur={this.handleInput} required={true}> 
										<option></option>
										{
												  this.state.Zones.map(zone=>(
														//Logic circuit and
														(this.state.sector_id==zone.sector_id)&& <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
													)
												   )
														
										}
									</Input>
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="School">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input disabled type="select" value={this.state.emis_id} name="emis_id" id="emis_id" invalid={this.state.EmisIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.getRecentVisitsBySchool(e);}} onBlur={(e)=>{this.handleInput(e);}} required={true}> 
										  <option></option>
										  {
											  this.state.Schools.map(school=>(
														 <option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
												)
											   )
													
										  }
								  </Input>
								  <FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="School Visit">School Visit&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
													<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input value={`${this.state.visit.school_name} visit conducted on ${moment(this.state.visit.present_visitation_date).format("YYYY-MM-DD")}`} disabled type="text" name="visit_id" id="visit_id">
															</Input>
								  <FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="present_visitation_date">Present Visitation Date&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="date"  value={this.state.present_visitation_date} name="present_visitation_date" id="present_visitation_date" invalid={this.state.presentDateState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="lead_inspector_id">Lead Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								<Input type="select" value={this.state.lead_inspector_id} name="lead_inspector_id" id="lead_inspector_id" invalid={this.state.leadInspectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
										<option></option>
										{
										  Users.map(user=>(
											 (user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.fourth_inspector_id) &&
											  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
											)
										  )
										}
								</Input>
								<FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="first_inspector_id">First Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="select" value={this.state.first_inspector_id} name="first_inspector_id" id="first_inspector_id" invalid={this.state.firstInspectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
											<option></option>
											{
											  Users.map(user=>(
												(user.user_role == 'Inspector' && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
												  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
												)
											  )
											}
								  </Input>
								  <FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="second_inspector_id">Second Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="select" value={this.state.second_inspector_id} name="second_inspector_id" id="second_inspector_id" invalid={this.state.secondInspectorIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
											<option></option>
											{
											  Users.map(user=>(
												(user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
												  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
												)
											  )
											}
								  </Input>
								  <FormFeedback invalid>
										  The field is required.
									</FormFeedback>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="third_inspector_id">Third Inspector Name</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="select" value={this.state.third_inspector_id} name="third_inspector_id" id="third_inspector_id"  onChange={this.handleInput} onBlur={this.handleInput} >
											<option></option>
											{
											  Users.map(user=>(
												(user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.lead_inspector_id) &&
												  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
												)
											  )
											}
								  </Input>
								</Col>
							  </Row>
							</FormGroup>
							<FormGroup>
							  <Row>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								  <Label for="fourth_inspector_id">Fourth Inspector Name</Label>					 
								</Col>
								<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								  <Input type="select" value={this.state.fourth_inspector_id} name="fourth_inspector_id" id="fourth_inspector_id" onChange={this.handleInput} onBlur={this.handleInput} >
											<option></option>
											{
											  Users.map(user=>(
												(user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
												  <option value={user.user_id} key={user.user_id}>{user.user_name}</option>
												)
											  )
											}
								  </Input>
								</Col>
							  </Row>
							</FormGroup>
							<div>
								The following action plan on this visit:<br />
								{this.state.MajorRecommendations && this.state.MajorRecommendations.length > 0 &&
									<GanttChart editMode={false} visit={this.state.Visits.filter(v => v.visit_id === this.state.visit_id)} updateRecommendationsList={() => { }} categories={this.state.categories} recommendations={this.state.MajorRecommendations} />
								}
							</div>
							<Table responsive striped size="lg">
								<thead>
										<tr>
											<th style={{width:'20%'}} className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">NES</th>
											<th style={{width:'30%'}} className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">Minor Recommendations</th>
											<th style={{width:'30%'}} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">Comments</th>
											<th style={{width:'20%'}} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Status</th>
										</tr>
								</thead>
								
								
								
								<tbody>
								{
									this.state.MinorRecommendations.map(rcmdtn=>(
										<tr key={rcmdtn.recommendation_id}>
											<td style={{width:'20%'}} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												{this.state.national_standards.find(cat => cat.nes_id == rcmdtn.nes_id).nes_name}
											</td>
											<td style={{width:'30%'}} className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
												{rcmdtn.recommendation_description}
											</td>
											<td style={{width:'30%'}} className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
												<Input defaultValue={rcmdtn.followup_comments} type="textarea" name="followup_comments" id={rcmdtn.recommendation_id} xs={12} sm={12} md={12} lg={12} xl={12}  onBlur={(e)=>{this.handleInput(e);}}  />
											</td>
											<td style={{ width: '20%' }} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
												<Input required type="select" defaultValue={rcmdtn.need_extra_followup} name="followup_status" id={rcmdtn.recommendation_id} onBlur={(e) => { this.handleInput(e); }} onChange={(e) => { this.handleInput(e); }}>
													<option></option>
													<option value='Achieved'>Achieved</option>
													<option value='Partially Achieved'>Partially Achieved</option>
													<option value='Not Achieved'>Not Achieved</option>
												</Input>
											</td>
										</tr>
									))
									
									}
													<tr>
														<td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															Extra Followup Comments
														</td>
														<td colspan="3" className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
															<Input defaultValue={this.state.extra_comments} type="textarea" name="other_comments" xs={12} sm={12} md={12} lg={12} xl={12} onBlur={(e) => {
																this.setState({
																	extra_comments: e.target.value
																})
															}} onChange={(e) => {
																this.setState({
																	extra_comments: e.target.value
																})
															}} />
														</td>
													</tr>
									
									
								</tbody>
								
								
								
							</Table>
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="Submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default InpectionFollowup;