import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class NationalStandards extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
	  nes_id:'',
	  nes_name:'',
	  category_id:'',
	  NesIdState:'',
	  NesNameState:'',
	  CategoryIdState:'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  role:window.sessionStorage.getItem('urole'),
	  Standards:[],
	  Categories:[],
	  loading: '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addNationalStandards = this.addNationalStandards.bind(this);
	this.editNationalStandards = this.editNationalStandards.bind(this);
	this.updateNationalStandards = this.updateNationalStandards.bind(this);
	this.deleteNationalStandards=this.deleteNationalStandards.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 nes_id:'',
	     nes_name:'',
	     category_id:'',
	     NesIdState:'',
	     NesNameState:'',
	     CategoryIdState:'',
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 nes_id:'',
	     nes_name:'',
	     category_id:'',
	     NesIdState:'',
         NesNameState:'',
	     CategoryIdState:'',
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit Schools
  editNationalStandards(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/national_standards/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].nes_id,
		  nes_name : response.data[0].nes_name,
		  category_id : response.data[0].category_id,
		  //division_id : response.data[0].division_id,
		  EditModalVisible:true,
		  //loading: false,
	  })
	}).catch(error => {
		  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
	  })
  }
  
  //Update 
	  updateNationalStandards(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const standard = {
		  nes_name : this.state.nes_name,
		  category_id: this.state.category_id,
		  //division_id : this.state.division_id,
		  
        }
		
		//this.setState({loading : true});
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/national_standards/update/${id}`, standard,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
                //this.setState({loading : false});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if(response.data['status']=='success'){
					this.setState({
						EditModalVisible:false, 
					//loading : false,
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  timer: 2500,
					  button: false
					})
					.then((sucess) => {
						Promise.all([axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
							([standard, category]) => {
								this.setState({
									Standards: standard.data,
									Categories: category.data,
									loading: false,
								})
							}
						)
					});
				}else{
					this.setState({
						EditModalVisible:true, 
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
				}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading: false,
				});
				
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteNationalStandards(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the national educational standard?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/national_standards/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								swal({
								  title: "",
								  text: "The nes record Deleted Successfully!",
								  icon: "success",
								  button: false,
								  timer: 2500
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
										([standard, category]) => {
											this.setState({
												Standards: standard.data,
												Categories: category.data,
												loading: false,
											})
										}
									)
								});
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  })
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/national_standards`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}), axios.get(API_URL + `/nes_categories`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([standard, category])=>{
		 this.setState({
			 Standards:standard.data,
			 Categories:category.data,
			 loading: false,
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addNationalStandards(event){
		event.preventDefault()

        const { history } = this.props

        const standard = {
		  nes_id : this.state.nes_id,
		  nes_name: this.state.nes_name,
		  category_id : this.state.category_id,
		  //division_id : this.state.division_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/national_standards', standard,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading: false,
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500
				})
					.then((sucess) => {
						Promise.all([axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
							([standard, category]) => {
								this.setState({
									Standards: standard.data,
									Categories: category.data,
									loading: false,
								})
							}
						)
				});
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading: false,
				});
				
			    if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='nes_id'){
		   if(e.target.value!=''){
			   this.setState({NesIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='nes_name'){
		   if(e.target.value!=''){
			   this.setState({NesNameState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NesNameState :'has-danger'});
		   }
	   
    }else if(e.target.name==='category_id'){
		   if(e.target.value!=''){
			   this.setState({CategoryIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({CategoryIdState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {Standards, Categories} = this.state;
    const data = {
      columns: [
        { 
          title: 'Nes Id',
           field: 'nes_id' 
        },
		{ 
          title: 'Nes Description',
           field: 'nes_name' 
        },
		{ 
           title: 'Nes Category',
           field: 'category_name' 
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,
        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">National Educational Standard</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">NES Management</h3>
                <hr/>
                <Button color="success" hidden={(this.state.role=='System Administrator')?false:true} onClick={this.toggle}> {<Add />} New NES</Button>
                <hr/>

                {
                     //Add user modal
					 
					 /*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addNationalStandards} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">NES ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_id">NES ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="number" name="nes_id" id="nes_id" invalid={this.state.NesIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_name">NES Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  name="nes_name" id="nes_name" invalid={this.state.NesNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="category_name">Category Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" name="category_id" id="category_id" invalid={this.state.CategoryIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
                                            <option></option>
                                            {
											  Categories.map(category=>(
												  <option value={category.category_id} key={category.category_id}>{category.category_name}</option>
												)
											  )
											}
                                        </Input>
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
				  
                      <MaterialTable
                          title="NATIONAL EDUCATIONAL STANDARDS IN MALAWI"
                          columns={data.columns}
                          data={
								Standards.map(standard=>(	
									{
									  
									  nes_id : standard.nes_id,
									  nes_name : standard.nes_name,
									  category_name : standard.category_name,
									 'OPTIONS': <pre><Link to={`/national_standards`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.editNationalStandards(standard.nes_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
											<Link to={`/national_standards`} className={(this.state.role=='System Administrator')?"btn":"btn disabled"} onClick={()=>this.deleteNationalStandards(standard.nes_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									}
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
						  
						  
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateNationalStandards} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">SCHOOL EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								</FormGroup>
								  
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_name">NES Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  value={this.state.nes_name} name="nes_name" id="nes_name" invalid={this.state.NesNameState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="category_name">Category Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="select" value={this.state.category_id} name="category_id" id="category_id" invalid={this.state.CategoryIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} > 
                                            <option></option>
                                            {
											  Categories.map(category=>(
												  <option value={category.category_id} key={category.category_id}>{category.category_name}</option>
												)
											  )
											}
                                        </Input>
										<FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default NationalStandards;
