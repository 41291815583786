import React, { Component } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';
import swal from 'sweetalert';


const API_URL = "http://localhost:8000/api";

//import swal from 'sweetalert';

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class TabledDataSummaries extends Component {
  constructor(props) {
    super(props);
	//const visit_id=this.props.match.params.visit_id;
	
    this.state = {
	  Schools:[],
	  Zones:[],
	  Clusters:[],
	  Constituencies:[],
	  Districts:[],
	  Divisions:[],
	  Standards:[],
	  Requirements:[],
	  Levels:[],
	  NesCategories:[]
    };
	
  }

    
  componentDidMount () {
	 
	Promise.all([
	axios.get(API_URL + `/total_schools`),
	axios.get(API_URL + `/total_zones`),
	axios.get(API_URL + `/total_clusters`),
	axios.get(API_URL + `/total_constituencies`),
	axios.get(API_URL + `/total_districts`),
	axios.get(API_URL + `/total_divisions`),
	axios.get(API_URL + `/total_nes`),
	axios.get(API_URL + `/total_nes_requirements`),
	axios.get(API_URL + `/total_nes_levels`),
	axios.get(API_URL + `/total_nes_categories`),
	
	]).then(
	 ([school,zone,cluster,constituency,district,division,standard,req,level,category])=>{
		 this.setState({
			 Schools:school.data[0],
			 Zones:zone.data[0],
			 Clusters:cluster.data[0],
			 Constituencies:constituency.data[0],
			 Districts:district.data[0],
			 Divisions:division.data[0],
			 Standards:standard.data[0],
			 Requirements:req.data[0],
			 Levels:level.data[0],
			 NesCategories:category.data[0],
		 })
	 }
	 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
  }
	
  

  render() {
    const {Schools, Zones,Clusters,Constituencies, Districts, Divisions,Standards, Requirements,Levels,NesCategories} = this.state;
    const data = {
      columns: [
        { 
          title: 'DATA ON',
           field: 'data_on' 
        },
        {
          title: 'TOTAL AVAILABLE',
          field: 'total_available',
        }
      ],
      
    };

    return (
      <React.Fragment>
			<table className="table table-responsive table-bordered table-condensed table-hover table-striped">
                <MaterialTable
				  title="VITAL DATA"
				  columns={data.columns}
				  data={
						[{
						  data_on : <i>SCHOOLS</i>,
						  total_available: Schools['COUNT']
						},
						{
						  data_on : <i>ZONES</i>,
						  total_available: Zones['COUNT']
						},
						{
						  data_on : <i>CLUSTERS</i>,
						  total_available: Clusters['COUNT']
						},
						{
						  data_on : <i>CONSTITUENCIES</i>,
						  total_available: Constituencies['COUNT']
						},
						{
						  data_on : <i>DISTRICTS</i>,
						  total_available: Districts['COUNT']
						},
						{
						  data_on : <i>DIVISIONS</i>,
						  total_available: Divisions['COUNT']
						},
						{
						  data_on : <i>NATIONAL EDUCATIONAL STANDARDS(NES)</i>,
						  total_available: Standards['COUNT']
						},
						{
						  data_on : <i>NES CATEGORIES</i>,
						  total_available: NesCategories['COUNT']
						},
						{
						  data_on :<i>SCHOOL INSPECTION REQUIREMENTS</i>,
						  total_available: Requirements['COUNT']
						},
						{
						  data_on : <i>NES REQUIREMENTS' LEVELS</i>,
						  total_available: Levels['COUNT']
						},
						]				
				  }      
				  options={{
					exportButton: true,
					tableLayout: 'auto'
				  }}
				/>     

			</table>       
      </React.Fragment>
    );
  }
}

export default TabledDataSummaries;
