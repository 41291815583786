import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// Custom Scrollbar
import { Link } from "react-router-dom";

import axios from 'axios';
import Loader from "../../../components/Loader";
import moment from "moment";

import SchoolsAdmissions from "../Charts/ZonalCluster/SchoolsAdmissions";
import InspectedSchools from "../Charts/ZonalCluster/InspectedSchools";
import InspectedSecondarySchools from "../Charts/ZonalCluster/InspectedSecondarySchools";
import InspectedPrimarySchools from "../Charts/ZonalCluster/InspectedPrimarySchools";
import TotalSchools from "../Charts/ZonalCluster/TotalSchools";
//import ClusterAvgPerformanceGraph from "../Reports/ClusterAvgPerformanceGraph";
//import ClusterNesAvgPerformanceGraph from "../Reports/ClusterNesAvgPerformanceGraph";
import ClusterProgressGraph from "../Reports/ClusterProgressGraph";
import TabledDataSummaries from "./TabledDataSummaries"

import "chartist/dist/scss/chartist.scss";
import {Add, Edit, Delete, ArrowLeft, Poll, Grade, School, Apartment} from '@material-ui/icons';

import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';


const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";


class ZonalClusterDashboard extends Component {
  constructor(props) {
    super(props);
	//const visit_id=this.props.match.params.visit_id;
	
    this.state = {
	  sector_id: sessionStorage.getItem('sector'),
	  Sec_Data:[],
	  Prim_Data:[],
	  Schools:[],
	  Zones:[],
	  Clusters:[],
	  Constituencies:[],
	  Districts:[],
	  Divisions:[],
	  Standards:[],
	  Requirements:[],
	  Levels:[],
	  NesCategories:[],
	  zone_id: window.sessionStorage.getItem("zone"),
	  zone_name:'',
	  role:window.sessionStorage.getItem("urole"),
	  bearer_auth:window.sessionStorage.getItem('token'),
	  loading : '',
    };
	
  }

    
  componentDidMount () {
	this.setState({loading : true});

    if(this.state.role=='Secondary Advisor'){
		 Promise.all([
			axios.get(API_URL + `/total_schools/primary/by_zone/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_schools/secondary/by_zone/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_clusters`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_constituencies`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes_requirements`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes_levels`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes_categories`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/zones/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			
			]).then(
			 ([p_data,s_data,zone,cluster,constituency,district,division,standard,req,level,category,zone_data])=>{
				 this.setState({
					 Prim_Data:(p_data.data.length>=1)?p_data.data[0]:'NULL DATA ON',
					 Sec_Data:(s_data.data.length>=1)?s_data.data[0]:'NULL DATA ON',
					 Zones:(zone.data.length>=1)?zone.data[0]:'NULL DATA ON',
					 Clusters:(cluster.data.length>=1)?cluster.data[0]:'NULL DATA ON',
					 Constituencies:(constituency.data.length>=1)?constituency.data[0]:'NULL DATA ON',
					 Districts:(district.data.length>=1)?district.data[0]:'NULL DATA ON',
					 Divisions:(division.data.length>=1)?division.data[0]:'NULL DATA ON',
					 Standards:(standard.data.length>=1)?standard.data[0]:'NULL DATA ON',
					 Requirements:(req.data.length>=1)?req.data[0]:'NULL DATA ON',
					 Levels:(level.data.length>=1)?level.data[0]:'NULL DATA ON',
					 NesCategories:(category.data.length>=1)?category.data[0]:'NULL DATA ON',
					 zone_name:(zone_data.data.length>=1)?zone_data.data[0]['zone_name'] + " CLUSTER":'NULL DATA ON',
					 loading : false,
				 })
			 }
			 ).catch(error => {
				if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "error",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the dashboard data. We will refresh the page in seconds..",
					  icon: "error",
					  timer : 5000
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
		 
	 }else if(this.state.role == 'PEA'){
		 Promise.all([
			axios.get(API_URL + `/total_schools/primary/by_zone/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_schools/secondary/by_zone/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_zones`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_clusters`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_constituencies`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_districts`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes_requirements`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes_levels`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/total_nes_categories`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			axios.get(API_URL + `/zones/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
			
			]).then(
			 ([p_data,s_data,zone,cluster,constituency,district,division,standard,req,level,category,zone_data])=>{
				 this.setState({
					 Prim_Data:(p_data.data.length>=1)?p_data.data[0]:'NULL DATA ON',
					 Sec_Data:(s_data.data.length>=1)?s_data.data[0]:'NULL DATA ON',
					 Zones:(zone.data.length>=1)?zone.data[0]:'NULL DATA ON',
					 Clusters:(cluster.data.length>=1)?cluster.data[0]:'NULL DATA ON',
					 Constituencies:(constituency.data.length>=1)?constituency.data[0]:'NULL DATA ON',
					 Districts:(district.data.length>=1)?district.data[0]:'NULL DATA ON',
					 Divisions:(division.data.length>=1)?division.data[0]:'NULL DATA ON',
					 Standards:(standard.data.length>=1)?standard.data[0]:'NULL DATA ON',
					 Requirements:(req.data.length>=1)?req.data[0]:'NULL DATA ON',
					 Levels:(level.data.length>=1)?level.data[0]:'NULL DATA ON',
					 NesCategories:(category.data.length>=1)?category.data[0]:'NULL DATA ON',
					 zone_name:(zone_data.data.length>=1)?zone_data.data[0]['zone_name'] + " ZONE":'NULL DATA ON',
					 loading : false,
				 })
			 }
			 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
		 
	 }
	 
	
  }

  render() {
    const {Prim_Data,Sec_Data, Zones,Clusters,Constituencies, Districts, Divisions,Standards, Requirements,Levels,NesCategories} = this.state;
    
	return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h5 className="font-size-18">{this.state.zone_name.toUpperCase()} </h5>
				<hr />
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Visualization</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>
		  <Row hidden>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <h6 className="card-title mb-4">QUICK LINKS</h6>
				  <hr className="bg-success" />
					<Row className="text-white">	
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/divisions">
							<Card className="bg-success">
								<CardBody>
								  <center>Divisions</center>
								</CardBody>	
							  </Card>	
						  </Link>
						  
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/districts">
							<Card className="bg-success">
								<CardBody>
								  <center>Districts</center>
								</CardBody>
							</Card>	
						  </Link>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/constituencies">
							<Card className="bg-success">
								<CardBody>
								  <center>Constituencies</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/zones">
							<Card className="bg-success">
								<CardBody>
								  <center>Zones/Clusters</center>
								</CardBody>
							</Card>
						  </Link>
						  
						</Col>
						
						
						
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/schools">
							<Card className="bg-success">
								<CardBody>
								  <center>Schools</center>
								</CardBody>
							</Card>
						  </Link>
						  
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/nes_categories">
							<Card className="bg-success">
								<CardBody>
								  <center>NES Categories</center>
								</CardBody>
							</Card>
						  </Link>
						  
						</Col>
						
						
						
						
					</Row>
					<hr className="bg-success" />
					<Row className="text-white">	
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/national_standards">
							<Card className="bg-success">
								<CardBody>
								  <center>Education Standards</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/nes_requirements">
							<Card className="bg-success">
								<CardBody>
								  <center>NES Requirements</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/school_visits">
							<Card className="bg-success">
								<CardBody>
								  <center>Inspection Visits</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/advisory_visits">
							<Card className="bg-success">
								<CardBody>
								  <center>Advisory Visits</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/perf_report">
							<Card className="bg-success">
								<CardBody>
								  <center>Performance Graph(s)</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={2} lg={2} xl={2}>
						  <Link className="text-white" to="/progress_report">
							<Card className="bg-success">
								<CardBody>
								  <center>Progress Report</center>
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						
					</Row>
						
					
				  
                </CardBody>
              </Card>
            </Col>
          </Row>
		  
		  <Row >
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <h6 className="card-title mb-4">QUICK STATISTICS</h6>
				  <hr className="bg-success" />
					<Row className="text-white">	
									{this.state.sector_id == 1 ? (
										<Col xs={12} sm={12} md={12} lg={12} xl={12}>
											<Link className="text-success" to="/schools">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Primary Schools</span>
														<span className="float-right"><School fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Prim_Data['COUNT']}</h2></strong></span></center>
													</CardBody>
												</Card>
											</Link>
						  
										</Col>
									) : (
						
										<Col xs={12} sm={12} md={12} lg={12} xl={12}>
											<Link className="text-success" to="/schools">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Secondary Schools</span>
														<span className="float-right"><School fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Sec_Data['COUNT']}</h2></strong></span></center>
								  
													</CardBody>
												</Card>
											</Link>
						  
										</Col>
									)}
						
						{/*<Col xs={12} sm={12} md={3} lg={3} xl={3}>
						  <Link className="text-success" to="/zones">
							<Card className="border border-success">
								<CardBody>
								  <span className="float-left">Zones</span>
								  <span className="float-right"><Apartment fontSize="large"/></span>
								  <br />
								  <hr  />
								  <center><span fontSize="large"><strong><h2>{Zones['COUNT']}</h2></strong></span></center>
								  
								</CardBody>
							  </Card>	
						  </Link>
						  
	</Col>
						<Col xs={12} sm={12} md={3} lg={3} xl={3}>
						  <Link className="text-success" to="/clusters">
							<Card className="border border-success">
								<CardBody>
								  <span className="float-left">Clusters</span>
								  <span className="float-right"><Apartment fontSize="large"/></span>
								  <br />
								  <hr  />
								  <center><span fontSize="large"><strong><h2>{Clusters['COUNT']}</h2></strong></span></center>
								  
								</CardBody>	
							  </Card>	
						  </Link>
						  
						</Col>*/}
						
						
						
					</Row>
					<hr className="bg-success" />
					<Row hidden className="text-white">	
						<Col xs={12} sm={12} md={4} lg={4} xl={4}>
						  <Link className="text-success" to="/constituencies">
							<Card className="border border-success">
								<CardBody>
								  <span className="float-left"> Constituencies</span>
								  <span className="float-right"><Apartment fontSize="large"/></span>
								  <br />
								  <hr  />
								  <center><span fontSize="large"><strong><h2>{Constituencies['COUNT']}</h2></strong></span></center>
								  
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={4} lg={4} xl={4}>
						  <Link  className="text-success" to="/districts">
							<Card className="border border-success">
								<CardBody>
								  <span className="float-left">Districts</span>
								  <span className="float-right"><Grade fontSize="large"/></span>
								  <br />
								  <hr  />
								  <center><span fontSize="large"><strong><h2>{Districts['COUNT']}</h2></strong></span></center>
								  
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						<Col xs={12} sm={12} md={4} lg={4} xl={4}>
						  <Link className="text-success" to="/divisions">
							<Card className="border border-success">
								<CardBody>
								  <span className="float-left">Divisions</span>
								  <span className="float-right"><Grade fontSize="large"/></span>
								  <br />
								  <hr />
								  <center><span fontSize="large"><strong><h2>{Divisions['COUNT']}</h2></strong></span></center>
								  
								</CardBody>
							</Card>
						  </Link>
						</Col>
						
						
					</Row>
						
					
				  
                </CardBody>
              </Card>
            </Col>
          </Row>
			
		
		 <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  
				  
					<InspectedSchools />
				  
                </CardBody>
              </Card>
            </Col>

		
          </Row>
		  
		  <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  
				  
					<ClusterProgressGraph start={'2021-01-01'} end={moment().format('YYYY-MM-DD')} zone_id={this.state.zone_id}/>
				  
                </CardBody>
              </Card>
            </Col>

		
          </Row>
		  
		  <Row>
		  {
			/* <ClusterAvgPerformanceGraph zone_id={this.state.zone_id} />
			
			<ClusterNesAvgPerformanceGraph zone_id={this.state.zone_id}/> */  
		  }
          </Row>
		  
		  
        </div>
      </React.Fragment>
    );
  }
}

export default ZonalClusterDashboard;
