import axios from "axios";
import AuthHeader from './AuthHeader';
//import API_URL from './API_URL';


import backend_url from "../routes/API_URL";

const API_URL = backend_url['api_url'];

class AuthService {
  constructor() {
	const user = sessionStorage.getItem('uid');
    if (user!=null) {
	  sessionStorage.setItem("uid", user);
	} else {
	  sessionStorage.removeItem("uid");
	}
  }
  
  
 
 login(email, password) {
    
    return axios.post(API_URL + "/users/login", {'user_email':email, 'user_password': password})
      .then((response) => {
        if(response.data[1].token){
			sessionStorage.setItem("uid", response.data[0].user_id);
			sessionStorage.setItem("fname", response.data[0].first_name );
			sessionStorage.setItem("mname", response.data[0].middle_name);
			sessionStorage.setItem("lname", response.data[0].last_name);
			sessionStorage.setItem("uname", response.data[0].user_name);
			sessionStorage.setItem("urole", response.data[0].user_role);
			sessionStorage.setItem("uphone", response.data[0].user_phone);
			sessionStorage.setItem("umail", response.data[0].user_email);
			sessionStorage.setItem("sector", response.data[0].sector_id);
			sessionStorage.setItem("emis", response.data[0].emis_id);
			sessionStorage.setItem("zone", response.data[0].zone_id);
			sessionStorage.setItem("district", response.data[0].district_id);
			sessionStorage.setItem("division", response.data[0].division_id);
			sessionStorage.setItem("token", response.data[1].token);
			return response.data;
		}
      }).catch(error => {
		  return error;
	 })
	 
  }

  logout() {
    sessionStorage.clear();
  }
  
  setLoggeedInUser = user => {
    sessionStorage.setItem("uid", JSON.stringify(user));
  };
  
  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!sessionStorage.getItem("uid")) return null;
    return sessionStorage.getItem("uid");
  };


  /* register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  } */
}

let _Auth = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initAuth = () => {
  if (!_Auth) {
    _Auth = new AuthService();
  }
  return _Auth;
};

/**
 * Returns the firebase backend
 */
const getAuth = () => {
  return _Auth;
};

export default new AuthService();

//export { initAuth, getAuth };