import React, { Component } from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Avatar from '@material-ui/core/Avatar';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import axiosPost from "../../../services/axios"
import logo from "../../../assets/images/gov-logo.png";
import Swal from 'sweetalert2'

import {Add, Edit, Delete, LockOpen} from '@material-ui/icons';
import swal from 'sweetalert';

import Loader from "../../../components/Loader";


import backend_url from "../../../routes/API_URL";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalVisible:false,
      EditModalVisible:false,
      emis_id:'',
      zone_id:'',
	  sector_id:'',
      district_id:'',
      division_id:'',
	  //salutation: '',
	  first_name: '',
	  middle_name: '',
	  last_name: '', 
	  user_role : '',
	  phone_number : '',
	  email_address : '',
	  school_name : '',
	  zone_name : '',
	  district_name : '',
	  division_name :'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  Users:[],
	  Divisions:[],
	  Sectors:[],
	  Districts:[],
	  Zones:[],
	  Schools:[],
	  loading: '',
	  text: ''
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addUser = this.addUser.bind(this);
	this.editUser = this.editUser.bind(this);
	this.updateUser = this.updateUser.bind(this);
	this.deleteUser =this.deleteUser.bind(this);
	
	this.filterDistrictsBeforeLoad = this.filterDistrictsBeforeLoad.bind(this);
	this.filterDistrictsAfterLoad = this.filterDistrictsAfterLoad.bind(this);
	this.filterZonesBeforeLoad = this.filterZonesBeforeLoad.bind(this);
	this.filterZonesAfterLoad = this.filterZonesAfterLoad.bind(this);
	this.filterSchoolsBeforeLoad = this.filterSchoolsBeforeLoad.bind(this);
	this.filterSchoolsAfterLoad = this.filterSchoolsAfterLoad.bind(this);
	
	this.abortController = new AbortController();
	
	this.AbortrequestTokenSource = axios.CancelToken.source().token;
	 
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		  modalVisible:false,
		  emis_id:'',
		  zone_id:'',
		  sector_id:'',
		  district_id:'',
		  division_id:'',
		  //salutation: '',
		  first_name: '',
		  middle_name: '',
		  last_name: '', 
		  user_role : '',
		  phone_number : '',
		  email_address : '',
		  school_name : '',
		  zone_name : '',
		  EmisState:'',
		  ZoneState:'',
		  SectorState:'',
		  DivisionState:'',
		  DistrictState:'',
		  SalutationState:'',
		  fnState:'',
		  mnState:'',
		  lnState:'',
		  UserRoleState:'',
		  phoneState:'',
		  emailState:'',
		 ZoneState: '',
		 pass_id: '',
		  passwordState: ''
		 }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({
		  EditModalVisible:false,
		  emis_id:'',
		  zone_id:'',
		  sector_id:'',
		  district_id:'',
		  division_id:'',
		  //salutation: '',
		  first_name: '',
		  middle_name: '',
		  last_name: '', 
		  user_role : '',
		  phone_number : '',
		  email_address : '',
		  school_name : '',
		  zone_name : '',
		  EmisState:'',
		  ZoneState:'',
		  SectorState:'',
		  DivisionState:'',
		  DistrictState:'',
		  SalutationState:'',
		  fnState:'',
		  mnState:'',
		  lnState:'',
		  UserRoleState:'',
		  phoneState:'',
		  emailState:'',
		  ZoneState:'',
		 }); 
    }
  }	
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit user 
  editUser(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data);
	  this.setState({
		  id:response.data.user_id,
		  //salutation: response.data.salutation,
		  first_name: response.data.first_name,
		  middle_name: response.data.middle_name,
		  last_name: response.data.last_name, 
		  user_role : response.data.user_role,
		  phone_number : response.data.user_phone,
		  email_id : response.data.user_email,
		  emis_id : response.data.emis_id,
		  sector_id : response.data.sector_id,
		  zone_id : response.data.zone_id,
		  district_id : response.data.district_id,
		  division_id : response.data.division_id,
		  EditModalVisible:true,
		  //loading : false,
	  });
	  
	  //Trigger a call to the filter function using edit state values
	  this.filterDistrictsBeforeLoad(response.data.division_id);
	  this.filterZonesBeforeLoad(response.data.district_id);
	  this.filterSchoolsBeforeLoad(response.data.zone_id);
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the user data. You should retry..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
			  
          })
  }
  
  //Update 
	  updateUser(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const user = {
		  id : this.state.id,
		  //salutation: this.state.salutation,
		  first_name: this.state.first_name,
		  middle_name: this.state.middle_name,
		  last_name: this.state.last_name, 
		  user_role : this.state.user_role,
		  phone_number : this.state.phone_number,
		  email_id : this.state.email_id,
		  emis_id : this.state.emis_id,
		  sector_id : this.state.sector_id,
		  zone_id : this.state.zone_id,
		  district_id : this.state.district_id,
		  division_id : this.state.division_id,
		  
        }
        
		
		//this.setState({loading : true});
		
		//console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({EditModalVisible:false});
				swal({
				  title: "",
				  text: "User updated successfully!",
				  icon: "success",
				  timer:2500,button:false
				})
				.then((sucess) => {
					Promise.all([axios.get(API_URL + `/users`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }), axios.get(API_URL + `/divisions`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }), axios.get(API_URL + `/sectors`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal })]).then(
						([user, division, sector]) => {
							this.setState({
								Users: user.data,
								Divisions: division.data,
								Sectors: sector.data,
								loading: false,
							})
						}
					)
				});
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false,
				
				});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to update the user. You should retry..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteUser(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the user?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
                            
							//this.setState({loading : true});
							
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								
								//this.setState({loading : false});
								
								swal({
								  title: "",
								  text: "The user record Deleted Successfully!",
								  icon: "success",
								  timer: 2500,button:false
								})
								.then((sucess) => {
									Promise.all([axios.get(API_URL + `/users`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }), axios.get(API_URL + `/divisions`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }), axios.get(API_URL + `/sectors`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal })]).then(
										([user, division, sector]) => {
											this.setState({
												Users: user.data,
												Divisions: division.data,
												Sectors: sector.data,
												loading: false,
											})
										}
									)
								});
							}).catch(error => {
									  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: "Failed to delete the user. You should retry..",
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
								  });
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/users`,{cancelToken : this.AbortrequestTokenSource, headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}, signal: this.abortController.signal}),axios.get(API_URL + `/divisions`,{cancelToken : this.AbortrequestTokenSource, headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}, signal: this.abortController.signal}),axios.get(API_URL + `/sectors`,{cancelToken : this.AbortrequestTokenSource, headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}, signal: this.abortController.signal})]).then(
	 ([user, division,sector])=>{
		 this.setState({
			 Users:user.data,
			 Divisions:division.data,
			 Sectors:sector.data,
			 loading : false,
		 })
	 }
	 ).catch(error => {
			  
			  //this.abortController.abort();
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the users data. We will refresh the page in seconds..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          });
  }
  
  componentWillUnmount(){
	//this.abortController.abort();
  }
	//Filtering
	 
	 filterDistrictsBeforeLoad(division){
		 //e.preventDefault();
		  
		  //this.setState({loading : true});
		  
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/districts/filter/${division}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the districts in this division . We will retry in afew seconds..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }
	 
	 filterDistrictsAfterLoad(e){
		 e.preventDefault();
		  
		  //this.setState({loading : true});
		  
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the districts in your division . You should retry..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 filterZonesBeforeLoad(district){
		 //e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${district}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the zones/clusters in this district. We wil retry in a few seconds..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							window.location.reload();
						});
				  }
          })
	 }
	 filterZonesAfterLoad(e){
		 e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the zones/clusters in this district. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 filterSchoolsBeforeLoad(zone){
		 //e.preventDefault();
		  
		  //this.setState({loading : true});
		  
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${zone}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the schools for this zone/cluster. We will retry in a few seconds..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						window.location.reload();
					});
			  }
          })
	 }
	 
	 filterSchoolsAfterLoad(e){
		 e.preventDefault();
		  
		  //this.setState({loading : true});
		  
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the schools for this zone/cluster. You should retry..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
  //Add user
	 addUser(event){
		event.preventDefault()

        const { history } = this.props

        const user = {
		  //salutation: this.state.salutation,
		  first_name: this.state.first_name,
		  middle_name: this.state.middle_name,
		  last_name: this.state.last_name, 
		  user_role : this.state.user_role,
		  phone_number : this.state.phone_number,
		  email_id : this.state.email_id,
		  emis_id : this.state.emis_id,
		  sector_id : this.state.sector_id,
		  zone_id : this.state.zone_id,
		  district_id : this.state.district_id,
		  division_id: this.state.division_id,
		  password: this.state.pass_id,
		  
        }
        
		//this.setState({loading : true});
		
		// console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false,
				//loading : false,
				
				});
			swal({
			  title: "",
			  text: "New user Added Successfully!",
			  icon: "success",
			  timer:2500,button:false
			})
			.then((sucess) => {
				Promise.all([axios.get(API_URL + `/users`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }), axios.get(API_URL + `/divisions`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }), axios.get(API_URL + `/sectors`, { cancelToken: this.AbortrequestTokenSource, headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal })]).then(
					([user, division, sector]) => {
						this.setState({
							Users: user.data,
							Divisions: division.data,
							Sectors: sector.data,
							loading: false,
						})
					}
				)
			});
          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading : false,
				});
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to add the user. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='division_id'){ 
		    if(e.target.value!=''){
			   this.setState({DivisionState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DivisionState :'has-danger'});
		   }
	   }else if(e.target.name==='sector_id'){
		    if(e.target.value!=''){
			   this.setState({SectorState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SectorState :'has-danger'});
		   }
	} else if (e.target.name === 'pass_id') {
		if (e.target.value != '') {
			this.setState({ passwordState: 'has-success', [e.target.id]: e.target.value });
		} else {
			this.setState({ passwordState: 'has-danger' });
		}
	}
	else if (e.target.name === 'user_role') {
		    if(e.target.value!=''){
			   this.setState({UserRoleState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({UserRoleState :'has-danger'});
		   }
	   
    }else if(e.target.type==='text'){
      //All name fields
	  const textRex = /[a-zA-Z]/;
	  if(e.target.name ==='first_name'){
		 if((textRex.test(e.target.value) && e.target.value!='') && e.target.value.length>=3){
			  this.setState({fnState :'has-success',[e.target.id]: e.target.value});
		  }else{
			  this.setState({fnState :'has-danger'});
		  } 
	  }else if(e.target.name==='middle_name'){
		  if((textRex.test(e.target.value) || e.target.value!='') || e.target.value.length>=3){
			  this.setState({mnState :'has-success',[e.target.id]: e.target.value});
		  }else{
			  this.setState({mnState :'has-danger'});
		  } 
	  }else if(e.target.name === 'last_name'){
		  if((textRex.test(e.target.value) && e.target.value!='') && e.target.value.length>=3){
			  this.setState({lnState :'has-success',[e.target.id]: e.target.value});
		  }else{
			  this.setState({lnState :'has-danger'});
		  } 
	  }
    }else if(e.target.type==='number'){
	  const numRex = /[0-9]+/;
	  if(numRex.test(e.target.value) && e.target.value.length>=10){
		  this.setState({phoneState :'has-success',[e.target.id]: e.target.value});
	  }else{
		  this.setState({phoneState :'has-danger'});
	  }
    }else if(e.target.type==='email'){
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    //const { validate } = this.state
		if (emailRex.test(e.target.value)) {
			this.setState({emailState :'has-success',[e.target.id]: e.target.value});
		} else {
			this.setState({emailState : 'has-danger'});
		}
    }else{
		if(e.target.name==='salutation'){
		   if(e.target.value!=''){
			   this.setState({SalutationState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({SalutationState :'has-danger'});
		   }
	   }else if(e.target.name==='emis_id'){
		    if(e.target.value!=''){
			   this.setState({EmisState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EmisState :'has-danger'});
		   }
	   }else if(e.target.name==='zone_id'){
		    if(e.target.value!=''){
			   this.setState({ZoneState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({ZoneState :'has-danger'});
		   }
	   }else if(e.target.name==='district_id'){
		    if(e.target.value!=''){
			   this.setState({DistrictState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({DistrictState :'has-danger'});
		   }
	  }
	}
        
  }

  render() {
    const {Users} = this.state;
    const {Divisions} = this.state;
    const {Sectors} = this.state;
    const data = {
      columns: [
        /* {
          field: 'url',
          title: 'Image',
          //render: rowData => <img src={logo} style={{width: 50, borderRadius: '50%'}}/>
          //render: rowData => <Avatar alt="Remy Sharp" />
		  render: rowData => <Avatar className='bg-sucess' alt="P" >P</Avatar>
        }, */
        { 
          title: 'Name',
           field: 'full_name' 
        },
        {
          title: 'Role',
          field: 'user_role',
        },
        {
          title: 'Phone',
          field: 'phone_number',
        },
        {
          title: 'Email',
          field: 'email_address',
        },
			/* {
			  title: 'School',
			  field: 'school_name',
			},
			{
			  title: 'Zone/Cluster',
			  field: 'zone_name',
			},
			{
			  title: 'District',
			  field: 'district_name',
			},
			{
			  title: 'Division',
			  field: 'division_name',
			}, */
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export: false, 
        },
      ],
      
	  };

	
	  const resetPassword = async (id) => {
		  const { value } = await Swal.fire({
			  title: 'Enter New Password',
			  input: 'text',
			  inputLabel: 'New Password',
			  showCancelButton: true,
			  inputValidator: (value) => {
				  if (!value) {
					  return 'You need to write something!'
				  }
			  }
		  })
		  if (value) {
			  axiosPost.patch(`users/reset_password/${id}`, {user_password: value})
				  .then(__ => {
					  Swal.fire(`User password has been reset`)
				  }).catch(__ => {
					Swal.fire("Something went wrong, try again later")
				})
		  }
	  }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">users</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">User Management</h3>
                <hr/>
                <Button color="success" onClick={this.toggle}> {<Add />} New User</Button>
                <hr/>

                {
					//Add user modal
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form autoComplete="off" onSubmit={this.addUser} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">USER ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
						  {
							  /* <FormGroup>
                                  <Row>
                                    <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
                                      <Label for="Salutation">Salutation</Label>					 
                                    </Col>
                                    <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
                                      <Input type="select" required={true} invalid={this.state.SalutationState==='has-danger'} name="salutation" id="salutation" onChange={this.handleInput} onBlur={this.handleInput}> 
                                          <option></option>
                                          <option>Ms.</option>
                                          <option>Mrs.</option>
                                          <option>Mr.</option>
                                          <option>Dr.</option>
                                          <option>Prof.</option>
                                      </Input>
									  
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup> */
						  }
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="First Name">First Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
														<Input autoComplete='off' type="text"  name="first_name" id="first_name" required={true} invalid={this.state.fnState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
                                       <FormFeedback invalid>
										  The field is required.
									   </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="Middle Name">Middle Name</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input autoComplete='off' type="text"  name="middle_name" id="middle_name" onChange={this.handleInput} onBlur={this.handleInput} />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="Last Name">Last Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="text"  name="last_name" id="last_name" required={true} invalid={this.state.lnState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="phone number">Phone Number&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Input autoComplete='off' type="number"  name="phone_number" id="phone_number" required={true} invalid={this.state.phoneState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
										<FormFeedback invalid>
										  The field is required and it should be a valid phone number.e.g. 099XXXXXXX
									    </FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="email">Email Address&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input autoComplete='off' type="email"  name="email_id" id="email_id" required={true} invalid={this.state.emailState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
									<FormFeedback invalid>
										  The field is required and it must be a valid email address.
									 </FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="email">Default Password&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                     <Input autoComplete='off' type="password"  name="pass_id" id="pass_id" required={true} defaultValue={this.state.pass_id} invalid={this.state.passwordState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}/>
                                     <FormFeedback invalid>
										  The field is required
									  </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="User Role">User Role&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="select" name="user_role" id="user_role" required={true} required={true} invalid={this.state.UserRoleState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} > 
                                            <option></option>
                                            <option>Pupil</option>
                                            <option>Student</option>
                                            <option>Teacher</option>
                                            <option>Head Teacher</option>
                                            <option>Deputy Head</option>
											<option>School Governance Chair</option>
                                            <option>Guardian</option>
                                            <option>PEA</option>
                                            <option>Secondary Advisor</option>
                                            <option>DEM</option>
                                            <option>Inspector</option>
                                            <option>Division Manager</option>
                                            <option>Ministry HQ</option>
											<option>EMIU</option>
											<option>HR</option>
											<option>DSE</option>
											<option>EDM</option>
											<option>DTED</option>
											<option>Eplanning</option>
											<option>DIE</option>
											<option>DST</option>
											<option>MANEB</option>
                                            <option>System Administrator</option>
                                        </Input>
										<FormFeedback invalid>
										  The field is required.
									    </FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>
								
								{
									(
										(this.state.user_role =="System Administrator" || this.state.user_role == 'Ministry HQ' || this.state.user_role == 'Inspector') ? 
										
										 (this.state.user_role=="Inspector")
										 ?
											<FormGroup>
											  <Row>
												<Col xs={4} sm={4} md={4} lg={4} xl={4}>
												  <Label for="Sector">Sector&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
												</Col>
												<Col xs={8} sm={8} md={8} lg={8} xl={8}>
													<Input type="select" name="sector_id" id="sector_id" required={true} invalid={this.state.SectorState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
														<option></option>
														{	
														  Sectors.map(sector=>(
															  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
															)
														  )
														}
													</Input>
													<FormFeedback invalid>
														  The field is required.
													</FormFeedback>
												</Col>
											  </Row>
											</FormGroup>
										 :
										 null
										
										:
										<div>	
										{	
											(
												(['Division Manager', 'EDM'].includes(this.state.user_role)) ?
													<FormGroup>
													  <Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
														  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="select" name="division_id" id="division_id" required={true} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{ this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput}> 
																<option></option>
																{
																  Divisions.map(division=>(
																	  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																	)
																  )
																}
															</Input>
															<FormFeedback invalid>
																  The field is required.
															 </FormFeedback>
														</Col>
													  </Row>
													</FormGroup>
													:
													(
													  (['DEM'].includes(this.state.user_role)) ?
														<div>
															<FormGroup>
															  <Row>
																<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																</Col>
																<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																	<Input type="select" name="division_id" id="division_id" required={true} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} > 
																		<option></option>
																		{
																		  Divisions.map(division=>(
																			  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																			)
																		  )
																		}
																	</Input>
																	<FormFeedback invalid>
																		  The field is required.
																	 </FormFeedback>
																	
																</Col>
															  </Row>
															</FormGroup>
															<FormGroup>
															  <Row>
																<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																</Col>
																<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																	<Input type="select" name="district_id" id="district_id" required={true} invalid={this.state.DistrictState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} > 
																			<option></option>
																			{
																				  
																			  this.state.Districts.map(district=>(
																							 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																					)
																				   )
																						
																			}
																	</Input>
																	<FormFeedback invalid>
																		  The field is required.
																	 </FormFeedback>
																</Col>
															  </Row>
															</FormGroup>
														</div>
														:
														(
																(this.state.user_role == "PEA" || this.state.user_role == "Secondary Advisor") ?
																	<div>
																		<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="division_id" id="division_id" required={true} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} > 
																						<option></option>
																						{
																						  Divisions.map(division=>(
																							  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="district_id" id="district_id" required={true} invalid={this.state.DistrictState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} > 
																							<option></option>
																							{
																								  
																							  this.state.Districts.map(district=>(
																											 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																									)
																								   )
																										
																							}
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Sector">Sector&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="sector_id" id="sector_id" required={true} invalid={this.state.SectorState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
																						<option></option>
																						{	
																						  Sectors.map(sector=>(
																							  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Zone">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="zone_id" id="zone_id" required={true} invalid={this.state.ZoneState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchoolsAfterLoad(e); }} onBlur={this.handleInput}> 
																						<option></option>
																						{
																								  this.state.Zones.map(zone=>(
																										//Logic circuit and
																										(this.state.sector_id==zone.sector_id)&& <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																									)
																								   )
																										
																					  }
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																	</div>
																	
																:
																<div>	
																{
																(this.state.user_role =='Head Teacher' || this.state.user_role == 'Deputy Head'  || this.state.user_role =='Teacher' || this.state.user_role =='PTA' || this.state.user_role == 'School Governance Chair'|| this.state.user_role == 'Guardian' || this.state.user_role == 'Student' || this.state.user_role == 'Pupil') ?
																	  <div>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="division_id" id="division_id" required={true} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} > 
																						<option></option>
																						{
																						  Divisions.map(division=>(
																							  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="district_id" id="district_id" required={true} invalid={this.state.DistrictState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} > 
																							<option></option>
																							{
																								  
																							  this.state.Districts.map(district=>(
																											 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																									)
																								   )
																										
																							}
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Sector">Sector&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="sector_id" id="sector_id" required={true} invalid={this.state.SectorState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
																						<option></option>
																						{	
																						  Sectors.map(sector=>(
																							  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Zone">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="zone_id" id="zone_id" required={true} invalid={this.state.ZoneState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchoolsAfterLoad(e); }} onBlur={this.handleInput}> 
																						<option></option>
																						{
																								  this.state.Zones.map(zone=>(
																										//Logic circuit and
																										(this.state.sector_id==zone.sector_id)&& <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																									)
																								   )
																										
																					  }
																					</Input>
																					<FormFeedback invalid>
																						  The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="School">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																				  <Input type="select" name="emis_id" id="emis_id" required={true} invalid={this.state.EmisState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
																						  <option></option>
																						  {
																							  this.state.Schools.map(school=>(
																										 <option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
																								)
																							   )
																									
																						  }
																				  </Input>
																				  <FormFeedback invalid>
																					  The field is required.
																				  </FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																	  </div>
																	  :
																	  null
																	  
																}
																</div>
														)
													)
											)
										}
										</div>	
									)
							}
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <table className="table table-responsive table-bordered table-condensed table-hover table-striped">
                      <MaterialTable
                          title="REGISTERED USERS"
                          columns={data.columns}
                          data={
								Users.map(user=>(	
									{
									  
									  full_name: user.first_name + " " + " " + ((user.middle_name==null)?'':user.middle_name) + " " + " " + user.last_name, 
									  user_role : user.user_role,
									  phone_number : user.user_phone,
									  email_address : user.user_email,
									  //school_name : user.school_name,
									  //zone_name : user.zone_name,
									  //district_name : user.district_name,
									  //division_name : user.division_name,
										'OPTIONS': <pre><Link to={`/users/`} onClick={() => this.editUser(user.user_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link to={`/users`} onClick={() => this.deleteUser(user.user_id)} ><span className="text-danger" ><Delete /></span></Link>&nbsp;&nbsp;<Link to={`/users`} onClick={() => resetPassword(user.user_id)} ><span className="text-danger" ><LockOpen /></span></Link></pre>
									}
						  ))} 
						  
                          options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </table>

                  {
                    //Edit user record modal
					
					/*
					NOTE the usage of the onChange and onBlur event to call the same function.
					The onBlur event has been used to enforce validation once the user move out of the field.
					*/
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateUser} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">USER EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								  </FormGroup>
								  {
									/* <FormGroup>
									  <Row>
										<Col xs='auto' sm={4} md={4} lg={4} xl={4}>
										  <Label for="Salutation">Salutation</Label>					 
										</Col>
										<Col xs='auto' sm={8} md={8} lg={8} xl={8}>
											  <Input value={this.state.salutation} invalid={this.state.SalutationState==='has-danger'} type="select" name="salutation" id="salutation" onChange={this.handleInput} onBlur={this.handleInput} required={true}> 
												  <option></option>
												  <option>Ms.</option>
												  <option>Mrs.</option>
												  <option>Mr.</option>
												  <option>Dr.</option>
												  <option>Prof.</option>
											  </Input>
											  <FormFeedback invalid>
												  The field is required.
											  </FormFeedback>
										</Col>
									  </Row>
									</FormGroup>   */
								  }
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="First Name">First Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="text"  name="first_name" id="first_name" defaultValue={this.state.first_name} invalid={this.state.fnState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}required={true}  />
                                      <FormFeedback invalid>
										  The field is required.
									  </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="Middle Name">Middle Name</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <Input type="text"  name="middle_name" id="middle_name" defaultValue={this.state.middle_name} onChange={this.handleInput} onBlur={this.handleInput} />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="Last Name">Last Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="text"  name="last_name" id="last_name" required={true} defaultValue={this.state.last_name} invalid={this.state.lnState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required />
									  <FormFeedback invalid>
										  The field is required.
									  </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="phone number">Phone Number&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <Input type="number"  name="phone_number" id="phone_number" required={true} defaultValue={this.state.phone_number} invalid={this.state.phoneState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
										<FormFeedback invalid>
										  The field is required and it should be a valid phone number.e.g. 099XXXXXXX
									    </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="email">Email Address&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                     <Input type="email"  name="email_id" id="email_id" required={true} defaultValue={this.state.email_id} invalid={this.state.emailState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required/>
                                     <FormFeedback invalid>
										  The field is required and it must be a valid email address.
									  </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="User Role">User Role&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                      <Input type="select" name="user_role" id="user_role" required={true} defaultValue={this.state.user_role} invalid={this.state.UserRoleState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} > 
                                            <option></option>
                                            <option>DQAS</option>
                                            <option>HQ</option>
                                            <option>DSE</option>
                                            <option>Pupil</option>
                                            <option>Student</option>
                                            <option>Teacher</option>
                                            <option>Head Teacher</option>
                                            <option>Deputy Head</option>
											<option>School Governance Chair</option>
                                            <option>Guardian</option>
                                            <option>PEA</option>
                                            <option>Secondary Advisor</option>
                                            <option>DEM</option>
                                            <option>Inspector</option>
                                            <option>Division Manager</option>
                                            <option>Ministry HQ</option><option>EMIU</option>
											<option>HR</option>
											<option>DSE</option>
											<option>DTED</option>
											<option>Eplanning</option>
											<option>DIE</option>
											<option>DST</option>
											<option>MANEB</option>
                                            <option>System Administrator</option>
                                        </Input>
										<FormFeedback invalid>
											The field is required.
										</FormFeedback>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                {
									(
										(this.state.user_role =="System Administrator" || this.state.user_role == 'Ministry HQ' || this.state.user_role == 'Inspector') ? 
											(this.state.user_role=="Inspector")
											 ?
												<FormGroup>
												  <Row>
													<Col xs={4} sm={4} md={4} lg={4} xl={4}>
													  <Label for="Sector">Sector&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
													</Col>
													<Col xs={8} sm={8} md={8} lg={8} xl={8}>
														<Input type="select" name="sector_id" id="sector_id" required={true} value={this.state.sector_id}  invalid={this.state.SectorState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
															<option></option>
															{	
															  Sectors.map(sector=>(
																  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
																)
															  )
															}
														</Input>
														<FormFeedback invalid>
															  The field is required.
														</FormFeedback>
													</Col>
												  </Row>
												</FormGroup>
											 :
											 null
										:
										<div>	
										{	
											(
												(this.state.user_role == 'Division Manager') ?
													<FormGroup>
													  <Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
														  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="select" name="division_id" id="division_id" required={true} value={this.state.division_id} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{ this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput}> 
																<option></option>
																{
																  Divisions.map(division=>(
																	  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																	)
																  )
																}
															</Input>
															
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													  </Row>
													</FormGroup>
													:
													(
													  (this.state.user_role =='DEM') ?
														<div>
															<FormGroup>
															  <Row>
																<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																</Col>
																<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																	<Input type="select" name="division_id" id="division_id" required={true} value={this.state.division_id} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} > 
																		<option></option>
																		{
																		  Divisions.map(division=>(
																			  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																			)
																		  )
																		}
																	</Input>
																	
																	<FormFeedback invalid>
																		The field is required.
																	</FormFeedback>
																</Col>
															  </Row>
															</FormGroup>
															<FormGroup>
															  <Row>
																<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																</Col>
																<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																	<Input type="select" name="district_id" id="district_id"  required={true} value={this.state.district_id} invalid={this.state.DistrictState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} > 
																			<option></option>
																			{
																				  
																			  this.state.Districts.map(district=>(
																							 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																					)
																				   )
																						
																			}
																	</Input>
																	
																	<FormFeedback invalid>
																		The field is required.
																	</FormFeedback>
																</Col>
															  </Row>
															</FormGroup>
														</div>
														:
														(
																(this.state.user_role == "PEA" || this.state.user_role == "Secondary Advisor") ?
																	<div>
																		<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="division_id" id="division_id" required={true} value={this.state.division_id} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} > 
																						<option></option>
																						{
																						  Divisions.map(division=>(
																							  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={4} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="district_id" id="district_id" required={true} value={this.state.district_id} invalid={this.state.DistrictState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} > 
																							<option></option>
																							{
																								  
																							  this.state.Districts.map(district=>(
																											 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																									)
																								   )
																										
																							}
																					</Input>
																					
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Sector">Sector&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="sector_id" id="sector_id" required={true} value={this.state.sector_id} invalid={this.state.SectorState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
																						<option></option>
																						{	
																						  Sectors.map(sector=>(
																							  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Zone">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="zone_id" id="zone_id" required={true} value={this.state.zone_id} invalid={this.state.ZoneState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchoolsAfterLoad(e); }} onBlur={this.handleInput}> 
																						<option></option>
																						{
																								  this.state.Zones.map(zone=>(
																										//Logic circuit and
																										(this.state.sector_id==zone.sector_id)&& <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																									)
																								   )
																										
																					  }
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																	</div>
																	
																:
																<div>	
																{
																(this.state.user_role =='Head Teacher' || this.state.user_role == 'Deputy Head'  || this.state.user_role =='Teacher' || this.state.user_role =='PTA' || this.state.user_role == 'School Governance Chair' || this.state.user_role == 'Guardian' || this.state.user_role == 'Student' || this.state.user_role == 'Pupil') ?
																	  <div>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="division_id" id="division_id" required={true} value={this.state.division_id} invalid={this.state.DivisionState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} > 
																						<option></option>
																						{
																						  Divisions.map(division=>(
																							  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="district_id" id="district_id" required={true} value={this.state.district_id} invalid={this.state.DistrictState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} > 
																							<option></option>
																							{
																								  
																							  this.state.Districts.map(district=>(
																											 <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																									)
																								   )
																										
																							}
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Sector">Sector&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="sector_id" id="sector_id" required={true} value={this.state.sector_id} invalid={this.state.SectorState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}> 
																						<option></option>
																						{	
																						  Sectors.map(sector=>(
																							  <option value={sector.sector_id} key={sector.sector_id}>{sector.sector_name}</option>
																							)
																						  )
																						}
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="Zone">Zone/Cluster Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																					<Input type="select" name="zone_id" id="zone_id" required={true} value={this.state.zone_id} invalid={this.state.ZoneState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchoolsAfterLoad(e); }} onBlur={this.handleInput}> 
																						<option></option>
																						{
																								  this.state.Zones.map(zone=>(
																										//Logic circuit and
																										(this.state.sector_id==zone.sector_id)&& <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																									)
																								   )
																										
																					  }
																					</Input>
																					<FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																			<FormGroup>
																			  <Row>
																				<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																				  <Label for="School">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
																				</Col>
																				<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																				  <Input type="select" name="emis_id" id="emis_id" required={true} value={this.state.emis_id} invalid={this.state.EmisState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} > 
																						  <option></option>
																						  {
																							  this.state.Schools.map(school=>(
																										 <option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
																								)
																							   )
																									
																						  }
																				  </Input>
																				    <FormFeedback invalid>
																						The field is required.
																					</FormFeedback>
																				</Col>
																			  </Row>
																			</FormGroup>
																	  </div>
																	  :
																	  null
																	  
																}
																</div>
														)
													)
											)
										}
										</div>	
									)
							}
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default Users;
