import React, { Component } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Carousel, CarouselControl, CarouselItem, CarouselCaption,  CarouselIndicators,  Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import poor from "../../../assets/images/poor.png";
import vgood from "../../../assets/images/vgood.png";
import excellent from "../../../assets/images/excellent.png";
import good from "../../../assets/images/good.png";
import NesAchievementGraph from './NesAchievementGraph';


import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import {Add, Edit, Delete, ArrowLeft, Image} from '@material-ui/icons';
import swal from 'sweetalert';
import SendEmailModal from "./SendEmailModal"


import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class KeyEvidence extends Component {
  constructor(props) {
    super(props);
	const visit_id=this.props.match.params.visit_id;
	const isExpired = this.props.match.params.isExpired;
	const logged_user_id = this.props.match.params.logged_user;
	const lead_inspector_id = this.props.match.params.lead_inspector;
	const first_inspector_id = this.props.match.params.first_inspector;
	
    this.state = {
	  //Get logged user role
		files: [],
		activeIndex: 0,
		previewImagesModal: false,
		togglePreviewModal: false,
		previewImages: [],
	  role:window.sessionStorage.getItem("urole"),
	  sector_id:window.sessionStorage.getItem("sector"),
      modalVisible:false,
      EditModalVisible:false,
	  visit_id:visit_id,
	  isExpired: isExpired,
	  logged_user_id: logged_user_id,
	  lead_inspector_id:lead_inspector_id,
	  first_inspector_id:first_inspector_id,
	  key_evidence_description:[],
	  key_evidence_edit_description:[],
	  key_evidence_status:[],
	  key_evidence_edit_status:[],
	  include_in_ir:'NULL',
	  nes_id:'',
	  requirement_id:[],
	  nes_level_id:[],
      bearer_auth:window.sessionStorage.getItem('token'),
	  //Requirements hold data from server and same as key Evidences
	  KeyEvidence:[],
	  Requirements:[],
	  Standards:[],
	  RequirementAchieved:[],
	  NesLevelAchieved:[],
	  Recommendation:[],
	  edited_recommendation:[],
	  ke_edit_state_data:{},
	  ke_edit_description_data:{},
	  Juridistiction:'NATIONAL',
	  KeyEvidenceDescrState:{},
	  KeyEvidenceStatusState:{},
	  recommendation_id:'',
	  recommendation_type:'Minor',
	  recommendation_description:'',
	  school_name: '',
	  visit_date:'',
	  category_id :'',
	  RecommendationIdState:'',
	  loading: '',
	  activeNes: '',
	  sendEmailModal: false,
	  emails: [],
	  deps: []
    };
    
	
    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addKeyEvidence = this.addKeyEvidence.bind(this);
	this.updateKeyEvidence = this.updateKeyEvidence.bind(this);
	this.deleteKeyEvidence=this.deleteKeyEvidence.bind(this);
	this.editKeyEvidence = this.editKeyEvidence.bind(this);
	this.toggleEmailModal = this.toggleEmailModal.bind(this);
	this.closeEmailModal = this.closeEmailModal.bind(this);
	this.setupData = this.setupData.bind(this)
	  this.resetState = this.resetState.bind(this)
	  this.uploadImages = this.uploadImages.bind(this)
	
	this.setId = this.setId.bind(this);
	}
	
	resetState() {
		this.setState({
			recommendation_id: '',
			activeNes: '',
			key_evidence_description: [],
			key_evidence_edit_description: [],
			key_evidence_status: [],
			key_evidence_edit_status: [],
			include_in_ir: 'NULL',
			nes_id: '',
			requirement_id: [],
			nes_level_id: [],
			edited_recommendation: [],
			ke_edit_state_data: {},
			ke_edit_description_data: {},
		})
	}
  
	toggleEmailModal() {
		this.setState({
			sendEmailModal: true
		})
	}

	closeEmailModal() {
		this.setState({
			sendEmailModal: false
		})
	}

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
	this.setState({
		modalVisible:false, 
		nes_id: '',
		emails: [],
		key_evidence_description:[],
	    key_evidence_edit_description:[],
	    key_evidence_status:[],
	    key_evidence_edit_status:[],
	    ke_edit_description_data:{},
	    KeyEvidenceDescrState:{},
	    KeyEvidenceStatusState:{},
		RecommendationIdState:'',
		
		}); 
    }
  }
  toggleEdit(state=true){
    if(this.state.EditModalVisible==false && state===true)
    {
     this.setState({EditModalVisible:true, KeyEvidenceStatusState:{}, KeyEvidenceDescrState:{},nes_id:''});
    }else{
     this.setState({
			EditModalVisible:false,
			nes_id:'',
			key_evidence_description:[],
			key_evidence_edit_description:[],
			key_evidence_status:[],
			key_evidence_edit_status:[],
			ke_edit_description_data:{},
			KeyEvidenceDescrState:{},
			KeyEvidenceStatusState:{},
			RecommendationIdState:'',
		  
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  
	 //delete
	deleteKeyEvidence(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Do you want to delete the evidence for the entire NES " + id + "?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
						confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.get(API_URL + `/key_evidences/delete/${this.state.visit_id}/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								this.setState({loading : false});
								swal({
								  title: "",
								  text: "The evidence has been deleted for the NES "+ id + ".",
								  icon: "success",
								  timer : 1250,
								  button: false
								})
								this.setupData()
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  });
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
	
	//Edit 
	editKeyEvidence(id){
		
		this.setState({loading : true});
	    
		Promise.all([
		 axios.get(API_URL + `/key_evidences/edit/${this.state.visit_id}/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),
		 axios.get(API_URL + `/recommendations/minor/${this.state.visit_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		 ]).then(([key_evidence,recommendation])=> {
		  //console.log(response.data);
		  
		  //Process the recommendation for retrieval in KE  based on NESId
		 const processed_recommendation={};
		 const recommendation_data = recommendation.data;
		 
		 //Loop through the recommendation while mapping the description to nes_id
			 for (let i = 0; i < recommendation_data.length; i++){
			 processed_recommendation[recommendation_data[i]['nes_id']] = recommendation_data[i]['recommendation_description'];
			 }
			 
			 
			 //Capture the fetched key_evidence data
			 const edit_data = key_evidence.data;
			 //Initialize empty objects for keeping the processed KE data
			 const ke_edit_data_state_obj = {};
		  const ke_edit_data_description_obj = {};
		  
		  
		  
		  //Set the dynamic states for editing array fields on key evide3nce
		  //let i = 0;
			this.setState({
				key_evidence_edit_status: edit_data.map(m => { return { state: `${m.requirement_id}:${m.key_evidence_status}` } }),
				key_evidence_status: edit_data.map(m => { return { state: `${m.requirement_id}:${m.key_evidence_status}` } }),
				key_evidence_edit_description: edit_data.map(m => { return { descr: `${m.requirement_id}:${m.key_evidence_description}` } }),
				key_evidence_description: edit_data.map(m => { return { descr: `${m.requirement_id}:${m.key_evidence_description}` } }),
				nes_id: edit_data[0]['nes_id'],
				loading: false,
				EditModalVisible: true,
				ke_edit_state_data: edit_data.reduce((obj, item) => ({ ...obj, [item.requirement_id]: item.key_evidence_status }), {}),
				ke_edit_description_data: edit_data.reduce((obj, item) => ({ ...obj, [item.requirement_id]: item.key_evidence_description }), {}),
				edited_recommendation: processed_recommendation,
				recommendation_description: processed_recommendation[edit_data[0]['nes_id']],
			})
		//   for(let i=0;i<edit_data.length;i++){
		// 	  ke_edit_data_state_obj[edit_data[i]['requirement_id']] = edit_data[i]['key_evidence_status'];
		// 	  ke_edit_data_description_obj[edit_data[i]['requirement_id']] = edit_data[i]['key_evidence_description'];
			  
		// 	 }
			 
			 axios.get(API_URL + `/nes_notifications/${recommendation.data.filter(r => r.nes_id === id)[0].recommendation_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
				 .then(({ data }) => {
				  this.setState({
					  emails: this.state.deps.filter(dept => data.some(d => d.department_id == dept.department_id)).map(f => f.short_name)
				  });
		  	  })
		  
		  //console.log(this.state.key_evidence_edit_description);
		  //console.log(this.state.key_evidence_edit_status);
		  
		}).catch(error => {
			      this.setState({loading : false});
				  
				  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
			  })
		
				this.setState({
					EditModalVisible: true,
				});	
	}

	setupData() {
		this.setState({ loading: true });

		//Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		//Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		Promise.all([
			axios.get(API_URL + `/departments`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/key_evidences/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/nes_requirements`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/recommendations/minor/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/key_evidences/level_achieved/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/key_evidences/nes_level_achieved/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
			axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
		]).then(
			([deps, key_evidence, standard, requirement, recommendation, rAchievement, nLevelAchieved, visit]) => {
				//Process the recommendation for retrieval in KE  based on NESId
				const processed_recommendation = {};
				const recommendation_data = recommendation.data;

				//Loop through the recommendation while mapping the description to nes_id
				for (let i = 0; i < recommendation_data.length; i++) {
					processed_recommendation[recommendation_data[i]['nes_id']] = recommendation_data[i]['recommendation_description'];
				}

				this.setState({
					deps: deps.data,
					KeyEvidence: key_evidence.data,
					Standards: standard.data,
					Requirements: requirement.data,
					Recommendation: processed_recommendation,
					RequirementAchieved: rAchievement.data,
					NesLevelAchieved: nLevelAchieved.data,
					school_name: visit.data[0]['school_name'],
					visit_date: new Date(visit.data[0]['present_visitation_date']).toLocaleDateString(),
					loading: false
				})
			}
		).catch(error => {
			this.setState({ loading: false });
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}
	
  componentDidMount () {
	 this.setupData()
  }
	
	uploadImages(id) {
		if (this.state.files.length > 0) {
			let formData = new FormData();
			formData.append('key_evidence_id', id);
			for (let i = 0; i < this.state.files.length; i++) {
				formData.append('files[]', this.state.files[i]);
			}
			axios.post(API_URL + `/key_evidences/${id}/upload`, formData, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}`, "Content-type": "multipart/form-data" } })
		}
	}
	
  //Add user
	 addKeyEvidence(event){
		event.preventDefault()

        const { history } = this.props

        const key_evidence = {
		  key_evidence_description : this.state.key_evidence_description,
		  key_evidence_status : this.state.key_evidence_status,
		  requirement_id:this.state.requirement_id,
		  recommendation_type : this.state.recommendation_type,
		  recommendation_description : this.state.recommendation_description,
		  visit_id : this.state.visit_id,
		  nes_id : this.state.nes_id,
		  category_id:this.state.category_id,
		  nes_level_id: this.state.nes_level_id,
		  emails: this.state.emails.map(name => this.state.deps.find(dep => dep.short_name == name).department_id)
        }
		
		this.setState({loading : true});
		
		//console.log(this.state.key_evidence_status);
				
        //console.log(key_evidence);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/key_evidences', key_evidence,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
			  this.setState({ modalVisible: false, loading: false });
			  const evidence = response.data['data'];
			  this.uploadImages(evidence.key_evidence_id);
			swal({
				title: "",
			  text: response.data['message'],
			  icon: response.data['status'],
			  timer : 1250,
			  button: false
			}).then(__ => {
				window.location.reload()
			})
          })
          .catch(error => {
            this.setState({loading : false});
			if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
	 }
	 
	 //update
	 updateKeyEvidence(event){
		event.preventDefault()

        const { history } = this.props

        const key_evidence = {
		  key_evidence_description : this.state.key_evidence_description,
		  key_evidence_status : this.state.key_evidence_status,
		  requirement_id:this.state.requirement_id,
		  recommendation_type : this.state.recommendation_type,
		  recommendation_description : this.state.recommendation_description,
		  visit_id : this.state.visit_id,
		  nes_id : this.state.nes_id,
		  category_id:this.state.category_id,
		  nes_level_id: this.state.nes_level_id,
		  recommendation_id: this.state.recommendation_id,
		  emails: this.state.emails.map(name => this.state.deps.find(dep => dep.short_name == name).department_id)
        }
		
		this.setState({loading : true});
				//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/key_evidences/update', key_evidence,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
			.then(response => {
			const evidence_id = response.data;
			console.log(evidence_id)
			this.uploadImages(evidence_id);
            this.setState({
				EditModalVisible:false, 
				loading : false});
			swal({
			  title: "",
			  text: response.data['message'],
			  icon: response.data['status'],
			  timer : 2500,
			  button: false
			}).then(__ => {
				// window.location.reload();
			})
          })
          .catch(error => {
            this.setState({loading : false});
			if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
			
	 
	})

	}

  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
	  const { name } = e.target
	  if (name == 'file') {
		  this.setState({ files: e.target.files })
	  }
	
	  if (e.target.name == 'key_evidence_description') {
		   if(e.target.value!=''){
			  //this.state.KeyEvidenceDescrState[e.target.id]='has-success'; 
			   
			   this.setState({ key_evidence_description: this.state.key_evidence_description.filter(f => f.descr.split(":")[0] != e.target.id).concat([{descr:e.target.id + ":" + e.target.value}])});
			   //this.setState({KeyEvidenceDescrState :'has-success',key_evidence_description : this.state.key_evidence_description.push([{descr:e.target.value}])});
			   //this.setState({KeyEvidenceDescrState :'has-success', key_evidence_description : this.state.key_evidence_description.concat({e.target.value})});
		   }else if(this.state.key_evidence_edit_description.length>0 && this.state.key_evidence_description.length==0){
				   this.setState({key_evidence_description:this.state.key_evidence_edit_description});
		   }else if(e.target.value==''){
				//this.state.KeyEvidenceDescrState[e.target.id]='has-danger';
				
			    //console.log(this.state.KeyEvidenceDescrState);
		   }
	/**
	Consider refactoring the next 2 elseifs into one function
	**/
	} else if (e.target.name == 'key_evidence_status') {
		   if(e.target.value!=''){
				//String to be added to the  key_evidence_status array
				let input_object = e.target.id + ":" + e.target.value;
				
				//Check the length of the key_evidence_status array
				if(this.state.key_evidence_status.length!=0){
					
					//Loop through the key_evidence_status array
					for(let i=0;i<this.state.key_evidence_status.length;i++){
						//Capture the array valuefor the current index 
						let req_status = this.state.key_evidence_status[i]['state'];
						
						//Explode the requirement id from the KE status
						let split_req_status = req_status.split(":");
						
						//Make a copy of the key_evidence_status array
						let key_evidence_status_copy = this.state.key_evidence_status;
						
						
						
						//Check if the  requirement id already exists in the key_evidence_status state
						if(split_req_status[0]==e.target.id){
							//Check if the KE status already exists in the key_evidence_status state
							if(split_req_status[1]!=e.target.value){	
								
								//Remove the array value by index in the copied  key_evidence_status
								this.state.key_evidence_status.splice(i, 1);
								//key_evidence_status_copy[i] = e.target.id + ":" + e.target.value;
								
								//Add  the new id and value to key_evidence_status
								this.setState({key_evidence_status : this.state.key_evidence_status.concat([{state:input_object}])});
							}
						}else if(req_status!=input_object){
							//Add the new string as before
							this.setState({key_evidence_status : this.state.key_evidence_status.concat([{state:input_object}])});
						}
					}
				}else{
		
					//Add the input_object if key_evidence_status state is empty
					this.setState({key_evidence_status : this.state.key_evidence_status.concat([{state:input_object}])});
				}
		   }else if(this.state.key_evidence_edit_status.length>0 && this.state.key_evidence_status.length==0){
				//String to be added to the  key_evidence_status array
				let input_object = e.target.id + ":" + e.target.value;
				
				//Check the length of the key_evidence_status array
				if(this.state.key_evidence_edit_status.length!=0){
					
					//Loop through the key_evidence_status array
					for(let i=0;i<this.state.key_evidence_edit_status.length;i++){
						//Capture the array valuefor the current index 
						let req_status = this.state.key_evidence_edit_status[i]['state'];
						
						//Explode the requirement id from the KE status
						let split_req_status = req_status.split(":");
						
						//Make a copy of the key_evidence_status array
						let key_evidence_status_copy = this.state.key_evidence_edit_status;
				
						//Check if the  requirement id already exists in the key_evidence_status state
						if(split_req_status[0]==e.target.id){
							//Check if the KE status already exists in the key_evidence_status state
							if(split_req_status[1]!=e.target.value){	
								
								//Remove the array value by index in the copied  key_evidence_status
								this.state.key_evidence_edit_status.splice(i, 1);
								//key_evidence_status_copy[i] = e.target.id + ":" + e.target.value;
								
								//Add  the new id and value to key_evidence_status
								this.setState({key_evidence_edit_status : this.state.key_evidence_edit_status.concat([{state:input_object}])});
							}
						}else if(req_status!=input_object){
							//Add the new string as before
							this.setState({key_evidence_edit_status : this.state.key_evidence_edit_status.concat([{state:input_object}])});
						}
					}
				}else{
					
					//Add the input_object if key_evidence_status state is empty
					this.setState({key_evidence_edit_status : this.state.key_evidence_edit_status.concat([{state:input_object}])});
				}
		   }else if(e.target.value==''){
				//this.state.KeyEvidenceStatusState[e.target.id]='has-danger';
				
				//console.log(this.state.KeyEvidenceStatusState);
				
				
		   } 
	   
	  } else if (e.target.name === 'nes_id') {
		  if(e.target.value!=''){
			  const exists = this.state.KeyEvidence.filter(k => k.nes_id == e.target.value)
			  if (exists.length > 0) return swal({
				  title: "",
				  text: `NES ${e.target.value} has already been added, perhaps try editing instead .`,
				  icon: "warning",
				  timer: 3000
			  })
			   this.setState({NESIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({NESIdState :'has-danger'});
		   }
	//Next two else ifs are not usable due to isssues with events in js 
    }else if(e.target.name==='requirement_id'){
		   if(e.target.value!=''){
			   //this.setState({RequirementIdState :'has-success', requirement_id : this.state.requirement_id.concat([{req:e.target.value}])});
			   this.setState({requirement_id : this.state.requirement_id.concat([{req:e.target.value}])});
		   }else{
			   //this.setState({RequirementIdState :'has-danger'});
		   }
	   
    } else if(e.target.name==='nes_level_id'){
		   if(e.target.value!=''){
			   //this.setState({NesLevelIdState :'has-success', nes_level_id : this.state.nes_level_id.concat([{state:e.target.value}])});
			   this.setState({nes_level_id : this.state.nes_level_id.concat([{state:e.target.value}])});
		   }else{
			   //this.setState({NesLevelIdState :'has-danger'});
		   }
	   
    }else if(e.target.name==='recommendation_description'){
		   if(e.target.value!=''){
			   this.setState({recommendation_description:e.target.value,RecommendationIdState:'has-success'});
		   }else{
			   this.setState({RecommendationIdState :'has-danger'});
		   }
	   
    } 
	
	
        
  }
	
  
  
  
  render() {
	  const { activeIndex, KeyEvidence, Standards, Requirements, Recommendation, RequirementAchieved, NesLevelAchieved, ke_edit_state_data, ke_edit_description_data } = this.state;
	  
	  const next = () => {
		  if (this.animating) return;
		  const nextIndex = this.state.activeIndex === this.state.previewImages.length - 1 ? 0 : this.state.activeIndex + 1;
		  this.setState({ activeIndex: nextIndex });
	  }
	  const previous = () => {
		  if (this.animating) return;
		  const nextIndex = this.state.activeIndex === 0 ? this.state.previewImages.length - 1 : this.state.activeIndex - 1;
		  this.setState({ activeIndex: nextIndex });
	  }

	  const goToIndex = (newIndex) => {
		  if (this.animating) return;
		  this.setState({ activeIndex: newIndex });
	  }

	  
	
	const previewImages = (e) => {
		const images = KeyEvidence.find(k => k.key_evidence_id == e).uploads
		this.setState({ previewImages: images, previewImagesModal: true, togglePreviewModal: true});
	}

	const onExiting = () => {
		this.animating = true;
	}

	const onExited = () => {
		this.animating = false;
	}
	  
	const deleteKeyEvidenceImage =  async () => {
		const id = this.state.previewImages[this.state.activeIndex].id
		// confirm delete
		const confirm = await swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this image!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			buttons: ["Cancel", "Proceed Delete"]
		})
		if (!confirm) return
		const resp = await axios.delete(`${API_URL}/key_evidences/${id}/deleteimage`, { headers: { Authorization: `Bearer ${this.state.bearer_auth}` } })
		if (resp.status === 200) {
			await this.setState({ previewImages: this.state.previewImages.filter(p => p.id !== id) })
			if (this.state.previewImages.length === 0) {
				this.setState({ previewImagesModal: false, togglePreviewModal: false })
				window.location.reload()
			}
			swal({
				title: "",
				text: "Image deleted successfully",
				icon: "success",
				timer: 3000
			})
		}
	}

	//const Reqs = [];
	//const Levels = [];
	
	//console.log("echoing the key evidence: ");
	//console.log(KeyEvidence);

    const data = {
      columns: [
        { 
          title: 'NES',
           field: 'nes_id' 
        },
        {
          title: 'Requirement',
          field: 'requirement_id',
        },
		{
          title: 'Level',
          field: 'nes_level_id',
        },
		{
          title: 'Status',
          field: 'key_evidence_status',
        }, 
		{
		  title: 'Evidence',
          field: 'comment_id',
        },
		{
		  title: 'Minor Recommendation',
          field: 'recommendation_id',
        },
        {
		  title: 'Actions',
		  field: 'OPTIONS',
		  export : false,
	   }, 
      ],
    };
    
    return (
	
		<React.Fragment>
			{/* modal to preview slideshow of images */}
			<Modal
				isOpen={this.state.previewImagesModal}
				size="lg"
				className="modal-dialog-centered"
			>
				<ModalHeader>
					Preview Images
				</ModalHeader>
				<ModalBody>
					<Carousel
						activeIndex={activeIndex}
						next={next}
						previous={previous}
					>
						<CarouselIndicators items={this.state.previewImages} activeIndex={activeIndex} onClickHandler={goToIndex} />
						{this.state.previewImages.map((item) => {
							return (	
								<CarouselItem
									key={item.src}
									onExiting={onExiting}
									onExited={onExited}
									
									>
									<img style={{ marginLeft: "130px" }} width={500} height={600} src={`${API_URL}/key_evidences/${item.file_name}/get_upload`} alt={item.file_name} />
								</CarouselItem>
								// delete picture trigger
							);
						})}
						<CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
						<CarouselControl direction="next" directionText="Next" onClickHandler={next} />
					</Carousel>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => {
						this.setState({ previewImagesModal: false, togglePreviewModal: false });
					}}>
						Close
					</Button>
					<Button color="danger" onClick={deleteKeyEvidenceImage}>
						Delete
					</Button>
				</ModalFooter>

			</Modal>
			{/* end of modal to preview slideshow of images */}
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Key Evidences</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Key Evidence Management</h3>
								<hr />
								<Button hidden={((this.state.role != 'Inspector' || ![this.state.lead_inspector_id, this.state.first_inspector_id, this.state.second_inspector_id].includes(this.state.logged_user_id) || this.state.isExpired == true)) ? true : false} color="success" onClick={this.toggle}> {<Add />} New Evidence</Button>
                <hr/>
				<span hidden readOnly>
					{
						  //Span tag used to hide the dynamic state change
						  //Setting the state for the above befor the resending of the form 
						  
						  //this.state.key_evidence_description = [],
						  //this.state.key_evidence_status = [],
						  this.state.requirement_id=[],
						  this.state.nes_level_id=[]
					}
				</span>
                {
				  //Add user modal
				  /*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
								}
				<SendEmailModal visit_id={this.state.visit_id} nes_achieved={this.state.NesLevelAchieved} showModal={this.state.sendEmailModal} closeModal={this.closeEmailModal} />
                <Modal size="lg" isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addKeyEvidence} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">KEY EVIDENCE ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                
                               
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_id">NES</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
										 <Input type="select"   name="nes_id" id="nes_id" invalid={this.state.NESIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required >
												<option></option>
												{
														Standards.sort((a, b) => a.requirement_id - b.requirement_id).map(standard =>(
																<option value={standard.nes_id} key={standard.nes_id}>{standard.nes_name}</option>  
																)
															  )
											    }
												
										  </Input>
										  <FormFeedback invalid>
											  The field is required.
										</FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								<hr />
								
										{
											  (this.state.nes_id) &&(
													<div>	
														<Table responsive striped size="lg">
															<thead>
																	<tr>
																		<th style={{width:'30%'}} className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">REQUIREMENT</th>
																		<th style={{width:'5%'}} className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">LEVEL</th>
																		<th style={{width:'15%'}} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">STATUS</th>
																		<th style={{width:'50%'}}className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">EVIDENCE</th>
																	</tr>
															</thead>
															
															
															
															<tbody>
															
																{
																	Requirements.sort((a, b) => a.requirement_id - b.requirement_id).map(requirement =>(
																		(this.state.nes_id==requirement.nes_id) ? 
																			(requirement.requirement_id!='99')?
																				(requirement.nes_level_id!='1')? 
																					(
																				
																							 <tr key={requirement.requirement_id}>
																								
																								<td>
																								{
																									//<Input type="number" defaultValue={requirement.requirement_id} name="requirement_id" id="requirement_id"  onChange={this.handleInput} readOnly hidden />
																								}
																								{`${requirement.nes_id}. ${requirement.requirement_id} ${requirement.requirement_name}`}
																								<p readOnly hidden>
																								{
																									this.state.requirement_id.push(requirement.requirement_id)
																								}</p>
																								</td>
																								<td>
																								{
																									//<Input type="number" defaultValue={requirement.nes_level_id} name="nes_level_id" id="nes_level_id"  onChange={this.handleInput} readOnly hidden />
																								}
																								{requirement.nes_level_id}
																								<p readOnly hidden>
																								{
																									this.state.nes_level_id.push(requirement.requirement_id + ":" + requirement.nes_level_id)
																								}
																								</p>
																								</td>
																								<td>
																								{
																									//<Input type="select"  defaultValue='' name="key_evidence_status" id="key_evidence_status" invalid={this.state.KeyEvidenceStatusState==='has-danger'} onBlur={this.handleInput} >
																								}
																									<Input type="select" defaultValue='' name="key_evidence_status" id={requirement.requirement_id} onBlur={(e)=>{this.handleInput(e);}}>
																										<option></option>
																										<option value='Positive'>Positive</option>
																										<option value='Negative'>Negative</option>
																									</Input>
																									{
							/* 																			<FormFeedback invalid>
																											  The fields are required and inter-dependent.
																										</FormFeedback>
							 */																			
																									}
																								</td>
																								<td>
																								{
																									//<Input type="textarea"  name="key_evidence_description" id="key_evidence_description" invalid={this.state.KeyEvidenceDescrState==='has-danger'} onBlur={this.handleInput} />
																								}
																									<Input type="textarea" name="key_evidence_description" xs={12} sm={12} md={12} lg={12} xl={12} id={requirement.requirement_id}  onBlur={(e)=>{this.handleInput(e);}}  />
																									{
							/* 																			<FormFeedback invalid>
																											  The fields are required and inter-dependent.
																										</FormFeedback>
							 */																			
																									}
																								</td>
																							 </tr>
																					)
																				: ''
																			:'' 
																		: ''
																		)	
																			
																	)
																}
										
														
												 </tbody>
													
													
													
												</Table>
												<FormGroup>
												  <Row>
													<Col xs={4} sm={4} md={4} lg={4} xl={4}>
													  <Label for="recommendation_description">MINOR RECOMMENDATION&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
													</Col>
													<Col xs={8} sm={8} md={8} lg={8} xl={8}>
													  <Input type="textarea"  name="recommendation_description" id="recommendation_description" invalid={this.state.RecommendationIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
													  <FormFeedback invalid>
														  The field is required.
													 </FormFeedback>
													</Col>
												  </Row>
														</FormGroup>
														<Row>
															{/* add a file upload button field */}
															<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																<Label for="recommendation_description">UPLOAD&nbsp;</Label>
															</Col>
															<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																<FormGroup>
																	<Label for="exampleFile">File</Label>
																	<Input className="file-upload" multiple accept=".jpg, .png, .jpeg" type="file" name="file" id="file-upload" onInput={this.handleInput} onChange={this.handleInput} />
																	<FormText color="muted">
																		Upload a file
																	</FormText>
																</FormGroup>
															</Col>
														</Row>
														<Row>
															<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																<Label for="recommendation_description">SEND NOTIFICATION TO&nbsp;</Label>
															</Col>
															<Col xs={8} sm={8} md={8} lg={8} xl={8}>
														<FormControl className="col-md-12" fullWidth>
															<InputLabel id="demo-multiple-checkbox-label">Departments</InputLabel>
															<Select
																labelId="demo-multiple-checkbox-label"
																id="demo-multiple-checkbox"
																multiple
																value={this.state.emails}
																onChange={(e) => {
																	const {
																		target: { value },
																	} = e;
																	this.setState({
																		emails: typeof value === 'string' ? value.split(',') : value
																	})
																}}
																input={<OutlinedInput label="Select Departments" />}
																renderValue={(selected) => selected.join(', ')}
																MenuProps={{
																	PaperProps: {
																		style: {
																			maxHeight: 48 * 4.5 + 8,
																			width: 250,
																		},
																	},
																}}
															>
																{this.state.deps.map((dep) => (
																	<MenuItem key={dep.short_name} value={dep.short_name}>
																		<Checkbox checked={this.state.emails.indexOf(dep.short_name) > -1} />
																		<ListItemText primary={dep.short_name} />
																	</MenuItem>
																))}
															</Select>
														</FormControl>
												
															</Col>
															</Row>
											</div>
											 
										)
								}
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
				
				{
					//console.log("keyEvidence")
				}
				{
					//console.log(KeyEvidence)
				}
                 
				  
                  <Table responsive striped hover size="lg">
                      <MaterialTable
						  title={`KEY EVIDENCES AT ${this.state.school_name} ${this.state.sector_id == 1 ? 'PRIMARY' : 'SECONDARY'} SCHOOL INSPECTION VISIT CONDUCTED ON ${this.state.visit_date}`}
                          columns={data.columns}
                          data={KeyEvidence.map((key_evidence, idx, array)=>(
										(this.state.role!="Inspector")?
											({
											  //nes_id : key_evidence.nes_id,
											  nes_id : (idx!=0) ?(array[idx]['nes_name']!=array[idx-1]['nes_name']) ? array[idx]['nes_name'] : ' ' : array[idx]['nes_name'],
											  requirement_id : array[idx]['nes_id'] + "." +array[idx]['requirement_id'] + "." + key_evidence.requirement_name,
											  nes_level_id:key_evidence.nes_level_id,
											  key_evidence_status : key_evidence.key_evidence_status,
											  comment_id : key_evidence.key_evidence_description,
											  recommendation_id : (idx!=0) ?(array[idx]['nes_name']!=array[idx-1]['nes_name']) ? Recommendation[array[idx]['nes_id']] : ' ' : Recommendation[array[idx]['nes_id']],
											  'OPTIONS': <pre><Link class="btn invisible" to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editKeyEvidence(key_evidence.nes_id)} ><span className="text-success" ><Edit /></span></Link>&nbsp;&nbsp;<Link className="btn invisible" to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteKeyEvidence(key_evidence.nes_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
											})
											:
											({
											  //nes_id : key_evidence.nes_id,
											  nes_id : (idx!=0) ?(array[idx]['nes_name']!=array[idx-1]['nes_name']) ? array[idx]['nes_name'] : ' ' : array[idx]['nes_name'],
											  requirement_id : array[idx]['nes_id'] + "." + array[idx]['requirement_id'] + "." + key_evidence.requirement_name,
											  nes_level_id:key_evidence.nes_level_id,
											  key_evidence_status : key_evidence.key_evidence_status,
											  comment_id : key_evidence.key_evidence_description,
											  recommendation_id : (idx!=0) ?(array[idx]['nes_name']!=array[idx-1]['nes_name']) ? Recommendation[array[idx]['nes_id']] : ' ' : Recommendation[array[idx]['nes_id']],
									  'OPTIONS': (idx != 0) ? (array[idx]['nes_name'] != array[idx - 1]['nes_name']) ? <pre><Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? "btn disabled" : "btn"} to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.editKeyEvidence(key_evidence.nes_id)} ><span className="text-success" ><Edit /></span></Link>&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? "btn invisible" : "btn"} to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.deleteKeyEvidence(key_evidence.nes_id)} ><span className="text-danger" ><Delete /></span>&nbsp;&nbsp;</Link>{key_evidence.uploads.length > 0 && <Link to={'#'} onClick={() => previewImages(key_evidence.key_evidence_id)}><Image /></Link>}
									  </pre> :
										 
										  ' ' : <pre><Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? "btn invisible" : "btn"} to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.editKeyEvidence(key_evidence.nes_id)} ><span className="text-success" ><Edit /></span></Link>&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != this.state.lead_inspector_id) && (this.state.logged_user_id != this.state.first_inspector_id || this.state.isExpired == true)) ? "btn invisible" : "btn"} to={`/key_evidences/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={() => this.deleteKeyEvidence(key_evidence.nes_id)} ><span className="text-danger" ><Delete /></span>&nbsp;&nbsp;</Link>{key_evidence.uploads.length > 0 && <Link to={'#'} onClick={() => previewImages(key_evidence.key_evidence_id)}><Image /></Link>}
									  </pre>
											})
						  ))}
						  
                          options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType: 'overlay',
							pageSize: 20,
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </Table>
				  {
					//Edit modal
					// console.log(Recommendation)
				  }
				  
				  
				  <Modal fullscreen={true} size="lg" isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateKeyEvidence} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">KEY EVIDENCE EDIT FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                
                               
								<FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="nes_id">EDITING ON NES</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
										{
											Standards.map(standard =>(
														(standard.nes_id==this.state.nes_id) &&
														(<strong>{standard.nes_name}</strong>)  
													))
										}
									</Col>
                                  </Row>
                                </FormGroup>
								
								<hr />
								
								{
									  (this.state.nes_id) &&(
											<div>	
												<Table responsive striped size="lg">
													<thead>
															<tr>
																<th style={{width:'30%'}} className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">REQUIREMENT</th>
																<th style={{width:'5%'}} className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">LEVEL</th>
																<th style={{width:'15%'}} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">STATUS</th>
																<th style={{width:'50%'}}className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">EVIDENCE</th>
															</tr>
													</thead>
													
													
													
													<tbody>
													{
													Requirements.sort((a, b) => a.requirement_id - b.requirement_id).map(requirement =>(
														(this.state.nes_id==requirement.nes_id && requirement.requirement_id!='99'  && requirement.nes_level_id!='1') &&
															(
															
																	<tr key={requirement.requirement_id}>
																		
																				<td>
																				{`${requirement.nes_id}. ${requirement.requirement_id} ${requirement.requirement_name}`}
																				<p readOnly hidden>
																				{
																					this.state.requirement_id.push(requirement.requirement_id)
																				}</p>
																				</td>
																				<td>
																				{
																					//<Input type="number" defaultValue={requirement.nes_level_id} name="nes_level_id" id="nes_level_id"  onChange={this.handleInput} readOnly hidden />
																				}
																				{requirement.nes_level_id}
																				<p readOnly hidden>
																				{
																					this.state.nes_level_id.push(requirement.requirement_id + ":" + requirement.nes_level_id)
																				}
																				</p>
																				</td>
																				<td>
																				{
																					//<Input type="select"  defaultValue='' name="key_evidence_status" id="key_evidence_status" invalid={this.state.KeyEvidenceStatusState==='has-danger'} onBlur={this.handleInput} >
																					//ke_edit_state_data[requirement.requirement_id]
																				}
																					<Input type="select" defaultValue={ke_edit_state_data[requirement.requirement_id]} name="key_evidence_status" id={requirement.requirement_id} onChange={this.handleInput} onBlur={this.handleInput} >
																						<option></option>
																						<option value='Positive'>Positive</option>
																						<option value='Negative'>Negative</option>
																					</Input>
																					<FormFeedback invalid>
																						  The fields are required and inter-dependent.
																					</FormFeedback>
																				</td>
																				<td>
																				{
																					//<Input type="textarea"  name="key_evidence_description" id="key_evidence_description" invalid={this.state.KeyEvidenceDescrState==='has-danger'} onBlur={this.handleInput} />
																				}
																					<Input type="textarea" defaultValue={ke_edit_description_data[requirement.requirement_id]} name="key_evidence_description" id={requirement.requirement_id} onChange={this.handleInput} onBlur={this.handleInput} />
																					<FormFeedback invalid>
																						  The fields are required and inter-dependent.
																					</FormFeedback>
																				</td>
																			</tr>
																		
																 
															)
															
														)
													 )
													}
												 </tbody>
													
													
													
												</Table>
												
												<FormGroup>
												  <Row>
													<Col xs={4} sm={4} md={4} lg={4} xl={4}>
													  <Label for="recommendation_description">MINOR RECOMMENDATION&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
													</Col>
													<Col xs={8} sm={8} md={8} lg={8} xl={8}>
													  <Input type="textarea"  name="recommendation_description" id="recommendation_description" defaultValue={this.state.recommendation_description} invalid={this.state.RecommendationIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
													  <FormFeedback invalid>
														  The field is required.
													 </FormFeedback>
																</Col>
															</Row>
														<Row>
															{/* add a file upload button field */}
															<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																<Label for="recommendation_description">UPLOAD&nbsp;</Label>
															</Col>
															<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																<FormGroup>
																	<Label for="exampleFile">File</Label>
																	<Input className="file-upload" multiple accept=".jpg, .png, .jpeg" type="file" name="file" id="file-upload" onInput={this.handleInput} onChange={this.handleInput} />
																	<FormText color="muted">
																		Upload a file
																	</FormText>
																</FormGroup>
															</Col>
														</Row>
															&nbsp;
															&nbsp;
															&nbsp;
															<Row>
																<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																	<Label for="recommendation_description">SEND NOTIFICATION TO&nbsp;</Label>
																</Col>
																<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																	<FormControl className="col-md-12" fullWidth>
																		<InputLabel id="demo-multiple-checkbox-label">Departments</InputLabel>
																		<Select
																			labelId="demo-multiple-checkbox-label"
																			id="demo-multiple-checkbox"
																			multiple
																			value={this.state.emails}
																			onChange={(e) => {
																				const {
																					target: { value },
																				} = e;
																				this.setState({
																					emails: typeof value === 'string' ? value.split(',') : value
																				})
																			}}
																			input={<OutlinedInput label="Select Departments" />}
																			renderValue={(selected) => selected.join(', ')}
																			MenuProps={{
																				PaperProps: {
																					style: {
																						maxHeight: 48 * 4.5 + 8,
																						width: 250,
																					},
																				},
																			}}
																		>
																			{this.state.deps.map((dep) => (
																				<MenuItem key={dep.short_name} value={dep.short_name}>
																					<Checkbox checked={this.state.emails.indexOf(dep.short_name) > -1} />
																					<ListItemText primary={dep.short_name} />
																				</MenuItem>
																			))}
																		</Select>
																	</FormControl>

																</Col>
															</Row>
												</FormGroup>
												
												
											</div>
										)
								}
								
								{
									//this.setState({requirement_id:Reqs, nes_level_id:Levels})
								}
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
				  
				  <hr />
				  <div className="container-fluid">
					  <Row>
						<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						  <div className="bg-success text-white"><center>REQUIREMENT ACHIEVEMENT PERCENTAGE</center></div>
							<table  className="table table-bordered table-condensed table-hover table-striped ">
								
								<thead>
									<tr>
										<th style={{width:'30%'}} className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">NES</th>
										<th style={{width:'5%'}} className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">LEVEL</th>
										<th style={{width:'15%'}} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">ACHIEVED</th>
										<th style={{width:'50%'}}className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">OUT OF</th>
										<th style={{width:'50%'}}className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">PERCENTAGE</th>
									</tr>
								</thead>
								<tbody>
								{	
								   RequirementAchieved.map(achievement=>(	
										<tr>
											<td>{achievement.nes_id}</td>
											<td>{achievement.nes_level_id}</td>
											<td>{achievement.achieved}</td>
											<td>{achievement.out_of}</td>
											<td>{achievement.percentage}</td>
										</tr>
										)
									)
										
								}
								</tbody>
							</table>
						</Col>

						<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						  <div className="bg-success text-white"><center>NES LEVEL ACHIEVEMENT</center></div>
							<Table responsive striped size="lg">
								<thead>
									<tr>
										<th>NES</th>
										<th>LEVEL ACHIEVED</th>
										<th>REACTION IMAGE</th>
									</tr>
								</thead>
								<tbody>
								{	
								   this.state.NesLevelAchieved.map(level=>(	
										<tr>
											<td>{level.nes_id}</td>
											<td>{level.level}</td>
											<td>
											{
												(level.level==1) && <center><img src={poor} className="img img-circle" alt="poor" width="30" height="30" /></center>
											}
											{
												(level.level==2) && <center><img src={good} className="img img-circle" alt="good" width="30" height="30" /></center>
											}
											{
												(level.level==3) && <center><img src={vgood} className="img img-circle" alt="vgood" width="30" height="30" /></center>
											}
											{
												(level.level==4) && <center><img src={excellent} className="img img-circle" alt="excellent" width="30" height="30" /></center>
											}
											
											</td>
										</tr>
										)
									)
										
								}
								</tbody>
								
								
							</Table>
						</Col>
						
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
						  <hr />
						  <div className="bg-success text-white"><center>NES ACHIEVEMENT GRAPH</center></div>
						  <hr />
							{!this.state.loading && <NesAchievementGraph visit_id={this.state.visit_id} />}
						  
						</Col>
					  </Row>
				 </div>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default KeyEvidence;