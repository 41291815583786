import axios from "axios"
import backend_url from "../routes/API_URL";
const API_URL = backend_url['api_url'];


const service = axios.create({
    baseURL: API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})


service.interceptors.request.use(
    config => {
        const token = window.sessionStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }
);

service.interceptors.response.use(
    response => {
        if (response.status == 401) {
            window.location.href = '/login'
        }
        return response
    }
);

export default service