import React, { useState, useEffect } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Table, TableBody, TableHead,Container, Row, FormFeedback, Media, Col,Modal, ModalHeader, ModalBody, ModalFooter,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import PDF, { Text, AddPage, Line, Image, Html } from 'jspdf-react';
import axios from 'axios';

import swal from 'sweetalert';



export default function KeyEvidenceStatusTable(props){
	const visit_id = props.visit_id;
	const nes_id = props.nes_id;
	const KeyEvidence = props.key_evidence;
	const MinorRecommendation = props.minor_recommendation;
	
	
	return(
	<div>
		<Table bordered striped responsive>
		   <thead>
				<tr>
					<th>Requirement</th>
					<th>Status</th>
					<th>Evidence</th>
				</tr>
		   </thead>
		   <tbody>
		   {
			   KeyEvidence.map((evidence, idx, array)=>(
							(evidence['visit_id']==visit_id && evidence['nes_id']==nes_id) ?
								(evidence['key_evidence_status']=='Positive')?
								 (
								  <tr>
									<td>
									{nes_id + "." + evidence['requirement_id'] + " " +  evidence['requirement_name']}
									</td>
									<td>+</td>
									<td>{evidence['key_evidence_description']}</td>
								 </tr>
								 )
								: 
								(
								<tr>
									<td>
									{nes_id + "." + evidence['requirement_id'] + " " +  evidence['requirement_name']}
									</td>
									<td>-</td>
									<td>{evidence['key_evidence_description']}</td>
								</tr>
								)	
							:
							''
					)
			)
		   }
		   <tr>
		     <td>
			 Recommendation for this NES:
			 </td>
			 <td colspan={2}>
				{
						MinorRecommendation.map(recommendation=>(
							(recommendation['nes_id']==nes_id && recommendation['visit_id']==visit_id)?recommendation['recommendation_description']:''
						)
					)
				}
			 </td>
		   </tr>
		   </tbody>
		</Table>
	</div>
	)
}