import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];


class DivisionAvgPerformanceGraph extends Component {
   constructor(props) {
    super(props);
	const division_id=this.props.division_id;
	const start_date = this.props.start_date;
	const end_date = this.props.end_date;
	
    this.state = {
      division_id:division_id,
	  GraphicalData:[],
	  division_name:'',
	  bearer_auth:window.sessionStorage.getItem('token'),
	  start_date: this.props.start_date,
	  end_date: this.props.end_date,
      size: props.size
    };

  }
  
  componentDidMount () {
	
	 Promise.all([axios.get(API_URL + `/key_evidences/perf/division/${this.state.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/divisions/${this.state.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([g_data, division_data])=>{
		 this.setState({
			 GraphicalData:g_data.data,
			 division_name:(division_data.data.length>=1)?division_data.data[0]['division_name']:'NULL DATA ON',
			 
		 })
		 
		 //console.log(this.state.division_name);
		 //console.log(division_data.data[0]['division_name']);
		 
	 }
	 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the graphical data. You should retry",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  
  componentDidUpdate (prevProps) {
	 
	 if(this.props.division_id != prevProps.division_id || this.props.start_date!=prevProps.start_date || this.props.end_date!=prevProps.end_date){
			Promise.all([axios.get(API_URL + `/key_evidences/perf/division/${this.props.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/divisions/${this.props.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([g_data,  division_data])=>{
				 this.setState({
					 GraphicalData:g_data.data,
					 division_id:this.props.division_id,
					 division_name:(division_data.data.length>=1)?division_data.data[0]['division_name']:'NULL DATA ON',
					 start_date:this.props.start_date,
					 end_date: this.props.end_date,
					 
				 })
				 
				 
			 }
			 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the graphical data. You should retry",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
  }
  
  
  render() {
    const {GraphicalData, Division} = this.state;
	//const label = [];
	//const serie = [];
	//console.log(graphical_data);
	
	//Find the frequency of elements in an array/object
	const entryFrequency = (obj) =>{
		const level_freq = {};
		
		obj.forEach(idx=>{
			if(level_freq[idx]){
				level_freq[idx]++;
			}else{
				level_freq[idx] = 1;
			}
		});
		
		return level_freq
	}
	
	const districts = [];
	const level_data = [];
	
	//Capture the unique division names. You may altrenatively use the set() object 
	for(let i=0;i<GraphicalData.length;i++){
		let formated_date= new Date(GraphicalData[i]['DATE']).toLocaleDateString();
		
		//Start of date processing for comparison below
		let splited_formated_date = formated_date.split("/");
		let splited_start_date = this.state.start_date.split("/");
		let splited_end_date = this.state.end_date.split("/");
		
		//Assignment based on primitive values
		formated_date = new Date(splited_formated_date[2],splited_formated_date[1],splited_formated_date[0]).getTime();
		let start_date = new Date(splited_start_date[2],splited_start_date[1],splited_start_date[0]).getTime();
		let end_date = new Date(splited_end_date[2],splited_end_date[1],splited_end_date[0]).getTime();
		
		if(i==0 && end_date>=start_date && (formated_date>=start_date && formated_date<=end_date)){
			districts.push(GraphicalData[i]['district_name']);
		}else if(i>=1 && districts.includes(GraphicalData[i]['district_name'])==false && end_date>=start_date && (formated_date>=start_date && formated_date<=end_date)){
			districts.push(GraphicalData[i]['district_name']);
		}else{
			continue;
		}
	}
	
	//console.log(division);
	
	let level_one_data = [];
	let level_two_data = [];
	let level_three_data =[];
	let level_four_data = [];
	
	//Default all level_*_data based on the length of the divisions
	
	for(let idx=0;idx<districts.length;idx++){
		level_one_data.push(0);
		level_two_data.push(0);
		level_three_data.push(0);
		level_four_data.push(0);
	}
	
	/**
	Come up a dynamic series' data
	**/
	//Loop through the divisions
	for(let m=0;m<districts.length;m++){
		
		const uncategorised_levels = [];
		//Loop through the data from the database 
		for(let n=0;n<GraphicalData.length; n++){
			let formated_date= new Date(GraphicalData[n]['DATE']).toLocaleDateString();
			
			//Start of date processing for comparison below
			let splited_formated_date = formated_date.split("/");
			let splited_start_date = this.state.start_date.split("/");
			let splited_end_date = this.state.end_date.split("/");
			
			//Assignment based on primitive values
			formated_date = new Date(splited_formated_date[2],splited_formated_date[1],splited_formated_date[0]).getTime();
			let start_date = new Date(splited_start_date[2],splited_start_date[1],splited_start_date[0]).getTime();
			let end_date = new Date(splited_end_date[2],splited_end_date[1],splited_end_date[0]).getTime();
			
			
			//First condition
			if(districts[m]==GraphicalData[n]['district_name'] && end_date>=start_date && (formated_date>=start_date && formated_date<=end_date)){
				uncategorised_levels.push(GraphicalData[n]['level_achieved']);
			}
		}
		
		const categorised_levels = entryFrequency(uncategorised_levels);
		let total_level_data = 0;
		
		for(const v in categorised_levels){
			total_level_data+=categorised_levels[v];
		}
	    
		
		//console.log(categorised_levels);
		//console.log(total_level_data);
		
		//const cat_length = categorised_levels.length;
		
		//length 
		let cat_length=0;
		
		for(const idx in categorised_levels){
			cat_length+=1;
		}
		
		//console.log(cat_length);
		//Map data to levels while balancing up the lengths
		for(const idx in categorised_levels){
			
			if(idx=='1'){
				level_one_data[m]=parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='2'){
				level_two_data[m]= parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='3'){
				level_three_data[m]=parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='4'){
				level_four_data[m] =parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}
			
			
			
		}
		
		
	}
	
	/* console.log("Level 1: ");
	console.log(level_one_data);
	
	console.log("Level 2: ");
	console.log(level_two_data);
	
	console.log("Level 3: ");
	console.log(level_three_data);
	
	console.log("Level 4: ");
	console.log(level_four_data); */
	
			
	const series = [{
	  name: 'Level 1',
	  data: level_one_data
	}, {
	  name: 'Level 2',
	  data: level_two_data
	}, {
	  name: 'Level 3',
	  data: level_three_data
	}, {
	  name: 'Level 4',
	  data: level_four_data
	},];
	
	
	
	const options = {
	  chart: {
		type: 'bar',
		height: 300,
		stacked: true,
		stackType: '100%'
	  },
	  dataLabels: {
		  enabled: false,
		  enabledOnSeries: undefined,
		  formatter: (value) => value.toFixed(2) +'%',
	  },
	  colors: ['#ff0000', '#0018ff', '#fffc00','#1bb60b'],
	  plotOptions: {
		bar: {
		  horizontal: false,
		},
	  },
	  stroke: {
		width: 1,
		colors: ['#fff']
	  },
	  title: {
		text: this.state.division_name + ' Division Level Achievements By District(s) From ' + this.state.start_date + "-" + this.state.end_date,
		align: 'center',
		margin: 10,
		offsetX: 0,
		offsetY: 0,
		floating: true,
		style: {
		fontSize:  '12px',
		fontWeight:  'bold',
		fontFamily:  undefined,
		color:  '#263238'
		}
	  },
	  xaxis: {
		categories: districts,
		title: {
				text: 'DISTRICT(S)'
			},
	  },
	  yaxis: {
		  forceNiceScale: false,
		  title: {
				text: 'PERCENTAGE OF SCHOOLS'
		  },
		  max: 100,
		  labels: {
			formatter: (value) => value.toFixed(0) +'%',
		  },
		},
	  tooltip: {
		y: {
		  formatter: (value) => value.toFixed(2) +'%',
		}
	  },
	  fill: {
		opacity: 1,
		colors: ['#ff0000', '#0018ff', '#fffc00','#1bb60b']
	  },
	  legend: {
		position: 'bottom',
	  }
	}
			
    return (
      <React.Fragment>
        <div className="colxs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
			<div className="card">
				<ReactApexChart options={options} series={series} type="bar" height="290" />
			</div>
		</div>
		
		
      </React.Fragment>
    );
  }
}

export default DivisionAvgPerformanceGraph;
