import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,Container, Row, Col,Badge,
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from "reactstrap";
import { Home, Notes, Report, Assessment, Assignment, AddComment, Place, Score,Settings, ShowChart, Create, School, List, PersonOutline} from '@material-ui/icons';

class MinistryHQMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const user =  sessionStorage.getItem("uname");
     const role =  sessionStorage.getItem("urole");
  
    return (
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">
                
                
            </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="text-success"><PersonOutline /></i>
				<span className="badge badge-pill badge-success float-right">
                  Online
                </span>
                <span>{user}</span>
              </Link>
            </li>
			<hr />
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="text-success"><Home /></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
			  <Link to="/#" className="has-arrow waves-effect">
				<i className="text-success"><Place /></i>
				<span>School Visit</span>
			  </Link>
			  <ul className="sub-menu" aria-expanded="false">
				<li>
				  <Link to="/inspection_visits"><i className="text-success"><Assessment /></i>Inspection Visits</Link>
				</li>
				{
					/*<li>
					  <Link to="/advisory_visits"><i className="text-success"><Assignment /></i>Advisory Visits</Link>
					</li> */
				}
				
			  </ul>
			</li>
			<li>
			  <Link to="/#" className="has-arrow waves-effect">
				<i className="text-success"><Assessment /></i>
				<span>Reports</span>
			  </Link>
			  <ul className="sub-menu" aria-expanded="false">
				<li>
				  <Link to="/perf_report"><i className="text-success"><Score /></i>Performance Report</Link>
				</li>
				<li>
				  <Link to="/progress_report"><i className="text-success"><ShowChart /></i>Progress Report</Link>
				</li>
				
				<li>
					  <Link to="/executive_summary"><i className="text-success"><Assignment /></i>Executive Summary</Link>
				</li>
				
			  </ul>
			</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="text-success"><Settings /></i>
                <span>Configurations</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/schools"><i className="text-success"><List /></i>Schools</Link>
                </li>
				<li>
                  <Link to="/zones"><i className="text-success"><List /></i>Zones</Link>
                </li>
				<li>
                  <Link to="/sectors"><i className="text-success"><List /></i>Sectors</Link>
                </li>
				<li>
                  <Link to="/districts"><i className="text-success"><List /></i>Districts</Link>
                </li>
				<li>
                  <Link to="/divisions"><i className="text-success"><List /></i>Divisions</Link>
                </li>
				<li>
                  <Link to="/nes_categories"><i className="text-success"><List /></i>NES Categories</Link>
                </li>
				<li>
                  <Link to="/nes_levels"><i className="text-success"><List /></i>NES Levels</Link>
                </li>
				<li>
                  <Link to="/nes_requirements"><i className="text-success"><List /></i>NES Requirements</Link>
                </li>
				<li>
                  <Link to="/national_standards"><i className="text-success"><List /></i>National Standards</Link>
                </li>
				<li>
                  <Link to="/visit_types"><i className="text-success"><List /></i>Visit Types</Link>
                </li>
				<li>
                  <Link to="/priorities"><i className="text-success"><List /></i>Priorities</Link>
                </li>
              </ul>
        </li>
		
            
            
          </ul>
        </div>
      );
  }
}

export default MinistryHQMenu;
