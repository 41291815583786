import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// Custom Scrollbar
import { Link } from "react-router-dom";

import axios from 'axios';
import Loader from "../../../components/Loader";

import InspectedSchools2 from "../Charts/Inspector/InspectedSchools2";
//import NationalPerformanceReport from "../Reports/NationalAvgPerformanceGraph";
//import NationalNesPerformanceReport from "../Reports/NationalNesAvgPerformanceGraph";
import NationalProgressGraph from "../Reports/NationalProgressGraph";

import "chartist/dist/scss/chartist.scss";
import { Grade, School, Apartment } from '@material-ui/icons';

import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';
import moment from 'moment'


const API_URL = backend_url['api_url'];
//const API_URL = "http://localhost:8000/api";

class InspectorDashboard extends Component {
	constructor(props) {
		super(props);
		//const visit_id=this.props.match.params.visit_id;
		this.state = {
			sector_id: sessionStorage.getItem('sector'),
			Sec_Data: [],
			Prim_Data: [],
			Schools: [],
			Zones: [],
			Clusters: [],
			Constituencies: [],
			Districts: [],
			Divisions: [],
			Standards: [],
			Requirements: [],
			Levels: [],
			bearer_auth: window.sessionStorage.getItem('token'),
			NesCategories: [],
			loading: false,
		};
	}


	componentDidMount() {

		this.setState({ loading: true });


		axios.get(API_URL + `/inspector/summary`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(
			({data}) => {
				this.setState({
					Visit_Data: data.total_visited,
					Prim_Data: data.total_primary,
					Sec_Data: data.total_secondary,
					Zones: data.total_szones,
					Clusters: data.total_clusters,
					Constituencies: data.total_constituency,
					Districts: data.total_district,
					Divisions: data.total_division,
					Standards: data.total_nes,
					Requirements: data.total_reqs,
					Levels: data.total_levs,
					NesCategories: data.total_cat,
					loading: false,
				})
			}
		).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "error",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: "Failed to load the dashboard data. We will refresh the page in seconds..",
					icon: "error",
					timer: 5000
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	render() {
		const { Prim_Data, Sec_Data, Zones, Clusters, Constituencies, Districts, Divisions, Standards, Requirements, Levels, NesCategories } = this.state;
		const data = this.state

		return (
			<React.Fragment>
				<div className="container-fluid">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>
					<Row className="align-items-center">
						<Col sm={6}>
							<div className="page-title-box">
								<h4 className="font-size-18">INSPECTION LEVEL</h4>
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/#">Dashboard</Link>
									</li>
									<li className="breadcrumb-item active">Visualization</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">

							</div>
						</Col>
					</Row>
					<Row hidden>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>
									<h6 className="card-title mb-4">QUICK LINKS</h6>
									<hr className="bg-success" />
									<Row className="text-white">
										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/divisions">
												<Card className="bg-success">
													<CardBody>
														<center>Divisions</center>
													</CardBody>
												</Card>
											</Link>

										</Col>

										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/districts">
												<Card className="bg-success">
													<CardBody>
														<center>Districts</center>
													</CardBody>
												</Card>
											</Link>
										</Col>
										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/constituencies">
												<Card className="bg-success">
													<CardBody>
														<center>Constituencies</center>
													</CardBody>
												</Card>
											</Link>
										</Col>
										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/zones">
												<Card className="bg-success">
													<CardBody>
														<center>{this.state.sector_id == 1 ? 'Zone' : 'Cluster'}</center>
													</CardBody>
												</Card>
											</Link>

										</Col>




										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/schools">
												<Card className="bg-success">
													<CardBody>
														<center>Schools</center>
													</CardBody>
												</Card>
											</Link>

										</Col>

										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/nes_categories">
												<Card className="bg-success">
													<CardBody>
														<center>NES Categories</center>
													</CardBody>
												</Card>
											</Link>

										</Col>




									</Row>
									<hr className="bg-success" />
									<Row className="text-white">
										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/national_standards">
												<Card className="bg-success">
													<CardBody>
														<center>Education Standards</center>
													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/nes_requirements">
												<Card className="bg-success">
													<CardBody>
														<center>NES Requirements</center>
													</CardBody>
												</Card>
											</Link>
										</Col>
										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/school_visits">
												<Card className="bg-success">
													<CardBody>
														<center>Inspection Visits</center>
													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/advisory_visits">
												<Card className="bg-success">
													<CardBody>
														<center>Advisory Visits</center>
													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/perf_report">
												<Card className="bg-success">
													<CardBody>
														<center>Performance Graph(s)</center>
													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={2} lg={2} xl={2}>
											<Link className="text-white" to="/progress_report">
												<Card className="bg-success">
													<CardBody>
														<center>Progress Report</center>
													</CardBody>
												</Card>
											</Link>
										</Col>


									</Row>



								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row >
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>
									<h6 className="card-title mb-4">QUICK STATISTICS</h6>
									<hr className="bg-success" />
									<Row className="text-white">

										{this.state.sector_id == 1 ? (
											<Col xs={12} sm={12} md={6} lg={6} xl={6}>
												<Link className="text-success" to="/schools">
													<Card className="border border-success">
														<CardBody>
															<span className="float-left">Primary Schools</span>
															<span className="float-right"><School fontSize="large" /></span>
															<br />
															<hr />
															<center><span fontSize="large"><strong><h2>{Prim_Data}</h2></strong></span></center>
														</CardBody>
													</Card>
												</Link>

											</Col>
										) : (


											<Col xs={12} sm={12} md={6} lg={6} xl={6}>
												<Link className="text-success" to="/schools">
													<Card className="border border-success">
														<CardBody>
															<span className="float-left">Secondary Schools</span>
															<span className="float-right"><School fontSize="large" /></span>
															<br />
															<hr />
															<center><span fontSize="large"><strong><h2>{Sec_Data}</h2></strong></span></center>

														</CardBody>
													</Card>
												</Link>

											</Col>
										)}
										{this.state.sector_id == 1 ? (
											<Col xs={12} sm={12} md={6} lg={6} xl={6}>
												<Link className="text-success" to="/zones">
													<Card className="border border-success">
														<CardBody>
															<span className="float-left">Zones</span>
															<span className="float-right"><Apartment fontSize="large" /></span>
															<br />
															<hr />
															<center><span fontSize="large"><strong><h2>{Zones}</h2></strong></span></center>

														</CardBody>
													</Card>
												</Link>

											</Col>
										) : (
											<Col xs={12} sm={12} md={6} lg={6} xl={6}>
												<Link className="text-success" to="/zones">
													<Card className="border border-success">
														<CardBody>
															<span className="float-left">Clusters</span>
															<span className="float-right"><Apartment fontSize="large" /></span>
															<br />
															<hr />
															<center><span fontSize="large"><strong><h2>{Clusters}</h2></strong></span></center>

														</CardBody>
													</Card>
												</Link>

											</Col>
										)}
									</Row>
									<hr className="bg-success" />
									<Row hidden className="text-white">
										<Col xs={12} sm={12} md={4} lg={4} xl={4}>
											<Link className="text-success" to="/constituencies">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left"> Constituencies</span>
														<span className="float-right"><Apartment fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Constituencies}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={4} lg={4} xl={4}>
											<Link className="text-success" to="/districts">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Districts</span>
														<span className="float-right"><Grade fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Districts}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={4} lg={4} xl={4}>
											<Link className="text-success" to="/divisions">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Divisions</span>
														<span className="float-right"><Grade fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Divisions}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>
										</Col>


									</Row>



								</CardBody>
							</Card>
						</Col>
					</Row>


					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>
									{this.state.Visit_Data && this.state.Prim_Data != [] && < InspectedSchools2 summary={data} /> }
								</CardBody>
							</Card>
						</Col>


					</Row>



					<Row>
						{
							/* <NationalPerformanceReport />
							
							<NationalNesPerformanceReport /> */
						}

					</Row>

					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>


									<NationalProgressGraph start={moment().subtract(2, 'years').startOf('year').format("YYYY-MM-DD")} end={moment().endOf('year').format("YYYY-MM-DD")} />

								</CardBody>
							</Card>
						</Col>


					</Row>

				</div>
			</React.Fragment>
		);
	}
}

export default InspectorDashboard;
