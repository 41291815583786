import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import {Table, TableBody, TableHead,Container, Row, FormFeedback, Media, Col,Modal, ModalHeader, ModalBody, ModalFooter,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import swal from 'sweetalert';

import backend_url from "../../../../routes/API_URL";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";

class Ghantt extends Component {
    constructor(props) {
        super(props);
		//const visit_id=this.props.visit_id;
		const recommendation_id=this.props.recommendation_id;
		
		this.state = {
		  ActionPlanData:[],
		  recommendation_id: recommendation_id,
		  bearer_auth:window.sessionStorage.getItem('token'),
		};
		
		

        /*
		this.state = {
          
            series: [
              {
                name: 'Pitson',
				data: [
                  {
                    x: 'Coding',
                    y: [
                      new Date('2019-03-02').getTime(),
                      new Date('2019-03-04').getTime()
                    ]
                  },
                  {
                    x: 'Testing',
                    y: [
                      new Date('2019-03-04').getTime(),
                      new Date('2019-03-08').getTime()
                    ]
                  },
                  {
                    x: 'Validation',
                    y: [
                      new Date('2019-03-08').getTime(),
                      new Date('2019-03-12').getTime()
                    ]
                  },
                  {
                    x: 'Deployment',
                    y: [
                      new Date('2019-03-12').getTime(),
                      new Date('2019-03-18').getTime()
                    ]
                  }
                ]
              }
            ],
            options: {
              chart: {
                height: 350,
                type: 'rangeBar'
              },
              plotOptions: {
                bar: {
                  horizontal: true
                }
              },
              xaxis: {
                type: 'datetime'
              }
            },
          
          
          }; */
		  
    }
	
	componentDidMount () {
		 
		 Promise.all([axios.get(API_URL + `/action_plans/by_recommendation/${this.state.recommendation_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		 ([action_plan])=>{
			 this.setState({
				 ActionPlanData:action_plan.data,
			 })
		 }
		 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
	}
	
	componentDidUpdate (props) {
		 
		 if(this.state.recommendation_id != this.props.recommendation_id){
			 Promise.all([axios.get(API_URL + `/action_plans/by_recommendation/${this.props.recommendation_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([action_plan])=>{
				 this.setState({
					 ActionPlanData:action_plan.data,
					 recommendation_id: this.props.recommendation_id,
				 })
			 }
			 ).catch(error => {
			  swal({
				  title: "",
				  text: error.name + " : " + error.message + ". For security reasons we're logging you out.",
				  icon: "error",
				  timer : 1250
				})
				.then((sucess) => {
					window.sessionStorage.clear();
					window.location.reload();
				});
          })
		}
	}
    render() {
        const data = [];
	
		const ActionPlans = this.state.ActionPlanData;
		
	    const data_label = {};
		
		//console.log(ActionPlans);
		for(let i=0;i<ActionPlans.length;i++){
			
			//Set the fill color based on the activity status
			let fill_color = '';
			
			if(ActionPlans[i]['activity_status']=="Open"){
				fill_color = '#008FFB';
				data_label[ActionPlans[i]['activity_name']] = "Active";
			}else if(ActionPlans[i]['activity_status'] == 'Completed'){
				fill_color = '#00E396';
				data_label[ActionPlans[i]['activity_name']] = "Completed";
			}else if(ActionPlans[i]['activity_status'] == 'Partially Completed'){
				fill_color = '#FEB019';
				data_label[ActionPlans[i]['activity_name']] = "Partially Competed";
			}else if(ActionPlans[i]['activity_status'] == 'Closed'){
				fill_color = '#FF4560';
				data_label[ActionPlans[i]['activity_name']] = "Closed";
			}
			
			
			const data_object = {
                    x: ActionPlans[i]['activity_name'],
                    y: [
                      new Date(ActionPlans[i]['activity_start_date']).getTime(),
                      new Date(ActionPlans[i]['activity_finish_date']).getTime()
                    ],
					fillColor: fill_color,
                  };
			
			data.push(data_object);
		}
		
		
		//console.log(data_label);
		
		const series = [
              {
                name: 'Activity Timeframe',
				data: data,
              }
            ];
			
        const options = {
              //colors: ['#ef0000', '#3c4ccf', '#02a499'],
              //colors: ['#efac51','#84674','#cbec0d','#798a16','#080d4f','#e10e41','#76525b','#4e3239','#2ec487','#13271f','#7f49a9','#4c0f7c','#af8ccb','#300750','#eb26af','#2ecc71','#943126','#f8c471','#7d3c98','#fdedec','#aed6f1'],
			  chart: {
                height: 350,
                type: 'rangeBar'
              },
              plotOptions: {
                bar: {
                  horizontal: true
                }
              },
              xaxis: {
                type: 'datetime'
              },
			  dataLabels: {
                enabled: true,
                formatter: function(val, opts) {
                  var label = opts.w.globals.labels[opts.dataPointIndex]
				  //var a = moment(val[0])
                  //var b = moment(val[1])
                  //var diff = b.diff(a, 'days')
                  return label;
                },
                style: {
                  colors: ['#f3f4f5', '#fff']
                }
              },
              grid: {
                row: {
                  colors: ['#f3f4f5', '#fff'],
                  opacity: 1
                }
              }
            };
		
		return (
            <React.Fragment>
                
				<ReactApexChart options={options} series={series} type="rangeBar" height={350} />
				<hr />
				
				<p>
				    <strong><em>KEY COLOR</em></strong>
					<br />
					<span className="badge badge-primary">
					  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					  &nbsp;&nbsp;: <em>Open Activity</em>
					 &nbsp;&nbsp;
					 <span className="badge badge-success">
					  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					 &nbsp;&nbsp;:  <em>Completed Activity</em>
					 &nbsp;&nbsp;
					<span className="badge badge-warning">
					   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					 &nbsp;&nbsp;:  <em>Partially Completed Activity</em>
					&nbsp;&nbsp;
					<span className="badge badge-danger">
					  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					 &nbsp;&nbsp;:  <em>Closed Activity</em>
				</p>
				
            </React.Fragment>
        );
    }
}

export default Ghantt;