import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import { Add, Edit, Delete } from '@material-ui/icons';
import swal from 'sweetalert';

import backend_url from "../../../routes/API_URL";

import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class Priorities extends Component {
	constructor(props) {
		super(props);
		this.state = {

			modalVisible: false,
			EditModalVisible: false,
			priority_id: '',
			priority_description: '',
			PriorityIdState: '',
			PriorityDescrState: '',
			bearer_auth: window.sessionStorage.getItem('token'),
			role: window.sessionStorage.getItem('urole'),
			Priorities: [],
			loading: '',
		};

		//Toggle modal
		this.toggle = this.toggle.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.addPriorities = this.addPriorities.bind(this);
		this.editPriorities = this.editPriorities.bind(this);
		this.updatePriorities = this.updatePriorities.bind(this);
		this.deletePriorities = this.deletePriorities.bind(this);
		;

		this.setId = this.setId.bind(this);
	}

	//Handle the display of the modal
	toggle() {
		if (this.state.modalVisible == false) {
			this.setState({ modalVisible: true });
		} else {
			this.setState({
				modalVisible: false,
				priority_id: '',
				priority_description: '',
				PriorityIdState: '',
				PriorityDescrState: '',
			});
		}
	}
	toggleEdit() {
		if (this.state.EditModalVisible == false) {
			this.setState({ EditModalVisiblet: true });
		} else {
			this.setState({
				EditModalVisible: false,
				priority_id: '',
				priority_description: '',
				PriorityIdState: '',
				PriorityDescrState: '',
			});
		}
	}

	//Setting Edit ID
	setId(e) {
		this.setState({
			[e.target.id]: e.target.value
		});
	}

	//Edit Zones 
	editPriorities(id) {

		//this.setState({loading : true});
		//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/priorities/edit/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			//console.log(response.data[0]);
			this.setState({
				id: response.data[0].priority_id,
				priority_description: response.data[0].priority_description,
				EditModalVisible: true,
				//loading: false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					button: false,
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					button: false,
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",

				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	//Update 
	updatePriorities(event) {
		event.preventDefault()

		const { history } = this.props
		const id = this.state.id

		const priority = {
			priority_description: this.state.priority_description,

		}

		//this.setState({loading : true});

		//console.log(user);
		//axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.patch(API_URL + `/priorities/update/${id}`, priority, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({
					EditModalVisible: false,
					//loading: false,
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.data['status'] == 'success') {
					this.setState({
						EditModalVisible: false,
						//loading : false,
					});
					swal({
						button: false,
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						timer: 2500
					})
						.then((sucess) => {
							Promise.all([axios.get(API_URL + `/priorities`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
								([priority]) => {
									this.setState({
										Priorities: priority.data,
										loading: false,
									})
								})
						});
				} else {
					this.setState({
						EditModalVisible: true,
					});
					swal({
						button: false,
						title: "",
						text: response.data['message'],
						icon: response.data['status'],

					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
			.catch(error => {
				this.setState({
					EditModalVisible: false,
					//loading: false,
				});
				if (error.response.status == '401') {
					swal({
						button: false,
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						button: false,
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",

					})
						.then((sucess) => {
							this.setState({ EditModalVisible: true });
						});
				}
			})
	}

	//delete
	deletePriorities(id) {
		//Confirming deletion
		swal({
			title: "",
			text: "Are you sure to delete the priority?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				confirm: {
					text: "Delete",
					value: true,
					visible: true,
					className: "",
					closeModal: true
				},
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: "",
					closeModal: true,
				}
			}
		})
			.then((willDelete) => {
				//Confirmed condition
				if (willDelete) {
					//this.setState({loading : true});
					//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
					axios.delete(API_URL + `/priorities/delete/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then((response) => {
						//this.setState({loading : false});
						swal({
							button: false,
							title: "",
							text: "The priority record Deleted Successfully!",
							icon: "success",

						})
							.then((sucess) => {
								Promise.all([axios.get(API_URL + `/priorities`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
									([priority]) => {
										this.setState({
											Priorities: priority.data,
											loading: false,
										})
									})
							});
					}).catch(error => {
						if (error.response.status == '401') {
							swal({
								button: false,
								title: "",
								text: "Your session has expired. You'll automatically be logged out within seconds.",
								icon: "warning",
								timer: 2000
							})
								.then((sucess) => {
									window.sessionStorage.clear();
									window.location.reload();
								});
						} else {
							swal({
								button: false,
								title: "",
								text: error.name + ': ' + error.message,
								icon: "error",

							})
								.then((sucess) => {
									//window.location.reload();
								});
						}
					})
					//unconfirmed condition
				} else {
					//window.location.reload();
				}
			});
	}
	componentDidMount() {

		this.setState({ loading: true });
		//Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		//Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
		Promise.all([axios.get(API_URL + `/priorities`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
			([priority]) => {
				this.setState({
					Priorities: priority.data,
					loading: false,
				})
			}
		).catch(error => {
			this.setState({ loading: false });
			if (error.response.status == '401') {
				swal({
					button: false,
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					button: false,
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",

				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	//Add user
	addPriorities(event) {
		event.preventDefault()

		const { history } = this.props

		const priority = {
			priority_id: this.state.priority_id,
			priority_description: this.state.priority_description,

		}

		//this.setState({loading : true});
		//console.log(user);
		//console.log(exam);
		//axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.post(API_URL + '/priorities', priority, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({
					modalVisible: false,
					//loading: false,
				});

				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.data['status'] == 'success') {
					this.setState({
						modalVisible: false,
						//loading : false,
					});
					swal({
						button: false,
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						timer: 2500,
						button: false
					})
						.then((sucess) => {
							Promise.all([axios.get(API_URL + `/priorities`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
								([priority]) => {
									this.setState({
										Priorities: priority.data,
										loading: false,
									})
								})
						});
				} else {
					this.setState({
						modalVisible: true,
					});
					swal({
						button: false,
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						button: true
					})
						.then((sucess) => {
							Promise.all([axios.get(API_URL + `/priorities`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
								([priority]) => {
									this.setState({
										Priorities: priority.data,
										loading: false,
									})
								})

						});
				}
			})
			.catch(error => {
				this.setState({
					modalVisible: true,
					//loading: false,
				});
				if (error.response.status == '401') {
					swal({
						button: false,
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						button: false,
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",

					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
	}


	//Form inputs handler and validator
	handleInput(e) {
		e.preventDefault();
		if (e.target.name === 'priority_id') {
			if (e.target.value != '') {
				this.setState({ PriorityIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ PriorityIdState: 'has-danger' });
			}

		} else if (e.target.name === 'priority_description') {
			if (e.target.value != '') {
				this.setState({ PriorityDescrState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ PriorityDescrState: 'has-danger' });
			}

		}


	}

	render() {
		const { Priorities } = this.state;
		const data = {
			columns: [
				{
					title: 'Priority Id',
					field: 'priority_id'
				},

				{
					title: 'Description',
					field: 'priority_description'
				},

				{
					title: 'Actions',
					field: 'OPTIONS',
					export: false,
				},
			],

		};

		return (
			<React.Fragment>
				<div className="container-fluid">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>

					<Row className="align-items-center">
						<Col sm={6}>
							<div className="page-title-box">
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>

									<li className="breadcrumb-item active">priorities</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">

							</div>
						</Col>
					</Row>

					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<h3 className="card-title">Priority Management</h3>
									<hr />
									<Button color="success" hidden={(this.state.role == 'System Administrator') ? false : true} onClick={this.toggle}> {<Add />} New Priority</Button>
									<hr />

									{
										//Add user modal

										/*
											NOTE the usage of the onChange and onBlur event to call the same function.
											The onBlur event has been used to enforce validation once the user move out of the field.
										*/
									}
									<Modal isOpen={this.state.modalVisible}>
										<Form onSubmit={this.addPriorities} >
											<ModalHeader toggle={this.toggle}>
												<center className="justify-content-center">PRIORITY ADDITION FORM</center>
											</ModalHeader>
											<ModalBody>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="priority_id">Priority ID&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="number" name="priority_id" id="priority_id" invalid={this.state.PriorityIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="priority_description">Priority Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="text" name="priority_description" id="priority_description" invalid={this.state.PriorityDescrState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>



											</ModalBody>
											<ModalFooter>
												<Button type="submit" color="success">Create</Button>
												<Button color="danger" onClick={this.toggle}>Cancel</Button>
											</ModalFooter>
										</Form>
									</Modal>


									<Table responsive striped size="lg">
										<MaterialTable
											title="PRIORITY LEVELS IN MALAWI"
											columns={data.columns}
											data={
												Priorities.map(priority => (
													{

														priority_id: priority.priority_id,
														priority_description: priority.priority_description,
														'OPTIONS': <pre><Link to={`/priorities`} className={(this.state.role == 'System Administrator') ? "btn" : "btn disabled"} onClick={() => this.editPriorities(priority.priority_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;
															<Link to={`/priorities`} className={(this.state.role == 'System Administrator') ? "btn" : "btn disabled"} onClick={() => this.deletePriorities(priority.priority_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
													}
												))}

											options={{
												exportButton: true,
												tableLayout: 'auto',
												loadingType: 'overlay',
												pageSizeOptions: [5, 20, 50, 100, 500, 1000],
												emptyRowsWhenPaging: false,
												columnsButton: true,
												exportAllData: true,
												actionsColumnIndex: -1,
												search: true,
												sorting: true
											}} />

									</Table>

									{
										//Edit user record modal
									}
									<Modal isOpen={this.state.EditModalVisible}>
										<Form onSubmit={this.updatePriorities} >
											<ModalHeader toggle={this.toggleEdit}>
												<center className="justify-content-center"> PRIORITY'S EDITING FORM</center>
											</ModalHeader>
											<ModalBody>
												<FormGroup>
													<Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
												</FormGroup>

												{
													/*
													  <FormGroup>
														<Row>
														  <Col xs='auto' sm={4} md={4} lg={4} xl={4}>
															<Label for="sector_id">Sector ID</Label>					 
														  </Col>
														  <Col xs='auto' sm={8} md={8} lg={8} xl={8}>
															<Input type="number" value={this.state.sector_id} name="sector_id" id="sector_id" onChange={this.handleInput} />
														  </Col>
														</Row>
													  </FormGroup>
													*/
												}
												<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="priority_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="text" value={this.state.priority_description} name="priority_description" id="priority_description" invalid={this.state.PriorityDescrState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
															<FormFeedback invalid>
																The field is required.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>


											</ModalBody>
											<ModalFooter>
												<Button type="submit" color="success">Update</Button>
												<Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
											</ModalFooter>
										</Form>
									</Modal>

								</div>
							</div>
						</div>
					</div>


				</div>
			</React.Fragment>
		);
	}
}

export default Priorities;
