import React from 'react';
import { Spinner } from 'reactstrap';

/**
 * Renders the preloader
 */
const Loader = () => {
    return (
        <div className="preloader" style={{position:"fixed"}}>
            <div className="status">
                <Spinner size="lg" color={"success"} style={{ width: "5rem", height: "5rem" }} />
            </div>
        </div>
    );
};

export default Loader;
