import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import {Container, Row, Card, CardBody, Alert, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Avatar from '@material-ui/core/Avatar';
import { connect } from "react-redux";

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';


import Loader from "../../../components/Loader";
// actions

// import images
import logo from "../../../assets/images/gov-logo.png";

import backend_url from "../../../routes/API_URL";

const API_URL = backend_url['api_url'];



class ForgetPassword extends Component {
  constructor(props) {
        super(props);
		
		this.state = {
			ModalVisible:true,
			email_id : '',
			EmailState:'',
			is_loading:false,
			is_success:false,
			error:false,
			
        };
		
        this.toggleModal = this.toggleModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.emailLink = this.emailLink.bind(this);
		
    }

	toggleModal(){
		if(this.state.ModalVisible==false)
		{
		 this.setState({ModalVisible:true});
		}else{
		 this.setState({ModalVisible:false}); 
		}
  }
  
  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
      if(e.target.name==='email_id'){
		   const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			//const { validate } = this.state
			if (emailRex.test(e.target.value)) {
				this.setState({EmailState :'has-success',[e.target.id]: e.target.value});
			} else {
				this.setState({EmailState : 'has-danger'});
			}
	   }
        
  }
	
   //updating password
   emailLink(event){
		event.preventDefault()

        const { history } = this.props;
        
		const id = this.state.id;
		
        const user = {
		  email_id : this.state.email_id,
        }
		
		this.setState({is_loading : true});
		
		//console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + `/guest/reset_details`, user)
          .then(response => {
            this.setState({ModalVisible:false, is_loading:false, is_success:true,error:false});
          })
          .catch(error => {
            this.setState({
				ModalVisible:false, 
				error : true,
				is_loading:false,
				is_success:false,
				
				});
          }) 
	 }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-danger">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          EMAILING FORM
                        </h5>
                        <Link to="/" className="logo logo-admin">
                          <img src={logo} height="45" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <Form onSubmit={this.emailLink} >
							{this.state.is_loading ? <Loader /> : null}
							
						  <ModalBody>
                                {this.state.error ? (
									<Alert color="danger"><center>Failed to initiate password reset. Retry or use a registered email..</center></Alert>
								  ) : null}
								  
								  {this.state.is_success ? (
									<Alert color="success"><center>Password reset link sent successfully. Check you email inbox..</center></Alert>
								  ) : null}
								<FormGroup>
                                  <Row>
                                    
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Input type="email" className="form-control" name="email_id" id="email_id" invalid={this.state.EmailState==='has-danger'} onChange={(e)=>{this.handleInput(e); }} placeholder='Email' required/>
                                       <FormFeedback invalid>
										  Enter a valid email address as the field cannot be empty
									   </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="primary">Send me the link</Button>
						  </ModalFooter>
						  
							<Row className="form-group mt-2 mb-0">
									<div className="col-12 mt-4">
									  
									  <center>
											<Link to="/login">
												Go to Login
											</Link>
									  </center>
									  
									</div>
							</Row>

                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgetPassword;
