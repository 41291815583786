import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../../components/Loader";
// actions
import { loginUser } from "../../../store/actions";

// import images
import logo from "../../../assets/images/gov-logo.png";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  {this.props.loading ? <Loader /> : null}

                  <Card className="overflow-hidden">
                    <div className="bg-danger">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          SIGN IN TO SIIS
                        </h5>
                        <Link to="/" className="logo logo-admin">
                          <img src={logo} height="45" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.props.error ? (
                            <Alert color="danger"><center>Invalid Email or Password!!</center></Alert>
                          ) : null}
							
                          <div className="form-group">
                            <AvField
                              name="user_email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              placeholder="Enter Password"
                            />
                          </div>

                          <Row className="form-group">
                            <Col sm={6}>
                              &nbsp;
                              {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                            </Col>
                            <Col sm={6} className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </Col>
                          </Row>
							<Row className="form-group mt-2 mb-0">
									<div className="col-12 mt-4">
									  
									  <center>
											<Link to="/email">
												Forgot your password?
											</Link>
									  </center>
									  
									</div>
							</Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                {
					/*
						<div className="mt-5 text-center">
						  <p>
							Incase you are not a regisered user, please contact the system administrator ?{" "}
							<Link
							  to="pages-register"
							  className="font-weight-medium text-primary"
							>
							  {" "}
							  Signup now{" "}
							</Link>{" "}
						  </p>
						  <p className="mb-0">
							© {new Date().getFullYear()} Veltrix. Crafted with{" "}
							<i className="mdi mdi-heart text-danger"></i> by Themesbrand
						  </p>
						</div>
					
					*/
				}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(connect(mapStatetoProps, { loginUser })(Login));
