import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import {authProtectedRoutes, publicRoutes} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";


// Import scss
import "./assets/scss/theme.scss";


// Import Firebase Configuration file
//import { initFirebaseBackend } from "./helpers/authUtils";
import AuthService from './services/AuthService';

//import checkActionPlanStatus from './pages/common/ActionPlans/Notification';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout;
        //layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  

  render() {
    const Layout = this.getLayout();
	
	//Get user role and make redirections accordingly
	//const role =  sessionStorage.getItem("urole")
	
    return (
      <React.Fragment>
        <Router>
		  
          <Switch>
            {publicRoutes.map((route, idx) => (
				  <AppRoute
					path={route.path}
					layout={NonAuthLayout}
					component={route.component}
					key={idx}
					isAuthProtected={false}
				  />
				))
			 }
			
            
          {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))
          }
		  
		  
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
