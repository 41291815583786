import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages

//Part one for admin user
import Login from "../pages/admin/Authentication/Login";
import FogetPassword from "../pages/admin/Authentication/FogetPassword";
import ResetPassword from "../pages/admin/Authentication/ResetPassword";
import Logout from "../pages/admin/Authentication/Logout";

//Common components 
import Dashboard from "../pages/common/Dashboard/index"; 
import Users from "../pages/admin/Users/index";
import AuditTrails from "../pages/admin/AuditTrails/index";
import ErrorLogs from "../pages/admin/ErrorLogs/index";
import PasswordResetModal from "../pages/admin/Users/PasswordResetModal";

//Components for the inspector, Division manager, Cluster Manager,PEA, Ministry HQ, HeadTeacher and PTA with filters 
import InspectionVisits from "../pages/common/Visits/index";
import InpectionFollowup from "../pages/common/Visits/InpectionFollowup";
import ControlPanel from "../pages/common/Visits/ControlPanel";
import GoodPractices from "../pages/common/GoodPractices/index";
import Strengths from "../pages/common/Strengths/index";
import Weaknesses from "../pages/common/Weaknesses/index";
import CriticalIssues from "../pages/common/CriticalIssues/index";
import Recommendations from "../pages/common/Recommendations/index";
import ActionPlans from "../pages/common/ActionPlans/index";
import KeyEvidence from "../pages/common/KeyEvidence/index";
import InspectionGantt from "../pages/common/InspectorGantt/index";
import LevelAchieved from "../pages/common/LevelAchieved/index";
import PerformanceReport from "../pages/common/Reports/index";
import ProgressGraph from "../pages/common/Reports/ProgressGraph";
import InspectionPerformanceReport  from "../pages/common/Reports/InspectionPerformanceReport";
import FilterGhanttChart from "../pages/common/ActionPlans/FilterGhanttChart";
import ExecutiveSummary from "../pages/common/Reports/ExecutiveSummary";
import ExecutiveSummaryReport from "../pages/common/Reports/ExecutiveSummaryReport";
import InspectionFollowupReport from '../pages/common/Reports/InspectionFollowupReport'

import DepartmentsPage from "../pages/admin/Departments"


import Schools from "../pages/common/Schools/index";
import Constituencies from "../pages/common/Constituencies/index";
import Sectors from "../pages/common/Sectors/index";
import Zones from "../pages/common/Zones/index";
import Districts from "../pages/common/Districts/index";
import Divisions from "../pages/common/Divisions/index";

import NESCategories from "../pages/common/NESCategories/index";
import NationalStandards from "../pages/common/NationalStandards/index";
import VisitTypes from "../pages/common/VisitTypes/index";
import NESLevels from "../pages/common/NESLevels/index";
import NESRequirements from "../pages/common/NESRequirements/index";
import Priorities from "../pages/common/Priorities/index";


//Inspector 


//MoeST HQ

//Primary Pupil
import PupilDashboard from "../pages/pupil/Dashboard";

//Secondary Students 
import StudentDashboard from "../pages/student/Dashboard";

//Guardian 
import GuardianDashboard from "../pages/guardian/Dashboard";

//PTA
import PTADashboard from "../pages/pta/Dashboard";





const authProtectedRoutes = [
 
  
  
  //Common Routes
    { path: "/dashboard", component: Dashboard },
  
  //Special admin routes 
  { path: "/departments", component: DepartmentsPage },
  { path: "/users", component: Users },
  { path: "/followup-report/:id/:followupid", component: InspectionFollowupReport },
  { path: "/pword_change", component: PasswordResetModal },
  { path: "/audits", component: AuditTrails },
  { path: "/logs", component: ErrorLogs },
  
  
  
  //Special Teacher routes 
  
  
  //Special Head Teacher/Deputy Head Routes 
  
  
  //Special PEA routes
  { path: "/activity_progress_filter", component: FilterGhanttChart},
  
  //Special Cluster Manager routes 
  
  
  //Special DEM routes 
  
  
  //Special routes for the division manager
  
  
  //Special routes for the inspector 
  { path: "/inspection_visits", component: InspectionVisits},
  { path: "/followup_visits", component: InpectionFollowup},
  { path: "/inspection_panel/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: ControlPanel},
  { path: "/good_practices/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: GoodPractices},
  { path: "/strengths/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: Strengths},
  { path: "/weaknesses/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: Weaknesses},
  { path: "/critical_issues/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: CriticalIssues},
  { path: "/recommendations/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: Recommendations},
  { path: "/action_plans/:visit_id&:recommendation_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: ActionPlans},
  { path: "/key_evidences/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: KeyEvidence},
  { path: "/level_achieved/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: LevelAchieved},
  { path: "/perf_report", component: PerformanceReport},
  { path: "/inspection_action_plans", component: InspectionGantt},
  { path: "/performance_reports/:visit_id&:isExpired&:logged_user&:lead_inspector&:first_inspector", component: InspectionPerformanceReport},
  { path: "/progress_report", component: ProgressGraph},
  { path: "/executive_summary", component: ExecutiveSummaryReport},
  
  //Special routes for the Ministry HQ 
  { path: "/divisions", component: Divisions },
  { path: "/districts", component: Districts },
  { path: "/zones", component: Zones },
  { path: "/sectors", component: Sectors },
  { path: "/schools", component: Schools },
  { path: "/constituencies", component: Constituencies },
  { path: "/nes_categories", component: NESCategories },
  { path: "/national_standards", component: NationalStandards },
  { path: "/visit_types", component: VisitTypes },
  { path: "/nes_levels", component:  NESLevels },
  { path: "/nes_requirements", component:  NESRequirements },
  { path: "/priorities", component:  Priorities },
  
  //Special routes for a primary school pupil 
  
  
  //Special routes for the secondary school student 
  
  //Special Routes for the Guardian 
  
  //Special routes for the PTA committee
  
  
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
  
];



const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/email", component: FogetPassword },
  { path: "/reset_password/:id&:email", component: ResetPassword },
  
];



export { authProtectedRoutes, publicRoutes };
