import React from 'react';
import logo from "../../assets/images/gov-logo.png";

const Footer = () => {

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  col-xl-12">
              © {new Date().getFullYear()} Ministry of Education, Science and Technology<span className="d-none d-sm-inline-block">. Designed and Maintained By <a href='https://www.technixmw.com'>TechNix Malawi</a>.</span>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;