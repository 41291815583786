import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,Container, Row, Col,Badge,
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from "reactstrap";

import { Home, Notes, Report, Assessment, Assignment, People,AddComment, Place, Score,Settings, Details, List, ShowChart, Create, School, PersonOutline} from '@material-ui/icons';


class PeaMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const user =  sessionStorage.getItem("uname");
    const role =  sessionStorage.getItem("urole");
  
    return (
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">
                
                
            </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="text-success"><PersonOutline /></i>
				<span className="badge badge-pill badge-success float-right">
                  Online
                </span>
                <span>{user}</span>
              </Link>
            </li>
			<hr />   
          
             <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="text-success"><Home /></i>
                
                <span>Dashboard</span>
              </Link>
            </li>
			<li>
			  <Link to="/#" className="has-arrow waves-effect">
				<i className="text-success"><Place /></i>
				<span>School Visit</span>
			  </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/inspection_action_plans"><i className="text-success"><Assessment /></i>Action Plans</Link>
              </li>
				<li>
				  <Link to="/inspection_visits"><i className="text-success"><Assessment /></i>Inspection Visits</Link>
				</li>
					<li>
							<Link to="/followup_visits"><i className="text-success"><Assessment /></i>Followup Inspection</Link>
						</li>
				
			  </ul>
			</li>
			
			<li>
			  <Link to="/#" className="has-arrow waves-effect">
				<i className="text-success"><Assessment /></i>
				<span>Reports</span>
			  </Link>
			  <ul className="sub-menu" aria-expanded="false">
				<li>
				  <Link to="/perf_report"><i className="text-success"><Score /></i>Performance Report</Link>
				</li>
				<li>
				  <Link to="/advisory_visits"><i className="text-success"><ShowChart /></i>Progress Report</Link>
				</li>
				<li>
				  <Link to="/activity_progress_filter"><i className="text-success"><Score /></i>Action Plans</Link>
				</li>
			  </ul>
				
			</li>
			
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="text-success"><Details /></i>
                <span>Configured data</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                
				
                <li>
                  <Link to="/schools"><i className="text-success"><List /></i>Schools</Link>
                </li>
              </ul>
             </li>
            {
			 /*  {
			 /*  
				<li>
					  <Link to="/#" className="has-arrow waves-effect">
						<i className="text-success"><Settings /></i>
						<span>System Settings</span>
					  </Link>
					  <ul className="sub-menu" aria-expanded="false">
							<li>
							  <Link to="/users"><i className="text-success"><People /></i>System Users</Link>
							</li>
						  </ul> 
				</li>
			*/
			  }
            
            
          </ul>
        </div>
      );
  }
}

export default PeaMenu;
