import React, { useState, useEffect } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Table, TableBody, TableHead,Container, Row, FormFeedback, Media, Col,Modal, ModalHeader, ModalBody, ModalFooter,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import KeyEvidenceStatusTable from './KeyEvidenceStatusTable';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import PDF, { Text, AddPage, Line, Image, Html } from 'jspdf-react';
import axios from 'axios';
import swal from 'sweetalert';

import poor from "../../../assets/images/poor.png";
import vgood from "../../../assets/images/vgood.png";
import excellent from "../../../assets/images/excellent.png";
import good from "../../../assets/images/good.png";

export default function TrackEvidenceAlgo(props){
	const level_one_pct = props.level_four_pct1;
	const level_two_pct = props.level_two_pct2;
	const level_three_pct = props.level_three_pct3;
	const level_four_pct = props.level_four_pct4;
	const TrackEvidence = props.track_evidence;
	const KeyEvidence = props.key_evidence;
	const nes_id = props.nes_id;
	const nes_name = props.nes_name;
	const visit_id = props.visit_id;
	const LevelAchieved = props.LevelAchieved;
	const MinorRecommendation = props.minor_recommendation;
	
	return(
	<div>
		
		{
			TrackEvidence.map(evidence=>(
					(evidence['nes_id']==nes_id && evidence['visit_id']==visit_id) &&
													
							(
							<div>
							    <h4><strong><u>{nes_name}</u></strong></h4>
								<br/>
								
								
								{
									LevelAchieved.map(level=>(	
										<span>
										{
											(level.level==1 && level.nes_id==nes_id) &&
													(
												   
													   <p>
													   
													   <img src={poor} className="img img-circle" alt="poor" width="30" height="30" />
													   <strong>This NES Does not meet minimum standards</strong>
													   </p>
													 )
										}
										{
												(level.level==2 && level.nes_id==nes_id) &&
												(
											   
												   <p>
												   
													<img src={good} className="img img-circle" alt="good" width="30" height="30" />
												   <strong>This NES Meets minimum standards</strong></p>
												 )
										}
										{
											 
												(level.level==3 && level.nes_id==nes_id) && 
												
													(
										  
													   <p>
													   
													   <img src={vgood} className="img img-circle" alt="vgood" width="30" height="30" />
													   <strong>This NES Exceeds minimum standards</strong></p>
													 )
										}
										{
											
												(level.level==4 && level.nes_id==nes_id) && 
												(
									   
												   <p>
												   <img src={excellent} className="img img-circle" alt="excellent" width="30" height="30" />
												   <strong>This NES have an Effective Practice</strong></p>
												 )
										}
										</span>	
										)
									)
								}
								
								
								
								
								<span>
									<KeyEvidenceStatusTable key_evidence={KeyEvidence} visit_id={visit_id} nes_id={nes_id} minor_recommendation={MinorRecommendation} />
								</span>
							</div>
							)
			))
		}
		
	</div>
	)
}