import React, { Component } from "react";
import SettingMenu from "../SettingMenu";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';
import swal from 'sweetalert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];


//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class CriticalIssues extends Component {
  constructor(props) {
    super(props);
	const visit_id=this.props.match.params.visit_id;
	const isExpired = this.props.match.params.isExpired;
	const logged_user_id = this.props.match.params.logged_user;
	const lead_inspector_id = this.props.match.params.lead_inspector;
	const first_inspector_id = this.props.match.params.first_inspector;
	
	
    this.state = {

      //Get logged user role
	  role:window.sessionStorage.getItem("urole"),
	  modalVisible:false,
      EditModalVisible:false,
	  visit_id :visit_id,
	  isExpired: isExpired,
	  logged_user_id: logged_user_id,
	  lead_inspector_id:lead_inspector_id,
	  first_inspector_id:first_inspector_id,
	  critical_description:'',
	  nes_id :'',
      bearer_auth:window.sessionStorage.getItem('token'),
	  Weaknesses:[],
	  Standards:[],
	  CriticalIssues: [],
	  Visit: {},
	  loading: '',
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	this.addCriticalIssues = this.addCriticalIssues.bind(this);
	this.editCriticalIssues= this.editCriticalIssues.bind(this);
	this.updateCriticalIssues = this.updateCriticalIssues.bind(this);
	this.deleteCriticalIssues =this.deleteCriticalIssues.bind(this);
	;
	
	this.setId = this.setId.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({
		 modalVisible:false,
		 critical_description:'',
		 nes_id :'',
		 NesIdState:'',
		 CriticalIssueIdState:'',
		 
	  }); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisible:true});
    }else{
     this.setState({
		 EditModalVisible:false,
		 critical_description:'',
	     nes_id :'',
		 CriticalIssueIdState:'',
		 NesIdState:'',
		 
		 }); 
    }
  }
  
  //Setting Edit ID
  setId(e){
		 this.setState({
			 [e.target.id]: e.target.value
		 });
	 }
  
  //Edit user 
  editCriticalIssues(id) {
	
	//this.setState({loading : true});
	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/critical_issues/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].critical_issue_id,
		  critical_issue_description : response.data[0].critical_issue_description,
		  visit_id : response.data[0].visit_id,
		  EditModalVisible:true,
		  //loading : false
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
  
  //Update 
	  updateCriticalIssues(event){
		event.preventDefault()

        const { history } = this.props
        const id = this.state.id
		
        const critical_issue = {
		  critical_issue_id : this.state.critical_issue_id,
		  critical_issue_description : this.state.critical_issue_description,
		  visit_id : this.state.visit_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/critical_issues/update/${id}`, critical_issue,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				EditModalVisible:false, 
				//loading : false
				});
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if(response.data['status']=='success'){
					this.setState({
						EditModalVisible:false, 
					//loading : false,
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  timer:2500,
					  buttons: false
					})
					axios.get(API_URL + `/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
									{ headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
									).then(
									(visit) => {
										this.setState({
										  CriticalIssues: visit.data
									 })
							}
						)
				}else{
					this.setState({
						EditModalVisible:true, 
					});
					swal({
					  title: "",
					  text: response.data['message'],
					  icon: response.data['status'],
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
				}
          })
          .catch(error => {
            this.setState({
				EditModalVisible:false, 
				//loading : false
				});
			  if(error.response.status=='401'){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						this.setState({EditModalVisible:true});
					});
			  }
          }) 
	 }
	 
	 //delete
	deleteCriticalIssues(id){
			//Confirming deletion
			swal({
					  title: "",
					  text: "Are you sure to delete the critical issue?",
					  icon: "warning",
					  dangerMode: true,
					  buttons: {
					  confirm: {
						  text: "Delete",
						  value: true,
						  visible: true,
						  className: "",
						  closeModal: true
						},
						cancel: {
						  text: "Cancel",
						  value: false,
						  visible: true,
						  className: "",
						  closeModal: true,
						}
					  }
					})
					.then((willDelete) => {
					  //Confirmed condition
					  if(willDelete){
							//this.setState({loading : true});
							//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
							axios.delete(API_URL + `/critical_issues/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
								//this.setState({loading : false});
								
								swal({
								  title: "",
								  text: "The critical issue record Deleted Successfully!",
								  icon: "success",
								  timer:2500,
				  buttons: false
				})
				axios.get(API_URL + `/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					            { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					            ).then(
						        (visit) => {
							        this.setState({
							  	    CriticalIssues: visit.data
							     })
						}
					)
							}).catch(error => {
								  if(error.response.status=='401'){
											swal({
											  title: "",
											  text: "Your session has expired. You'll automatically be logged out within seconds.",
											  icon: "warning",
											  timer : 2000
											})
											.then((sucess) => {
												window.sessionStorage.clear();
												window.location.reload();
											});
									  }else{
										  swal({
											  title: "",
											  text: error.name + ': ' + error.message,
											  icon: "error",
											  button:true
											})
											.then((sucess) => {
												//window.location.reload();
											});
									  }
							  });
					  //unconfirmed condition
					  } else {
						  //window.location.reload();
					  }
			});
	}
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	  Promise.all([
		  axios.get(API_URL + `/critical_issues/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		  axios.get(API_URL + `/national_standards`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }),
		  axios.get(API_URL + `/inspection_visits/${this.state.visit_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
	  ]).then(
	 ([critical_issue, standard, visit])=>{
		 this.setState({
			 CriticalIssues:critical_issue.data,
			 Standards: standard.data,
			 Visit: visit.data[0],
			 loading : false
			 
		 })
	 }
	 ).catch(error => {
			  this.setState({loading : false});
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: error.name + ': ' + error.message,
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          })
  }
	
  //Add user
	 addCriticalIssues(event){
		event.preventDefault()

        const { history } = this.props

        const critical_issue = {
		  critical_issue_description : this.state.critical_issue_description,
		  visit_id : this.state.visit_id,
		  
        }
		
		//this.setState({loading : true});
		
        //console.log(user);
		//console.log(exam);
        //axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.post(API_URL + '/critical_issues', critical_issue,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
          .then(response => {
            this.setState({
				modalVisible:false, 
				//loading : false
				});
			//Conditional based on backed responses . Introduced for error handling via message passing with REST
			if(response.data['status']=='success'){
				this.setState({
					modalVisible:false, 
				//loading : false,
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  timer:2500,
				  buttons: false
				})
				axios.get(API_URL + `/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id, 
					            { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					            ).then(
						        (visit) => {
							        this.setState({
							  	    CriticalIssues: visit.data
							     })
						}
					)
			}else{
				this.setState({
					modalVisible:true, 
				});
				swal({
				  title: "",
				  text: response.data['message'],
				  icon: response.data['status'],
				  button:true
				})
				.then((sucess) => {
					//window.location.reload();
				});
			}          })
          .catch(error => {
            this.setState({
				modalVisible:true, 
				//loading : false
				});
				
				if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: error.name + ': ' + error.message,
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          }) 
	 }


  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
    if(e.target.name==='critical_issue_description'){
		   if(e.target.value!=''){
			   this.setState({CriticalIssueIdState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({CriticalIssueIdState :'has-danger'});
		   }
	   
    }
	
        
  }

  render() {
    const {CriticalIssues, Standards} = this.state;
    const data = {
      columns: [
        /* { 
          title: 'ID',
           field: 'critical_issue_id' 
        }, */
        {
          title: 'Description',
          field: 'critical_issue_description',
        },
        {
          title: 'Actions',
          field: 'OPTIONS',
		  export : false,

        },
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">critical issue</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Critical issue Management</h3>
                <hr/>
                <Button hidden={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?true:false} color="success" onClick={this.toggle}> {<Add />} New Critical issue</Button>
                <hr/>

                {
                      //Add user modal
						/*
							NOTE the usage of the onChange and onBlur event to call the same function.
							The onBlur event has been used to enforce validation once the user move out of the field.
						*/
                }
                <Modal isOpen={this.state.modalVisible}>
                        <Form onSubmit={this.addCriticalIssues} >
                          <ModalHeader toggle={this.toggle}>
                            <center className="justify-content-center">CRITICAL ISSUE ADDITION FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                
                                
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="critical_issue_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea" name="critical_issue_description" id="critical_issue_description" invalid={this.state.CriticalIssueIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Create</Button>
                            <Button color="danger" onClick={this.toggle}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                 
                  
                  <Table responsive striped size="lg">
                      <MaterialTable
                          title={`CRITICAL ISSUES AT ${this.state.Visit.school_name  || ''} ${this.state.Visit.sector_name || ''}`}
                          columns={data.columns}
                          data={
								CriticalIssues.map(critical_issue=>(	
									(this.state.role!="Inspector") ?
									({
									  
									  //critical_issue_id : critical_issue.critical_issue_id,
									  critical_issue_description :critical_issue.critical_issue_description,
									 'OPTIONS': <pre><Link className="btn disabled" to={`/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editCriticalIssues(critical_issue.critical_issue_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className="btn disabled" to={`/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteCriticalIssues(critical_issue.critical_issue_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									})
									
									:
									({
									  
									  //critical_issue_id : critical_issue.critical_issue_id,
									  critical_issue_description :critical_issue.critical_issue_description,
									 'OPTIONS': <pre><Link className={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?"btn disabled":"btn"} to={`/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.editCriticalIssues(critical_issue.critical_issue_id)} ><span className='text-success' ><Edit /></span></Link>&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id!=this.state.lead_inspector_id) && (this.state.logged_user_id!=this.state.first_inspector_id || this.state.isExpired==true))?"btn disabled":"btn"} to={`/critical_issues/` + this.state.visit_id + "&" + this.state.isExpired + "&" + this.state.logged_user_id + "&" + this.state.lead_inspector_id + "&" + this.state.first_inspector_id} onClick={()=>this.deleteCriticalIssues(critical_issue.critical_issue_id)} ><span className="text-danger" ><Delete /></span></Link></pre>
									})
									
						  ))}      
                          
						  options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </Table>

                  {
                    //Edit user record modal
                  }
                  <Modal isOpen={this.state.EditModalVisible}>
                        <Form onSubmit={this.updateCriticalIssues} >
                          <ModalHeader toggle={this.toggleEdit}>
                            <center className="justify-content-center">CRITICAL ISSUE EDITING FORM</center>
                          </ModalHeader>
                          <ModalBody>
                                <FormGroup>
									 <Input type="text" name="id" id="id" defaultValue={this.state.id} onLoad={this.setId} readOnly hidden />
								  </FormGroup>
								 
                                
                                
								 
                                <FormGroup>
                                  <Row>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                      <Label for="critical_issue_description">Description&nbsp;<i className="text-danger">*</i>&nbsp;</Label>					 
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
									  <Input type="textarea"  defaultValue={this.state.critical_issue_description} name="critical_issue_description" id="critical_issue_description" invalid={this.state.CriticalIssueIdState==='has-danger'} onChange={this.handleInput} onBlur={this.handleInput}required={true} />
									  <FormFeedback invalid>
										  The field is required.
									 </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                            
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="success">Update</Button>
                            <Button color="danger" onClick={this.toggleEdit}>Cancel</Button>
                          </ModalFooter>
                        </Form>
                </Modal>
                  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default CriticalIssues;
