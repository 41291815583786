import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import backend_url from "../../../routes/API_URL";
import moment from "moment"

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];

class DivisionalProgressGraph extends Component {
   constructor(props) {
    super(props);
	const district_id=this.props.district_id;
	
	const end_date = new Date().getFullYear();
	const start_date = end_date-3;
	
	   this.state = {
		   header: props.header ? props.header : 'years',
	  GraphicalData:[],
	  bearer_auth:window.sessionStorage.getItem('token'),
	  district_id: district_id,
	  district_name:'',
	  start_date: props.start ? new Date(props.start).getFullYear() : start_date,
	  end_date: props.end ? new Date(props.end).getFullYear() : end_date,
	 start: moment(props.start).startOf('year').format("YYYY-MM-DD"),
		end: moment(props.end).endOf('year').format("YYYY-MM-DD"),
	  level: props.level
	  //Update controllers
    };
    
  }
  
  componentDidMount () {
	 
	  Promise.all([axios.get(API_URL + `/progress_report/district/${this.state.district_id}?start_date=${this.state.start}&end_date=${this.state.end}&level=${this.state.level}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/districts/${this.state.district_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([g_data, district_data])=>{
		 this.setState({
			 GraphicalData:g_data.data,
			 district_name: (district_data.data.length>=1)?district_data.data[0]['district_name']:'Empty',
		 });
		 
		 //console.log(g_data);
	 }
	 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the graphical data. You should rety..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  
  componentDidUpdate (prevProps) {
	 
	 if(this.props.district_id != prevProps.district_id){
		 Promise.all([axios.get(API_URL + `/progress_report/district/${this.props.district_id}?start_date=${this.state.start}&end_date=${this.state.end}&level=${this.state.level}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/districts/${this.props.district_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
			 ([g_data, district_data])=>{
				 this.setState({
					 GraphicalData:g_data.data,
					 district_name: (district_data.data.length>=1)?district_data.data[0]['district_name']:'Empty',
				 });
				 
				 //console.log(g_data);
			 }
			 ).catch(error => {
					  if(error.response.status=='401'){
								swal({
								  title: "",
								  text: "Your session has expired. You'll automatically be logged out within seconds.",
								  icon: "warning",
								  timer : 2000
								})
								.then((sucess) => {
									window.sessionStorage.clear();
									window.location.reload();
								});
						  }else{
							  swal({
								  title: "",
								  text: "Failed to load the graphical data. You should retry..",
								  icon: "error",
								  button:true
								})
								.then((sucess) => {
									//window.location.reload();
								});
						  }
				  })
	 }
	 
  }
  
  
  render() {
    const {GraphicalData} = this.state;
	//const label = [];
	//const serie = [];
	//console.log(graphical_data);
	
	//Find the frequency of elements in an array/object
	const entryFrequency = (obj) =>{
		const level_freq = {};
		
		obj.forEach(idx=>{
			if(level_freq[idx]){
				level_freq[idx]++;
			}else{
				level_freq[idx] = 1;
			}
		});
		
		return level_freq
	}
	
	const years = [];
	const level_data = [];
	
	
	//Capture the unique division names. You may altrenatively use the set() object 
	for(let i=0;i<GraphicalData.length;i++){
		let formated_date= GraphicalData[i]['DATE'];
		
		if(i==0 && this.state.end_date>=this.state.start_date && (formated_date>=this.state.start_date && formated_date<=this.state.end_date)){
			years.push(formated_date);
			//console.log(formated_date);
		}else if(i>=1 && years.includes(formated_date)==false && this.state.end_date>=this.state.start_date && (formated_date>=this.state.start_date && formated_date<=this.state.end_date)){
			years.push(formated_date);
			//console.log(formated_date);
		}else{
			continue;
		}
	}
	
	let level_one_data = [];
	let level_two_data = [];
	let level_three_data =[];
	let level_four_data = [];
	
	//Default all level_*_data based on the length of the divisions
	
	for(let idx=0;idx<years.length;idx++){
		level_one_data.push(0);
		level_two_data.push(0);
		level_three_data.push(0);
		level_four_data.push(0);
	}
	
	/**
	Come up a dynamic series' data
	**/
	
	
	//Loop through the divisions
	for(let m=0;m<years.length;m++){
		
		const uncategorised_levels = [];
		//Loop through the data from the database 
		for(let n=0;n<GraphicalData.length; n++){
			
			let formated_date= GraphicalData[n]['DATE'];
			//First condition
			
			
			
			if(years[m]==formated_date && this.state.end_date>=this.state.start_date && (formated_date>=this.state.start_date && formated_date<=this.state.end_date)){
				uncategorised_levels.push(GraphicalData[n]['level_achieved']);
			}
		}
		
		const categorised_levels = entryFrequency(uncategorised_levels);
		let total_level_data = 0;
		
		for(const v in categorised_levels){
			total_level_data+=categorised_levels[v];
		}
	    
		
		//console.log(categorised_levels);
		//console.log(total_level_data);
		
		//const cat_length = categorised_levels.length;
		
		//length 
		let cat_length=0;
		
		for(const idx in categorised_levels){
			cat_length+=1;
		}
		
		//console.log(cat_length);
		//Map data to levels while balancing up the lengths
		for(const idx in categorised_levels){
			
			if(idx=='1'){
				level_one_data[m]=parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='2'){
				level_two_data[m]= parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='3'){
				level_three_data[m]=parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='4'){
				level_four_data[m] =parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}
			
			
			
		}
		
		
	}
	
	
	/* console.log("Level 1: ");
	console.log(level_one_data);
	
	console.log("Level 2: ");
	console.log(level_two_data);
	
	console.log("Level 3: ");
	console.log(level_three_data);
	
	console.log("Level 4: ");
	console.log(level_four_data); */
	
			
	const series = [{
	  name: 'Level 1',
	  data: level_one_data
	}, {
	  name: 'Level 2',
	  data: level_two_data
	}, {
	  name: 'Level 3',
	  data: level_three_data
	}, {
	  name: 'Level 4',
	  data: level_four_data
	},];
	
	
	
	const options = {
	  chart: {
		type: 'bar',
		height: 500,
		stacked: true,
		stackType: '100%'
	  },
	  dataLabels: {
		  enabled: false,
		  enabledOnSeries: undefined,
		  formatter: (value) => value.toFixed(2) +'%',
	  },
	  colors: ['#ff0000', '#0018ff', '#fffc00','#1bb60b'],
	  plotOptions: {
		bar: {
		  horizontal: false,
		},
	  },
	  stroke: {
		width: 1,
		colors: ['#fff']
	  },
	  title: {
		  text: this.state.header && this.state.header == 'dates' ? `${this.state.district_name} District Progress Report from ${this.state.start} to ${this.state.end}` : this.state.district_name + ` District Progress Report from ${this.state.start_date} to ${this.state.end_date}  (${this.state.end_date - this.state.start_date + 1} Year(s))`,
		align: 'center',
		margin: 10,
		offsetX: 0,
		offsetY: 0,
		floating: false,
		style: {
		fontSize:  '12px',
		fontWeight:  'bold',
		fontFamily:  undefined,
		color:  '#263238'
		}
	  },
	  xaxis: {
		categories: years,
		title: {
				text: 'INSPECTION YEAR(S)'
			},
	  },
	  yaxis: {
		  forceNiceScale: false,
		  title: {
				text: 'PERCENTAGE OF SCHOOLS'
		  },
		  max: 100,
		  labels: {
			formatter: (value) => value.toFixed(0) +'%',
		  },
		},
	  tooltip: {
		y: {
		  formatter: (value) => value.toFixed(2) +'%',
		}
	  },
	  fill: {
		opacity: 1,
		colors: ['#ff0000', '#0018ff', '#fffc00','#1bb60b']
	  },
	  legend: {
		position: 'bottom',
	  }
	}
    return (
      <React.Fragment>
        <div className="colxs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<div className="card">
				<ReactApexChart id='chart' options={options} series={series} type="bar" height='500' />
			</div>
		</div>
		
		
      </React.Fragment>
    );
  }
}

export default DivisionalProgressGraph;
