import React from 'react'
import { Row, Col, Button, Table, Media, Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import Loader from "../../../components/Loader";
import logo from "../../../assets/images/gov-logo.png";
import axios from "../../../services/axios";
import swal from 'sweetalert';
import NationalProgressGraph from './NationalProgressGraph';
import { ArrowLeft } from "@material-ui/icons"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
import {
    useParams
} from "react-router-dom";

function ExecutiveSummaryReport(props) {

    const [loading, setloading] = React.useState(true)

    const [visit, setVisit] = React.useState({})
    const [followupVisit, setFollowupVisit] = React.useState({})
    const [nationalStandards, setNationalStandards] = React.useState({})
    const [firstInspector, setFirstInspector] = React.useState({})
    const [secondInspector, setSecondInspector] = React.useState({})
    const [thirdInspector, setThirdInspector] = React.useState({})
    const [fourthInspector, setFourthInspector] = React.useState({})
    const [minorRecommendations, setMinorRecommendations] = React.useState([])

    const { id, followupid } = useParams()


    const getData = async () => {
        axios.get(`inspection_visits/${id}`)
            .then(({ data }) => setVisit(data[0]))
        axios.get(`inspection_visits/${followupid}`)
            .then(({ data }) => setFollowupVisit(data[0]))
        axios.get(`/national_standards`)
            .then(({ data }) => setNationalStandards(data))
        axios.get(`/inspection_report/first_inspector/${id}`)
            .then(({ data }) => setFirstInspector(data[0]))
        axios.get(`/inspection_report/second_inspector/${id}`)
            .then(({ data }) => setSecondInspector(data[0]))
        axios.get(`/inspection_report/third_inspector/${id}`)
            .then(({ data }) => setThirdInspector(data[0]))
        axios.get(`/inspection_report/fourth_inspector/${id}`)
            .then(({ data }) => setFourthInspector(data[0]))
        axios.get(`recommendations/minor/${id}`)
            .then(({ data }) => setMinorRecommendations(data))
    }


    React.useEffect(() => {
        getData().then(_ => setloading(false));
    }, [])

    const generateWord = () => {
        var filename = 'FOLLOW UP INSPECTION REPORT'
        var element = 'report'
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html = preHtml + document.getElementById(element).innerHTML + postHtml;

        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });

        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

        // Specify file name
        filename = filename ? filename + '.doc' : 'document.doc';

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);

    }

    const printPDF = () => {
        window.print();
    }

    return (
        <div>
            <React.Fragment>
                <div className="container">
                    <Row className="position-center">
                        {loading && <Loader />}
                    </Row>

                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>

                                    <li className="breadcrumb-item active">Inspection Followup Report</li>
                                </ol>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="float-right d-none d-md-block">
                                <Link className="btn btn-primary " to={`/followup_visits/`} ><ArrowLeft />Back</Link>
                            </div>
                        </Col>
                    </Row>

                </div>
                <div className="container">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="card">
                                <div className="card-body">
                                    <hr />
                                    <Row>
                                        <Col style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                            <Button onClick={generateWord} className="btn btn-success pull-right"><FileDownloadIcon /></Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Table className='table table-responsive' id='report' borderless responsive>
                                        <thead>
                                            <tr>
                                                <th md={5} className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"></th>
                                                <th md={2} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                    <Media src={logo} />

                                                </th>
                                                <th md={5} className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5"></th>
                                            </tr>
                                            <tr>
                                                <th colSpan='3'>
                                                    <center><h2>{'FOLLOW UP INSPECTION REPORT'}</h2></center>
                                                </th>
                                            </tr>
                                            <br></br>
                                            <tr>
                                                <th ></th>
                                                <th md={2}></th>
                                                <td md={5}></td>
                                            </tr>
                                            <tr>
                                                <th >HEAD TEACHER</th>
                                                <th md={2}>:</th>
                                                <td md={5} > ...</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >ESTABLISHMENT</th>
                                                <th>:</th>
                                                <td > {visit.establishment_year}</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >POSTAL ADDRESS</th>
                                                <th>:</th>
                                                <td > {visit.school_address}</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >FULL INSPECTION DATE</th>
                                                <th>:</th>
                                                <td > {visit.present_visitation_date}</td>
                                            </tr><br></br>
                                            <tr >
                                                <th>FOLLOW UP INSPECTION DATE</th>
                                                <td>:</td>
                                                <td > {followupVisit.present_visitation_date}</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >ENROLMENT</th>
                                                <th>:</th>
                                                <td > ...</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >QUALIFIED TEACHERS</th>
                                                <th>:</th>
                                                <td >...</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >UNQUALIFIED TEACHERS</th>
                                                <th>:</th>
                                                <td > ...</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >LEAD INSPECTOR</th>
                                                <th>:</th>
                                                <td > {firstInspector.full_name}</td>
                                            </tr><br></br>
                                            <tr>
                                                <th >INSPECTION TEAM</th>
                                                <th>:</th>
                                                <td >

                                                    {'1. ' + firstInspector.full_name}<br />
                                                    {'2. ' + secondInspector.full_name}<br />
                                                    {thirdInspector ? '3. ' + thirdInspector.full_name : '3. None'}<br />
                                                    {fourthInspector ? '4. ' + fourthInspector.full_name : '4. None'}<br />

                                                </td>
                                            </tr><br></br>                                           
                                            {/* <tr>
                                                <th >EXPECTED RE-INSPECTION DATE</th>
                                                <th>:</th>
                                                <td > ...</td>
                                            </tr><br></br> */}
                                            <tr>
                                                <td colSpan="3">

                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">

                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <td colSpan="10">
                                            </td>
                                            <tr>
                                                <td colSpan="3">
                                                    <h3><strong>1.0. BACKGROUND TO THE SCHOOL</strong></h3><br />
                                                    The school was established in {visit.establishment_year} and has an enrolment of ... students (... boys and ... girls). The school has ... teachers of which ... are males and ... are females; ... are qualified and ... unqualified. On the day of inspection, ... teachers were present, and ... students attended school. The community around the school earns its living mainly through small scale businesses as most people travel to South Africa in search for jobs and so they bring back commodities for sale. It is a single streamed school which has 6 classrooms of which one is used as a staffroom and has a temporary administration block. The Directorate of Quality Assurance Services (DQAS) conducted a follow-up visit to the school on {moment(followupVisit.present_visitation_date).format("LL")}. This was a follow up to the full inspection that was carried out on {moment(visit.present_visitation_date).format("LL")}.
                                                    <b></b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <h3><strong>2.0. THE PURPOSE OF FOLLOW UP VISIT AND THIS REPORT </strong></h3><br />
                                                    {visit.school_name} was supervised by a team of {fourthInspector ? '4' : thirdInspector ? '3' : '2'} officers led by {firstInspector.full_name}. The purpose of this follow up and support visit was to check whether the school fulfilled the action points that the external evaluators made during the inspection visit.

                                                    This will guide the school to improve its practice and provisions.As a result, students’ learning will improve and they will achieve better outcomes for their own benefit and for the benefit of the nation.
                                                    <b></b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <h3><strong>3.0. FOLLOW UP INSPECTION FINDINGS  </strong></h3><br />
                                                    The section summarises the conclusions on progress made on the action points followed by a discussion of the results of the findings. The results of teams’ conclusions on progress on the key action points during the follow-up and support visit have been summarised in Table 3.1.
                                                    <b></b>
                                                    <table style={{paddingTop: '5em'}} className='table table-bordered table-responsive'> 
                                                        <tr>
                                                            <th>NES</th>
                                                            <th>Minor Recommendation</th>
                                                            <th>Comments</th>
                                                            <th>Achieved</th>
                                                            <th>Partially achieved</th>
                                                            <th>Not achieved</th>
                                                        </tr>
                                                        {minorRecommendations &&
                                                            minorRecommendations.map((m) => {
                                                                m.ns = nationalStandards.find(ns => ns.nes_id == m.nes_id)
                                                                return m
                                                        }).map(nes => (
                                                            <tr>
                                                                <td>{nes.ns.nes_name}</td>
                                                                <td>{nes.recommendation_description}</td>
                                                                <td>{nes.followup_comments}</td>
                                                                <td>{nes.need_extra_followup == 'Achieved' ? '✅' : ''}</td>
                                                                <td>{nes.need_extra_followup == 'Partially Achieved' ? '✅' : ''}</td>
                                                                <td>{nes.need_extra_followup == 'Not Achieved' ? '✅' : ''}</td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3">
                                                    <h3><strong>4.0. OTHER ACTIONS POINTS DISCUSSED DURING THE FOLLOW UP INSPECTION VISIT</strong></h3><br />
                                                    <ul style={{paddingTop: '1em'}}>
                                                        {minorRecommendations.length > 0 && 
                                                            minorRecommendations[0].extra_comments
                                                        }
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </div>
    )
}

export default ExecutiveSummaryReport